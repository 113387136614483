import { TreeSelectItemType } from '@vision/ui/interfaces';

export function convertToTree<T>(list: T[], callback: (item: T) => TreeSelectItemType): TreeSelectItemType[] {
  const mappedData: Record<string, TreeSelectItemType> = {};
  const treeSelectItems: TreeSelectItemType[] = [];

  list.forEach((item) => {
    const treeSelectItem: TreeSelectItemType = callback(item);
    mappedData[treeSelectItem.id] = treeSelectItem;
  });

  Object.values(mappedData).forEach((treeItem) => {
    const parent = mappedData[treeItem.parentId || -1];
    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(treeItem);
    } else {
      treeSelectItems.push(treeItem);
    }
  });

  return treeSelectItems;
}

export function convertToList(treeItem: TreeSelectItemType): TreeSelectItemType[] {
  const treeItemsToCheck: TreeSelectItemType[] = [treeItem];
  const flatTreeItems: TreeSelectItemType[] = [];

  while (treeItemsToCheck.length > 0) {
    const item: TreeSelectItemType = {
      ...treeItemsToCheck.pop(),
    };

    if (item.children.length > 0) {
      item.children.forEach((childItem) => {
        treeItemsToCheck.push(childItem);
      });
    }

    item.children = [];

    flatTreeItems.push(item);
  }

  return flatTreeItems;
}

export function findItemFromTree(treeItem: TreeSelectItemType[], id: string): TreeSelectItemType | undefined {
  const stack: TreeSelectItemType[] = [...treeItem];

  while (stack.length > 0) {
    const item = stack.pop();

    if (item.id === id) {
      return item;
    }

    if (item.children.length > 0) {
      item.children.forEach((childItem) => {
        stack.push(childItem);
      });
    }
  }

  return undefined;
}
