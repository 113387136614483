import type * as charts from 'echarts/charts';
import type * as components from 'echarts/components';
import React from 'react';
import { ECExtensions, KnownRegisteredSeriesOption, Values } from './ECharts.types';

export const optionCreator =
  <T>(key: string, defaultProps?: T) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, ...props }: React.PropsWithChildren<T>) => ({ [key]: { ...defaultProps, ...props } });

export const seriesOptionCreator =
  <T extends Values<KnownRegisteredSeriesOption>>(type: keyof KnownRegisteredSeriesOption, defaultProps?: T) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, ...props }: React.PropsWithChildren<T>) => ({
    series: { ...defaultProps, ...props, type },
  });

export const chartLoader = (names: (keyof typeof charts)[]) => async () => {
  const module = await import('echarts/charts');

  return names.map((name) => module[name]);
};

export const componentLoader = (names: (keyof typeof components)[]) => async (): Promise<ECExtensions> => {
  const module = await import('echarts/components');

  return names.map((name) => module[name]);
};
