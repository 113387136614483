import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { Department } from '@vision/ui/interfaces';
import { axiosBaseQuery } from './base.service';

export const DepartmentsService = createApi({
  reducerPath: 'DepartmentsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDepartments: builder.query<Department[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: '/v1/departments',
        method: HttpMethod.GET,
        params: {
          account_id: accountId,
        },
      }),
    }),
  }),
});

export const { useGetDepartmentsQuery } = DepartmentsService;
