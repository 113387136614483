import { ReportTimeIntervalType } from '@vision/ui/enums';
import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { TrendChart1CompareRenderer } from '../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../default-chart.config';
import { TrendChart1Measures } from './trend-chart1.measures';

export const TrendChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: TrendChart1Measures,
  compareRendererComponent: TrendChart1CompareRenderer,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    timeInterval: ReportTimeIntervalType.DAY,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: true,
      displayFormat: 'auto',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      displayFormat: 'auto',
      position: 'top',
    },
    serieDot: {
      active: false,
      symbolSize: 6,
      symbolType: 'circle',
      symbolFilling: 'full',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};

/*selectedQuestionFlowIds: array()
      .of(string())
      .when('selectedCompare', {
        is: 'question-title',
        then: (field) => field.min(1, 'validation:rules.generic.required'),
      }),
    selectedQuestionIds: array()
      .of(string())
      .when('selectedCompare', {
        is: 'question-title',
        then: (field) => field.min(1, 'validation:rules.generic.required'),
      }),
    selectedQuestionCategoryIds: array()
      .of(string())
      .when('selectedCompare', {
        is: 'question-category-name',
        then: (field) => field.min(1, 'validation:rules.generic.required'),
      }),
    selectedQuestionKeyIds: array()
      .of(string())
      .when('selectedCompare', {
        is: 'question-key',
        then: (field) => field.min(1, 'validation:rules.generic.required'),
      }),
    selectedQuestionOptionIds: array()
      .of(string())
      .when('selectedCompare', {
        is: 'question-option',
        then: (field) => field.min(1, 'validation:rules.generic.required'),
      }),*/
