import { ReportPermission } from '@vision/ui/enums';
import { ReportPermissionsDetailItem, ReportsPermissionsTableRow } from '@vision/ui/interfaces';

export function sortPermissionsByName(a: ReportsPermissionsTableRow, b: ReportsPermissionsTableRow) {
  return (a.name ?? '').localeCompare(b.name ?? '');
}

export function checkReportPermission(permissions: ReportPermissionsDetailItem[], codename: ReportPermission) {
  return permissions.some((permission) => permission.codename === codename);
}

export function createDefaultReportPermission(id: string, name: string): ReportsPermissionsTableRow {
  return {
    id,
    name,
    canCopy: false,
    canDelete: false,
    canEdit: false,
    canShare: false,
    canView: true,
  };
}

export function mapReportPermissions(
  id: string,
  name: string,
  permissions: ReportPermissionsDetailItem[],
): ReportsPermissionsTableRow {
  return {
    id,
    name,
    canCopy: checkReportPermission(permissions, ReportPermission.COPY),
    canDelete: checkReportPermission(permissions, ReportPermission.DELETE),
    canEdit: checkReportPermission(permissions, ReportPermission.UPDATE),
    canShare: checkReportPermission(permissions, ReportPermission.SHARE),
    canView: checkReportPermission(permissions, ReportPermission.SHOW),
  };
}
