import React from 'react';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type FilterClearAllProps = ButtonProps;

const _FilterClearAll = React.forwardRef<HTMLButtonElement, FilterClearAllProps>(
  ({ children, ...props }: React.PropsWithChildren<FilterClearAllProps>, ref) => {
    const { t } = useTranslation();
    return (
      <Button ref={ref} variant="subtle" size="xs" px={4.5} {...props}>
        {children || t('clearAll')}
      </Button>
    );
  },
);

export const FilterClearAll = createPolymorphicComponent<'button', FilterClearAllProps>(_FilterClearAll);
