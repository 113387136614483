import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ContentCategory } from '../interfaces';
import { axiosBaseQuery } from './base.service';

export const ContentCategoryService = createApi({
  reducerPath: 'ContentCategoryService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getContentCategories: builder.query<ContentCategory[], { accountId: string; status?: string }>({
      query: ({ accountId, status = 'active' }) => ({
        url: `/v1/accounts/${accountId}/content_categories`,
        method: HttpMethod.GET,
        params: {
          status,
        },
      }),
    }),
  }),
});

export const { useGetContentCategoriesQuery } = ContentCategoryService;
