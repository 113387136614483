import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  ContactCustomer,
  CreateCustomerRequest,
  CustomersImportFromCsvRequest,
  DynamicFilterOption,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const CustomersService = createApi({
  reducerPath: 'CustomersService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    customers: builder.query<ApiResponse<ContactCustomer[]>, { query: string; accountId: string }>({
      query: ({ accountId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${accountId}/customers`, query),
        method: HttpMethod.GET,
      }),
    }),
    //TODO: @yavuz move to VisionResourcesService
    dynamicFilterOptions: builder.query<ApiResponse<DynamicFilterOption[]>, string>({
      query: (url: string) => ({
        url,
        method: HttpMethod.GET,
      }),
    }),
    createCustomer: builder.query<void, CreateCustomerRequest>({
      query: (data) => ({
        url: '/v1/customers',
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.contacts.createContact.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    customersImportFromFile: builder.query<void, { nodeId: string; data: CustomersImportFromCsvRequest }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/customers/import_from_file`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    customersExportAsCsv: builder.query<
      void,
      {
        excelFormat?: boolean;
        nodeId: string;
        query: string;
      }
    >({
      query: ({ excelFormat = false, nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/customers/export_as_csv`, query),
        method: HttpMethod.GET,
        params: {
          excel_format: excelFormat,
          node_id: nodeId,
        },
      }),
    }),
  }),
});

export const {
  useDynamicFilterOptionsQuery,
  useLazyCreateCustomerQuery,
  useLazyCustomersExportAsCsvQuery,
  useLazyCustomersImportFromFileQuery,
  useLazyCustomersQuery,
} = CustomersService;
