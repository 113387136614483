import { flexRender, Header } from '@tanstack/react-table';
import { DataGridColumnSorter } from '@vision/ui/components';
import clsx from 'clsx';
import classes from './DataGridColumnHeader.module.scss';

interface DataGridColumnHeaderProps<TData> {
  ellipsis: boolean;
  header: Header<TData, any>;
  isLast: boolean;
}

export function DataGridColumnHeader<TData>({ ellipsis, header, isLast }: DataGridColumnHeaderProps<TData>) {
  const ColumnHeader = () => {
    return flexRender(header.column.columnDef.header, header.getContext());
  };

  if (header.isPlaceholder) {
    return null;
  }

  return (
    <>
      <div
        className={clsx({
          [classes.ellipsis]: ellipsis,
        })}
      >
        {ColumnHeader()}
      </div>
      <div className={classes.dataGridColumnHeaderButtons}>
        {header.column.getCanSort() && <DataGridColumnSorter column={header.column} />}
      </div>
      {!isLast && header.column.getCanResize() && (
        <div
          className={clsx('data-grid-header-resizer', classes.resizer)}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
        />
      )}
    </>
  );
}
