import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '@vision/ui/interfaces';
import { AccountService } from '@vision/ui/services';

export interface AuthSsoStateType {
  isSsoPage: boolean;
  ssoAccount: Account;
}

const initialState: AuthSsoStateType = {
  isSsoPage: false,
  ssoAccount: null,
};

export const AuthSsoState = createSlice({
  name: 'AuthSsoState',
  initialState,
  reducers: {
    clearAuthSsoStateAction: () => initialState,
    updateIsSsoPageAction: (state, action: PayloadAction<boolean>) => {
      state.isSsoPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(AccountService.endpoints.accountFromCode.matchFulfilled, (state, { payload }) => {
      state.ssoAccount = payload;
    });
  },
});

export const { clearAuthSsoStateAction, updateIsSsoPageAction } = AuthSsoState.actions;
