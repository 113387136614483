import { VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetCustomerCompaniesAsListQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareCompanyProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareCompany({ required }: ReportsDetailGraphicCompareCompanyProps) {
  const { t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const { data: companiesResponse, isFetching } = useGetCustomerCompaniesAsListQuery({
    nodeId: accountId,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(companiesResponse?.data, (item) => ({
        value: item.id,
        label: item.label,
      })),
    [companiesResponse],
  );

  return (
    <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.customer-company')}>
      <VMultiSelect
        id="report-compare-company"
        data-testid="input-report-compare-company"
        label={t('company')}
        data={selectItems}
        placeholder={isFetching ? t('loading') : t('search')}
        loading={isFetching}
        isValuesPillLoading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedCompanyIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedCompanyIds', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedCompanyIds')}
        withAsterisk={required}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
