import { faCloudExclamation, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, useMantineTheme } from '@mantine/core';
import { useDidUpdate, useTimeout } from '@mantine/hooks';
import {
  CellContext,
  ColumnDef,
  ColumnSizingInfoState,
  ColumnSizingState,
  Row,
  RowSelectionState,
  SortingState,
  TableOptions,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuProps,
  DataGridColumnHeader,
  DataGridLoader,
  DataGridRow,
  DataTableRowExpansionProps,
  EmptyContentView,
  EmptyContentViewProps,
  If,
  Prompt,
  PromptProps,
  VPagination,
  useRowExpansion,
} from '@vision/ui/components';
import { DATA_GRID_DEFAULT_CURRENT_PAGE, DATA_GRID_DEFAULT_PER_PAGE } from '@vision/ui/constants';
import {
  ColumnDefExtended,
  useDataGridFilterHeadroom,
  useIntersectionObserver,
  useNavigationLinkMatched,
  useUserSelectNoneLock,
} from '@vision/ui/hooks';
import { Pagination } from '@vision/ui/interfaces';
import { conditionalFn, ensureArray } from '@vision/ui/utils';
import clsx from 'clsx';
import React, { CSSProperties, ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import classes from './DataGridSimple.module.scss';

export interface DataGridEmptyViewOptions extends EmptyContentViewProps {}

type AvailableTableOptions<T> = Pick<TableOptions<T>, 'data'> &
  Partial<Pick<TableOptions<T>, 'enableColumnResizing' | 'enableRowSelection' | 'enableSorting'>>;

export type DataGridDataFn<TData> = (data: TData) => string;
export type DataGridRowDataId<TData> = DataGridDataFn<TData>;

export interface DataGridRowDeleteOptions<TData> extends Partial<PromptProps> {
  content?: string;
  disabled?: ActionMenuItem<TData>['disabled'];
  hidden?: ActionMenuItem<TData>['hidden'];
  permission?: string;
  prompt?: boolean;
  promptTitleDataKey?: keyof TData | DataGridRowDataId<TData>;
  title?: string;
}

export type DataGridSimpleProps<TData> = Partial<AvailableTableOptions<TData>> &
  Partial<{
    actionMenu: ActionMenuProps<TData>;
    actionSlot: React.ReactNode;
    className: string;
    columns: ColumnDefExtended<TData>[];
    ellipsis: boolean;
    emptyViewOptions: DataGridEmptyViewOptions;
    error: boolean;
    errorViewOptions: DataGridEmptyViewOptions;
    idAccessor: string;
    loading: boolean;
    onColumnSizeChange: (state: ColumnSizingState) => void;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onRowClick: (data: TData) => void;
    onRowDelete: (data: TData) => void;
    onRowDeletePromptOpened: (data: TData) => void;
    onRowDeleteOptions: DataGridRowDeleteOptions<TData>;
    onRowSelectionChange: (data: TData[]) => void;
    onAllRowsSelected: (data: TData[]) => void;
    onAllRowsUnSelected: () => void;
    onRowSelected: (item: TData, checked?: boolean) => void;
    getIsAllRowsSelected?: () => boolean;
    getIsSomeRowsSelected?: () => boolean;
    onSortChange: (state: SortingState) => void;
    pagination: Pagination;
    rowDataId: keyof TData | DataGridRowDataId<TData>;
    rowExpansion: DataTableRowExpansionProps<TData>;
    rowExpansionLazy: boolean;
    rowSelectionDisableCondition: (row: TData) => boolean;
    rowSelectionState: number[]; // Selected row indexes, rowSelectionState={[0, 4, 8]}
    sortingState: SortingState;
    style: CSSProperties;
    withFilters: boolean;
    onRowHover: boolean;
    rowSelectionIds?: string[];
  }>;

function _DataGridSimple<TData>({
  actionMenu,
  actionSlot,
  className,
  columns = [],
  data,
  ellipsis,
  emptyViewOptions,
  enableColumnResizing,
  enableRowSelection,
  enableSorting,
  error,
  errorViewOptions,
  idAccessor = 'id',
  loading,
  onColumnSizeChange,
  onPageChange,
  onPageSizeChange,
  onRowClick,
  onRowDelete,
  onRowDeleteOptions,
  onRowDeletePromptOpened,
  onRowSelectionChange,
  onAllRowsSelected,
  onAllRowsUnSelected,
  onRowSelected,
  getIsAllRowsSelected: getIsAllRowsSelectedProps,
  getIsSomeRowsSelected: getIsSomeRowsSelectedProps,
  onSortChange,
  pagination,
  rowDataId,
  rowExpansion,
  rowExpansionLazy = true,
  rowSelectionDisableCondition,
  rowSelectionState,
  sortingState,
  style,
  withFilters,
  onRowHover,
  rowSelectionIds,
}: DataGridSimpleProps<TData>) {
  const theme = useMantineTheme();
  const { t } = useTranslation(['translation', 'data-grid']);
  const memoizedData = useMemo(() => ensureArray(data), [data]);
  const memoizedErrorViewOptions = useMemo(
    () =>
      errorViewOptions || {
        buttonVisible: false,
        description: t('somethingWentWrong'),
        icon: <FontAwesomeIcon icon={faCloudExclamation} fontSize={48} />,
        title: t('error'),
      },
    [errorViewOptions],
  );
  const rowExpansionInfo = useRowExpansion<TData>({
    rowExpansion,
    rows: memoizedData,
    idAccessor,
  });
  const [columnSizingInfo, setColumnSizingInfo] = useState<ColumnSizingInfoState>({} as ColumnSizingInfoState);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({} as ColumnSizingState);
  const [sorting, setSorting] = useState<SortingState>(ensureArray(sortingState));
  const [deletePromptOpened, setDeletePromptOpened] = useState(false);
  const [deletingData, setDeletingData] = useState<TData>(null);
  const [internalLoading, setInternalLoading] = useState(true);
  const [internalRowDeleteOptions, setInternalRowDeleteOptions] = useState<DataGridRowDeleteOptions<TData>>({
    ...onRowDeleteOptions,
    prompt: onRowDeleteOptions?.prompt ?? true,
  });
  const [userSelectionLocked, setUserSelectionLocked] = useUserSelectNoneLock();
  const pinned = useDataGridFilterHeadroom();
  const matchedLink = useNavigationLinkMatched();
  const tableHeadRef = useRef<HTMLTableSectionElement>();
  const tableHeadIntersection = useIntersectionObserver(tableHeadRef, {
    rootMargin: `-${matchedLink ? 179 : 139}px`, // Matches with DataGridSimple.module.scss, check .dataGridTableHeaderPinned class
    threshold: [0, 1.0],
  });
  const shouldBePinned = pinned && withFilters && !tableHeadIntersection?.isIntersecting;

  const [rowSelectionStateInternal, setRowSelectionStateInternal] = useState<RowSelectionState>(() => {
    return rowSelectionState
      ? rowSelectionState.reduce(
          (acc, rowIndex) => ({
            ...acc,
            [rowIndex]: true,
          }),
          {},
        )
      : {};
  });

  const handlePromptClose = () => {
    setDeletePromptOpened(false);
  };

  const handlePromptConfirm = () => {
    onRowDelete(deletingData);
    handlePromptClose();
  };

  const getToggleAllRowsSelectedHandler = () => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.currentTarget.checked;

      if (checked) {
        onAllRowsSelected?.(table.getSelectedRowModel().flatRows.map((row) => row.original));
      } else {
        onAllRowsUnSelected?.();
      }

      table.setRowSelection((old) => {
        const value = typeof checked !== 'undefined' ? checked : !table.getIsAllRowsSelected();

        const rowSelectionClone = { ...old };

        const preGroupedFlatRows = table.getPreGroupedRowModel().flatRows;

        if (value) {
          preGroupedFlatRows.forEach((row) => {
            const selectionDisabled = rowSelectionDisableCondition && rowSelectionDisableCondition(row.original);
            if (selectionDisabled) {
              return;
            }
            rowSelectionClone[row.id] = true;
          });
        } else {
          preGroupedFlatRows.forEach((row) => {
            delete rowSelectionClone[row.id];
          });
        }

        return rowSelectionClone;
      });
    };
  };

  const getIsAllRowsSelected = () => {
    const preGroupedFlatRows = table.getFilteredRowModel().flatRows;
    const { rowSelection } = table.getState();

    let isAllRowsSelected = Boolean(preGroupedFlatRows.length && Object.keys(rowSelection).length);

    if (isAllRowsSelected) {
      if (
        preGroupedFlatRows.some((row) => {
          const selectionDisabled = rowSelectionDisableCondition && rowSelectionDisableCondition(row.original);
          return !selectionDisabled && !rowSelection[row.id];
        })
      ) {
        isAllRowsSelected = false;
      }
    }

    return isAllRowsSelected;
  };

  const getIsSomeRowsSelected = () => {
    const totalSelected = Object.keys(table.getState().rowSelection ?? {}).length;
    const selectableRows = table
      .getFilteredRowModel()
      .flatRows.filter((row) => (rowSelectionDisableCondition ? !rowSelectionDisableCondition(row.original) : true));

    return totalSelected > 0 && totalSelected < selectableRows.length;
  };

  const handleCalculateTableHeadWidth = () => {
    if (!tableHeadRef.current) {
      return;
    }

    const rowColumns = Array.from(tableHeadRef.current.querySelector('tbody tr:first-child')?.children ?? []);
    const headColumns = Array.from(
      tableHeadRef.current.querySelector('thead tr')?.children ?? [],
    ) as HTMLTableCellElement[];
    const rowColumnWidths = rowColumns.map((tdChild) => tdChild.clientWidth);

    headColumns.forEach((thChild, index: number) => {
      thChild.style.width = `${rowColumnWidths[index]}px`;
    });
  };

  const memoizedColumns = useMemo(() => {
    let extraColumns = [...columns];

    if (enableRowSelection) {
      const checkboxColumns: ColumnDefExtended<TData>[] = [
        {
          id: 'data-grid-selection',
          filterKey: 'data-grid-selection',
          size: 70,
          minSize: 70,
          header: () => {
            return (
              <Checkbox
                checked={getIsAllRowsSelectedProps?.() ?? getIsAllRowsSelected()} // bu local deki hepsi seçilmiş ise gösterir
                indeterminate={getIsSomeRowsSelectedProps?.() ?? getIsSomeRowsSelected()} // hepsi seçili değil iken isSelectedOtherPageRow un değerini göster
                onChange={getToggleAllRowsSelectedHandler()}
              />
            );
          },
          cell: ({ row }: CellContext<TData, any>) => {
            const selectionDisabled = rowSelectionDisableCondition && rowSelectionDisableCondition(row.original);
            return (
              <Checkbox
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                disabled={selectionDisabled}
                onClick={(event: React.MouseEvent) => {
                  onRowSelected?.(row.original, !row.getIsSelected());
                  event.stopPropagation();
                }}
              />
            );
          },
        },
      ];

      // Checkbox selections should be on first column
      extraColumns = checkboxColumns.concat(extraColumns);
    }

    if (actionMenu || onRowDelete) {
      const actionMenuColumns: ColumnDefExtended<TData>[] = [
        {
          id: 'data-grid-action-menu',
          filterKey: 'data-grid-action-menu',
          size: 70,
          cell: ({ row }: CellContext<TData, any>) => {
            const actionMenuWithRowInfo = {
              ...actionMenu,
              preventDefaultOnClick: true,
              items: ensureArray(actionMenu?.items).map((item) => {
                const hidden = conditionalFn(item.hidden, row.original);
                const disabled = conditionalFn(item.disabled, row.original);
                return {
                  ...item,
                  disabled,
                  hidden,
                  onClick: () => item.onClick?.(row.original),
                };
              }),
            } as ActionMenuProps<TData>;

            if (onRowDelete) {
              actionMenuWithRowInfo.items.push({
                permission: onRowDeleteOptions.permission,
                label: t('delete'),
                icon: faTrashCan,
                onClick() {
                  if (internalRowDeleteOptions.prompt) {
                    setDeletingData(row.original);
                    setDeletePromptOpened(true);
                    onRowDeletePromptOpened?.(row.original);
                  } else {
                    onRowDelete(row.original);
                  }
                },
                disabled: conditionalFn(internalRowDeleteOptions.disabled, row.original),
                hidden: conditionalFn(internalRowDeleteOptions.hidden, row.original),
              });
            }

            const hasActionMenuItems =
              actionMenuWithRowInfo.items.filter((item) =>
                typeof item.hidden === 'function' ? !item.hidden() : !item.hidden,
              ).length > 0;

            if (!hasActionMenuItems) {
              return null;
            }

            return <ActionMenu {...actionMenuWithRowInfo} />;
          },
        },
      ];

      // Action menu should be on last column
      extraColumns = extraColumns.concat(actionMenuColumns);
    }

    return extraColumns;
  }, [columns, enableRowSelection, actionMenu, onRowDeleteOptions, rowSelectionStateInternal]);

  const table = useReactTable<TData>({
    data: memoizedData,
    columns: memoizedColumns as ColumnDef<TData>[],
    columnResizeMode: 'onChange',
    enableColumnResizing,
    enableRowSelection,
    enableSorting,
    manualPagination: true,
    manualSorting: true,
    sortDescFirst: true,
    state: {
      columnSizingInfo,
      columnSizing,
      sorting,
      rowSelection: rowSelectionStateInternal,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
    onRowSelectionChange: setRowSelectionStateInternal,
    onSortingChange: setSorting,
    pageCount: pagination?.total_pages,
  });

  useEffect(() => {
    if (!rowSelectionIds) {
      return;
    }

    if (!rowSelectionIds.length) {
      setRowSelectionStateInternal({});
    } else {
      if (table) {
        table.setRowSelection((old) => {
          const rowSelectionClone = { ...old }; // {1: true, 5: true}

          const preGroupedFlatRows = table.getGroupedRowModel().flatRows; // []

          preGroupedFlatRows.forEach((row) => {
            delete rowSelectionClone[row.id];
          });

          // Here, we will find the rowSelectionIds and then send them back with new markings.
          rowSelectionIds.forEach((item) => {
            const index = preGroupedFlatRows.find((row) => {
              const param = row.original as Row<TData> & { id: string };

              return param?.id === item;
            });

            if (index) {
              rowSelectionClone[index.id] = true;
            }
          });

          return rowSelectionClone;
        });
      }
    }
  }, [rowSelectionIds, table.getRowModel().rows]);

  const currentPage = useMemo(() => {
    return pagination?.current || DATA_GRID_DEFAULT_CURRENT_PAGE;
  }, [pagination]);

  const pageSize = useMemo(() => {
    return pagination?.per_page || DATA_GRID_DEFAULT_PER_PAGE;
  }, [pagination]);

  const selectedRowData = useMemo(
    () => table.getSelectedRowModel().flatRows.map((row) => row.original),
    [table.getSelectedRowModel().flatRows],
  );

  const headers = useMemo(() => {
    return table.getHeaderGroups().map((group) => (
      <tr key={group.id} role="row">
        {group.headers.map((header, index) => (
          <th
            className={classes.dataGridTableHeaderColumn}
            data-testid={`section-data-grid-table-header-${header.id}`}
            key={header.id}
            role="columnheader"
            style={{
              width: header.column.getSize(),
            }}
          >
            <div className={classes.dataGridTableHeaderCell}>
              <DataGridColumnHeader ellipsis={ellipsis} header={header} isLast={group.headers.length - 1 === index} />
            </div>
          </th>
        ))}
      </tr>
    ));
  }, [table.getHeaderGroups(), columnSizingInfo.deltaOffset]);

  const rows = useMemo(() => {
    return table
      .getRowModel()
      .rows.map((row) => (
        <DataGridRow
          key={row.id}
          rowDataId={rowDataId}
          ellipsis={ellipsis}
          expansion={rowExpansionInfo}
          onRowClick={onRowClick}
          row={row}
          rowExpansionLazy={rowExpansionLazy}
          isColumnResizing={!!columnSizingInfo.isResizingColumn}
          isActiveCursor={!!onRowHover}
        />
      ));
  }, [table.getRowModel().rows, rowExpansionInfo]);

  const promptTitle = useMemo(() => {
    if (internalRowDeleteOptions.title) {
      return internalRowDeleteOptions.title;
    }

    if (!internalRowDeleteOptions.promptTitleDataKey) {
      return null;
    }

    if (!deletingData) {
      return null;
    }

    const text =
      typeof internalRowDeleteOptions.promptTitleDataKey === 'function'
        ? internalRowDeleteOptions.promptTitleDataKey(deletingData)
        : deletingData[internalRowDeleteOptions.promptTitleDataKey];

    return t('deleteConfirmTitle', {
      text,
      interpolation: {
        escapeValue: false,
      },
    });
  }, [deletingData, internalRowDeleteOptions]);

  const promptContent = useMemo(() => {
    if (internalRowDeleteOptions.content) {
      return internalRowDeleteOptions.content;
    }

    return t('prompts.delete');
  }, [deletingData, internalRowDeleteOptions]);

  useDidUpdate(() => {
    onSortChange?.(sorting);
  }, [sorting]);

  useDidUpdate(() => {
    setSorting(sortingState);
  }, [sortingState]);

  useDeepCompareEffect(() => {
    if (!enableRowSelection) {
      return;
    }
    onRowSelectionChange?.(selectedRowData);
  }, [selectedRowData]);

  useDidUpdate(() => {
    setUserSelectionLocked(!!columnSizingInfo.isResizingColumn && !userSelectionLocked);

    if (!columnSizingInfo.isResizingColumn) {
      onColumnSizeChange?.(table.getState().columnSizing);
    }
  }, [columnSizing, columnSizingInfo.isResizingColumn]);

  useTimeout(() => setInternalLoading(false), 400, { autoInvoke: true });

  useEffect(() => {
    handleCalculateTableHeadWidth();
    window.addEventListener('resize', handleCalculateTableHeadWidth);
    return () => window.removeEventListener('resize', handleCalculateTableHeadWidth);
  }, [handleCalculateTableHeadWidth]);

  useEffect(() => {
    setInternalRowDeleteOptions({
      ...onRowDeleteOptions,
      prompt: onRowDeleteOptions?.prompt ?? true,
    });
  }, [onRowDeleteOptions]);

  return (
    <>
      <div className={clsx(classes.dataGrid, className)} style={style} data-testid="section-data-grid">
        {actionSlot}
        <div className={classes.dataGridWrapper}>
          <table className={classes.dataGridTable} role="table" data-testid="section-data-grid-table">
            {/* Tarayıcı zoom yapıldığında position: sticky düzgün çalışmıyor. position: fixed kullanıldığında ise layout değişimi oluyor. */}
            {/* Bu nedenle, 2 farklı thead oluşturup "hacky" bir yöntemle bu layout değişimini önlemiş oluyoruz. Orijinal theadi "fixed" yapmadığımızda */}
            <thead
              role="rowgroup"
              data-testid="section-data-grid-table-header"
              className={clsx(classes.dataGridTableHeader, classes.dataGridTableHeaderSticky, {
                [classes.dataGridTableHeaderPinned]: shouldBePinned,
                [classes.dataGridTableHeaderPinnedWithNavigation]: shouldBePinned && !!matchedLink,
              })}
            >
              {headers}
            </thead>
            <thead
              ref={tableHeadRef}
              role="rowgroup"
              data-testid="section-data-grid-table-header"
              className={classes.dataGridTableHeader}
            >
              {headers}
            </thead>

            <tbody role="rowgroup" data-testid="section-data-grid-table-body">
              <If value={!error && data?.length > 0}>{rows}</If>

              <If value={!data?.length && !internalLoading && !loading}>
                <tr>
                  <td colSpan={memoizedColumns.length}>
                    <EmptyContentView
                      className={classes.dataGridTableEmptyView}
                      {...(error ? memoizedErrorViewOptions : emptyViewOptions)}
                    />
                  </td>
                </tr>
              </If>
            </tbody>
          </table>

          <DataGridLoader loading={loading} />
        </div>

        <If value={pagination?.total_count > 0 && data?.length > 0}>
          <VPagination
            className={classes.dataGridPagination}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageCount={pagination?.total_pages}
            pageSize={pageSize}
            totalRecordCount={pagination?.total_count}
          />
        </If>
      </div>

      <Prompt
        {...onRowDeleteOptions}
        content={promptContent}
        heading={t('delete')}
        title={promptTitle}
        onCancel={handlePromptClose}
        onConfirm={handlePromptConfirm}
        opened={deletePromptOpened}
        icon={<FontAwesomeIcon color={theme.colors.gray[0]} icon={faTrashCan} size="4x" />}
      />
    </>
  );
}

export const DataGridSimple = React.memo(_DataGridSimple) as typeof _DataGridSimple;
