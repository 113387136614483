import { ComboboxItemGroup, Stack } from '@mantine/core';
import { VMultiSelect, VSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { ensureArray, getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../../ReportsDetailGraphicCompareComponents';

interface ReportsDetailGraphicMeasureMatrixQuestionProps {
  multiple?: boolean;
}

export function ReportsDetailGraphicMeasureMatrixQuestion({
  multiple = true,
}: ReportsDetailGraphicMeasureMatrixQuestionProps) {
  const { i18n, t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestions: true,
    includeQuestionOptions: true,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectedFlowQuestions = useMemo(() => {
    return flows?.map((item) => ensureArray(item.questions)).flat();
  }, [flows]);

  const selectItems = useMemo(() => {
    return ensureArray(flows).map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((question) => !!question.body)
          .map((question) => ({
            label: getBodyText(question.body, i18n.language) || t('no-question-title'),
            value: question.id,
          })),
      } as ComboboxItemGroup;
    });
  }, [flows]);

  const handleOnChangedQuestions = (values: string[]) => {
    // Yeni seçilen sorular, mevcut seçilen sorularla filtrelenir.
    const newSelectedQuestions = selectedFlowQuestions.filter((question) => values.includes(question.id));

    // Yeni seçilen soruların tüm seçenekleri birleştirilir.
    const options = newSelectedQuestions.reduce((acc, question) => {
      return acc.concat(ensureArray(question.options));
    }, []);

    // Mevcut seçili seçeneklerin id'leri yeni seçeneklerin id'leri ile karşılaştırılarak filtrelenir.
    const newSelectedQuestionOptionIds = formik.values.selectedFilters.selectedQuestionOptionIds.filter(
      (selectedQuestionOptionId) => options.some((option) => option.id === selectedQuestionOptionId),
    );

    // Formik değerlerini günceller.
    formik.setValues({
      ...formik.values,
      selectedFilters: {
        ...formik.values.selectedFilters,
        selectedQuestionIds: values,
        selectedQuestionOptionIds: newSelectedQuestionOptionIds,
      },
    });
  };

  return (
    <Stack gap={5}>
      <ReportsDetailGraphicCompareLayout title={t('question')}>
        {multiple ? (
          <VMultiSelect
            id="report-measure-matrix-question"
            data-testid="input-report-measure-matrix-question"
            label={t('question')}
            loading={isFetchingFlows}
            placeholder={isFetchingFlows ? t('loading') : multiple ? t('search') : null}
            isValuesPillLoading={isFetchingFlows}
            data={selectItems}
            searchable={true}
            value={formik.values.selectedFilters.selectedQuestionIds}
            onChange={handleOnChangedQuestions}
            error={translateErrorMessage(formik, 'selectedFilters.selectedQuestionIds')}
            onBlur={formik.handleBlur}
            withAsterisk={true}
          />
        ) : (
          <VSelect
            data-testid="report-measure-matrix-question-single"
            label={t('question')}
            loading={isFetchingFlows}
            data={selectItems}
            searchable={true}
            value={formik.values.selectedFilters.selectedQuestionIds[0] || null}
            onChange={(value) => {
              handleOnChangedQuestions([value]);
            }}
            error={translateErrorMessage(formik, 'selectedFilters.selectedQuestionIds')}
            onBlur={formik.handleBlur}
            withAsterisk={true}
          />
        )}
      </ReportsDetailGraphicCompareLayout>
    </Stack>
  );
}
