import { RadarOption } from 'echarts/types/dist/shared';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { optionCreator } from '../../ECharts.utils';

export type RadarProps = EventHandlerProps & RadarOption;
/**
 * @example
 * ```tsx
 * <Radar data={[
 *     { value: 1048, name: 'Search Engine' },
 *     { value: 735, name: 'Direct' },
 *     { value: 580, name: 'Email' },
 *     { value: 484, name: 'Union Ads' },
 *     { value: 300, name: 'Video Ads' }
 * ]} />
 * ```
 */
export const Radar: EC<RadarProps> = () => <></>;

Radar.optionOf = optionCreator('radar');
