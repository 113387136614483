import { useDidUpdate } from '@mantine/hooks';
import { DatepickerWithStartEnd, DatepickerWithStartEndValue } from '@vision/ui/components';
import { Filter, FilterSelections } from '@vision/ui/interfaces';
import { parseFilterValidationDate } from '@vision/ui/utils';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

function updateSelectedDates(filter: Filter, filterSelections: FilterSelections[]): DatepickerWithStartEndValue {
  const found = filterSelections.find((item) => item.key === filter.key);
  if (!found) {
    return {
      from: null,
      to: null,
    };
  }

  const val = found.value as string;

  if (val) {
    const [from, to] = val.split(',').map(Number);

    return {
      from: new Date(from),
      to: to ? new Date(to) : null,
    };
  }

  return {
    from: null,
    to: null,
  };
}

interface DataGridFilterDatepickerControlProps {
  filter: Filter;
  filterSelections: FilterSelections[];
  onChange?: (selections: FilterSelections[]) => void;
}

export function DataGridFilterDatepickerControl({
  filter,
  filterSelections,
  onChange,
}: DataGridFilterDatepickerControlProps) {
  const [value, setValue] = useState<DatepickerWithStartEndValue>(updateSelectedDates(filter, filterSelections));

  const { minDate, maxDate } = useMemo(() => {
    return parseFilterValidationDate(filter);
  }, [filter]);

  const calculateChanges = (val: DatepickerWithStartEndValue) => {
    if (!val.from) {
      return filterSelections.filter((item) => item.key !== filter.key);
    }

    const index = filterSelections.findIndex((item) => item.key === filter.key);

    const from = val.from.valueOf();
    const to = val.to?.valueOf();

    const mapped = [from, to].filter(Boolean).join(',');

    if (index === -1) {
      return [
        ...filterSelections,
        {
          key: filter.key,
          value: mapped,
        },
      ];
    }
    const selection = filterSelections[index];
    return [
      ...filterSelections.slice(0, index),
      {
        ...selection,
        value: mapped,
      },
      ...filterSelections.slice(index + 1),
    ];
  };

  useDidUpdate(() => {
    const changes = calculateChanges(value);
    onChange?.(changes);
  }, [value]);

  useDeepCompareEffect(() => {
    setValue(updateSelectedDates(filter, filterSelections));
  }, [filter, filterSelections]);

  return (
    <DatepickerWithStartEnd
      value={value}
      onChange={setValue}
      minDate={minDate}
      maxDate={maxDate}
      startDateProps={{
        'data-testid': 'input-filter-datepicker-start',
      }}
      endDateProps={{
        'data-testid': 'input-filter-datepicker-end',
      }}
    />
  );
}
