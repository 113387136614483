import { Center, Loader } from '@mantine/core';
import classes from './DataGridLoader.module.scss';
import clsx from 'clsx';

interface DataGridLoaderProps {
  loading: boolean;
}

export function DataGridLoader({ loading }: DataGridLoaderProps) {
  if (!loading) {
    return null;
  }

  return (
    <Center
      data-testid="section-data-grid-loader"
      className={clsx(classes.dataGridLoader, {
        [classes.dataGridLoaderLoading]: loading,
      })}
    >
      <Loader />
    </Center>
  );
}
