import { ComboboxItem } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetNodeChildrenQuery } from '@vision/ui/services';
import {
  arrayGroupBy,
  convertArrayToSelectItems,
  filterUnitTypeNodeListByNodeType,
  translateErrorMessage,
} from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareUnitProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareUnit({ required }: ReportsDetailGraphicCompareUnitProps) {
  const { t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const { data: nodes, isFetching } = useGetNodeChildrenQuery({
    nodeId: accountId,
    includeSelf: true,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const groupedUnitTypes = useMemo(() => {
    const filtered = filterUnitTypeNodeListByNodeType(nodes);
    return arrayGroupBy(filtered, (item) => item.type);
  }, [nodes]);

  const unitTypeSelectItems = useMemo(() => {
    return Object.keys(groupedUnitTypes).map(
      (unitType) =>
        ({
          label: unitType,
          value: unitType,
        }) as ComboboxItem,
    );
  }, [groupedUnitTypes]);

  const unitsSelectItems = useMemo(() => {
    return formik.values.selectedCompareCriteria.selectedUnitTypeIds.reduce((acc, unitType) => {
      const units = groupedUnitTypes[unitType];
      return [
        ...acc,
        ...convertArrayToSelectItems(units, (item) => ({
          label: item.name,
          value: item.id,
        })),
      ];
    }, []);
  }, [groupedUnitTypes, formik.values.selectedCompareCriteria.selectedUnitTypeIds]);

  const handleSelectedUnitTypeIdsChange = (values: string[]) => {
    // Eğer unit type seçimleri değişirse, seçilen unitleri güncelle ve formik'e set et
    // Eğer seçilen unit type'ın altında seçilen units yoksai bu formik ten çıkarmak gerekli.
    const newUnits = values
      .flatMap((unitType) => groupedUnitTypes[unitType] || [])
      .filter((newUnit) =>
        formik.values.selectedCompareCriteria.selectedUnitIds.some((unitId) => newUnit.id === unitId),
      );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedUnitTypeIds: values,
        selectedUnitIds: newUnits.map((unit) => unit.id),
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.unit-name')}>
      <VMultiSelect
        id="report-compare-unit-types"
        data-testid="input-report-compare-unit-types"
        label={t('unitTypes')}
        data={unitTypeSelectItems}
        loading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedUnitTypeIds}
        onChange={handleSelectedUnitTypeIdsChange}
        searchable={true}
        clearable={true}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedUnitTypeIds')}
        withAsterisk={required}
        withSelectAll={false}
      />

      <If value={unitsSelectItems.length > 0 && formik.values.selectedCompareCriteria.selectedUnitTypeIds.length > 0}>
        <VMultiSelect
          id="report-compare-units"
          data-testid="input-report-compare-units"
          label={t('units')}
          data={unitsSelectItems}
          value={formik.values.selectedCompareCriteria.selectedUnitIds}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedUnitIds', e)}
          searchable={true}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedUnitIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
