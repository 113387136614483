import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { CreateContactNoteRequest, Customer, FeedbackTimelineResponse, Note } from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '../utils';
import { axiosBaseQuery } from './base.service';

export const ContactDetailService = createApi({
  reducerPath: 'ContactDetailService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getContact: builder.query<Customer, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/customers/${id} `,
        method: HttpMethod.GET,
      }),
    }),
    updateContact: builder.mutation<void, { id: string; data: Partial<Customer> }>({
      query: ({ id, data }) => ({
        url: `/v1/customers/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.contacts.updateContact.{{status}}',
          },
        }),
      }),
    }),
    createContactNote: builder.query<Note, CreateContactNoteRequest>({
      query: (data) => ({
        url: `/v1/customer_notes`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.contacts.createNote.{{status}}',
          },
        }),
      }),
    }),
    updateContactNote: builder.mutation<Note, { id: string; data: Note; message?: string }>({
      query: ({ id, data, message = 'updateNote' }) => ({
        url: `/v1/customer_notes/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: `http.message.contacts.${message}.{{status}}`,
          },
        }),
      }),
    }),
    deleteContactNote: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/customer_notes/${id}/destroy`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.contacts.deleteNote.{{status}}',
          },
        }),
      }),
    }),
    getContactFeedbacksTimeline: builder.query<
      FeedbackTimelineResponse,
      { creators: string; from: string; to: string; page: number }
    >({
      query: (params) => ({
        url: `/v1/feedback_timeline`,
        method: HttpMethod.GET,
        params,
      }),
    }),
    deleteContactSegment: builder.mutation<void, { id: string; segment_id: string }>({
      query: ({ id, segment_id }) => ({
        url: `/v1/customers/${id}/segments/${segment_id}/destroy`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateContactSegments: builder.mutation<void, { id: string; data: { segment_id: string } }>({
      query: ({ id, data }) => ({
        url: `/v1/customers/${id}/segments`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
  }),
});

export const {
  useLazyGetContactQuery,
  useUpdateContactMutation,
  useLazyCreateContactNoteQuery,
  useUpdateContactNoteMutation,
  useDeleteContactNoteMutation,
  useLazyGetContactFeedbacksTimelineQuery,
  useDeleteContactSegmentMutation,
  useUpdateContactSegmentsMutation,
} = ContactDetailService;
