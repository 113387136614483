import { PillsInputProps } from '@mantine/core';
import { TreeSelectItemType } from '@vision/ui/interfaces';
import { TreeSelectContextProvider } from './TreeSelectContext';
import { TreeSelectItemRenderer } from './components';

interface TreeSelectProps<T> {
  inputProps?: Omit<PillsInputProps, 'data-testid'> & {
    placeholder?: string;
  };
  items: T[];
  mapper: (item: T) => TreeSelectItemType;
  onChange: (value: string[]) => void;
  value: string[];
  withDropdown?: boolean;
  withSubCategories?: boolean;
  showSelectedItemsOnTop?: boolean;
  loading?: boolean;
}

export function TreeSelect<T>({
  inputProps,
  items,
  mapper,
  onChange,
  value,
  withDropdown,
  withSubCategories = true,
  showSelectedItemsOnTop = true,
}: TreeSelectProps<T>) {
  return (
    <TreeSelectContextProvider
      items={items}
      mapper={mapper}
      onChange={onChange}
      value={value}
      withSubCategories={withSubCategories}
    >
      <TreeSelectItemRenderer
        withDropdown={withDropdown}
        inputProps={inputProps}
        showSelectedItemsOnTop={showSelectedItemsOnTop}
      />
    </TreeSelectContextProvider>
  );
}
