import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, ensureArray, getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionTitleProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionTitle({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionTitleProps) {
  const { i18n, t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestionOptions: true,
    includeQuestions: true,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(flows, (item) => ({
        value: item.id,
        label: item.name,
      })),
    [flows],
  );

  const selectedFlows = useMemo(() => {
    if (!flows) {
      return [];
    }

    return formik.values.selectedCompareCriteria.selectedQuestionFlowIds.map((flowId) =>
      flows.find((item) => item.id === flowId),
    );
  }, [flows, formik.values.selectedCompareCriteria.selectedQuestionFlowIds]);

  const flowQuestionSelectItems = useMemo(() => {
    return selectedFlows.map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((question) => !!question.body)
          .map((question) => ({
            label: getBodyText(question.body, i18n.language) || t('no-question-title'),
            value: question.id,
          })),
      } as ComboboxItemGroup;
    });
  }, [selectedFlows, formik.values.selectedCompareCriteria.selectedQuestionFlowIds]);

  const handleSelectFlow = (value: string[]) => {
    // Seçilen flow'ları yeni bir diziye aktar
    const newSelectedFlows = value.map((flowId) => flows.find((item) => item.id === flowId));

    // Seçilen flow'larda yer alan soruların ID'lerini bul
    const validQuestionIds = new Set(
      newSelectedFlows.flatMap((flow) => flow?.questions?.map((question) => question.id) || []),
    );

    // Mevcut seçili sorular arasından geçerli olanları filtrele
    const newSelectedQuestionIds = formik.values.selectedCompareCriteria.selectedQuestionIds.filter(
      (selectedQuestionId) => validQuestionIds.has(selectedQuestionId),
    );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionFlowIds: value,
        selectedQuestionIds: newSelectedQuestionIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        loading={isFetchingFlows}
        placeholder={isFetchingFlows ? t('loading') : t('search')}
        isValuesPillLoading={isFetchingFlows}
        data={selectItems}
        searchable={true}
        value={formik.values.selectedCompareCriteria.selectedQuestionFlowIds}
        onBlur={formik.handleBlur}
        onChange={handleSelectFlow}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionFlowIds')}
        withAsterisk={required}
      />

      <If value={formik.values.selectedCompareCriteria.selectedQuestionFlowIds.length > 0}>
        <VMultiSelect
          id="report-compare-flow-question"
          data-testid="input-report-compare-flow-question"
          label={t('question')}
          loading={isFetchingFlows}
          placeholder={isFetchingFlows ? t('loading') : t('search')}
          isValuesPillLoading={isFetchingFlows}
          data={flowQuestionSelectItems}
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
