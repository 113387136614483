import { createSelector } from '@reduxjs/toolkit';
import { WorkflowDisplayMode } from '@vision/ui/enums';
import { RootState } from '@vision/ui/store';

export const addWorkflowModalSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ managingWorkflow, addWorkflowModalOpened, displayMode }) => ({
    addWorkflowModalOpened,
    managingWorkflow,
    editing: displayMode === WorkflowDisplayMode.EDIT,
    previewing: displayMode === WorkflowDisplayMode.PREVIEW,
  }),
);

export const addConditionPanelSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ addConditionPanelOpened, managingWorkflow, editingCondition }) => ({
    opened: addConditionPanelOpened,
    workflow: managingWorkflow,
    editingCondition,
  }),
);

export const addFilterPanelSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ addFilterPanelOpened, editingFilter }) => ({
    opened: addFilterPanelOpened,
    editingFilter,
  }),
);

export const addTaskPanelSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ addTaskPanelOpened, editingTask }) => ({
    opened: addTaskPanelOpened,
    editingTask,
  }),
);

export const workflowEditorSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ managingWorkflow, addConditionPanelOpened, addFilterPanelOpened, addTaskPanelOpened, displayMode }) => ({
    addConditionPanelOpened,
    addFilterPanelOpened,
    addTaskPanelOpened,
    editing: displayMode === WorkflowDisplayMode.EDIT,
    managingWorkflow,
  }),
);

export const managingWorkflowSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ managingWorkflow, displayMode }) => ({
    managingWorkflow,
    previewing: displayMode === WorkflowDisplayMode.PREVIEW,
    editing: displayMode === WorkflowDisplayMode.EDIT,
  }),
);

export const managingWorkflowAndEditingTaskSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ managingWorkflow, editingTask }) => ({
    managingWorkflow,
    editingTask,
  }),
);

export const workflowDisplayModeSelector = createSelector(
  (state: RootState) => state.WorkflowsState,
  ({ displayMode }) => ({
    displayMode,
    editing: displayMode === WorkflowDisplayMode.EDIT,
    previewing: displayMode === WorkflowDisplayMode.PREVIEW,
  }),
);
