import { TreemapSeriesOption } from 'echarts/charts';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type TreeMapSeriesProps = SeriesProps<TreemapSeriesOption>;
/**
 * @example
 * ```tsx
 * <TreeMapSeries
 *     name="Disk Usage"
 *     levels={[
 *       {
 *         itemStyle: {
 *           borderWidth: 0,
 *           gapWidth: 5
 *         }
 *       },
 *       {
 *         itemStyle: {
 *           gapWidth: 1
 *         }
 *       },
 *       {
 *         colorSaturation: [0.35, 0.5],
 *         itemStyle: {
 *           gapWidth: 1,
 *           borderColorSaturation: 0.6
 *         }
 *       }
 *     ]}
 *     data={[
 *       {
 *         "value": 40,
 *         "name": "Accessibility",
 *         "path": "Accessibility"
 *       },
 *       {
 *         "value": 180,
 *         "name": "Accounts",
 *         "path": "Accounts",
 *         "children": [
 *           {
 *             "value": 76,
 *             "name": "Access",
 *             "path": "Accounts/Access",
 *             "children": [
 *               {
 *                 "value": 12,
 *                 "name": "DefaultAccessPlugin.bundle",
 *                 "path": "Accounts/Access/DefaultAccessPlugin.bundle"
 *               },
 *               {
 *                 "value": 28,
 *                 "name": "FacebookAccessPlugin.bundle",
 *                 "path": "Accounts/Access/FacebookAccessPlugin.bundle"
 *               },
 *               {
 *                 "value": 20,
 *                 "name": "LinkedInAccessPlugin.bundle",
 *                 "path": "Accounts/Access/LinkedInAccessPlugin.bundle"
 *               },
 *               {
 *                 "value": 16,
 *                 "name": "TencentWeiboAccessPlugin.bundle",
 *                 "path": "Accounts/Access/TencentWeiboAccessPlugin.bundle"
 *               }
 *             ]
 *           },
 *           {
 *             "value": 92,
 *             "name": "Authentication",
 *             "path": "Accounts/Authentication",
 *             "children": [
 *               {
 *                 "value": 24,
 *                 "name": "FacebookAuthenticationPlugin.bundle",
 *                 "path": "Accounts/Authentication/FacebookAuthenticationPlugin.bundle"
 *               },
 *               {
 *                 "value": 16,
 *                 "name": "LinkedInAuthenticationPlugin.bundle",
 *                 "path": "Accounts/Authentication/LinkedInAuthenticationPlugin.bundle"
 *               },
 *               {
 *                 "value": 20,
 *                 "name": "TencentWeiboAuthenticationPlugin.bundle",
 *                 "path": "Accounts/Authentication/TencentWeiboAuthenticationPlugin.bundle"
 *               },
 *               {
 *                 "value": 16,
 *                 "name": "TwitterAuthenticationPlugin.bundle",
 *                 "path": "Accounts/Authentication/TwitterAuthenticationPlugin.bundle"
 *               },
 *               {
 *                 "value": 16,
 *                 "name": "WeiboAuthenticationPlugin.bundle",
 *                 "path": "Accounts/Authentication/WeiboAuthenticationPlugin.bundle"
 *               }
 *             ]
 *           },
 *           {
 *             "value": 12,
 *             "name": "Notification",
 *             "path": "Accounts/Notification",
 *             "children": [
 *               {
 *                 "value": 12,
 *                 "name": "SPAAccountsNotificationPlugin.bundle",
 *                 "path": "Accounts/Notification/SPAAccountsNotificationPlugin.bundle"
 *               }
 *             ]
 *           }
 *         ]
 *       }
 *     ]}
 * />
 */

export const TreeMapSeries: EC<TreeMapSeriesProps> = () => <></>;

TreeMapSeries.optionOf = seriesOptionCreator('treemap');

TreeMapSeries.loadModule = chartLoader(['TreemapChart']);
