import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { TreeMapChart2CompareRenderer } from '../../components';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../default-chart.config';
import { TreeMapChart2Measures } from './tree-map-chart2.measures';

export const TreeMapChart2DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: TreeMapChart2Measures,
  compareRendererComponent: TreeMapChart2CompareRenderer,
  compareHeader: {
    labelKey: 'page-reports:compare',
    showIcon: false,
  },
  measureHeader: {
    labelKey: 'page-reports:measurement',
    showIcon: false,
  },
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      displayFormat: 'auto',
      hideFormatPosition: true,
    },
  },
};
