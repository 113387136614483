import { HeatmapSeriesOption } from 'echarts/charts';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type HeatMapSeriesProps = SeriesProps<HeatmapSeriesOption>;

export const HeatMapSeries: EC<HeatMapSeriesProps> = () => <></>;

HeatMapSeries.optionOf = seriesOptionCreator('heatmap');

HeatMapSeries.loadModule = chartLoader(['HeatmapChart']);
