import { YesNoType } from '../enums';

export type ReportChartFilterItemType =
  | 'channel-types'
  | 'channels'
  | 'flows'
  | 'questions'
  | 'question-options'
  | 'assigned-users'
  | 'feedback-status'
  | 'intelligent-tags'
  | 'languages'
  | 'only-negative-feedback'
  | 'only-feedback-with-comments'
  | 'only-feedback-with-contact-info'
  | 'tags'
  | 'tag-groups'
  | 'unit-types'
  | 'units'
  | 'spam-feedback'
  | 'content-category'
  | 'stage-titles'
  | 'departments';

export const ReportChartFilterItemTypes: ReportChartFilterItemType[] = [
  'channel-types',
  'channels',
  'flows',
  'questions',
  'question-options',
  'assigned-users',
  'feedback-status',
  'intelligent-tags',
  'languages',
  'only-negative-feedback',
  'only-feedback-with-comments',
  'only-feedback-with-contact-info',
  'tags',
  'tag-groups',
  'unit-types',
  'units',
  'spam-feedback',
  'content-category',
];

export const ExReportChartFilterItemTypes: ReportChartFilterItemType[] = [
  'stage-titles',
  'departments',
  'flows',
  'tags',
  'tag-groups',
  'questions',
  'question-options',
  'feedback-status',
  'spam-feedback',
];

interface ReportChartFilterTransformType {
  keyType: ReportChartFilterItemType;
  filterKey: string;
  initialValue?: null | string[];
  defaultValue?: null | YesNoType;
}

const ReportChartFilterTransform: ReportChartFilterTransformType[] = [
  {
    keyType: 'channel-types',
    filterKey: 'selectedChannelTypes',
    initialValue: [],
  },
  {
    keyType: 'channels',
    filterKey: 'selectedChannelIds',
    initialValue: [],
  },
  {
    keyType: 'flows',
    filterKey: 'selectedFlowIds',
    initialValue: [],
  },
  {
    keyType: 'questions',
    filterKey: 'selectedQuestionIds',
    initialValue: [],
  },
  {
    keyType: 'question-options',
    filterKey: 'selectedQuestionOptionIds',
    initialValue: [],
  },
  {
    keyType: 'assigned-users',
    filterKey: 'selectedAssignedUserIds',
    initialValue: [],
  },
  {
    keyType: 'feedback-status',
    filterKey: 'selectedFeedbackStatus',
    initialValue: [],
  },
  {
    keyType: 'intelligent-tags',
    filterKey: 'selectedIntelligentTagIds',
    initialValue: [],
  },
  {
    keyType: 'languages',
    filterKey: 'selectedLanguageIds',
    initialValue: [],
  },
  {
    keyType: 'only-negative-feedback',
    filterKey: 'onlyNegativeFeedback',
    initialValue: null,
    defaultValue: YesNoType.YES,
  },
  {
    keyType: 'only-feedback-with-comments',
    filterKey: 'hasComments',
    initialValue: null,
    defaultValue: YesNoType.YES,
  },
  {
    keyType: 'only-feedback-with-contact-info',
    filterKey: 'hasContactInfo',
    initialValue: null,
    defaultValue: YesNoType.YES,
  },
  {
    keyType: 'tags',
    filterKey: 'selectedTagIds',
    initialValue: [],
  },
  {
    keyType: 'tag-groups',
    filterKey: 'selectedTagGroupIds',
    initialValue: [],
  },
  {
    keyType: 'unit-types',
    filterKey: 'selectedUnitTypeIds',
    initialValue: [],
  },
  {
    keyType: 'units',
    filterKey: 'selectedUnitIds',
    initialValue: [],
  },
  {
    keyType: 'spam-feedback',
    filterKey: 'spamFeedback',
    initialValue: [],
  },
  {
    keyType: 'content-category',
    filterKey: 'selectedContentCategoryIds',
    initialValue: [],
  },
];

// find by keyType and return filterKey
export function getReportChartFilterTransformedKey(keyType: ReportChartFilterItemType): string {
  return ReportChartFilterTransform.find((item) => item.keyType === keyType)?.filterKey || '';
}

// find by keyType and return ReportChartFilterTransformType
export function getReportChartFilterTransformed(keyType: ReportChartFilterItemType): ReportChartFilterTransformType {
  return ReportChartFilterTransform.find((item) => item.keyType === keyType) || ({} as ReportChartFilterTransformType);
}
