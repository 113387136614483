import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { DashboardList, HttpBaseRequest, UserPreference } from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '../utils';
import { axiosBaseQuery } from './base.service';

export const UserProfileService = createApi({
  reducerPath: 'UserProfileService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    preferences: builder.query<UserPreference, { userId: string; nodeId: string }>({
      query: ({ userId, nodeId }) => ({
        url: `/v1/users/${userId}/preferences`,
        method: HttpMethod.GET,
        params: {
          node_id: nodeId,
        },
      }),
    }),
    userProfileDashboards: builder.query<DashboardList[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/dashboards`,
        method: HttpMethod.GET,
        params: {
          node_id: nodeId,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    updatePreferences: builder.mutation<
      UserPreference,
      HttpBaseRequest<{ userId: string; data: UserPreference & { node_id: string } }>
    >({
      query: ({ userId, data }) => ({
        url: `/v1/users/${userId}/preferences`,
        method: HttpMethod.POST,
        data,
      }),
    }),
  }),
});

export const { usePreferencesQuery, useLazyUserProfileDashboardsQuery, useUpdatePreferencesMutation } =
  UserProfileService;
