import { Group } from '@mantine/core';
import { TooltipInfo } from '@vision/ui/components';

export function InputLabelContent({ children }: React.PropsWithChildren) {
  return (
    <Group gap={5} display="inline-flex">
      {children}
    </Group>
  );
}

function InputLabelContentTooltip({ children }: React.PropsWithChildren) {
  return <TooltipInfo>{children}</TooltipInfo>;
}

InputLabelContent.Tooltip = InputLabelContentTooltip;
