import { Drawer, DrawerProps, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DrawerConfig } from '@vision/theme';
import { Breakpoints } from '@vision/ui/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface VDrawerProps extends DrawerProps {}

export function VDrawer({ children, size, ...props }: React.PropsWithChildren<VDrawerProps>) {
  const { t } = useTranslation();
  const matches = useMediaQuery(Breakpoints.DOWN.SM);
  const defaultSize = DrawerConfig.defaultProps.size;
  const calculatedSize = matches ? '100%' : size || defaultSize;

  return (
    <Drawer.Root position={DrawerConfig.defaultProps.position} {...props} size={calculatedSize}>
      <Drawer.Overlay {...DrawerConfig.defaultProps.overlayProps} />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title>{props.title}</Drawer.Title>
          <Tooltip label={t('close')}>
            <Drawer.CloseButton />
          </Tooltip>
        </Drawer.Header>
        <Drawer.Body>{children}</Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
}
