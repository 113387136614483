import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  BoxComponentProps,
  Button,
  Menu,
  Stack,
  Text,
  TextInput,
  TextProps,
  useMantineTheme,
} from '@mantine/core';
import { useDidUpdate, useInputState } from '@mantine/hooks';
import { If, TooltipCard } from '@vision/ui/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FilterSelector.scss';

interface FilterSelectorProps {
  clearable?: boolean;
  id: string;
  label: string;
  onClear?: VoidFunction;
  onSearch?: (text: string) => void;
  searchable?: boolean;
  selected?: boolean;
  tooltipChildren?: React.ReactNode;
  tooltipDisabled?: boolean;
  dropdownWidth?: number;
  dropdownContentProps?: Partial<BoxComponentProps>;
  textProps?: Omit<TextProps, 'children'>;
}

function _FilterSelector({
  children,
  clearable = true,
  id,
  label,
  onClear,
  onSearch,
  searchable = true,
  selected,
  tooltipChildren,
  tooltipDisabled,
  dropdownWidth,
  dropdownContentProps,
  textProps,
}: React.PropsWithChildren<FilterSelectorProps>) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useInputState('');
  const theme = useMantineTheme();
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchText('');
  };

  const handleClearInput = () => {
    setSearchText('');
  };

  useDidUpdate(() => {
    onSearch?.(searchText);
  }, [searchText]);

  return (
    <div className="filter-selector" data-testid="section-filter-selector">
      <Menu
        opened={open}
        onOpen={handleOpen}
        onClose={handleClose}
        withArrow={false}
        position="bottom-start"
        keepMounted={true}
      >
        <Menu.Target>
          <TooltipCard position="bottom-start" disabled={open || tooltipDisabled || !tooltipChildren}>
            <TooltipCard.Target>
              <Button
                data-testid={`button-filter-selector-${id}`}
                rightSection={
                  <FontAwesomeIcon
                    icon={open ? faChevronUp : faChevronDown}
                    color={theme.colors.gray[5]}
                    className="filter-selector-icon"
                  />
                }
                color="light.1"
                size="xs"
                className={clsx({
                  selected,
                })}
              >
                <Text
                  c={selected ? 'blue' : 'gray'}
                  component="span"
                  size="xs"
                  className="filter-selector-text"
                  data-testid="section-filter-selector-text"
                  {...textProps}
                >
                  {label}
                </Text>
              </Button>
            </TooltipCard.Target>

            <TooltipCard.Dropdown>{tooltipChildren}</TooltipCard.Dropdown>
          </TooltipCard>
        </Menu.Target>

        <Menu.Dropdown className="filter-selector-dropdown" w={dropdownWidth} maw={dropdownWidth}>
          <If value={searchable}>
            <Stack gap={5} align="flex-start" bg="light.1" p={10}>
              <TextInput
                data-testid="input-filter-selector-search"
                placeholder={t('search')}
                size="xs"
                wrapperProps={{ w: '100%' }}
                value={searchText}
                onChange={setSearchText}
                rightSection={
                  searchText ? (
                    <ActionIcon size="xs" onClick={handleClearInput} color="gray">
                      <FontAwesomeIcon icon={faXmark} fontSize={12} />
                    </ActionIcon>
                  ) : null
                }
              />

              <If value={selected && clearable}>
                <Button
                  data-testid="button-filter-selector-clear"
                  size="compact-xs"
                  variant="subtle"
                  color="blue"
                  onClick={onClear}
                >
                  {t('clearAll')}
                </Button>
              </If>
            </Stack>
          </If>

          <Box className="filter-selector-dropdown-content" {...dropdownContentProps}>
            {children}
          </Box>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

export const FilterSelector = React.memo(_FilterSelector);
