import { ReportChartGroupCompareItemType } from '@vision/ui/interfaces';
import React, { useEffect, useState } from 'react';
import {
  ReportsDetailGraphicCompareChannelType,
  ReportsDetailGraphicCompareUnit,
} from '../../../ReportsDetailGraphicCompareComponents';

interface CompareRendererConfig {
  component: React.FC<{
    compareItemType?: ReportChartGroupCompareItemType;
    required: boolean;
  }>;
  required: boolean;
}

const compareComponentMapping: Partial<Record<ReportChartGroupCompareItemType, CompareRendererConfig>> = {
  'unit-name': {
    component: ReportsDetailGraphicCompareUnit,
    required: false,
  },
  'channel-name': {
    component: ReportsDetailGraphicCompareChannelType,
    required: false,
  },
};

interface RadarChart1CompareRendererProps {
  compareItemTypes: ReportChartGroupCompareItemType[];
}

export function RadarChart1CompareRenderer({ compareItemTypes }: RadarChart1CompareRendererProps) {
  const [components, setComponents] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const newComponents: JSX.Element[] = compareItemTypes.map((compareItemType) => {
      const config = compareComponentMapping[compareItemType];

      if (!config) {
        return null;
      }

      const Component = config.component;

      return <Component key={compareItemType} compareItemType={compareItemType} required={config.required} />;
    });

    setComponents(newComponents);
  }, [compareItemTypes]);

  return <>{components}</>;
}
