import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { FeedbackTimelineResponse, UserStats } from '../interfaces';
import { axiosBaseQuery } from './base.service';

export const FeedbackService = createApi({
  reducerPath: 'FeedbackService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    // unused
    getUserMetrics: builder.query<UserStats, { userId: string; from: string; to: string }>({
      query: ({ userId, from, to }) => ({
        url: '/v1/feedback/user_metrics',
        method: HttpMethod.GET,
        params: {
          user_id: userId,
          from,
          to,
        },
      }),
    }),

    getFeedbackTimeline: builder.query<FeedbackTimelineResponse, { responsibles: string; from: string; to: string }>({
      query: ({ responsibles, from, to }) => ({
        url: '/v1/feedback_timeline',
        method: HttpMethod.GET,
        params: {
          responsibles,
          from,
          to,
        },
      }),
    }),
  }),
});

export const { useLazyGetFeedbackTimelineQuery } = FeedbackService;
