import { FunnelSeriesOption } from 'echarts/charts';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type FunnelSeriesProps = SeriesProps<FunnelSeriesOption>;
/**
 * @example
 * ```tsx
 * <FunnelSeries
 *     name="Funnel"
 *     data={[
 *       { value: 60, name: 'Visit' },
 *       { value: 40, name: 'Inquiry' },
 *       { value: 20, name: 'Order' },
 *       { value: 80, name: 'Click' },
 *       { value: 100, name: 'Show' }
 *     ]}
 * />
 */
export const FunnelSeries: EC<FunnelSeriesProps> = () => <></>;

FunnelSeries.optionOf = seriesOptionCreator('funnel');

FunnelSeries.loadModule = chartLoader(['FunnelChart']);
