import { useIsomorphicEffect, useWindowScroll } from '@mantine/hooks';
import { useMemo, useRef } from 'react';

export function useDataGridFilterHeadroom(fixedAt = 50) {
  const scrollRef = useRef(0);
  const [{ y: scrollPosition }] = useWindowScroll();

  useIsomorphicEffect(() => {
    scrollRef.current = window.scrollY;
  }, [scrollPosition]);

  return useMemo(() => {
    return scrollPosition < scrollRef.current && scrollPosition > fixedAt;
  }, [scrollPosition]);
}
