import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon } from '@mantine/core';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';

interface DataGridColumnSorterProps {
  column: Column<any, any>;
}

export function DataGridColumnSorter({ column }: DataGridColumnSorterProps) {
  const sorted = column.getIsSorted();

  const icon = useMemo(() => {
    if (!sorted) {
      return faSort;
    }

    if (sorted === 'desc') {
      return faSortDown;
    }
    return faSortUp;
  }, [sorted]);

  return (
    <ActionIcon onClick={column.getToggleSortingHandler()}>
      <FontAwesomeIcon icon={icon} />
    </ActionIcon>
  );
}
