import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { useAppSelector } from '@vision/ui/hooks';
import { ReportChartGroupCompareItemType, ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { getReportChartCompareKey } from '@vision/ui/pages/ReportsDetailGraphic/utils';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';
import { ReportsDetailGraphicConfigurationValueCompareSelect } from '../ReportsDetailGraphicConfigurationValueCompareSelect';

interface ReportsDetailGraphicConfigurationValueCompareProps {
  compareSelectItems: ComboboxParsedItemGroup[];
  disabled: boolean;
  showIcon?: boolean;
  labelKey?: string;
}

export function ReportsDetailGraphicConfigurationValueCompare({
  compareSelectItems,
  disabled,
  showIcon = true,
  labelKey,
}: ReportsDetailGraphicConfigurationValueCompareProps) {
  const { t } = useTranslation(['page-reports', 'translation']);
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const handleSelectCompare = (values: ReportChartGroupCompareItemType[]) => {
    // If the selected chart group element does not allow multiple comparisons and
    // any of the selected ones are already selected, terminate the operation.
    if (
      !selectedChartGroupItem.config.compareMultiple &&
      values.some((item) => formik.values.selectedCompare.includes(item))
    ) {
      return;
    }

    // Two compare value can be selected. So we find the values that are removed and subtract them from the formic values.
    // Filter the removed values from the selected values.
    const removedValue = formik.values.selectedCompare.filter((item) => !values.includes(item));

    // Creating deprecated Formik values.
    const removedFormikValues = getReportChartCompareKey(removedValue).reduce(
      (acc: Record<string, any>, item: string): Record<string, any> => {
        return {
          ...acc,
          [item]: [],
        };
      },
      {},
    );

    // Reset and update the Formik form
    formik.setValues({
      ...formik.values,
      selectedCompare: values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        ...removedFormikValues,
      },
    });
  };

  const CompareRendererComponent =
    formik.values.selectedCompare.length > 0 && selectedChartGroupItem.config.compareRendererComponent;

  return (
    <Stack gap={10}>
      <ReportsDetailGraphicConfigurationValueHeader
        label={t(labelKey || selectedChartGroupItem?.config?.compareHeader?.labelKey || 'page-reports:compareXAxis')}
        icon={
          showIcon
            ? (selectedChartGroupItem?.config?.compareHeader?.showIcon ?? <FontAwesomeIcon icon={faArrowRightLong} />)
            : null
        }
      />

      <ReportsDetailGraphicConfigurationValueCompareSelect
        compareSelectItems={compareSelectItems}
        value={formik.values.selectedCompare}
        disabled={disabled}
        onChange={handleSelectCompare}
        multiple={selectedChartGroupItem.config?.compareMultiple}
        error={translateErrorMessage(formik, 'selectedCompare')}
      />

      {CompareRendererComponent && <CompareRendererComponent compareItemTypes={formik.values.selectedCompare} />}
    </Stack>
  );
}
