import {
  DATA_GRID_DEFAULT_CURRENT_PAGE,
  DATA_GRID_DEFAULT_PAGE_SIZES,
  DATA_GRID_DEFAULT_PER_PAGE,
} from '@vision/ui/constants';
import { DataGridParams, UniqueDataGridParams } from '@vision/ui/interfaces';
import { omit } from '@vision/ui/utils';
import qs from 'qs';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce, useEffectOnce } from 'react-use';

function filterTruthyValues(object: Record<string, any>) {
  return Object.entries(object)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value as string }), {} as Record<string, string>);
}

function mergeQuery(query: string, extraQueryParams: Record<string, boolean | number | string>): string {
  return qs.stringify({
    ...qs.parse(query),
    ...extraQueryParams,
  });
}

function parseQuery(searchParams: URLSearchParams) {
  const parsed = qs.parse(searchParams.toString()) as DataGridParams;
  const filteredKeys: Array<keyof UniqueDataGridParams> = [
    'fc',
    'page',
    'per_page',
    'search_string',
    'sort_by',
    'sort_order',
  ];
  return Object.entries(parsed).reduce((acc, [key, value]) => {
    if (!filteredKeys.includes(key as keyof UniqueDataGridParams)) {
      return {
        ...acc,
        extraQueryValues: {
          ...acc.extraQueryValues,
          [key]: value,
        },
      };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {} as DataGridParams);
}

const defaultParams: DataGridParams = {
  extraQueryValues: null,
  fc: false,
  page: DATA_GRID_DEFAULT_CURRENT_PAGE,
  per_page: DATA_GRID_DEFAULT_PER_PAGE,
  search_string: null,
  sort_by: null,
  sort_order: null,
};

function normalizeQueryParams(search: URLSearchParams) {
  const params = parseQuery(search);

  if (Object.keys(params).length) {
    if (params.per_page) {
      const valid = DATA_GRID_DEFAULT_PAGE_SIZES.some((item) => item.value === Number(params.per_page));
      if (!valid) {
        return {
          ...params,
          per_page: DATA_GRID_DEFAULT_PER_PAGE,
        };
      }
    }
  }

  return params;
}

export const useDataGridParams = (extraQueryParams?: Record<string, boolean | number | string>) => {
  const [internalParams, setInternalParams] = useState<DataGridParams>(() => {
    return normalizeQueryParams(new URLSearchParams(window.location.search));
  });
  const [search, setSearch] = useSearchParams();
  const [query, setQuery] = useState<string>('');

  const dataGridUrlParamsWithFilterSelections = useMemo(() => {
    const { extraQueryValues, ...rest } = internalParams;
    return filterTruthyValues({ ...rest, ...extraQueryValues }) as unknown as DataGridParams;
  }, [internalParams]);

  const filterSelectionsDataGridUrlParams = useMemo(() => {
    return filterTruthyValues(internalParams.extraQueryValues || {});
  }, [internalParams]);

  useDebounce(
    () => {
      let val = qs.stringify(dataGridUrlParamsWithFilterSelections);

      if (!val) {
        const params = {
          page: defaultParams.page,
          per_page: defaultParams.per_page,
        };
        val = qs.stringify(params);
        setInternalParams(params);
      }
      if (extraQueryParams) {
        val = mergeQuery(val, extraQueryParams);
      }

      setQuery(qs.stringify(omit(qs.parse(val), 'fc'))); // remove fc from query,
      setSearch(val, { replace: true });
    },
    300,
    [dataGridUrlParamsWithFilterSelections],
  );

  // Eğer extraQueryParams dan gelen değerler her değiştiğinde istek atılması gerekirse bu kod ile sağlanabilir, şimdilik dursun
  // useEffect(() => {
  //   if (extraQueryParams) {
  //     const mergedQuery = mergeQuery(query, extraQueryParams);
  //     setQuery(mergedQuery);
  //     setSearch(mergedQuery, { replace: true });
  //   }
  // }, [extraQueryParams]);

  useEffectOnce(() => {
    const params = normalizeQueryParams(search);
    setInternalParams(params);
  });

  const handlePartialParamUpdate = (params: Partial<DataGridParams>) => {
    const merged = {
      ...internalParams,
      ...params,
    } as DataGridParams;
    setInternalParams({ ...merged });
  };

  return {
    dataGridUrlParamsWithFilterSelections,
    filterSelectionsDataGridUrlParams,
    params: internalParams,
    query,
    updateDataGridUrlParams: handlePartialParamUpdate,
  };
};
