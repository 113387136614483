import { ReportChartFilterConfig } from '@vision/ui/interfaces';

export const TableChart2Filtres: ReportChartFilterConfig[] = [
  {
    measures: ['overview-compared', 'overview-distribution', 'nps-realization-rate', 'csat-realization-rate'],
    filterItems: [
      'channel-types',
      'channels',
      'flows',
      'questions',
      'question-options',
      'assigned-users',
      'feedback-status',
      'intelligent-tags',
      'languages',
      'only-negative-feedback',
      'only-feedback-with-comments',
      'only-feedback-with-contact-info',
      'tags',
      'tag-groups',
      'unit-types',
      'units',
      'spam-feedback',
      'content-category',
    ],
  },
  {
    measures: ['matrix-question-csat-overview', 'matrix-question-response-count'],
    filterItems: [
      'channel-types',
      'channels',
      'question-options',
      'assigned-users',
      'feedback-status',
      'intelligent-tags',
      'languages',
      'only-negative-feedback',
      'only-feedback-with-comments',
      'only-feedback-with-contact-info',
      'tags',
      'tag-groups',
      'unit-types',
      'units',
      'spam-feedback',
      'content-category',
    ],
  },
];
