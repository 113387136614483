import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, ReportWizardItem, ReportWizardItemSimple, ReportWizardSaveRequest } from '@vision/ui/interfaces';
import { buildEndpointWithQuery } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const ReportWizardService = createApi({
  reducerPath: 'ReportWizardService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ReportWizardDetail', 'ReportWizardsList'],
  endpoints: (builder) => ({
    getReportWizardsList: builder.query<ApiResponse<ReportWizardItem[]>, void>({
      query: () => ({
        url: '/v2/widgets/list',
        method: HttpMethod.GET,
      }),
      providesTags: ['ReportWizardsList'],
    }),
    getReportWizards: builder.query<ApiResponse<ReportWizardItemSimple[]>, { query?: string }>({
      query: ({ query }) => ({
        url: buildEndpointWithQuery('/v2/widgets', query),
        method: HttpMethod.GET,
      }),
    }),
    getReportWizardDetail: builder.query<ApiResponse<ReportWizardItem>, { id: string }>({
      query: ({ id }) => ({
        url: `/v2/widgets/${id}`,
        method: HttpMethod.GET,
      }),
      providesTags: ['ReportWizardDetail'],
    }),
    saveReportWizard: builder.mutation<ReportWizardItem, ReportWizardSaveRequest>({
      query: (request) => ({
        url: '/v2/widgets',
        method: HttpMethod.POST,
        data: request,
      }),
      invalidatesTags: ['ReportWizardsList'],
    }),
    updateReportWizard: builder.mutation<ReportWizardItem, ReportWizardItem>({
      query: (request) => ({
        url: `/v2/widgets/${request.id}/update`,
        method: HttpMethod.POST,
        data: request,
      }),
      invalidatesTags: ['ReportWizardDetail', 'ReportWizardsList'],
    }),
    deleteReportWizard: builder.mutation<ApiResponse<{ id: string }>, { id: string }>({
      query: ({ id }) => ({
        url: `/v2/widgets/${id}/delete`,
        method: HttpMethod.POST,
      }),
      invalidatesTags: ['ReportWizardsList'],
    }),
  }),
});

export const {
  useGetReportWizardsListQuery,
  useSaveReportWizardMutation,
  useUpdateReportWizardMutation,
  useDeleteReportWizardMutation,
  useLazyGetReportWizardsQuery,
  useGetReportWizardDetailQuery,
} = ReportWizardService;
