import React, { createContext, useContext } from 'react';

export function useCreateContext<ContextValue>(defaultValue?: ContextValue) {
  const Context = createContext<ContextValue | null>(defaultValue || null);

  const _useContext = () => {
    return useContext(Context);
  };

  const Provider = ({ children, value }: { value: ContextValue; children: React.ReactNode }) => (
    <Context.Provider value={value}>{children}</Context.Provider>
  );

  return [Provider, _useContext] as const;
}
