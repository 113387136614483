import React from 'react';

interface CustomIconTrendChart2Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTrendChart2({ height, width, active, ...props }: CustomIconTrendChart2Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20L4 5H7L7 20H4Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17L19 17V20L7 20L7 17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7444 15.2348C15.3457 15.5714 14.8525 15.75 14.2859 15.75C13.4548 15.75 12.933 15.2493 12.6079 14.7747C12.3876 14.4532 12.1869 14.0319 12.0158 13.6729C11.9602 13.556 11.9076 13.4457 11.8586 13.3476C11.6275 12.8854 11.4059 12.5278 11.1263 12.2802C10.875 12.0578 10.5416 11.8929 10.0002 11.8929C9.47841 11.8929 9.12048 12.0007 8.86922 12.1431C8.61964 12.2845 8.42901 12.4861 8.28013 12.7467C7.96527 13.2977 7.85735 14.0766 7.85735 14.8929L6.14307 14.8929C6.14307 13.9949 6.24943 12.8452 6.79172 11.8962C7.0714 11.4067 7.47007 10.9655 8.02406 10.6516C8.57637 10.3386 9.2363 10.1786 10.0002 10.1786C10.9588 10.1786 11.6968 10.4958 12.2626 10.9966C12.7999 11.4722 13.1408 12.0789 13.3919 12.581C13.4812 12.7596 13.5567 12.9181 13.6246 13.0606C13.7714 13.3687 13.8828 13.6026 14.022 13.8057C14.1823 14.0396 14.2516 14.037 14.2809 14.0359C14.2827 14.0358 14.2844 14.0357 14.2859 14.0357C14.4694 14.0357 14.5655 13.9866 14.6388 13.9248C14.7312 13.8468 14.8416 13.699 14.9532 13.4281C15.1603 12.9251 15.276 12.2589 15.417 11.4479C15.4366 11.335 15.4566 11.2194 15.4775 11.101C15.635 10.2088 15.8406 9.15289 16.3131 8.32185C16.5587 7.88998 16.8926 7.48698 17.3566 7.19486C17.8257 6.89947 18.3764 6.75 19.0002 6.75V8.46429C18.6597 8.46429 18.4336 8.54249 18.27 8.64554C18.1011 8.75186 17.9462 8.91805 17.8034 9.16922C17.4991 9.70425 17.3297 10.4697 17.1657 11.399C17.1467 11.5069 17.1277 11.6177 17.1083 11.7307C16.9744 12.5097 16.8235 13.3882 16.5383 14.0808C16.3686 14.4929 16.1241 14.9144 15.7444 15.2348Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path opacity="0.2" d="M11 7V17H15V7H11Z" fill={active ? '#0D7BFF' : '#AAAFC9'} fillOpacity={active ? 0.22 : 1} />
    </svg>
  );
}
