import React from 'react';

interface CustomIconDashboardProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconDashboard({ height, width, ...props }: CustomIconDashboardProps) {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.31 14.602c.933 0 1.69.757 1.69 1.69v3.018A1.69 1.69 0 0 1 10.31 21H6.19a1.69 1.69 0 0 1-1.69-1.69v-3.018c0-.933.757-1.69 1.69-1.69zm0 1.69H6.19v3.018h4.12zM13 6.056c0-.933.757-1.69 1.69-1.69h3.506c.933 0 1.69.757 1.69 1.69v3.139a1.69 1.69 0 0 1-1.69 1.69H14.69A1.69 1.69 0 0 1 13 9.196zm1.69 3.139h3.506v-3.14H14.69zm-4.374-4.771c.933 0 1.69.757 1.69 1.69v5.81a1.69 1.69 0 0 1-1.69 1.69H6a1.69 1.69 0 0 1-1.69-1.69v-5.81c0-.933.757-1.69 1.69-1.69zm0 1.69H6v5.81h4.316zM18.29 12c.87 0 1.577.756 1.577 1.688v5.624c0 .932-.706 1.688-1.576 1.688h-3.715c-.87 0-1.576-.756-1.576-1.688v-5.625c0-.931.706-1.687 1.576-1.687h3.715zm0 1.688h-3.714v5.624h3.715v-5.625z"
        transform="translate(-3.637 -4.2) scale(.96189)"
      />
    </svg>
  );
}
