import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationsService } from '@vision/ui/services';
import { NotificationItem } from '@vision/ui/interfaces';

export interface NotificationStateType {
  notificationCount: number;
  notificationCurrentPage: number;
  notificationHasNextPage: boolean;
  notifications: NotificationItem[];
}

const initialState: NotificationStateType = {
  notificationCount: 0,
  notificationCurrentPage: 1,
  notificationHasNextPage: false,
  notifications: [],
};

export const NotificationState = createSlice({
  name: 'NotificationState',
  initialState,
  reducers: {
    clearNotificationsAction: (state) => {
      state.notificationCurrentPage = 1;
      state.notificationHasNextPage = false;
      state.notifications = [];
    },
    toggleNotificationReadStateAction: (state, action: PayloadAction<NotificationItem>) => {
      const index = state.notifications.findIndex((item) => item.id === action.payload.id);
      state.notifications[index].is_read = !state.notifications[index].is_read;
    },
    markAllNotificationsAsReadAction: (state) => {
      state.notifications.forEach((item) => {
        item.is_read = true;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(NotificationsService.endpoints.getNotificationCount.matchFulfilled, (state, { payload }) => {
      state.notificationCount = payload.data?.data;
    });
    builder.addMatcher(NotificationsService.endpoints.getNotifications.matchFulfilled, (state, { payload }) => {
      const {
        data,
        meta: {
          pagination: { current, next },
        },
      } = payload;

      if (state.notificationCurrentPage === 1) {
        state.notifications = data;
      } else {
        state.notifications = state.notifications.concat(data);
      }

      state.notificationCurrentPage = current;
      state.notificationHasNextPage = !!next;
    });
  },
});

export const { clearNotificationsAction, toggleNotificationReadStateAction, markAllNotificationsAsReadAction } =
  NotificationState.actions;
