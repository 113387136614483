import { MapSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type MapSeriesProps = SeriesProps<MapSeriesOption>;
/**
 * @example
 * ```tsx
 * <MapSeries
 *    map="WORLD_COUNTRIES"
 *    roam={true}
 *    selectedMode="multiple"
 *    label={{
 *      fontSize: 11,
 *      fontWeight: 'bold',
 *      show: true,
 *    }}
 *    labelLayout={{
 *      hideOverlap: true,
 *    }}
 *    select={{
 *      itemStyle: {
 *        areaColor: '#b03a5b',
 *      },
 *    }}
 *  />
 *
 *  <MapRegister mapName="WORLD_COUNTRIES" geoJson={worldCountriesJson as GeoJSONSourceInput} />
 * ```
 */
export const MapSeries: EC<MapSeriesProps> = () => <></>;

MapSeries.optionOf = seriesOptionCreator('map');

MapSeries.loadModule = chartLoader(['MapChart']);
