import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { t } from 'i18next';
import { array, object, string } from 'yup';
import { DefaultReportChartThemeFont, GRAPHIC_TITLE_MAX_CHAR_COUNT } from '../default-chart.config';
import { GaugeChart1Measures } from './gauge-chart1.measures';

export const GaugeChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: object().shape({
    selectedDateRange: array().of(string()).min(2, 'validation:rules.generic.required'),
    selectedMeasure: string().required('validation:rules.generic.required'),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
  }),
  measures: GaugeChart1Measures,
  compareRendererComponent: null,
  compareMultiple: true,
  hideCompare: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
    transactionalDate: {
      active: false,
    },
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
  },
};
