import { Badge, BadgeProps } from '@mantine/core';
import { DEFAULT_TAG_BACKGROUND_COLOR, DEFAULT_TAG_FONT_COLOR } from '@vision/ui/constants';
import React from 'react';

export interface TagItem {
  backgroundColor: string;
  fontColor: string;
  label: string;
}

export interface TagProps extends BadgeProps, TagItem {}

const _Tag = React.forwardRef<HTMLDivElement, TagProps>(({ backgroundColor, fontColor, label, ...props }, ref) => {
  return (
    <Badge
      ref={ref}
      radius="sm"
      variant="filled"
      style={{
        backgroundColor: backgroundColor || DEFAULT_TAG_BACKGROUND_COLOR,
        color: fontColor || DEFAULT_TAG_FONT_COLOR,
      }}
      title={label}
      {...props}
    >
      {label || '-'}
    </Badge>
  );
});

export const Tag = React.memo(_Tag);
