import { useMemo } from 'react';

export interface LowercaseProps {
  value: string;
}

export function Lowercase({ value }: LowercaseProps) {
  if (!value) {
    return null;
  }

  const transformed = useMemo(() => value.toLocaleLowerCase(), [value]);

  return <>{transformed}</>;
}
