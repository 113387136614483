import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { BarChart1CompareRenderer } from '../../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../../default-chart.config';
import { ExBarChart1Measures } from './ex-bar-chart1.measures';

export const ExBarChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: ExBarChart1Measures,
  compareRendererComponent: BarChart1CompareRenderer,
  compareMultiple: true,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
    sortResults: {
      limit: 0,
      order: null,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: false,
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      labelDisplayFormat: 'number',
      position: 'outside',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
