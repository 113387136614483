import React from 'react';

interface CustomIconTreeMapChart2Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTreeMapChart2({ height, width, active, ...props }: CustomIconTreeMapChart2Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8V5H20V8H17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 14V10H7V14H4Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12V9H20V12H17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 16V13H20V16H17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20V17H20V20H17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        d="M6.5 12C8.15012 12 10 11.5 12 9.5C14 7.5 14.5 6.49988 18 6.5"
        stroke={active ? '#0D7BFF' : '#AAAFC9'}
        strokeWidth="2"
      />
      <path
        d="M18 18.5C16.3499 18.5 14 19 12 16.5C10 14 10 11.9999 6.5 12"
        stroke={active ? '#0D7BFF' : '#AAAFC9'}
        strokeWidth="2"
      />
      <path d="M18 14.5C16.5 14.5 11.5 14.5 9.5 12.5" stroke={active ? '#0D7BFF' : '#AAAFC9'} strokeWidth="2" />
      <path
        d="M9.99974 12.4999C11.2673 11.6979 15.1703 10.5 18 10.5"
        stroke={active ? '#0D7BFF' : '#AAAFC9'}
        strokeWidth="2"
      />
    </svg>
  );
}
