import { useCheckPermission } from '@vision/ui/hooks';
import React, { memo } from 'react';

interface PermissionProps {
  value: string;
  fallback?: React.ReactNode;
}

function PermissionToggleComponent({ children, fallback, value }: React.PropsWithChildren<PermissionProps>) {
  const permitted = useCheckPermission(value);

  if (value && !permitted) {
    if (fallback) {
      return <>{fallback}</>;
    }
    return null;
  }

  return <>{children}</>;
}

export const PermissionToggle = memo(PermissionToggleComponent);
