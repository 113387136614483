import React from 'react';

interface CustomIconHeadSideHeadPhonesProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconHeadSideHeadPhones({ height, width, ...props }: CustomIconHeadSideHeadPhonesProps) {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      fill="currentColor"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.914 8.594c-.657-1.472-1.516-4.743-2.285-5.837A6.503 6.503 0 0 0 8.316 0H6.254C2.988 0 .131 2.505.005 5.767a5.982 5.982 0 0 0 1.996 4.697v4.787c0 .415.336.75.75.75a.72.72 0 0 0 .722-.722V9.794l-.472-.445a4.486 4.486 0 0 1-1.004-5.392c.722-1.417 2.182-2.28 3.754-2.426l1.846 1.555C6.025 3.43 4.82 4.826 4.82 6.502A3.5 3.5 0 0 0 8.319 10c1.932 0 3.528-1.564 3.528-3.498a3.5 3.5 0 0 0-2.75-3.417s-.25-.084-.75-.084-.75.085-.75.085L5.751 1.531l1.5-.03h1.066c1.597 0 3.153.793 4.084 2.122.352.495.848 2.05 1.262 3.187.203.606.403 1.184.595 1.691h-1.759v3.5a.498.498 0 0 1-.5.5H9.5v2.75c0 .414.336.75.75.75a.72.72 0 0 0 .722-.722v-1.278h1a1.97 1.97 0 0 0 1.973-1.972v-2.028h.972c.806 0 1.292-.748.997-1.407zm-5.567-2.093a2.003 2.003 0 0 1-2 2 2.003 2.003 0 0 1-2-2 2.003 2.003 0 0 1 2-2 2.003 2.003 0 0 1 2 2zm-3.027 0a1 1 0 1 0 1.999 0 1 1 0 0 0-2 0z" />
      <path d="m6.602 7.887 1.64.92-2.02 3.463a.942.942 0 0 1-1.315.321" />
    </svg>
  );
}
