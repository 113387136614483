import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Stack, StackProps, Text, UnstyledButton, createPolymorphicComponent } from '@mantine/core';
import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import classes from './CollapseWithTitle.module.scss';

interface CollapseWithTitleProps extends Omit<StackProps, 'title'> {
  opened?: boolean;
  title: React.ReactNode;
  titleBtnClassName?: string;
  collapseClassName?: string;
}

const _CollapseWithTitle = forwardRef<HTMLDivElement, CollapseWithTitleProps>(
  (
    {
      children,
      opened: openedProp,
      title,
      titleBtnClassName,
      collapseClassName,
      ...props
    }: React.PropsWithChildren<CollapseWithTitleProps>,
    ref,
  ) => {
    const [opened, setOpened] = useState(openedProp ?? false);

    const icon = opened ? faAngleUp : faAngleDown;

    const toggleCollapse = () => {
      setOpened((prev) => !prev);
    };

    useEffect(() => {
      setOpened(openedProp);
    }, [openedProp]);

    return (
      <Stack ref={ref} className={classes.collapseWithTitle} bg="light.1" {...props}>
        <UnstyledButton className={clsx(classes.collapseWithTitleBtn, titleBtnClassName)} onClick={toggleCollapse}>
          <Text component="span" c={opened ? 'blue' : 'dark'} fw={700}>
            {title}
          </Text>

          <FontAwesomeIcon
            className={clsx({
              [classes.collapseWithTitleBtnIconActive]: opened,
            })}
            icon={icon}
          />
        </UnstyledButton>

        <Collapse className={collapseClassName} in={opened}>
          {children}
        </Collapse>
      </Stack>
    );
  },
);

export const CollapseWithTitle = createPolymorphicComponent<'div', CollapseWithTitleProps>(_CollapseWithTitle);
