import type { EmojiMartData } from '@emoji-mart/data';
import React, { createContext, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

interface EmojiLanguages {
  en: Record<string, any>;
  tr: Record<string, any>;
}

export interface EmojiContextValues {
  emojiData: EmojiMartData;
  emojiDataReady: boolean;
  emojiLanguages: EmojiLanguages;
  textWithNativeEmoji: (text: string) => string;
}

export const EmojiContext = createContext<EmojiContextValues>({
  emojiData: null,
  emojiDataReady: false,
  emojiLanguages: {} as EmojiLanguages,
  textWithNativeEmoji: null,
});

// We have to replace the old emojis from cljs project with the new ones.
// TODO: Remove this once we have migrated to the new emojis.
export const obsoleteEmojisToReplace: Record<string, string> = {
  ':slight_smile:': ':slightly_smiling_face:',
  ':slight_frown:': ':slightly_frowning_face:',
  ':frowning2:': ':white_frowning_face:',
  ':thumbsdown:': ':-1:',
  ':thumbsup:': ':+1:',
};

const colons = `:[a-zA-Z0-9-_+]+:`;
const skin = `:skin-tone-[2-6]:`;
export const EMOJI_REGEX = new RegExp(`(${colons}${skin}|${colons})`, 'g');

export function convertLegacyEmojis(text: string): string {
  return text.replace(EMOJI_REGEX, (matched) => {
    if (obsoleteEmojisToReplace[matched]) {
      return obsoleteEmojisToReplace[matched];
    }
    return matched;
  });
}

export function EmojiContextProvider({ children }: React.PropsWithChildren) {
  const [emojiData, setEmojiData] = useState<EmojiMartData>(null);
  const [emojiLanguages, setEmojiLanguages] = useState<EmojiLanguages>({} as EmojiLanguages);
  const [emojiDataReady, setEmojiDataReady] = useState(false);

  const initEmoji = async () => {
    if (emojiDataReady) {
      return;
    }
    const [{ default: emojiData }, { init }, { default: enTranslations }, { default: trTranslations }] =
      await Promise.all([
        import('@emoji-mart/data'),
        import('emoji-mart'),
        import('@emoji-mart/data/i18n/en.json'),
        import('@emoji-mart/data/i18n/tr.json'),
      ]);
    await init({ data: emojiData });
    setEmojiDataReady(true);
    setEmojiData(emojiData as EmojiMartData);

    setEmojiLanguages({
      en: enTranslations,
      tr: trTranslations,
    });
  };

  const textWithNativeEmoji = (text: string) => {
    if (!text) {
      return text;
    }

    return convertLegacyEmojis(text).replace(EMOJI_REGEX, (matched) => {
      const emoji = matched.replace(/:/g, '');

      if (!emojiData) {
        return matched;
      }

      const emojiMatched = emojiData.emojis[emoji];
      if (emojiMatched) {
        return emojiMatched.skins[0].native;
      }
      return matched;
    });
  };

  const contextValue: EmojiContextValues = useMemo(() => {
    return {
      emojiData,
      emojiDataReady,
      emojiLanguages,
      textWithNativeEmoji,
    };
  }, [emojiData, emojiDataReady, textWithNativeEmoji]);

  useEffectOnce(() => {
    initEmoji();
  });

  return <EmojiContext.Provider value={contextValue}>{children}</EmojiContext.Provider>;
}
