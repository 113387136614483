import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BoxProps,
  CloseButtonProps,
  Combobox,
  ComboboxItem,
  ComboboxLikeProps,
  ElementProps,
  MultiSelectFactory,
  Pill,
  PillsInput,
  StylesApiProps,
  __BaseInputProps,
  extractStyleProps,
  getOptionsLockup,
  getParsedComboboxData,
  isOptionsGroup,
  useCombobox,
  useMantineTheme,
  useProps,
  useResolvedStylesApi,
  useStyles,
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { CustomIconSort } from '@vision/ui/icons';
import clsx from 'clsx';
import { dequal } from 'dequal';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filterDisabledValues, filterPickedValues } from '../VSelect';
import { VSelectOptionsDropdown } from '../VSelect/VSelectOptionsDropdown';
import classes from './VMultiSelect.module.scss';

export interface VMultiSelectProps
  extends BoxProps,
    __BaseInputProps,
    ComboboxLikeProps,
    StylesApiProps<MultiSelectFactory>,
    ElementProps<'input', 'size' | 'value' | 'defaultValue' | 'onChange'> {
  checkIconPosition?: 'left' | 'right';
  clearable?: boolean;
  clearButtonProps?: CloseButtonProps & ElementProps<'button'>;
  defaultSearchValue?: string;
  defaultValue?: string[];
  hidePickedOptions?: boolean;
  itemComponent?: React.FC<any>;
  loading?: boolean;
  maxValues?: number;
  nothingFoundMessage?: React.ReactNode;
  onChange?(value: string[]): void;
  onScrollToBottom?: VoidFunction;
  onSearchChange?(value: string): void;
  searchable?: boolean;
  searchValue?: string;
  showAllSelectedPill?: boolean;
  showSelectedValuesOnInput?: boolean;
  value?: string[];
  withCheckIcon?: boolean;
  withinPortal?: boolean;
  withSelectAll?: boolean;
  withCloseDropdownMaxValue?: boolean;
  isValuesPillLoading?: boolean;
  showSelectedPillCountText?: boolean;
  withoutDropdown?: boolean;
  maxVisibleItems?: number;
}

const defaultProps: Partial<VMultiSelectProps> = {
  checkIconPosition: 'left',
  maxValues: Infinity,
  rightSectionPointerEvents: 'none',
  showAllSelectedPill: true,
  showSelectedValuesOnInput: true,
  size: 'sm',
  withCheckIcon: true,
  withinPortal: false,
  withSelectAll: true,
  withCloseDropdownMaxValue: false,
  withoutDropdown: false,
  maxVisibleItems: 2,
};

export function VMultiSelect(_props: VMultiSelectProps) {
  const { t } = useTranslation();
  const props = useProps('MultiSelect', defaultProps, _props);

  const {
    checkIconPosition,
    className,
    classNames,
    clearable,
    clearButtonProps,
    comboboxProps,
    data,
    defaultDropdownOpened,
    defaultSearchValue,
    defaultValue,
    description,
    descriptionProps,
    disabled,
    dropdownOpened,
    error,
    errorProps,
    filter,
    form,
    hidePickedOptions,
    inputContainer,
    inputWrapperOrder,
    itemComponent: ItemComponent,
    label,
    labelProps,
    leftSection,
    leftSectionPointerEvents,
    leftSectionProps,
    leftSectionWidth,
    limit,
    loading,
    maxDropdownHeight,
    maxValues,
    name,
    nothingFoundMessage = t('noResults'),
    onBlur,
    onChange,
    onDropdownClose,
    onDropdownOpen,
    onFocus,
    onKeyDown,
    onOptionSubmit,
    onScrollToBottom,
    onSearchChange,
    placeholder: placeholderProp,
    radius,
    readOnly,
    rightSection,
    rightSectionPointerEvents,
    rightSectionProps,
    rightSectionWidth,
    searchable,
    searchValue,
    selectFirstOptionOnChange,
    showAllSelectedPill,
    showSelectedValuesOnInput,
    size,
    style,
    styles,
    unstyled,
    value,
    variant,
    withAsterisk,
    withCheckIcon,
    withErrorStyles,
    withinPortal,
    withScrollArea,
    withSelectAll = true,
    wrapperProps,
    withCloseDropdownMaxValue,
    isValuesPillLoading,
    showSelectedPillCountText,
    withoutDropdown,
    maxVisibleItems,
    ...others
  } = props;
  const theme = useMantineTheme();
  const parsedData = getParsedComboboxData(data);
  const optionsLockup = getOptionsLockup(parsedData);

  const getStyles = useStyles<MultiSelectFactory>({
    name: 'MultiSelect',
    classes,
    props,
    classNames,
    styles,
    unstyled,
  });

  const { resolvedClassNames, resolvedStyles } = useResolvedStylesApi<MultiSelectFactory>({
    props,
    styles,
    classNames,
  });

  const combobox = useCombobox({
    opened: dropdownOpened,
    defaultOpened: defaultDropdownOpened,
    onDropdownOpen,
    onDropdownClose: () => {
      onDropdownClose?.();
      combobox.resetSelectedOption();
    },
  });

  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    finalValue: [],
    onChange,
  });

  const [_searchValue, setSearchValue] = useUncontrolled({
    value: searchValue,
    defaultValue: defaultSearchValue,
    finalValue: '',
    onChange: onSearchChange,
  });

  const { styleProps, rest } = extractStyleProps(others);

  const rightSectionIcon = useMemo(() => {
    if (loading) {
      return <FontAwesomeIcon icon={faSpinnerThird} color={theme.colors.gray[2]} spin={loading} />;
    }
    return <CustomIconSort color={combobox.dropdownOpened ? theme.colors.dark[5] : theme.colors.gray[2]} />;
  }, [loading, combobox.dropdownOpened]);

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event);

    if (event.key === 'Backspace' && _searchValue.length === 0 && _value.length > 0) {
      setValue(_value.slice(0, _value.length - 1));
    }
  };

  const handleSelectAll = () => {
    const filteredSelectableOptions = filterDisabledValues(parsedData);

    setValue(filteredSelectableOptions);
  };

  const handleDeselectAll = () => {
    setValue([]);
    setSearchValue('');
  };

  const getSelectedValuesAsPill = () => {
    if (isValuesPillLoading) return;

    if (showSelectedPillCountText && _value.length > maxVisibleItems) {
      return [];
    }

    const filteredSelectableOptions = filterDisabledValues(parsedData);
    const isAllSelected = dequal(_value.slice().sort(), filteredSelectableOptions.slice().sort());

    if (showAllSelectedPill && isAllSelected && _value?.length > 0) {
      return (
        <Pill withRemoveButton={!readOnly} onRemove={handleDeselectAll} unstyled={unstyled} {...getStyles('pill')}>
          {t('allSelected')}
        </Pill>
      );
    }

    if (!showSelectedValuesOnInput) {
      return [];
    }

    return _value.map((item, index) => (
      <Pill
        key={`${item}-${index}`}
        withRemoveButton={!readOnly}
        onRemove={() => setValue(_value.filter((i) => item !== i))}
        unstyled={unstyled}
        {...getStyles('pill')}
      >
        {optionsLockup[item]?.label || item}
      </Pill>
    ));
  };

  const clearButton = clearable && _value.length > 0 && !disabled && !readOnly && (
    <Combobox.ClearButton size={size as string} {...clearButtonProps} onClear={handleDeselectAll} />
  );

  const filteredData = filterPickedValues({
    data: parsedData,
    value: _value,
  });

  // Seçili değerler üstte gösterilir
  const sortedParsedData = useMemo(() => {
    const normalizedData = hidePickedOptions ? filteredData : parsedData;
    if (_value?.length > 0) {
      const selectedData = normalizedData.filter((item) => {
        if (isOptionsGroup(item)) {
          return item.items.some((childItem) => _value.includes(childItem.value));
        }
        return _value.includes((item as ComboboxItem).value);
      });

      const filtered = normalizedData.filter(Boolean).filter((item) => {
        if (isOptionsGroup(item)) {
          const found = item.items.some((childItem) => _value.includes(childItem.value));

          return !found;
        }
        return !_value.includes((item as ComboboxItem).value);
      });

      return selectedData.concat(filtered);
    }
    return normalizedData;
  }, [parsedData, hidePickedOptions, filteredData, _value]);

  useEffect(() => {
    if (selectFirstOptionOnChange) {
      combobox.selectFirstOption();
    }
  }, [selectFirstOptionOnChange, _value]);

  const placeholder = useMemo(() => {
    if (showSelectedPillCountText && _value.length > maxVisibleItems) {
      return t('selectedCount', { count: _value.length });
    }
    // normalde placeholder aşağıdaki gibi ama tasarımda seçim sayısını placeholderın aynısı yapmışlar.
    // Eğer tasarım değişir ise aşağıdaki kodu placeholder a yerine koy. getSelectedValuesAsPill içindeki kontrol de yeni tasarımı ekle.
    //placeholder={showSelectedValuesOnInput && _value?.length > 0 && !searchable ? null : placeholder}
    if (showSelectedValuesOnInput && _value?.length > 0 && !searchable) return null;
    return placeholderProp ?? (searchable ? t('search') : t('select'));
  }, [searchable, _value, t, showSelectedValuesOnInput, showSelectedPillCountText, placeholderProp]);

  const pillInputType = useMemo(() => {
    // type={!searchable && _value?.length > 0 && showSelectedPillCountText ? 'hidden' : 'visible'}
    // Eğer seçilenlerin sayısını placeholderda göstermek istiyorsak ve searchable false ise input type hidden olmalı
    // Eğer searchable true ise input type visible olmalı ki placeholder gözüksün
    if (showSelectedPillCountText) {
      if (searchable) {
        return 'visible';
      } else {
        return _value?.length > 0 && _value?.length <= maxVisibleItems ? 'hidden' : 'visible';
      }
    }

    return !searchable && _value?.length > 0 ? 'hidden' : 'visible';
  }, [searchable, _value, showSelectedPillCountText]);

  return (
    <>
      <Combobox
        store={combobox}
        classNames={resolvedClassNames}
        styles={resolvedStyles}
        unstyled={unstyled}
        size={size}
        readOnly={readOnly}
        __staticSelector="MultiSelect"
        onOptionSubmit={(val) => {
          onOptionSubmit?.(val);
          setSearchValue('');
          combobox.updateSelectedOptionIndex('selected');

          if (_value.includes(optionsLockup[val].value)) {
            setValue(_value.filter((v) => v !== optionsLockup[val].value));
          } else if (_value.length < maxValues) {
            setValue([..._value, optionsLockup[val].value]);

            const shouldCloseDropdown = withCloseDropdownMaxValue && _value.length === maxValues - 1;

            if (shouldCloseDropdown) {
              combobox.closeDropdown();
            }
          }
        }}
        keepMounted={false}
        withinPortal={withinPortal}
        withArrow={false}
        position="bottom-start"
        {...comboboxProps}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            {...styleProps}
            __staticSelector="MultiSelect"
            classNames={resolvedClassNames}
            data-testid="input-multi-select-items"
            styles={resolvedStyles}
            unstyled={unstyled}
            className={clsx(classes.multiSelectRoot, className)}
            style={style}
            size={size}
            variant={variant}
            disabled={disabled}
            radius={radius}
            rightSection={rightSection || clearButton || rightSectionIcon}
            rightSectionPointerEvents={clearButton ? 'all' : rightSectionPointerEvents}
            rightSectionWidth={rightSectionWidth}
            rightSectionProps={rightSectionProps}
            leftSection={leftSection}
            leftSectionWidth={leftSectionWidth}
            leftSectionPointerEvents={leftSectionPointerEvents}
            leftSectionProps={leftSectionProps}
            inputContainer={inputContainer}
            inputWrapperOrder={inputWrapperOrder}
            withAsterisk={withAsterisk}
            labelProps={labelProps}
            descriptionProps={descriptionProps}
            errorProps={errorProps}
            wrapperProps={wrapperProps}
            description={description}
            label={label}
            error={error}
            multiline={true}
            withErrorStyles={withErrorStyles}
            __stylesApiProps={{
              ...props,
              rightSectionPointerEvents: clearButton ? 'all' : rightSectionPointerEvents,
              multiline: true,
            }}
            pointer={!searchable}
            onClick={() => (searchable ? combobox.openDropdown() : combobox.toggleDropdown())}
            data-expanded={combobox.dropdownOpened}
          >
            <Pill.Group disabled={disabled} unstyled={unstyled} {...getStyles('pillsList')}>
              {getSelectedValuesAsPill()}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  {...(rest as any)}
                  classNames={{
                    field: classes.selectInputField,
                  }}
                  placeholder={placeholder}
                  //type={!searchable && _value?.length > 0 && showSelectedPillCountText ? 'hidden' : 'visible'}
                  type={pillInputType}
                  {...getStyles('inputField')}
                  unstyled={unstyled}
                  onFocus={(event) => {
                    onFocus?.(event);

                    if (searchable) {
                      combobox.openDropdown();
                    }
                  }}
                  onBlur={(event) => {
                    onBlur?.(event);
                    combobox.closeDropdown();

                    if (searchable) {
                      combobox.closeDropdown();
                    }

                    setSearchValue('');
                  }}
                  onKeyDown={handleInputKeydown}
                  value={_searchValue}
                  onChange={(event) => {
                    setSearchValue(event.currentTarget.value);

                    if (searchable) {
                      combobox.openDropdown();
                    }

                    if (selectFirstOptionOnChange) {
                      combobox.selectFirstOption();
                    }
                  }}
                  disabled={disabled}
                  readOnly={readOnly || !searchable}
                  pointer={!searchable}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <VSelectOptionsDropdown
          checkIconPosition={checkIconPosition}
          data={sortedParsedData}
          filter={filter}
          filterOptions={searchable}
          hidden={readOnly || disabled}
          hiddenWhenEmpty={
            !nothingFoundMessage || (hidePickedOptions && filteredData.length === 0 && _searchValue.trim().length === 0)
          }
          itemComponent={ItemComponent}
          limit={limit}
          maxDropdownHeight={maxDropdownHeight}
          nothingFoundMessage={loading ? t('loading') : nothingFoundMessage}
          onScrollToBottom={onScrollToBottom}
          search={_searchValue}
          value={_value}
          withCheckIcon={withCheckIcon}
          withScrollArea={withScrollArea}
          withSelectAll={withSelectAll}
          onSelectAll={handleSelectAll}
          onDeselectAll={handleDeselectAll}
          withoutDropdown={withoutDropdown}
        />
      </Combobox>
      <input type="hidden" name={name} value={_value.join(',')} form={form} disabled={disabled} />
    </>
  );
}
