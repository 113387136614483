import { ReportChartPeriodAndValueComparison } from './report-chart-period-and-value-comparison';
import { ReportChartPeriodAndValueComparisonApiType } from './report-chart-period-and-value-comparison-api-type';

export const ReportChartPeriodAndValueComparisonMapping: Record<
  ReportChartPeriodAndValueComparison,
  ReportChartPeriodAndValueComparisonApiType
> = {
  'yesterday-nps': 'yesterday_nps',
  'last-week-nps': 'last_week_nps',
  'last-month-nps': 'last_month_nps',
  'last-year-nps': 'last_year_nps',
  'yesterday-count': 'yesterday_count',
  'last-week-count': 'last_week_count',
  'last-month-count': 'last_month_count',
  'last-year-count': 'last_year_count',
  'yesterday-positivity': 'yesterday_positivity',
  'last-week-positivity': 'last_week_positivity',
  'last-month-positivity': 'last_month_positivity',
};
