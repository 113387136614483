import React from 'react';

interface CustomIconTableChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTableChart1({ height, width, active, ...props }: CustomIconTableChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path opacity="0.5" d="M4 8V11H7V8H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path opacity="0.5" d="M4 16V20H7V16H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path opacity="0.5" d="M4 4V7H7V4H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7V4H13V7H8Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7V4H20V7H14Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 11V8H20V11H14Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 11V8H13V11H8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 15V12H13V15H8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 15V12H20V15H14Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 20V16H20V20H14Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 20V16H13V20H8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path opacity="0.5" d="M4 12V15H7V12H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
    </svg>
  );
}
