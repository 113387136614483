import React from 'react';

interface CustomIconFlowProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconFlow({ height, width, ...props }: CustomIconFlowProps) {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      fill="none"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
        <path d="M0 4.1a2.526 2.526 0 1 1 5.053 0A2.526 2.526 0 0 1 0 4.1zM2.526 2.95a1.149 1.149 0 1 0 0 2.298 1.149 1.149 0 0 0 0-2.298zm10.315-1.174a.746.746 0 0 1 1.021-.003l1.925 1.825a.674.674 0 0 1 .213.487.674.674 0 0 1-.21.488l-1.925 1.848a.746.746 0 0 1-1.02.004.667.667 0 0 1-.004-.974l1.417-1.361-1.413-1.34a.667.667 0 0 1-.003-.974zm0 7.8a.746.746 0 0 1 1.021-.003l1.925 1.824a.674.674 0 0 1 .213.487.674.674 0 0 1-.21.489l-1.925 1.849a.746.746 0 0 1-1.02.003.667.667 0 0 1-.004-.974l1.417-1.36-1.413-1.34a.667.667 0 0 1-.004-.975z" />
        <path d="M9.007 11.288A1.947 1.947 0 0 1 7.06 9.341v-4.82a.632.632 0 0 0-1.263 0v4.82a3.21 3.21 0 0 0 3.21 3.21h5.699a.632.632 0 0 0 0-1.263z" />
        <path d="M3.68 4.1a.53.53 0 0 1 .53-.53h10.106a.53.53 0 0 1 0 1.06H4.21a.53.53 0 0 1-.531-.53z" />
      </g>
    </svg>
  );
}
