import { CollapseProps, Transition } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './DataGridRowExpansion.module.scss';

export interface DataTableRowExpansionContent<T> {
  collapse: () => void;
  columnStyles?: React.CSSProperties;
  row: Row<T>;
}

type DataTableRowExpansionCollapseProps = Pick<
  CollapseProps,
  'animateOpacity' | 'transitionDuration' | 'transitionTimingFunction'
>;

export type DataTableRowExpansionProps<T> = {
  /**
   * Defines when rows should expand; defaults to `click`
   */
  trigger?: 'click' | 'always';

  /**
   * If true, multiple rows can be expanded at the same time
   */
  allowMultiple?: boolean;

  /**
   * Function defining which rows will be initially expanded;
   * does nothing if `trigger === 'always'`
   */
  initiallyExpanded?: (row: T) => boolean;

  /**
   * Additional properties passed to the Mantine Collapse component wrapping the custom content
   */
  collapseProps?: DataTableRowExpansionCollapseProps;

  /**
   * Function returning the custom content to be lazily rendered for an expanded row;
   * accepts the current row and a `collapse()` callback that can be used to collapse the expanded row
   */
  content: (props: DataTableRowExpansionContent<T>) => React.ReactNode;
};

function getValueAtPath(obj: unknown, path: string) {
  if (!path) {
    return undefined;
  }
  const pathArray = path.match(/([^[.\]])+/g) as string[];
  return pathArray.reduce((prevObj: unknown, key) => prevObj && (prevObj as Record<string, unknown>)[key], obj);
}

export function useRowExpansion<T>({
  rowExpansion,
  rows,
  idAccessor,
}: {
  rowExpansion?: DataTableRowExpansionProps<T>;
  rows: T[] | undefined;
  idAccessor: string;
}) {
  const {
    trigger = 'click',
    allowMultiple = false,
    initiallyExpanded = () => false,
    collapseProps,
  } = rowExpansion || {};

  const [expandedRowIds, setExpandedRowIds] = useState<Set<unknown>>(() => {
    if (trigger === 'always' && rows) {
      return new Set(rows.map((r) => getValueAtPath(r, idAccessor)));
    }

    if (initiallyExpanded && rows) {
      const initiallyExpandedRowIds = rows.filter(initiallyExpanded).map((r) => getValueAtPath(r, idAccessor));
      return new Set(allowMultiple ? initiallyExpandedRowIds : [initiallyExpandedRowIds[0]]);
    }

    return new Set();
  });

  const collapseRow = useCallback(
    (row: Row<T>) => {
      const rowId = getValueAtPath(row.original, idAccessor);
      const newExpandedRowIds = new Set(expandedRowIds);
      newExpandedRowIds.delete(rowId);
      setExpandedRowIds(newExpandedRowIds);
    },
    [idAccessor, expandedRowIds],
  );

  return useMemo(() => {
    const isRowExpanded = (row: T) =>
      trigger === 'always' ? true : expandedRowIds.has(getValueAtPath(row, idAccessor));

    const expandRow = (row: T) => {
      const rowId = getValueAtPath(row, idAccessor);
      setExpandedRowIds((prevExpandedRowIds) =>
        allowMultiple ? new Set([...prevExpandedRowIds, rowId]) : new Set([rowId]),
      );
    };

    return {
      enabled: !!rowExpansion,
      expandOnClick: trigger !== 'always',
      isRowExpanded,
      expandRow,
      collapseRow,
      collapseProps,
      content: (row: Row<T>) => () => {
        return rowExpansion?.content({
          collapse: () => collapseRow(row),
          row,
        });
      },
    };
  }, [rowExpansion, allowMultiple, trigger, collapseRow, expandedRowIds, idAccessor]);
}

export function useRowExpansionStatus(open: boolean, transitionDuration?: number) {
  const [expanded, setExpanded] = useState(open);
  const [visible, setVisible] = useState(open);

  const expand = useTimeout(() => setExpanded(true), 0);
  const hide = useTimeout(() => setVisible(false), transitionDuration || 200);

  useEffect(() => {
    if (open) {
      hide.clear();
      setVisible(true);
      expand.start();
    } else {
      expand.clear();
      setExpanded(false);
      hide.start();
    }
  }, [expand, hide, open]);

  return useMemo(() => {
    return {
      expanded,
      visible,
    };
  }, [expanded, visible]);
}

interface DataGridRowExpansionProps {
  className?: string;
  collapseProps?: DataTableRowExpansionCollapseProps;
  content: () => React.ReactNode;
  open: boolean;
  rowExpansionLazy?: boolean;
}

export function DataGridRowExpansion({
  className,
  collapseProps,
  content,
  open,
  rowExpansionLazy,
}: DataGridRowExpansionProps) {
  const { expanded, visible } = useRowExpansionStatus(open, collapseProps?.transitionDuration);

  if (rowExpansionLazy && !visible) {
    return null;
  }

  return (
    <Transition mounted={expanded && visible} transition="scale-y" exitDuration={0}>
      {(styles) => {
        return (
          <tr
            style={styles}
            className={clsx(classes.dataGridRowExpansion, className, {
              [classes.dataGridRowExpansionExpanded]: expanded,
            })}
          >
            {content()}
          </tr>
        );
      }}
    </Transition>
  );
}
