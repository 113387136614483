import { ComboboxItemGroup, Stack } from '@mantine/core';
import { VMultiSelect, VSelect } from '@vision/ui/components';
import { ChannelType } from '@vision/ui/enums';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetNodeChildrenQuery } from '@vision/ui/services';
import { convertEnumToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createChannelSelectItems } from '../../../../utils';
import { ReportsDetailGraphicCompareLayout } from '../../../ReportsDetailGraphicCompareComponents';

const FunnelChannelTypes = [
  ChannelType.LINK,
  ChannelType.EMAIL,
  ChannelType.SMS,
  ChannelType.PUSH_NOTIFICATION,
  ChannelType.WHATSAPP,
  ChannelType.WEB_WIDGET,
];

// v1 de campaignType olarak geçiyor bu yüzden burada da aynı şekilde kullanıldı.
// sadece isim değişikliği yapıldı.
// filtre den channelType ve channel kaldırıldı.
// channelType yerine campaignType kullanıldı.
// channel aynı şekilde devam ediyor kullanımı

export function FunnelChart1ValueComponent() {
  const { t, i18n } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: nodes, isFetching } = useGetNodeChildrenQuery({
    nodeId: accountId,
    includeSelf: true,
  });

  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const channelTypes = useMemo(() => {
    return convertEnumToSelectItems(FunnelChannelTypes, (item) => ({
      label: t(`channels.${item.value}`),
      value: item.value,
    }));
  }, [i18n.language]);

  const channelSelectItems: ComboboxItemGroup[] = useMemo(() => {
    if (!formik.values.selectedCustomize.campaignType || !nodes) {
      return [];
    }

    return createChannelSelectItems([formik.values.selectedCustomize.campaignType], nodes);
  }, [nodes, formik.values.selectedCustomize.campaignType]);

  const handleChannelTypeChange = (values: string) => {
    if (!nodes) return;

    formik.setValues({
      ...formik.values,
      selectedFilters: {
        ...formik.values.selectedFilters,
        selectedChannelIds: [],
      },
      selectedCustomize: {
        ...formik.values.selectedCustomize,
        campaignType: values,
      },
    });
  };

  return (
    <Stack gap={10} mt={-20}>
      <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.channel-name')}>
        <Stack gap={10}>
          <VSelect
            data-testid="input-funnel-channel"
            id="input-funnel-channel"
            label={t('channelType')}
            data={channelTypes}
            value={formik.values.selectedCustomize.campaignType}
            onChange={handleChannelTypeChange}
            withinPortal={true}
            withAsterisk={true}
            w="100%"
          />

          <VMultiSelect
            id="report-compare-channels"
            data-testid="input-report-compare-channels"
            label={t('page-reports:channels')}
            data={channelSelectItems}
            disabled={!formik.values.selectedCustomize.campaignType}
            value={formik.values.selectedFilters.selectedChannelIds}
            placeholder={isFetching ? t('loading') : t('search')}
            isValuesPillLoading={isFetching}
            loading={isFetching}
            searchable={true}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue('selectedFilters.selectedChannelIds', e)}
            error={translateErrorMessage(formik, 'selectedFilters.selectedChannelIds')}
            withAsterisk={true}
          />
        </Stack>
      </ReportsDetailGraphicCompareLayout>
    </Stack>
  );
}
