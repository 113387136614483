import { Stack } from '@mantine/core';
import { ReportChartMeasureType } from '@vision/ui/interfaces';
import { ReportsDetailGraphicMeasureMatrixQuestion } from '../../../ReportsDetailGraphicMeasureComponents';

interface BarChart1MeasureRendererProps {
  measure: ReportChartMeasureType;
}

// burası daha dinamik olacak
export function BarChart1MeasureRenderer({ measure }: BarChart1MeasureRendererProps) {
  if (measure === 'matrix-question-csat-overview') {
    return (
      <Stack>
        <ReportsDetailGraphicMeasureMatrixQuestion />
      </Stack>
    );
  }

  return null;
}
