import { MantineSize, Radio, Stack } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { DatepickerWithStartEnd, DatepickerWithStartEndValue, If } from '@vision/ui/components';
import { Filter, FilterOption, FilterSelections } from '@vision/ui/interfaces';
import { parseFilterValidationDate } from '@vision/ui/utils';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

export interface DataGridFilterRadioDatepickerControlProps {
  datepickerVisible?: boolean;
  filter: Filter;
  filterSelections: FilterSelections[];
  onChange?: (selections: FilterSelections[]) => void;
  searchText?: string;
  size?: MantineSize;
}

export function DataGridFilterRadioDatepickerControl({
  datepickerVisible = true,
  filterSelections,
  filter,
  onChange,
  searchText,
  size = 'sm',
}: DataGridFilterRadioDatepickerControlProps) {
  const [radioValue, setRadioValue] = useState<string>();
  const [datepickerValue, setDatepickerValue] = useState<DatepickerWithStartEndValue>();

  const { minDate, maxDate } = useMemo(() => {
    return parseFilterValidationDate(filter);
  }, [filter]);

  const options = useMemo(
    () =>
      filter.options.filter((option) =>
        searchText ? option.label.toLowerCase().includes(searchText.toLowerCase()) : true,
      ),
    [filter, searchText],
  );

  const resetDatepickerValue = () => {
    setDatepickerValue({
      from: null,
      to: null,
    });
  };

  const resetRadioValue = () => {
    setRadioValue(null);
  };

  const handleRadioValueChange = (value: string) => {
    setRadioValue(value);
    resetDatepickerValue();
    handleOnValueChange(value, datepickerValue);
  };

  const handleDatepickerValueChange = (value: DatepickerWithStartEndValue) => {
    setDatepickerValue(value);
    resetRadioValue();
    handleOnValueChange(radioValue, value);
  };

  const calculateChanges = (radioVal: string, datepickerVal: DatepickerWithStartEndValue) => {
    if (!radioVal && !datepickerVal?.from && !datepickerVal?.to) {
      return filterSelections.filter((item) => item.key !== filter.key);
    }

    const index = filterSelections.findIndex((item) => item.key === filter.key);

    const val = radioVal || [datepickerVal.from?.valueOf(), datepickerVal.to?.valueOf()].filter(Boolean).join(',');

    if (index === -1) {
      return [
        ...filterSelections,
        {
          key: filter.key,
          value: val,
        },
      ];
    }
    const selection = filterSelections[index];
    return [
      ...filterSelections.slice(0, index),
      {
        ...selection,
        value: val,
      },
      ...filterSelections.slice(index + 1),
    ];
  };

  const handleOnValueChange = (radioVal: string, datepickerVal: DatepickerWithStartEndValue) => {
    const changes = calculateChanges(radioVal, datepickerVal);
    onChange?.(changes);
  };

  const handleRadioToggle = (option: FilterOption) => {
    if (option.value === radioValue) {
      handleRadioValueChange(null);
    }
  };

  useDidUpdate(() => {
    if (!datepickerVisible) {
      resetDatepickerValue();
    }
  }, [datepickerVisible]);

  useDeepCompareEffect(() => {
    const found = filterSelections.find((item) => item.key === filter.key);
    if (found) {
      const isRadioSelection = filter.options.some((option) => option.value === found.value); // direkt bir value eşleşmesi var ise RADIO dur
      const val = found.value as string;
      if (isRadioSelection) {
        setRadioValue(val);
      } else {
        const [from, to] = val.split(',').map(Number);
        setDatepickerValue({
          from: new Date(from),
          to: to ? new Date(to) : null,
        });
      }
    } else {
      resetRadioValue();
      resetDatepickerValue();
    }
  }, [filter, filterSelections]);

  return (
    <Stack gap={20}>
      <Radio.Group
        name={filter.key}
        value={radioValue}
        onChange={handleRadioValueChange}
        data-testid="section-filter-radio-datepicker-control"
      >
        <Stack gap={10}>
          {options.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              label={item.label}
              onClick={() => handleRadioToggle(item)}
              data-testid={`input-filter-radio-datepicker-${item.value}`}
              size={size}
            />
          ))}
        </Stack>
      </Radio.Group>

      <If value={datepickerVisible}>
        <DatepickerWithStartEnd
          value={datepickerValue}
          onChange={handleDatepickerValueChange}
          minDate={minDate}
          maxDate={maxDate}
          startDateProps={{
            'data-testid': 'input-filter-radio-datepicker-start',
          }}
          endDateProps={{
            'data-testid': 'input-filter-radio-datepicker-end',
          }}
        />
      </If>
    </Stack>
  );
}
