import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  Company,
  CreateCompanyRequest,
  DynamicFilterOption,
  HttpBaseRequest,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const CompanyService = createApi({
  reducerPath: 'CompanyService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCustomerCompaniesAsList: builder.query<ApiResponse<DynamicFilterOption[]>, HttpBaseRequest<{ nodeId: string }>>({
      query: ({ nodeId }) => ({
        url: `/v2/accounts/${nodeId}/customer_companies/list`,
        method: HttpMethod.GET,
      }),
    }),
    getCompanies: builder.query<ApiResponse<Company[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/customer_companies`, query),
        method: HttpMethod.GET,
      }),
    }),
    companiesExportAsCsv: builder.query<
      void,
      {
        excelFormat?: boolean;
        nodeId: string;
        query: string;
      }
    >({
      query: ({ excelFormat = false, nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/customer_companies/export_as_csv`, query),
        method: HttpMethod.GET,
        params: {
          excel_format: excelFormat,
        },
      }),
    }),
    createCompany: builder.mutation<Company, CreateCompanyRequest>({
      query: (data) => ({
        url: '/v1/companies',
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.createCompany.{{status}}',
          },
        }),
      }),
    }),
    getCompaniesList: builder.query<Company[], { node_id: string }>({
      query: (params) => ({
        url: `/v1/companies`,
        method: HttpMethod.GET,
        params,
      }),
    }),
  }),
});

export const {
  useGetCustomerCompaniesAsListQuery,
  useLazyCompaniesExportAsCsvQuery,
  useCreateCompanyMutation,
  useGetCompaniesListQuery,
  useLazyGetCompaniesQuery,
} = CompanyService;
