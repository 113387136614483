import React from 'react';

interface CustomIconTrendChart4Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTrendChart4({ height, width, active, ...props }: CustomIconTrendChart4Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20L4 5H7L7 20H4Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17L19 17V20L7 20L7 17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 18L7.75 9.90225L11.5 6L15.625 9.90225L19 8.25V18H7.75Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18V12.1523L10.75 8.25L14.875 12.1523L19 10.5V18H7Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
