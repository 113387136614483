import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { buildEndpointWithQuery } from '@vision/ui/utils';
import { HttpBaseRequest, SchemaField } from '../interfaces';
import { axiosBaseQuery } from './base.service';

export const SchemaFieldsService = createApi({
  reducerPath: 'SchemaFieldsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSchemaFields: builder.query<SchemaField[], HttpBaseRequest<{ accountId: string; query?: string }>>({
      query: ({ accountId, query }) => ({
        url: buildEndpointWithQuery(`/v1/accounts/${accountId}/schema_fields`, query),
        method: HttpMethod.GET,
      }),
    }),
    createSchemaField: builder.mutation<SchemaField, HttpBaseRequest<{ accountId: string; data: SchemaField }>>({
      query: ({ accountId, data }) => ({
        url: `/v1/accounts/${accountId}/schema_fields`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    updateSchemaField: builder.mutation<SchemaField, HttpBaseRequest<{ accountId: string; data: SchemaField }>>({
      query: ({ accountId, data }) => ({
        url: `/v1/accounts/${accountId}/schema_fields/${data.id}/update`,
        method: HttpMethod.POST,
        data,
      }),
    }),
  }),
});

export const {
  useGetSchemaFieldsQuery,
  useLazyGetSchemaFieldsQuery,
  useCreateSchemaFieldMutation,
  useUpdateSchemaFieldMutation,
} = SchemaFieldsService;
