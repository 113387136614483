import React from 'react';

interface CustomIconCatiProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconCati({ height, width, className, ...props }: CustomIconCatiProps) {
  return (
    <svg
      className={className}
      fill="none"
      width={width || 20}
      height={height || 20}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_19550_49982)">
        <path
          d="M19.8829 14.2597L16.4317 12.7801C15.6793 12.454 14.7926 12.6709 14.2808 13.3055L13.3165 14.4829C11.7427 13.5779 10.4244 12.26 9.52086 10.7189L10.7 9.75312C11.3294 9.23931 11.5466 8.35788 11.2278 7.60754L9.74016 4.111C9.38309 3.29564 8.49815 2.85071 7.63605 3.04998L4.4291 3.79013C3.58985 3.98202 3.00293 4.719 3.00293 5.58215C3.00293 14.0836 9.91588 20.9966 18.4174 20.9966C19.2805 20.9966 20.0175 20.41 20.1781 19.5722L20.9183 16.3621C21.1516 15.4968 20.7018 14.6112 19.8829 14.2597ZM19.3065 15.9818L18.5653 19.1944C18.5489 19.233 18.4912 19.3097 18.4171 19.3097C10.8469 19.3097 4.68956 13.1523 4.68956 5.58219C4.68956 5.5086 4.73514 5.45096 4.80708 5.43448L8.01719 4.69363C8.02817 4.69088 8.03971 4.68978 8.05069 4.68978C8.11163 4.68978 8.16874 4.72658 8.19292 4.78147L9.67391 8.23725C9.70027 8.29931 9.6827 8.37178 9.62998 8.41515L7.91615 9.78932C7.61303 10.0375 7.52077 10.462 7.69319 10.8134C8.85332 13.1762 10.7951 15.1187 13.1582 16.2784C13.478 16.4508 13.9345 16.3586 14.183 16.0555L15.5891 14.338C15.6281 14.2885 15.7011 14.2698 15.7621 14.2984L19.2154 15.7777C19.2819 15.8377 19.3206 15.9115 19.3065 15.9818Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5392 5.73833C13.6324 5.33474 14.0352 5.08314 14.4388 5.17637C15.1737 5.34613 16.2518 5.78392 17.1841 6.56505C18.1288 7.35658 18.9555 8.5317 19.0598 10.147C19.0864 10.5603 18.773 10.917 18.3596 10.9437C17.9463 10.9704 17.5895 10.6569 17.5629 10.2436C17.4913 9.13452 16.9343 8.31271 16.2207 7.71481C15.4947 7.10651 14.6401 6.76238 14.1012 6.63788C13.6976 6.54466 13.446 6.14191 13.5392 5.73833Z"
        fill="currentColor"
      />
    </svg>
  );
}
