import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { ChannelType } from '@vision/ui/enums';
import { useChannelTypes, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetNodeChildrenQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createChannelSelectItems } from '../../../utils';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareChannelTypeProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareChannelType({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareChannelTypeProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: nodes, isFetching } = useGetNodeChildrenQuery({
    nodeId: accountId,
    includeSelf: true,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const channelTypes = useChannelTypes();

  const channelTypeSelectItems = useMemo(
    () => channelTypes.filter((item) => item.value !== ChannelType.KIOSK),
    [channelTypes],
  );

  const channelSelectItems: ComboboxItemGroup[] = useMemo(() => {
    if (!formik.values.selectedCompareCriteria.selectedChannelTypes.length || !nodes) {
      return [];
    }

    return createChannelSelectItems(formik.values.selectedCompareCriteria.selectedChannelTypes, nodes);
  }, [nodes, formik.values.selectedCompareCriteria.selectedChannelTypes]);

  const handleChannelTypeChange = (values: string[]) => {
    if (!nodes) return;

    const newSelectedChannelIds = values.flatMap((channelType) => {
      const channels = nodes.filter((item) => item.type === channelType);
      const selectItems = convertArrayToSelectItems(channels, (item) => ({
        label: item.name,
        value: item.id,
      }));
      return formik.values.selectedCompareCriteria.selectedChannelIds.filter((selectedChannelId) =>
        selectItems.some((selectItem) => selectItem.value === selectedChannelId),
      );
    });

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedChannelTypes: values,
        selectedChannelIds: newSelectedChannelIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-channel-type"
        data-testid="input-report-compare-channel-type"
        label={t('channelType')}
        data={channelTypeSelectItems}
        loading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedChannelTypes}
        searchable={true}
        clearable={true}
        placeholder={isFetching ? t('loading') : t('search')}
        isValuesPillLoading={isFetching}
        onBlur={formik.handleBlur}
        onChange={handleChannelTypeChange}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedChannelTypes')}
        withAsterisk={required}
        withSelectAll={false}
      />

      <If value={formik.values.selectedCompareCriteria.selectedChannelTypes.length > 0}>
        <VMultiSelect
          id="report-compare-channels"
          data-testid="input-report-compare-channels"
          label={t('page-reports:channels')}
          data={channelSelectItems}
          value={formik.values.selectedCompareCriteria.selectedChannelIds}
          placeholder={isFetching ? t('loading') : t('search')}
          isValuesPillLoading={isFetching}
          loading={isFetching}
          searchable={true}
          clearable={true}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedChannelIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedChannelIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
