import { createListenerMiddleware } from '@reduxjs/toolkit';
import { refetchNotificationsAction } from '../actions';
import { changeSelectedAccountAction } from '../states';

export const ListenerMiddleware = createListenerMiddleware();

/*
* Burada dispatch edilen farklı "action" lar dinlenebilir ve istenilen side effect koşulabilir
*
ListenerMiddleware.startListening({
  actionCreator: action1,
  effect: async (action, listenerApi) => {
    // Custom logic
  },
});

ListenerMiddleware.startListening({
  actionCreator: action2,
  effect: async (action, listenerApi) => {
    // Custom logic
  },
});
* */

ListenerMiddleware.startListening({
  actionCreator: changeSelectedAccountAction,
  effect: async (action, listenerApi) => {
    // Seçili hesap değiştiği zaman bildirimler tekrar çekilir
    listenerApi.dispatch(refetchNotificationsAction(action.payload));
  },
});
