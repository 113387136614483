import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfigExtended } from '@vision/ui/interfaces';
import {
  failureUploadProgress,
  getNextFileId,
  removeUploadFileProgress,
  removeUploadProgress,
  startUploadProgress,
  store,
  successUploadProgress,
  updateUploadProgress,
} from '@vision/ui/store';
import { ENV, insertIfObject, uuid } from '@vision/ui/utils';
import { createHttpEvent } from '@vision/ui/window-events';
import axios, { AxiosError, AxiosProgressEvent, AxiosResponse, CanceledError } from 'axios';

const dispatchNotificationHttpEvent = createHttpEvent('dispatchHttpNotification');

function trackUploadProgress(event: AxiosProgressEvent, uploadTrackId: string, fileId: number) {
  const { loaded, total } = event;
  const progress = Math.floor((loaded / total) * 100);

  store.dispatch(updateUploadProgress({ id: uploadTrackId, progress, fileId }));
}

export const axiosBaseQuery =
  (baseUrl = ENV.API_URL): BaseQueryFn<AxiosRequestConfigExtended, unknown, AxiosResponse> =>
  async ({ url, method, data, params, uploadTrackId, extraOptions }, { abort, signal }) => {
    const { selectedAccountId, token } = store.getState().AuthState;
    const isUpload = !!uploadTrackId;
    const fileId = isUpload ? getNextFileId(store.getState().UploadProgressState.progress, uploadTrackId) : null;

    if (isUpload) {
      const listener = () => {
        store.dispatch(removeUploadProgress({ id: uploadTrackId }));
        signal.removeEventListener('abort', listener);
      };
      signal.addEventListener('abort', listener);

      const formData = data as FormData;
      const files = formData.getAll('file') as File | File[];
      store.dispatch(
        startUploadProgress({
          abort,
          files,
          id: uploadTrackId,
          remove: () => store.dispatch(removeUploadFileProgress({ id: uploadTrackId, fileId })),
        }),
      );
    }

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        signal,
        ...insertIfObject(isUpload, {
          onUploadProgress: (event) => trackUploadProgress(event, uploadTrackId, fileId),
        }),
        headers: {
          ...insertIfObject(!!token, {
            Authorization: `Token token="${token}"`,
          }),
          ...insertIfObject(!!selectedAccountId, {
            'x-node-id': selectedAccountId,
          }),
          'x-correlation-id': uuid(),
        },
      });

      if (isUpload) {
        store.dispatch(successUploadProgress({ id: uploadTrackId, fileId }));
      }

      dispatchNotificationHttpEvent({
        extraOptions,
        meta: result,
      });

      return result;
    } catch (axiosError) {
      const err = axiosError as AxiosError | CanceledError<unknown>;

      dispatchNotificationHttpEvent({
        extraOptions,
        meta: err,
      });

      if (isUpload) {
        store.dispatch(failureUploadProgress({ id: uploadTrackId, fileId }));
      }
      return {
        data: null,
        error: (err.response || err) as AxiosResponse,
        meta: null,
      };
    }
  };
