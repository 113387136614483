import { Group, Text } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { FilterAddButton, FilterClearAll, FilterSelector } from '@vision/ui/components';
import { useDataGridContext } from '@vision/ui/context';
import { FilterKey, FilterType } from '@vision/ui/enums';
import { Filter, FilterSelections } from '@vision/ui/interfaces';
import { getDefaultFilterSelections, processFilterSelections } from '@vision/ui/utils';
import { dequal } from 'dequal';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridFilterRenderer } from '../DataGridFilterControls';
import { DataGridFilterDrawer } from '../DataGridFilterDrawer';
import { DataGridFilterSelectionTooltipLabel } from '../DataGridFilterSelectionTooltipLabel';
import classes from './DataGridFilters.module.scss';

interface FilterWithSelected extends Filter {
  selected: boolean;
}

interface DataGridFiltersProps {
  filterSelectionsFromUrl: FilterSelections[];
  onFilterSelectionChange: (selections: FilterSelections[]) => void;
}

function _DataGridFilters({ filterSelectionsFromUrl, onFilterSelectionChange }: DataGridFiltersProps) {
  const { meta } = useDataGridContext();
  const { t } = useTranslation(['translation', 'data-grid']);

  const [filterSelections, setFilterSelections] = useState<FilterSelections[]>(() => {
    if (filterSelectionsFromUrl && filterSelectionsFromUrl.length) {
      return filterSelectionsFromUrl;
    }
    return processFilterSelections(meta);
  });

  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false);
  const [searchText, setSearchText] = useInputState('');

  const fixedFilters = useMemo<FilterWithSelected[]>(
    () =>
      meta.filters
        .filter((item) => item.fixed && item.filterable)
        .map((item) => ({
          ...item,
          selected: filterSelections.some((selectedFilter) => selectedFilter.key === item.key),
        })),
    [meta, filterSelections],
  );

  const extraSelectedFiltersCount = useMemo<number>(
    () =>
      meta.filters
        .filter((item) => !item.fixed)
        .map((item) => ({
          ...item,
          selected: filterSelections.some((selectedFilter) => selectedFilter.key === item.key),
        }))
        .filter((item) => item.selected).length,
    [meta, filterSelections],
  );

  const defaultSelectedFilterKeys = useMemo<string[]>(
    () =>
      meta.filters
        .filter(
          (filter) =>
            filter.key === FilterKey.STATUS &&
            filter.type === FilterType.RADIO &&
            filter.options.some((option) => option.default_selected),
        )
        .map((filter) => filter.key),
    [meta],
  );

  const handleClearFilter = (filter: Filter) => {
    const filtered = filterSelections.filter((item) => item.key !== filter.key);
    onFilterSelectionChange(filtered);
    setFilterSelections(filtered);
  };

  const handleClearAll = () => {
    onFilterSelectionChange([]);
    setFilterSelections(getDefaultFilterSelections(meta));
  };

  const handleUpdateFiltersFromDrawer = (selections: FilterSelections[]) => {
    if (!dequal(filterSelections, selections)) {
      setFilterSelections(selections);
      onFilterSelectionChange(selections);
    }
  };

  const handleUpdateFilterSelections = (selections: FilterSelections[]) => {
    if (!dequal(filterSelections, selections)) {
      setFilterSelections(selections);
      onFilterSelectionChange(selections);
    }

    // Close Drawer if it is open
    handleDrawerClose();
  };

  const handleDrawerClose = useCallback(() => {
    setFilterDrawerOpened(false);
  }, [setFilterDrawerOpened]);

  const handleDrawerOpen = useCallback(() => {
    setFilterDrawerOpened(true);
  }, [setFilterDrawerOpened]);

  return (
    <>
      <Group gap={10} className={classes.dataGridFilters} data-testid="section-data-grid-filters">
        <Text c="gray" fw={700} component="span" className={classes.dataGridFiltersText}>
          {t('data-grid:filters')}
        </Text>

        <Group gap={10} className={classes.dataGridFiltersFixed}>
          {fixedFilters.map((filter) => {
            const isDefaultSelectedFilter = defaultSelectedFilterKeys.includes(filter.key);

            return (
              <FilterSelector
                clearable={!isDefaultSelectedFilter}
                key={filter.key}
                id={filter.key}
                label={filter.label}
                selected={filter.selected}
                tooltipDisabled={!filter.selected}
                tooltipChildren={
                  <DataGridFilterSelectionTooltipLabel filter={filter} filterSelections={filterSelections} />
                }
                onSearch={setSearchText}
                onClear={() => handleClearFilter(filter)}
                dropdownWidth={filter.type == FilterType.DATEPICKER && 500}
                searchable={filter.type !== FilterType.TEXT && filter.type !== FilterType.DATEPICKER}
              >
                <DataGridFilterRenderer
                  isDefaultSelectedFilter={isDefaultSelectedFilter}
                  filter={filter}
                  filterSelections={filterSelections}
                  onFilterChange={handleUpdateFilterSelections}
                  searchText={searchText}
                  radioDatepickerControlOptions={{ datepickerVisible: false }}
                  size="xs"
                />
              </FilterSelector>
            );
          })}
        </Group>

        <FilterAddButton
          className={classes.dataGridFiltersBtnAdd}
          count={extraSelectedFiltersCount}
          onClick={handleDrawerOpen}
          data-testid="button-filter-add"
        >
          <Text component="span" size="xs" className={classes.dataGridFiltersBtnAddText}>
            {t('data-grid:addFilter')}
          </Text>
        </FilterAddButton>

        <FilterClearAll
          className={classes.dataGridFiltersBtnClear}
          onClick={handleClearAll}
          data-testid="button-filter-clear-all"
        />
      </Group>

      <DataGridFilterDrawer
        filterSelections={filterSelections}
        opened={filterDrawerOpened}
        onClose={handleDrawerClose}
        onFilterSelectionChange={handleUpdateFiltersFromDrawer}
      />
    </>
  );
}

export const DataGridFilters = React.memo(_DataGridFilters);
