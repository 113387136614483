import { RelativeTimeFormatSingularUnit } from '@formatjs/ecma402-abstract';
import dayjs from 'dayjs';
import { forwardRef, useMemo } from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { DateFormatter } from '@vision/ui/components';
import { DateFormatterProps } from '../DateFormatter';

const units: RelativeTimeFormatSingularUnit[] = ['year', 'month', 'day', 'hour', 'minute', 'second']; // Do not change the order

function findClosestRelativeTime(value: Date) {
  const date = dayjs(new Date(value));
  return units.find((unit) => Math.abs(date.diff(new Date(), unit)) > 0);
}

export interface DateRelativeTimeProps extends Pick<DateFormatterProps, 'variant'> {
  alwaysRelative?: boolean;
  value: Date;
}

export const DateRelativeTime = forwardRef<HTMLSpanElement, DateRelativeTimeProps>(
  ({ alwaysRelative, value, variant, ...props }, ref) => {
    const { diff, unit } = useMemo(() => {
      const date = dayjs(new Date(value));
      const closestUnit = findClosestRelativeTime(value);
      return {
        diff: date.diff(new Date(), closestUnit),
        unit: closestUnit,
      };
    }, [value]);

    const interval = useMemo(() => {
      if (unit === 'second') {
        return 1;
      }
      if (unit === 'minute') {
        return 59;
      }
      return null;
    }, [unit]);

    if (!alwaysRelative && (unit === 'day' || unit === 'month' || unit === 'year') && Math.abs(diff) > 5) {
      return (
        <span ref={ref} {...props}>
          <DateFormatter value={value} variant={variant} />
        </span>
      );
    }

    return (
      <span ref={ref} {...props}>
        <FormattedRelativeTime value={diff} unit={unit} updateIntervalInSeconds={interval} />
      </span>
    );
  },
);
