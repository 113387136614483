import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, Directory, HttpBaseRequest } from '@vision/ui/interfaces';
import { axiosBaseQuery } from './base.service';

export const FileService = createApi({
  reducerPath: 'FileService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    directories: builder.query<ApiResponse<Directory[]>, HttpBaseRequest<{ accountId: string }>>({
      query: ({ accountId }) => ({
        url: `/v2/file_service/${accountId}/directories`,
        method: HttpMethod.GET,
      }),
    }),
    directory: builder.query<ApiResponse<Directory>, HttpBaseRequest<{ accountId: string; directoryId: string }>>({
      query: ({ accountId, directoryId }) => ({
        url: `/v2/file_service/${accountId}/directories/${directoryId}`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useDirectoriesQuery, useDirectoryQuery } = FileService;
