import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { HttpBaseRequest, INode, User } from '@vision/ui/interfaces';
import { axiosBaseQuery } from './base.service';

export const NodesService = createApi({
  reducerPath: 'NodesService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNodeChildren: builder.query<
      INode[],
      HttpBaseRequest<{ nodeId: string; lightweight?: boolean; includeSelf?: boolean; type?: string }>
    >({
      query: ({ nodeId, lightweight, includeSelf, type }) => ({
        url: `/v1/nodes/${nodeId}/children`,
        method: HttpMethod.GET,
        params: {
          lightweight,
          include_self: includeSelf,
          type,
        },
      }),
    }),
    nodeUsers: builder.query<User[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/nodes/${nodeId}/users`,
        method: HttpMethod.GET,
      }),
    }),
    nodeDetail: builder.query<INode, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/nodes/${nodeId}/detail`,
        method: HttpMethod.GET,
      }),
    }),
    nodeTypes: builder.query<User[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v1/nodes/${nodeId}/types`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const { useGetNodeChildrenQuery, useNodeUsersQuery, useNodeDetailQuery, useNodeTypesQuery } = NodesService;
