import { ReportChartGroupCompareItemType } from '@vision/ui/interfaces';
import React, { useEffect, useState } from 'react';

interface CompareRendererConfig {
  component: React.FC<{
    compareItemType?: ReportChartGroupCompareItemType;
    required: boolean;
  }>;
  required: boolean;
}

const compareComponentMapping: Partial<Record<ReportChartGroupCompareItemType, CompareRendererConfig>> = {};

interface GeoChart1CompareRendererProps {
  compareItemTypes: ReportChartGroupCompareItemType[];
}

export function GeoChart1CompareRenderer({ compareItemTypes }: GeoChart1CompareRendererProps) {
  const [components, setComponents] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const newComponents: JSX.Element[] = compareItemTypes.map((compareItemType) => {
      const config = compareComponentMapping[compareItemType];

      if (!config) {
        return null;
      }

      const Component = config.component;

      return <Component key={compareItemType} compareItemType={compareItemType} required={config.required} />;
    });

    setComponents(newComponents);
  }, [compareItemTypes]);

  return <>{components}</>;
}
