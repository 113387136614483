import { VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareFlowProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareFlow({ required }: ReportsDetailGraphicCompareFlowProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching } = useGetFlowsQuery({
    nodeId: accountId,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(flows, (item) => ({
        value: item.id,
        label: item.name,
      })),
    [flows],
  );

  return (
    <ReportsDetailGraphicCompareLayout title={t('flows')}>
      <VMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        data={selectItems}
        placeholder={isFetching ? t('loading') : t('search')}
        loading={isFetching}
        isValuesPillLoading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedFlowIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedFlowIds', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedFlowIds')}
        withAsterisk={required}
        withSelectAll={false}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
