import React from 'react';

interface CustomIconBarChart5Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconBarChart5({ height, width, active, ...props }: CustomIconBarChart5Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M4 8L4 20H7L7 8H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M8 10V20H12V10H8Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M17 15V20H20V15H17Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M13 4L13 20H16V4H13Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
    </svg>
  );
}
