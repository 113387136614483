import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, Group, Popover, PopoverProps, Text } from '@mantine/core';
import React, { cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { If } from '../If';
import { TooltipCardContainer } from '../TooltipCard';
import classes from './PopConfirm.module.scss';

interface PopConfirmProps {
  autoToggle?: boolean;
  btnCancelLabel?: string;
  btnConfirmLabel?: string;
  cancelBtnProps?: ButtonProps;
  closeOnClickOutside?: boolean;
  confirmBtnProps?: ButtonProps;
  description?: string;
  disabled?: boolean;
  onCancel?: VoidFunction;
  onConfirm: VoidFunction;
  opened?: boolean;
  position?: PopoverProps['position'];
  title: string;
  width?: number;
}

export const PopConfirm = React.forwardRef<React.ReactElement, React.PropsWithChildren<PopConfirmProps>>(
  (
    {
      autoToggle = true,
      btnCancelLabel,
      btnConfirmLabel,
      cancelBtnProps,
      children,
      closeOnClickOutside = true,
      confirmBtnProps,
      description,
      disabled,
      onCancel,
      onConfirm,
      opened: openedProp,
      title,
      width = 215,
      position,
    }: React.PropsWithChildren<PopConfirmProps>,
    ref,
  ) => {
    const [opened, setOpened] = useState<boolean>(openedProp || false);
    const { t } = useTranslation();

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
      onConfirm.call(e);
    };

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
      setOpened(false);
      onCancel?.call(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape' && opened) {
        setOpened(false);
      }
    };

    useEffect(() => {
      if (typeof openedProp === 'boolean') {
        setOpened(openedProp);
      }
    }, [openedProp]);

    return (
      <Popover
        opened={opened}
        onChange={setOpened}
        onClose={() => handleCancel(undefined)}
        width={width}
        closeOnClickOutside={closeOnClickOutside}
        disabled={disabled}
        {...(position ? { position } : {})}
      >
        <Popover.Target>
          {cloneElement(children as React.ReactElement, {
            ref,
            onClick: (e: React.MouseEvent) => {
              if (React.isValidElement(children)) {
                children?.props.onClick?.(e);
              }

              if (autoToggle) {
                setOpened(!opened);
              }
            },
            onKeyDown: (e: React.KeyboardEvent<any>) => {
              if (React.isValidElement(children)) {
                children?.props.onKeyDown?.(e);
              }
              handleKeyDown(e);
            },
          })}
        </Popover.Target>
        <Popover.Dropdown className={classes.popConfirmDropdown}>
          <TooltipCardContainer>
            <Text mb={10} fz={14} fw={500}>
              {title}
            </Text>
            <If value={!!description}>
              <Text size="xs" mb={10}>
                {description}
              </Text>
            </If>

            <Group justify="flex-end" gap={10}>
              <Button
                leftSection={<FontAwesomeIcon icon={faCheck} fontSize={14} />}
                size="compact-xs"
                variant="outline"
                onClick={handleConfirm}
                {...confirmBtnProps}
              >
                {btnConfirmLabel || t('confirm')}
              </Button>
              <Button
                leftSection={<FontAwesomeIcon icon={faXmark} fontSize={14} />}
                size="compact-xs"
                variant="outline"
                onClick={handleCancel}
                {...cancelBtnProps}
              >
                {btnCancelLabel || t('cancel')}
              </Button>
            </Group>
          </TooltipCardContainer>
        </Popover.Dropdown>
      </Popover>
    );
  },
);
