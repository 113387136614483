import { useDisclosure } from '@mantine/hooks';
import { useNavigationLinkMatched, useNavigationLinks } from '@vision/ui/hooks';
import { NavigationLink } from '@vision/ui/interfaces';
import React, { createContext, useEffect, useMemo, useState } from 'react';

interface LayoutNavigationHamburgerContextValues {
  handleMenuClose: VoidFunction;
  handleMenuOpen: VoidFunction;
  handleSelectLinkGroup: (link: NavigationLink) => void;
  links: NavigationLink[];
  menuOpened: boolean;
  resetSelectedMenuItems: VoidFunction;
  selectedSubMenuItems: NavigationLink[];
}

export const LayoutNavigationHamburgerContext = createContext<LayoutNavigationHamburgerContextValues>({
  handleMenuClose: null,
  handleMenuOpen: null,
  handleSelectLinkGroup: null,
  links: [],
  menuOpened: false,
  resetSelectedMenuItems: null,
  selectedSubMenuItems: [],
});

export function LayoutNavigationHamburgerProvider({ children }: React.PropsWithChildren) {
  const navigationLinks = useNavigationLinks().filter((link) => !link.hideFromNavigation);
  const matchedLink = useNavigationLinkMatched();
  const [menuOpened, { close: closeMenu, open: openMenu }] = useDisclosure(false);
  const [selectedSubMenuItems, setSelectedSubMenuItems] = useState<NavigationLink[]>([]);
  const [links, setLinks] = useState<NavigationLink[]>(() => navigationLinks);

  const resetSelectedMenuItems = () => {
    setSelectedSubMenuItems([]);
    setLinks(navigationLinks);
  };

  const handleMenuClose = () => {
    closeMenu();
    resetSelectedMenuItems();
  };

  const handleMenuOpen = () => {
    openMenu();
  };

  const handleSelectLinkGroup = (link: NavigationLink) => {
    setSelectedSubMenuItems((prevItems) => {
      const exist = prevItems.some((item) => item.id === link.id);
      if (exist) {
        return prevItems;
      }
      return [...prevItems, link];
    });

    if (link.items.length) {
      setLinks(link.items);
    } else {
      link.onClick?.();
      handleMenuClose();
    }
  };

  const handleInitialMenuState = () => {
    if (!matchedLink) {
      return;
    }
    const matchFound = links.find(
      (item) => item.id === matchedLink.id || item.id === matchedLink.parentNavigationLink?.id,
    );
    if (matchFound && matchFound.items.length && !matchFound.itemsHidden) {
      handleSelectLinkGroup(matchFound);
    }
  };

  useEffect(() => {
    if (menuOpened) {
      handleInitialMenuState();
    }
  }, [menuOpened]);

  const contextValues: LayoutNavigationHamburgerContextValues = useMemo(
    () => ({
      handleMenuClose,
      handleMenuOpen,
      handleSelectLinkGroup,
      links,
      menuOpened,
      resetSelectedMenuItems,
      selectedSubMenuItems,
    }),
    [
      handleMenuClose,
      handleMenuOpen,
      handleSelectLinkGroup,
      links,
      menuOpened,
      resetSelectedMenuItems,
      selectedSubMenuItems,
    ],
  );

  return (
    <LayoutNavigationHamburgerContext.Provider value={contextValues}>
      {children}
    </LayoutNavigationHamburgerContext.Provider>
  );
}
