import { MantineColor, Stack, Text, TextProps, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { useCreateContext } from '@vision/ui/hooks';
import { ForwardRefWithStaticComponents } from '@vision/ui/interfaces';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import classes from './BoxButton.module.scss';

export type BoxButtonContext = Partial<{
  active: boolean;
  activeColor: MantineColor;
  color: MantineColor;
}>;

export const [BoxButtonContextProvider, useBoxButtonContext] = useCreateContext<BoxButtonContext>();

interface BoxButtonProps extends UnstyledButtonProps, React.DOMAttributes<HTMLButtonElement> {
  active?: boolean;
  color?: MantineColor;
  icon: React.ReactNode;
  passive?: boolean;
  disabled?: boolean;
  boxButtonStackClassName?: string;
}

const _BoxButton = forwardRef<HTMLButtonElement, React.PropsWithChildren<BoxButtonProps>>(
  (
    {
      children,
      className,
      color = 'gray',
      icon,
      active,
      passive,
      boxButtonStackClassName,
      disabled,
      ...props
    }: React.PropsWithChildren<BoxButtonProps>,
    ref,
  ) => (
    <BoxButtonContextProvider
      value={{
        active,
        activeColor: 'blue',
        color,
      }}
    >
      <UnstyledButton
        {...props}
        ref={ref}
        className={clsx(classes.boxButton, className, {
          [classes.boxButtonActive]: disabled ? false : active,
          [classes.boxButtonPassive]: passive,
          [classes.boxButtonDisabled]: disabled,
        })}
      >
        <Stack gap={4} align="center" className={boxButtonStackClassName}>
          {icon}

          {children}
        </Stack>
      </UnstyledButton>
    </BoxButtonContextProvider>
  ),
) as any;

function BoxButtonTitle({ children, color: colorProp, className, ...props }: React.PropsWithChildren<TextProps>) {
  const { active, activeColor, color } = useBoxButtonContext();

  const getColor = () => {
    if (colorProp) {
      return colorProp;
    }

    if (active) {
      return activeColor || colorProp || color;
    }

    return colorProp || color;
  };

  return (
    <Text className={clsx(classes.boxButtonLabel, className)} c={getColor()} {...props}>
      {children}
    </Text>
  );
}

function BoxButtonDescription({ children, ...props }: React.PropsWithChildren<TextProps>) {
  const { color } = useBoxButtonContext();

  return (
    <Text c={color} {...props}>
      {children}
    </Text>
  );
}

_BoxButton.Title = BoxButtonTitle;
_BoxButton.Description = BoxButtonDescription;

export const BoxButton: ForwardRefWithStaticComponents<
  BoxButtonProps,
  { Title: typeof BoxButtonTitle; Description: typeof BoxButtonDescription }
> = _BoxButton;
