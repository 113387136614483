import { ComboboxItem, isOptionsGroup } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { BaseQueryFn, TypedUseLazyQuery } from '@reduxjs/toolkit/query/react';
import { ApiResponse, AxiosRequestConfigExtended, OptionsGroup } from '@vision/ui/interfaces';
import { ensureArray, filterUniqueItems, insertIfObject, uniqueArray } from '@vision/ui/utils';
import type { AxiosResponse } from 'axios';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect, useEffectOnce, useFirstMountState } from 'react-use';

const uniqueComboBoxOrOptionsGroupArray = (items: Array<ComboboxItem | OptionsGroup>) => {
  if (items.length > 0) {
    const firstItem = items[0];
    const isGroup = isOptionsGroup(firstItem);

    if (isGroup) {
      return items;
    } else {
      const comboboxItemArray = items as Array<ComboboxItem>;
      const uniqueItems = Array.from(new Map(comboboxItemArray.map((item) => [item.value, item])).values());
      return uniqueItems;
    }
  }

  return items;
};

type SingleSelectItemMapper<ResponseType> = (item: ResponseType) => ComboboxItem;
type MultiSelectItemMapper<ResponseType> = (items: ResponseType[]) => Array<ComboboxItem | OptionsGroup>;
type LazyLoadResponseType<ResponseType> = Array<ResponseType & { id: string }>;

export interface UseLazyLoadSelectOptions<RequestType, ResponseType> {
  searchKey?: string;
  filterKey?: string;
  apiRequestParameters?: Omit<RequestType, 'query'>;
  disabledCondition?: (item: ComboboxItem) => boolean;
  multiSelectItemMapper?: MultiSelectItemMapper<ResponseType>;
  singleSelectItemMapper?: SingleSelectItemMapper<ResponseType>;
  useLazyApiQueryFunction: TypedUseLazyQuery<
    ApiResponse<LazyLoadResponseType<ResponseType>>,
    RequestType,
    BaseQueryFn<AxiosRequestConfigExtended, unknown, AxiosResponse<any, any>>
  >;
  defaultValues?: string | string[];
  resetChangeRequestParameters?: boolean;
}

export function useLazyLoadSelect<RequestType, ResponseType>({
  searchKey = 'name',
  filterKey = 'ids',
  apiRequestParameters,
  disabledCondition,
  multiSelectItemMapper,
  singleSelectItemMapper,
  useLazyApiQueryFunction,
  defaultValues,
  resetChangeRequestParameters = false,
}: UseLazyLoadSelectOptions<RequestType, ResponseType>) {
  const isFirstMount = useFirstMountState();
  const [data, setData] = useState<Array<ComboboxItem | OptionsGroup>>([]);
  const [allData, setAllData] = useState<LazyLoadResponseType<ResponseType>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 300);

  const [getQueryFn, { data: queryResponse, isFetching: isLoading }] = useLazyApiQueryFunction();
  const [getQueryDataFn, { data: queryDataResponse, isFetching: isDefaultDataLoading }] = useLazyApiQueryFunction();
  const [getQueryFilterFn, { data: queryFilteredResponse, isFetching: isFilterQueryLoading }] =
    useLazyApiQueryFunction();
  const [loadedDefaultData, setLoadedDefaultData] = useState(false);

  const handleMapSelectItems = (response: LazyLoadResponseType<ResponseType>) => {
    if (singleSelectItemMapper) {
      return response.map(singleSelectItemMapper);
    }

    if (multiSelectItemMapper) {
      return multiSelectItemMapper(response);
    }

    // Default mapper
    return response.map((item) => ({
      value: item.id,
      label: item.id,
    }));
  };

  const allDataSelectItems = useMemo(() => handleMapSelectItems(allData), [allData]);

  const dataSelectItemsWithDisabledCondition: Array<ComboboxItem | OptionsGroup> = useMemo(() => {
    if (disabledCondition) {
      return data.map((item) => {
        const isGroup = isOptionsGroup(item);
        if (isGroup) {
          return {
            ...item,
            items: ensureArray(item.items).map((child) => ({
              ...child,
              disabled: disabledCondition(child) ?? false,
            })),
          };
        }
        return {
          ...item,
          disabled: disabledCondition(item) ?? false,
        };
      });
    }

    return data;
  }, [disabledCondition, data]);

  const handleLoadMore = (page: number) => {
    getQueryFn({
      query: qs.stringify({
        page,
        per_page: 20,
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const handleGetData = (value: Array<string> | string) => {
    getQueryDataFn({
      query: qs.stringify({
        [filterKey]: ensureArray(value).join(','),
        per_page: 50,
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const handleSearch = (query: string) => {
    // Query yok ise veya tüm sayfalar yüklenmiş ise API ye istek atma, Select componenti kendi içerisinde yazılan değeri zaten arayabiliyor
    if (!query || allPagesLoaded) {
      // Filtreleme yok ise şimdiye kadar çekilen tüm veriyi göster
      setData(allDataSelectItems);
      return;
    }

    getQueryFilterFn({
      query: qs.stringify({
        page: 1,
        per_page: 20,
        ...insertIfObject(!!query, {
          [searchKey]: query,
        }),
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const contextValue = useMemo(
    () => ({
      loadMore: handleLoadMore,
      currentPage: queryResponse?.meta?.pagination?.current,
      totalPage: queryResponse?.meta?.pagination?.total_pages,
    }),
    [handleLoadMore, queryResponse],
  );

  useEffect(() => {
    if (!queryResponse) {
      return;
    }

    // Bir sonraki sayfa yok ise tüm sayfalar çekilmiştir
    if (!queryResponse.meta.pagination.next) {
      setAllPagesLoaded(true);
    }

    // Gelen verileri filtrele ve eğer zaten var ise bir sonraki sayfayı çek
    const list = filterUniqueItems(handleMapSelectItems(queryResponse.data), data);

    // Eğer gelen item lar içinde hiçbir data yok ise bir sonraki sayfayı çek
    if (list.length > 0) {
      // Gelen verileri ayrı bir "allData" değeriyle tut, filtreleme yapıp filtreyi silerse şimdiye kadar çekilmiş verileri göstermek için kullanılır
      setAllData((prev) => uniqueArray(prev.concat(queryResponse.data), 'id'));
      setData((prev) => prev.concat(list));
    } else if (queryResponse.meta.pagination.next) {
      handleLoadMore(queryResponse.meta.pagination.current + 1);
    }
  }, [queryResponse]);

  useEffect(() => {
    if (!queryFilteredResponse) {
      return;
    }

    const list = handleMapSelectItems(queryFilteredResponse.data);

    setAllData((prev) => uniqueArray(prev.concat(queryFilteredResponse.data), 'id'));
    setData((prev) => uniqueComboBoxOrOptionsGroupArray(prev.concat(filterUniqueItems(list, data))));
  }, [queryFilteredResponse]);

  useEffect(() => {
    if (!queryDataResponse) {
      return;
    }

    const list = handleMapSelectItems(queryDataResponse.data);
    setLoadedDefaultData(true);
    setAllData((prev) => uniqueArray(prev.concat(queryDataResponse.data), 'id'));
    setData((prev) => uniqueComboBoxOrOptionsGroupArray(prev.concat(filterUniqueItems(list, data))));
  }, [queryDataResponse]);

  useEffect(() => {
    handleSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const fetchInitialData = (values: Array<string> | string) => {
    // Mount olunca ilk sayfayı çek
    handleLoadMore(1);

    // Default değerler var ise API den veriyi çek
    if (values && values.length > 0) {
      setLoadedDefaultData(false);
      handleGetData(values);
    } else {
      setLoadedDefaultData(true);
    }
  };

  useEffectOnce(() => {
    fetchInitialData(defaultValues);
  });

  useDeepCompareEffect(() => {
    // Parametreler değişince sıfırdan istek işlemleri başlamalı. Bu opsiyonel olmalı
    if (resetChangeRequestParameters && !isFirstMount) {
      // defaultValues ler sabit dir. bunları silmemek gerekiyor.
      setAllData([]);
      setData([]);
      fetchInitialData(defaultValues);
    }
  }, [resetChangeRequestParameters, apiRequestParameters]);

  return {
    allData,
    allDataSelectItems,
    contextValue,
    dataSelectItemsWithDisabledCondition,
    isFilterQueryLoading,
    isLoading,
    setSearchQuery,
    allPagesLoaded,
    isDefaultDataLoading,
    loadedDefaultData,
  };
}
