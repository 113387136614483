import { faArrowDownLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComboboxItem, ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { VMultiSelect, VSelect } from '@vision/ui/components';
import { useAppSelector, useReportCombination, useReportCombinationMulti } from '@vision/ui/hooks';
import {
  ReportChartGroupCompare,
  ReportChartGroupCompareConfig,
  ReportChartMeasureType,
  ReportsDetailGraphicSchemaValues,
} from '@vision/ui/interfaces';
import { convertToSelectGroupItems } from '@vision/ui/pages/ReportsDetailGraphic/utils';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicConfigurationValueCompare } from '../../../ReportsDetailGraphicConfigurationValue';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';
import { BarChart3LineMeasures, BarChart3Measures } from './bar-chart3.measures';

export function BarChart3ChartValueComponent() {
  const { t } = useTranslation(['translation', 'page-reports']);
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const reportCombinationLine: ReportChartGroupCompareConfig = useReportCombination(
    selectedChartGroupItem?.type,
    formik.values?.selectedLineMeasure,
  );
  const reportCombinationBar: ReportChartGroupCompareConfig[] = useReportCombinationMulti(
    selectedChartGroupItem?.type,
    formik.values?.selectedBarMeasures,
  );

  // compare 2 can be one.
  // bar as much as it wants
  // in matrix instead of simple compare question comes.
  const handleMeasureChange = (value: ReportChartMeasureType) => {
    formik.setFieldValue('selectedLineMeasure', value as ReportChartMeasureType);
  };

  const handleBarMeasuresChange = (value: ReportChartMeasureType[]) => {
    formik.setFieldValue('selectedBarMeasures', value as ReportChartMeasureType[]);
  };

  const lineMeasurementSelectItems = useMemo(() => {
    if (!selectedChartGroupItem) {
      return [];
    }

    return convertToSelectGroupItems(
      BarChart3LineMeasures,
      'reportMeasurementsGroupType',
      'reportMeasurementsGroupItemType',
    );
  }, [selectedChartGroupItem]);

  const measurementSelectItems = useMemo(() => {
    if (!selectedChartGroupItem) {
      return [];
    }

    return convertToSelectGroupItems(
      BarChart3Measures,
      'reportMeasurementsGroupType',
      'reportMeasurementsGroupItemType',
    );
  }, [selectedChartGroupItem]);

  const compareSelectLineItems = useMemo(() => {
    if (!reportCombinationLine) {
      return [];
    }

    return convertToSelectGroupItems(
      reportCombinationLine.compareItems,
      'reportCompareGroupType',
      'reportCompareGroupItemType',
    );
  }, [reportCombinationLine]);

  const compareSelectBarItems: ComboboxParsedItemGroup[] = useMemo(() => {
    if (!reportCombinationBar) {
      return [];
    }

    const allCompareItems: ReportChartGroupCompare[] = reportCombinationBar.reduce((acc, curr) => {
      return [...acc, ...curr.compareItems];
    }, []);

    // We group all Compare Items by type, combine the items in each group and return them.
    const allCompareItemsGrouped = allCompareItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.type === curr.type);
      if (existingGroup) {
        existingGroup.items = [...existingGroup.items, ...curr.items];
      } else {
        acc.push({ type: curr.type, items: [...curr.items] });
      }
      return acc;
    }, [] as ReportChartGroupCompare[]);

    const commonItems = allCompareItemsGrouped.filter((item) => {
      const existAll = reportCombinationBar.every((r) => r.compareItems.some((i) => i.type === item.type));
      return existAll;
    });

    return convertToSelectGroupItems(commonItems, 'reportCompareGroupType', 'reportCompareGroupItemType');
  }, [reportCombinationBar]);

  const compareSelectItems = useMemo(() => {
    // Assuming that the compareSelectLineItems, compareSelectBar1Items and compareSelectBar2Items arrays are in the format
    // [{group: string, items: {label: string, value: string}[]}], the following operations have been performed.
    // We combine and return the common elements in all three arrays. If the group names are the same, we reduce them to one.
    const allItems: ComboboxParsedItemGroup[] = [...compareSelectLineItems, ...compareSelectBarItems];

    const grouped: ComboboxParsedItemGroup[] = allItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.group === curr.group);
      if (existingGroup) {
        existingGroup.items = [...existingGroup.items, ...curr.items];
      } else {
        acc.push({ group: curr.group, items: [...curr.items] });
      }
      return acc;
    }, []);

    const uniqueGrouped = grouped.map((group) => {
      const itemsInAll = group.items.filter((item) => {
        const inAll =
          compareSelectLineItems.find((g) => g.group === group.group)?.items.some((i) => i.value === item.value) &&
          compareSelectBarItems.find((g) => g.group === group.group)?.items.some((i) => i.value === item.value);

        return inAll;
      });

      return {
        group: group.group,
        items: Array.from(new Set(itemsInAll.map((item) => item.value))).map(
          (value) => itemsInAll.find((item) => item.value === value) as ComboboxItem,
        ),
      };
    });

    return uniqueGrouped as ComboboxParsedItemGroup[];
  }, [compareSelectLineItems, compareSelectBarItems]);

  useEffect(() => {
    // If the values formik.values.selected Compare are not present in compare SelectItems, we delete the ones that are not present.
    const selectedCompareValues = formik.values.selectedCompare;
    const filteredSelectedCompareValues = selectedCompareValues.filter((value) =>
      compareSelectItems.some((group) => group.items.some((item) => item.value === value)),
    );

    if (selectedCompareValues.length !== filteredSelectedCompareValues.length) {
      formik.setFieldValue('selectedCompare', filteredSelectedCompareValues);
    }
  }, [compareSelectItems]);

  return (
    <Stack gap={20}>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader
          label={t('page-reports:measurementLineYAxis')}
          icon={<FontAwesomeIcon icon={faArrowDownLong} />}
        />

        <VSelect
          id="report-measurement-line"
          searchable={true}
          data-testid="input-report-measurement-line"
          data={lineMeasurementSelectItems}
          clearable={true}
          label={t('page-reports:measurement')}
          withAsterisk={true}
          value={formik.values?.selectedLineMeasure || null}
          onChange={handleMeasureChange}
          error={translateErrorMessage(formik, 'selectedLineMeasure')}
        />
      </Stack>

      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader
          label={t('page-reports:measurementBarYAxis')}
          icon={<FontAwesomeIcon icon={faArrowDownLong} />}
        />

        <VMultiSelect
          id="report-measurement-main"
          searchable={true}
          data-testid="input-report-measurement-bar"
          data={measurementSelectItems}
          clearable={true}
          withSelectAll={false}
          label={t('page-reports:measurement')}
          withAsterisk={true}
          value={formik.values?.selectedBarMeasures}
          onChange={handleBarMeasuresChange}
          placeholder={formik.values.selectedBarMeasures?.length < 2 ? t('translation:search') : null}
          error={translateErrorMessage(formik, 'selectedBarMeasures')}
          maxValues={3}
          withCloseDropdownMaxValue={true}
        />
      </Stack>

      <ReportsDetailGraphicConfigurationValueCompare
        compareSelectItems={compareSelectItems}
        disabled={!formik.values?.selectedLineMeasure || !formik.values?.selectedBarMeasures?.length}
      />
    </Stack>
  );
}
