import React from 'react';

interface CustomIconFunnelChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconFunnelChart1({ height, width, active, ...props }: CustomIconFunnelChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.01294 13.0066L4.0127 3.98706L19.9869 3.98706L15.9595 13.0066V19.9871H9.01294V13.0066Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path d="M19.9869 3.95557L19.0971 5.95557H5.11461L4.0127 3.95557H19.9869Z" fill="white" fillOpacity="0.5" />
    </svg>
  );
}
