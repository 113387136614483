import { Stack } from '@mantine/core';
import { VMultiSelect } from '@vision/ui/components';
import { useReportChartPeriodAndValueComparison } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../../ReportsDetailGraphicCompareComponents';

interface ReportsDetailGraphicMeasurePeriodAndValueComparisonProps {}

export function ReportsDetailGraphicMeasurePeriodAndValueComparison({}: ReportsDetailGraphicMeasurePeriodAndValueComparisonProps) {
  const { t } = useTranslation('page-reports');
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useReportChartPeriodAndValueComparison();

  return (
    <Stack gap={5}>
      <ReportsDetailGraphicCompareLayout title={t('periodAndValueComparison')}>
        <VMultiSelect
          id="report-measure-period-and-value-comparison"
          data-testid="input-report-measure-period-and-value-comparison-select"
          label={t('periodAndValueComparison')}
          data={selectItems}
          searchable={false}
          onChange={(value) => formik.setFieldValue('selectedPeriodAndValueComparison', value)}
          value={formik.values.selectedPeriodAndValueComparison}
          error={translateErrorMessage(formik, 'selectedPeriodAndValueComparison')}
          onBlur={formik.handleBlur}
        />
      </ReportsDetailGraphicCompareLayout>
    </Stack>
  );
}
