import { ComboboxItem } from '@mantine/core';
import { OptionsGroup, SegmentGroup, TagGroup } from '@vision/ui/interfaces';

export function groupItemsByName<
  T extends {
    id: string;
    name: string;
  },
>(groups: T[], key: keyof T): OptionsGroup[] {
  return groups.reduce((acc, group) => {
    const items: ComboboxItem[] = (group[key] as any[]).map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const exist = acc.find((item) => item.group === group.name);

    if (exist) {
      exist.items.push(...items);
      return acc;
    }

    return [
      ...acc,
      {
        group: group.name,
        items,
      },
    ];
  }, [] as OptionsGroup[]);
}

export function groupSegmentsByName(segmentGroups: SegmentGroup[]): OptionsGroup[] {
  return groupItemsByName(segmentGroups, 'segments');
}

export function groupTagGroupsByName(tagGroups: TagGroup[]): OptionsGroup[] {
  return groupItemsByName(tagGroups, 'tags');
}
