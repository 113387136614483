import { faAngleDown, faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './CsvExcelMenuSelect.module.scss';

export type ExportType = 'csv' | 'excel';

interface CsvExcelMenuSelectProps {
  onExport: (type: ExportType) => void;
}

const _CsvExcelMenuSelect = ({ onExport }: CsvExcelMenuSelectProps) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <Menu.Target>
        <Button
          data-testid="button-csv-excel-menu-select"
          className={clsx('csv-excel-menu-select', classes.exportBtn)}
          leftSection={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
          rightSection={<FontAwesomeIcon icon={faAngleDown} />}
          color="gray"
          variant="outline"
          size="sm"
        >
          {t('export')}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={() => onExport('csv')}>CSV</Menu.Item>
        <Menu.Item onClick={() => onExport('excel')}>Excel</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export const CsvExcelMenuSelect = React.memo(_CsvExcelMenuSelect);
