import { VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetUsersQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareUserProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareUser({ compareItemType, required }: ReportsDetailGraphicCompareUserProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: users, isFetching } = useGetUsersQuery({
    nodeId: accountId,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const formikKey = useMemo(() => {
    if (compareItemType === 'user-assigned') {
      return 'selectedUserAssignedIds';
    } else if (compareItemType === 'user-archiving') {
      return 'selectedUserArchivingIds';
    } else if (compareItemType === 'user-responsible') {
      return 'selectedUserResponsibleIds';
    } else return 'selectedUserIds';
  }, [compareItemType]);

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(users, (item) => ({
        value: item.id,
        label: item.full_name || item.email,
      })),
    [users],
  );

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-users"
        data-testid="input-report-compare-users"
        label={t('users')}
        name={`selectedCompareCriteria.${formikKey}`}
        data={selectItems}
        placeholder={isFetching ? t('loading') : t('search')}
        loading={isFetching}
        isValuesPillLoading={isFetching}
        value={formik.values.selectedCompareCriteria[formikKey]}
        onChange={(e) => {
          formik.setFieldValue(`selectedCompareCriteria.${formikKey}`, e);
        }}
        searchable={true}
        error={translateErrorMessage(formik, `selectedCompareCriteria.${formikKey}`)}
        withAsterisk={required}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
