import React from 'react';

interface CustomIconAdminProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconAdmin({ height, width, ...props }: CustomIconAdminProps) {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.226 2.045a.716.716 0 0 1 1.432 0v.825c0 .24.129.461.337.58l1.148.649a.665.665 0 0 0 .738-.056l1.085-.854a.4.4 0 0 1 .545.045.592.592 0 0 1 .051.732l-.777 1.14a.665.665 0 0 0-.005.742l.786 1.189a.665.665 0 0 0 .49.294l.968.092a.712.712 0 0 1 .646.71.741.741 0 0 1-.656.736l-.788.091a.665.665 0 0 0-.472.284l-.661.964a.665.665 0 0 0-.036.696l.535.98a.837.837 0 0 1-.097.943.427.427 0 0 1-.582.065l-1.26-.95a.665.665 0 0 0-.748-.037l-.62.38a.665.665 0 0 0-.31.484l-.166 1.323a.66.66 0 0 1-.656.577.622.622 0 0 1-.619-.562l-.1-1.041a.665.665 0 0 0-.51-.584l-1.18-.278a.665.665 0 0 0-.59.146l-.776.679a.68.68 0 0 1-.875.016.557.557 0 0 1-.108-.748l.673-.98a.665.665 0 0 0 .041-.686l-.69-1.319a.665.665 0 0 0-.59-.356h-.687a.811.811 0 0 1 0-1.622h.687a.665.665 0 0 0 .575-.33l.692-1.188a.665.665 0 0 0-.071-.767l-.474-.554a.784.784 0 0 1 .021-1.043.646.646 0 0 1 .906-.04l.669.602a.665.665 0 0 0 .816.059l.969-.65a.665.665 0 0 0 .294-.553zM7.942 0a2.046 2.046 0 0 0-2.046 2.045v.47l-.244.164-.284-.256a1.976 1.976 0 0 0-2.77.124A2.114 2.114 0 0 0 2.54 5.36l.168.196-.261.449h-.305a2.141 2.141 0 1 0 0 4.282h.285l.318.608-.447.65a1.888 1.888 0 0 0 .369 2.536c.76.613 1.85.594 2.585-.05l.52-.452.384.09.054.567A1.951 1.951 0 0 0 8.153 16a1.99 1.99 0 0 0 1.975-1.742l.12-.956.862.652a1.757 1.757 0 0 0 2.4-.266c.58-.682.68-1.654.25-2.441l-.34-.623.26-.377.487-.056A2.072 2.072 0 0 0 16 8.133c0-1.054-.8-1.934-1.85-2.034l-.65-.063-.364-.55.526-.771a1.922 1.922 0 0 0-.168-2.377 1.731 1.731 0 0 0-2.35-.195l-.735.578-.42-.24v-.436A2.046 2.046 0 0 0 7.941 0zm.059 4.798a3.254 3.254 0 1 0 0 6.508A3.254 3.254 0 0 0 8 4.8zM6.077 8.053a1.924 1.924 0 1 1 3.847 0 1.924 1.924 0 0 1-3.847 0z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
