import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query';
import { UploadStatus } from '@vision/ui/enums';

type Progress = Record<string, UploadLoaderProgress[]>;

export interface UploadLoaderProgress {
  abort: BaseQueryApi['abort'];
  files: File[];
  id: number;
  progress: number;
  remove: VoidFunction;
  status: UploadStatus;
}

function findFileIndex(progress: Progress, id: string, fileId: number) {
  return progress[id].findIndex((item) => item.id === fileId);
}

export function getNextFileId(progress: Progress, id: string) {
  const files = progress[id];
  if (!files) {
    return 1;
  }
  return files.length + 1;
}

export interface UploadStateType {
  progress: Progress;
}

const initialState: UploadStateType = {
  progress: {},
};

export const UploadProgressState = createSlice({
  name: 'UploadProgressState',
  initialState,
  reducers: {
    failureUploadProgress: (
      state,
      action: PayloadAction<{
        fileId: UploadLoaderProgress['id'];
        id: string;
      }>,
    ) => {
      const {
        payload: { fileId, id },
      } = action;
      const index = findFileIndex(state.progress, id, fileId);
      state.progress[id][index].status = UploadStatus.FAILED;
      state.progress[id][index].progress = 0;
    },
    removeUploadProgress: (state, action: PayloadAction<{ id: string }>) => {
      const { payload } = action;
      state.progress = Object.entries(state.progress)
        .filter(([key]) => key !== payload.id)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    },
    removeUploadFileProgress: (state, action: PayloadAction<{ fileId: number; id: string }>) => {
      const {
        payload: { fileId, id },
      } = action;
      state.progress[id] = state.progress[id].filter((item) => item.id !== fileId);
    },
    startUploadProgress: (
      state,
      action: PayloadAction<{ abort: BaseQueryApi['abort']; id: string; files: File | File[]; remove: VoidFunction }>,
    ) => {
      const { payload } = action;
      const files = Array.isArray(payload.files) ? payload.files : [payload.files];
      if (!state.progress[payload.id]) {
        state.progress[payload.id] = [];
      }
      state.progress[payload.id].push({
        abort: payload.abort,
        files,
        id: state.progress[payload.id].length + 1,
        progress: 0,
        remove: payload.remove,
        status: UploadStatus.UPLOADING,
      });
    },
    successUploadProgress: (
      state,
      action: PayloadAction<{
        fileId: UploadLoaderProgress['id'];
        id: string;
      }>,
    ) => {
      const {
        payload: { fileId, id },
      } = action;
      const index = findFileIndex(state.progress, id, fileId);
      state.progress[id][index].status = UploadStatus.UPLOADED;
    },
    updateUploadProgress: (
      state,
      action: PayloadAction<{
        fileId: UploadLoaderProgress['id'];
        id: string;
        progress: UploadLoaderProgress['progress'];
      }>,
    ) => {
      const {
        payload: { fileId, id, progress },
      } = action;

      const index = findFileIndex(state.progress, id, fileId);
      state.progress[id][index].progress = progress;
    },
  },
});

export const {
  failureUploadProgress,
  removeUploadFileProgress,
  removeUploadProgress,
  startUploadProgress,
  successUploadProgress,
  updateUploadProgress,
} = UploadProgressState.actions;
