import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { array, string } from 'yup';
import { HeatMapChart1CompareRenderer } from '../../components';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../default-chart.config';
import { HeatMapChart1Measures } from './heat-map-chart1.measures';

export const HeatMapChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema(
    {
      selectedCompare: array()
        .of(string().required('validation:rules.generic.required'))
        .min(2, 'validation:rules.generic.mustBeSelectedLength')
        .max(2, 'validation:rules.generic.mustBeSelectedLength'),
    },
    true,
  ),
  measures: HeatMapChart1Measures,
  compareRendererComponent: HeatMapChart1CompareRenderer,
  compareMultiple: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      labelDisplayFormat: 'number',
      hideFormatPosition: true,
    },
  },
};
