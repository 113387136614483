import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { Continent, Country, CountryState, GeoJSONSourceInput, TimeZone } from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const VisionResourcesService = createApi({
  reducerPath: 'VisionResourcesService',
  baseQuery: axiosBaseQuery('/resources'),
  endpoints: (builder) => ({
    getTimeZones: builder.query<TimeZone[], void>({
      query: () => ({
        url: '/time-zones.json',
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getContinents: builder.query<Continent[], void>({
      query: () => ({
        url: '/continents/continents.json',
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getCountries: builder.query<Country[], { continentCode: string }>({
      query: ({ continentCode }) => ({
        url: `/countries/${continentCode}.json`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getCountryCities: builder.query<CountryState[], { countryCode: string }>({
      query: ({ countryCode }) => ({
        url: `/cities/${countryCode}.json`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getAllContinentsGeoJson: builder.query<GeoJSONSourceInput, void>({
      query: () => ({
        url: '/geo/continents-geo.json',
        method: HttpMethod.GET,
      }),
    }),
    getCountriesGeoJson: builder.query<GeoJSONSourceInput, void>({
      query: () => ({
        url: '/geo/countries-geo.json',
        method: HttpMethod.GET,
      }),
    }),
    getContinentGeoJson: builder.query<GeoJSONSourceInput, { continentCode: string }>({
      query: ({ continentCode }) => ({
        //url: `/geo/continents/${continentCode}-geo.json`,
        url: continentCode === 'all' ? '/geo/continents-geo.json' : `/geo/continents/${continentCode}-geo.json`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getCountryGeoJson: builder.query<GeoJSONSourceInput, { countryCode: string }>({
      query: ({ countryCode }) => ({
        url: `/geo/countries/${countryCode}-geo.json`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
  }),
});

export const {
  useGetTimeZonesQuery,
  useGetContinentsQuery,
  useGetCountriesQuery,
  useGetCountryCitiesQuery,
  useGetAllContinentsGeoJsonQuery,
  useGetContinentGeoJsonQuery,
  useGetCountriesGeoJsonQuery,
  useGetCountryGeoJsonQuery,
} = VisionResourcesService;
