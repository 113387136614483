import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod, ReportType } from '@vision/ui/enums';
import { Language } from '@vision/ui/interfaces';
import { axiosBaseQuery } from './base.service';

export const ExDashboardService = createApi({
  reducerPath: 'ExDashboardService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getExDashboards: builder.query<Language[], { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards`,
        method: HttpMethod.GET,
        params: {
          type: ReportType.EX_DASHBOARD,
        },
      }),
    }),
  }),
});

export const { useGetExDashboardsQuery } = ExDashboardService;
