import { ReportChartGroupCompareItemType } from '@vision/ui/interfaces';
import React, { useEffect, useState } from 'react';
import {
  ReportsDetailGraphicCompareChannelType,
  ReportsDetailGraphicCompareCompany,
  ReportsDetailGraphicCompareContentCategory,
  ReportsDetailGraphicCompareFeedbackStatus,
  ReportsDetailGraphicCompareFlow,
  ReportsDetailGraphicCompareQuestionCategory,
  ReportsDetailGraphicCompareQuestionKey,
  ReportsDetailGraphicCompareQuestionOption,
  ReportsDetailGraphicCompareQuestionResponse,
  ReportsDetailGraphicCompareQuestionTitle,
  ReportsDetailGraphicCompareSchema,
  ReportsDetailGraphicCompareTagGroup,
  ReportsDetailGraphicCompareTagIntelligent,
  ReportsDetailGraphicCompareTagName,
  ReportsDetailGraphicCompareUnit,
  ReportsDetailGraphicCompareUser,
} from '../../../ReportsDetailGraphicCompareComponents';

interface CompareRendererConfig {
  component: React.FC<{
    compareItemType?: ReportChartGroupCompareItemType;
    required: boolean;
  }>;
  required: boolean;
}

const compareComponentMapping: Partial<Record<ReportChartGroupCompareItemType, CompareRendererConfig>> = {
  'user-assigned': {
    component: ReportsDetailGraphicCompareUser,
    required: false,
  },
  'user-archiving': {
    component: ReportsDetailGraphicCompareUser,
    required: false,
  },
  'user-responsible': {
    component: ReportsDetailGraphicCompareUser,
    required: false,
  },
  'flow-name': {
    component: ReportsDetailGraphicCompareFlow,
    required: false,
  },
  'unit-name': {
    component: ReportsDetailGraphicCompareUnit,
    required: false,
  },
  'customer-company': {
    component: ReportsDetailGraphicCompareCompany,
    required: false,
  },
  'customer-schema': {
    component: ReportsDetailGraphicCompareSchema,
    required: true,
  },
  'tag-name': {
    component: ReportsDetailGraphicCompareTagName,
    required: false,
  },
  'tag-group': {
    component: ReportsDetailGraphicCompareTagGroup,
    required: false,
  },
  'tag-intelligent': {
    component: ReportsDetailGraphicCompareTagIntelligent,
    required: false,
  },
  'channel-name': {
    component: ReportsDetailGraphicCompareChannelType,
    required: false,
  },
  'question-title': {
    component: ReportsDetailGraphicCompareQuestionTitle,
    required: true,
  },
  'question-option': {
    component: ReportsDetailGraphicCompareQuestionOption,
    required: true,
  },
  'question-response': {
    component: ReportsDetailGraphicCompareQuestionResponse,
    required: true,
  },
  'question-key': {
    component: ReportsDetailGraphicCompareQuestionKey,
    required: true,
  },
  'question-category-name': {
    component: ReportsDetailGraphicCompareQuestionCategory,
    required: true,
  },
  'category-content-name': {
    component: ReportsDetailGraphicCompareContentCategory,
    required: true,
  },
  'feedback-status': {
    component: ReportsDetailGraphicCompareFeedbackStatus,
    required: false,
  },
};

interface TrendChart1CompareRendererProps {
  compareItemTypes: ReportChartGroupCompareItemType[];
}

export function TrendChart1CompareRenderer({ compareItemTypes }: TrendChart1CompareRendererProps) {
  const [components, setComponents] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const newComponents: JSX.Element[] = compareItemTypes.map((compareItemType) => {
      const config = compareComponentMapping[compareItemType];

      if (!config) {
        return null;
      }

      const Component = config.component;

      return <Component key={compareItemType} compareItemType={compareItemType} required={config.required} />;
    });

    setComponents(newComponents);
  }, [compareItemTypes]);

  return <>{components}</>;
}
