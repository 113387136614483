import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, ensureArray, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionKeyProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionKey({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionKeyProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestionOptions: true,
    includeQuestions: true,
  });

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(flows, (item) => ({
        value: item.id,
        label: item.name,
      })),
    [flows],
  );

  const selectedFlows = useMemo(() => {
    if (!flows) {
      return [];
    }

    return formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds.map((flowId) =>
      flows.find((item) => item.id === flowId),
    );
  }, [flows, formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds]);

  const flowQuestionKeySelectItems = useMemo(() => {
    return selectedFlows.map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((item) => item.key)
          .map((question) => ({
            label: question.key,
            value: question.id,
          })),
      } as ComboboxItemGroup;
    });
  }, [selectedFlows]);

  const handleSelectFlow = (value: string[]) => {
    const newSelectedFlows = value.map((flowId) => flows.find((item) => item.id === flowId));

    const newSelectedQuestionKeyIds = formik.values.selectedCompareCriteria.selectedQuestionKeyIds.filter(
      (questionKeyId) =>
        newSelectedFlows.some((flow) => flow.questions.some((question) => question.id === questionKeyId)),
    );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionKeyFlowIds: value,
        selectedQuestionKeyIds: newSelectedQuestionKeyIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        loading={isFetchingFlows}
        data={selectItems}
        searchable={true}
        placeholder={isFetchingFlows ? t('loading') : t('search')}
        isValuesPillLoading={isFetchingFlows}
        value={formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds}
        onChange={handleSelectFlow}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionKeyFlowIds')}
        withAsterisk={required}
      />

      <If value={formik.values.selectedCompareCriteria.selectedQuestionKeyFlowIds.length > 0}>
        <VMultiSelect
          id="report-compare-flow-question-key"
          data-testid="input-report-compare-flow-question-key"
          label={t('questionKey')}
          loading={isFetchingFlows}
          placeholder={isFetchingFlows ? t('loading') : t('search')}
          isValuesPillLoading={isFetchingFlows}
          data={flowQuestionKeySelectItems}
          value={formik.values.selectedCompareCriteria.selectedQuestionKeyIds}
          searchable={true}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionKeyIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionKeyIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
