import React from 'react';

interface CustomIconRadarChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconRadarChart1({ height, width, active, ...props }: CustomIconRadarChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M11.1085 4.47214L18.7169 10L15.8107 18.9443H6.40617L3.5 10L11.1085 4.47214Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.928 3.84595L22.1302 7.44706L20.337 16.413L9.89389 17.5734L6.2334 9.64232L14.928 3.84595ZM15.0716 6.15386L8.76621 10.3575L11.1057 15.4264L18.6626 14.5868L19.8694 8.55275L15.0716 6.15386Z"
        fill={active ? '#86BDFF' : '#DBDDE8'}
      />
    </svg>
  );
}
