import { PISANO_FOUNDATION_DATE } from '@vision/ui/constants';
import { FilterType } from '@vision/ui/enums';
import {
  ApiResponse,
  DataGridFilterChange,
  DataGridParams,
  Filter,
  FilterSelections,
  FilterValidationDate,
  Meta,
} from '@vision/ui/interfaces';
import { coerceBooleanProperty } from '@vision/ui/utils/coercion';
import dayjs from 'dayjs';
import qs from 'qs';

export function processFilterSelections(meta: Meta) {
  // Eğer filter_selections zaten dolu ise kullan
  if (meta.filter_selections.length) {
    return meta.filter_selections;
  }

  const queryParams = qs.parse(new URLSearchParams(window.location.search).toString()) as DataGridParams;

  // Eğer kullanıcı filtrelerde değişiklik yaptıysa en son seçim ne ise onu kullan
  if (coerceBooleanProperty(queryParams.fc)) {
    return meta.filter_selections;
  }

  // Eğer filter_selections yok fakat default_selected değerler var ise onları kullanarak filter_selections'i oluştur
  return getDefaultFilterSelections(meta);
}

export function getDefaultFilterSelections(meta: Meta) {
  return meta.filters
    .filter((item) => item.options.filter((option) => option.default_selected).length > 0)
    .map((item) => {
      const defaultSelections = item.options.filter((option) => option.default_selected);

      if (item.type === FilterType.CHECKBOX) {
        return {
          key: item.key,
          value: defaultSelections.map((selection) => selection.value),
        };
      }

      if (item.type === FilterType.RADIO || item.type === FilterType.TEXT) {
        return {
          key: item.key,
          value: defaultSelections[0].value,
        };
      }

      return null;
    })
    .filter(Boolean);
}

export function parseSelectionsToQueryObject(items: FilterSelections[]): Record<string, string> {
  return items
    .map((item) => {
      // CHECKBOX
      if (Array.isArray(item.value)) {
        return {
          [item.key]: item.value.join(','),
        };
      }
      // DATEPICKER
      if (typeof item.value === 'object' && !Array.isArray(item.value)) {
        const val = item.value;
        const from = val.from.valueOf();
        const to = val.to?.valueOf();
        const mapped = [from, to].filter(Boolean).join(',');
        return {
          [item.key]: mapped,
        };
      }
      // RADIO, TEXT
      return {
        [item.key]: item.value as string,
      };
    })
    .reduce((acc, item) => ({ ...acc, ...item }), {});
}

export function parseQueryObjectToSelections(queryObject: Record<string, string>): FilterSelections[] {
  return Object.entries(queryObject).map(([key, value]) => {
    return {
      key,
      value: typeof value === 'string' && value.includes(',') ? value.split(',') : value,
    };
  });
}

export function normalizeSelectionsWithMetaResponse(meta: Meta, selections: FilterSelections[]) {
  return selections.map((item) => {
    const originalFilter = meta.filters.find((filter) => filter.key === item.key);
    if (originalFilter) {
      if (originalFilter.type === FilterType.CHECKBOX) {
        return {
          ...item,
          value: Array.isArray(item.value) ? item.value : [item.value],
        };
      }
      if (originalFilter.type === FilterType.DATEPICKER || originalFilter.type === FilterType.RADIO_DATEPICKER) {
        return {
          ...item,
          value: Array.isArray(item.value) ? item.value.join(',') : item.value,
        };
      }
      return item;
    }
    return item;
  }) as FilterSelections[];
}

export function updateFilterQueryWithPage(filter: DataGridFilterChange, page: number) {
  const params: DataGridParams = {
    ...filter.params,
    page,
  };
  return {
    ...filter,
    params,
    query: qs.stringify(params),
  } satisfies DataGridFilterChange;
}

export function updateFilterQueryOnRecordDelete(filter: DataGridFilterChange, response: ApiResponse<any>) {
  const page = filter.params.page;
  const currentDataLength = response.data.length;

  if (currentDataLength === 1 && page > 1) {
    return updateFilterQueryWithPage(filter, response.meta.pagination.previous);
  }

  return filter;
}

export function parseFilterValidationDate(filter: Filter) {
  function normalizeDate(key: keyof FilterValidationDate, defaultDate: Date) {
    const validation = filter.validation as FilterValidationDate;
    if (validation && validation[key]) {
      return new Date(validation[key]);
    }

    return defaultDate;
  }

  const minDate = normalizeDate('minDate', PISANO_FOUNDATION_DATE);
  const maxDate = normalizeDate(
    'maxDate',
    dayjs()
      .add(dayjs.duration({ years: 100 }))
      .toDate(),
  );

  return {
    minDate,
    maxDate,
  };
}
