import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mantine/core';
import React from 'react';
import classes from './TooltipInfo.module.scss';

export function TooltipInfo({ children }: React.PropsWithChildren) {
  return (
    <Tooltip label={children} maw={215}>
      <FontAwesomeIcon icon={faCircleInfo} className={classes.tooltipInfoIcon} />
    </Tooltip>
  );
}
