import React from 'react';

interface CustomIconBarChart3Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconBarChart3({ height, width, active, ...props }: CustomIconBarChart3Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M4 8L4 20H7L7 8H4Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M8 10V20H12V10H8Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M17 15V20H20V15H17Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M13 4L13 20H16V4H13Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path
        d="M20.479 6.30469L21.9166 7.69511L13.6281 16.2649L8.83805 13.8639L3.63827 18.1758L2.36182 16.6361L8.56747 11.4914L13.21 13.8189L20.479 6.30469Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
