import { HttpExtraOptions } from '@vision/ui/interfaces';
import { mergeDeep } from './object.utils';
import { __DEV__ } from './dev.utils';

export function createHttpExtraOptions(options: HttpExtraOptions, override?: HttpExtraOptions): HttpExtraOptions {
  return mergeDeep<HttpExtraOptions>(options, override);
}

export function noop(values: any) {
  // do nothing
  if (__DEV__) {
    console.log(values);
  }
}
