import React from 'react';

interface CustomIconHeatmapChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconHeatmapChart1({ height, width, active, ...props }: CustomIconHeatmapChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M7 17C8.10457 17 9 16.1046 9 15C9 13.8954 8.10457 13 7 13C5.89543 13 5 13.8954 5 15C5 16.1046 5.89543 17 7 17Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        d="M10.5 12C11.8807 12 13 10.8807 13 9.5C13 8.11929 11.8807 7 10.5 7C9.11929 7 8 8.11929 8 9.5C8 10.8807 9.11929 12 10.5 12Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.5"
        d="M5.5 8C6.32843 8 7 7.32843 7 6.5C7 5.67157 6.32843 5 5.5 5C4.67157 5 4 5.67157 4 6.5C4 7.32843 4.67157 8 5.5 8Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        d="M16.5 7C17.3284 7 18 6.32843 18 5.5C18 4.67157 17.3284 4 16.5 4C15.6716 4 15 4.67157 15 5.5C15 6.32843 15.6716 7 16.5 7Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.5"
        d="M19.5 14C20.3284 14 21 13.3284 21 12.5C21 11.6716 20.3284 11 19.5 11C18.6716 11 18 11.6716 18 12.5C18 13.3284 18.6716 14 19.5 14Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.5"
        d="M14 20C15.6569 20 17 18.6569 17 17C17 15.3431 15.6569 14 14 14C12.3431 14 11 15.3431 11 17C11 18.6569 12.3431 20 14 20Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
