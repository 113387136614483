import { ReportTimeIntervalType } from '@vision/ui/enums';
import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { TrendChart4CompareRenderer } from '../../components';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../default-chart.config';
import { TrendChart4Measures } from './trend-chart4.measures';

export const TrendChart4DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: TrendChart4Measures,
  compareRendererComponent: TrendChart4CompareRenderer,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    timeInterval: ReportTimeIntervalType.DAY,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: true,
      displayFormat: 'auto',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      displayFormat: 'auto',
      position: 'top',
    },
    serieDot: {
      active: false,
      symbolSize: 6,
      symbolType: 'circle',
      symbolFilling: 'full',
    },
    series: {
      customize: false,
      items: [],
    },
  },
};
