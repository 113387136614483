import { VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetSchemaFieldsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareSchemaProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareSchema({ required }: ReportsDetailGraphicCompareSchemaProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: schemaFields, isFetching } = useGetSchemaFieldsQuery({
    accountId,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(schemaFields, (item) => ({
        value: item.id,
        label: item.field_name,
      })),
    [schemaFields],
  );

  return (
    <ReportsDetailGraphicCompareLayout title={t('schema')}>
      <VMultiSelect
        id="report-compare-schema"
        data-testid="input-report-compare-schema"
        label={t('schema')}
        data={selectItems}
        loading={isFetching}
        value={formik.values.selectedCompareCriteria.selectedSchemaCodes}
        searchable={true}
        onBlur={formik.handleBlur}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedSchemaCodes', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedSchemaCodes')}
        withAsterisk={required}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
