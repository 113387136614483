import { Role } from '@vision/ui/enums';
import { User } from '@vision/ui/interfaces';
import { useMemo } from 'react';
import { useUser } from './useUser';

export function checkRole(user: User, role: Role | Role[]) {
  if (Array.isArray(role)) {
    return role.includes(user.role);
  }
  return role === user.role;
}

export function useCheckRole(role: Role | Role[]): boolean {
  const user = useUser();

  return useMemo(() => {
    if (!role) {
      return true;
    }
    return checkRole(user, role);
  }, [user, role]);
}
