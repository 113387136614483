import { ColorInput, ColorInputProps, ColorSwatch, Group } from '@mantine/core';
import { ensureHexColor } from '@vision/ui/utils';
import { dequal } from 'dequal';
import React from 'react';
import classes from './TagColorSelector.module.scss';

interface TagColorSelectorProps {
  colors: string[];
  inputProps?: ColorInputProps & { 'data-testid'?: string };
  onSelect: (color: string) => void;
}

function _TagColorSelector({ colors, inputProps, onSelect }: TagColorSelectorProps) {
  const handleOnChange = (value: string) => {
    const normalized = ensureHexColor(value);
    inputProps?.onChange?.(normalized);
  };

  return (
    <Group className={classes.tagColorSelector} align="flex-end" gap={10}>
      <ColorInput wrapperProps={{ w: 245 }} {...inputProps} onChange={handleOnChange} />

      <Group className={classes.tagColorSelectorContent} gap={10}>
        {colors.map((color) => (
          <ColorSwatch
            data-testid={`input-color-swatch-${color}`}
            key={color}
            color={color}
            withShadow={false}
            onClick={() => onSelect(color)}
            style={{
              cursor: 'pointer',
            }}
          />
        ))}
      </Group>
    </Group>
  );
}

export const TagColorSelector = React.memo(_TagColorSelector, (prevProps, nextProps) => {
  if (prevProps.inputProps.value !== nextProps.inputProps.value) {
    return false;
  }
  if (prevProps.inputProps.error !== nextProps.inputProps.error) {
    return false;
  }
  return dequal(prevProps.colors, nextProps.colors);
});
