import { faPlusCircle, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, ComboboxItem, Group, Input, Stack } from '@mantine/core';
import { useDidUpdate, useUncontrolled } from '@mantine/hooks';
import { If } from '@vision/ui/components';
import { UseLazyLoadSelectOptions } from '@vision/ui/hooks';
import { KeyValue } from '@vision/ui/interfaces';
import { ensureArray } from '@vision/ui/utils';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './KeyValuePairInput.module.scss';
import {
  KeyValuePairInputPropsType,
  KeyValuePairInputSwitchCase,
  KeyValuePairInputType,
  KeyValuePairInputValueType,
} from './components';

export interface KeyValuePairInputProps {
  disabled?: boolean;
  keyError?: (index: number) => React.ReactNode;
  keyInputType?: KeyValuePairInputType;
  keyItemOptions?: ComboboxItem[];
  keyLabel?: string;
  keyProps?: KeyValuePairInputPropsType;
  keyValueInputMapping?: {
    keySelectedValue: string;
    lazyLoadOptions?: UseLazyLoadSelectOptions<any, any>;
    valueInputType?: KeyValuePairInputType;
    valueItemOptions?: ComboboxItem[];
  }[];
  label?: string;
  maxPairs?: number;
  multiple?: boolean;
  name: string;
  onChange: (value: KeyValue<KeyValuePairInputValueType, KeyValuePairInputValueType>[]) => void;
  value: KeyValue<KeyValuePairInputValueType, KeyValuePairInputValueType>[];
  valueError?: (index: number) => React.ReactNode;
  valueInputType?: KeyValuePairInputType;
  valueItemOptions?: ComboboxItem[];
  valueLabel?: string;
  valueProps?: KeyValuePairInputPropsType;
  enterKeyChangeClearValue?: boolean;
}

export interface KeyValuePairInputRef {
  clear: VoidFunction;
}

export const KeyValuePairInput = forwardRef(
  (
    {
      disabled = false,
      keyError,
      keyInputType = 'input',
      keyItemOptions = [],
      keyLabel,
      keyProps,
      keyValueInputMapping,
      label,
      maxPairs = 10,
      multiple,
      name,
      onChange,
      value,
      valueError,
      valueInputType = 'input',
      valueItemOptions = [],
      valueLabel,
      valueProps,
      enterKeyChangeClearValue = false,
    }: KeyValuePairInputProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [pairs, setPairs] = useUncontrolled<KeyValue<KeyValuePairInputValueType, KeyValuePairInputValueType>[]>({
      value,
      defaultValue: [{ key: '', value: '' }],
      onChange,
    });

    const handleAddPair = () => {
      setPairs([...pairs, { key: '', value: '' }]);
    };

    const handleRemovePair = (index: number) => {
      setPairs(pairs.filter((_, i) => i !== index));
    };

    const handleChange = (
      index: number,
      value: KeyValuePairInputValueType,
      key: KeyValuePairInputValueType,
      clearValue = false,
    ) => {
      setPairs(
        pairs.map((pair, i) => {
          if (i === index) {
            return {
              key,
              value: clearValue ? '' : value,
            };
          }

          return pair;
        }),
      );
    };

    useDidUpdate(() => {
      setPairs([pairs[0]]);
    }, [multiple]);

    useImperativeHandle(ref, () => ({
      clear: () => setPairs([{ key: '', value: '' }]),
    }));

    return (
      <>
        {label && <Input.Label>{label}</Input.Label>}
        <Stack gap={10}>
          {pairs.map((pair, index) => {
            const found = ensureArray(keyValueInputMapping).find((item) => item.keySelectedValue === pair.key);
            const computedValueInputType = found?.valueInputType || valueInputType;
            const computedValueItemOptions = found?.valueItemOptions || valueItemOptions;

            return (
              <Group
                key={index}
                wrap="nowrap"
                gap={10}
                align={keyProps?.error || valueProps?.error ? 'center' : 'flex-end'}
              >
                <Group gap={10} align="flex-start" wrap="nowrap">
                  <KeyValuePairInputSwitchCase
                    label={keyLabel}
                    placeholder={t('key')}
                    inputType={keyInputType}
                    inputProps={keyProps}
                    itemOptions={keyItemOptions}
                    name={name}
                    index={index}
                    disabled={disabled}
                    handleChange={(index, value) => handleChange(index, pair.value, value, enterKeyChangeClearValue)}
                    value={pair.key}
                    error={keyError?.(index)}
                  />

                  <KeyValuePairInputSwitchCase
                    label={valueLabel}
                    placeholder={t('value')}
                    inputType={computedValueInputType}
                    inputProps={valueProps}
                    itemOptions={computedValueItemOptions}
                    name={name}
                    index={index}
                    disabled={disabled}
                    handleChange={(index, value) => handleChange(index, value, pair.key)}
                    value={pair.value}
                    lazyLoadOptions={found?.lazyLoadOptions}
                    error={valueError?.(index)}
                  />
                </Group>

                <If value={multiple}>
                  <Group className={classes.keyValuePairInputActions} gap={10} wrap="nowrap">
                    <If value={index === pairs.length - 1}>
                      <ActionIcon
                        data-testid="button-add-key-value-pair"
                        disabled={
                          !pairs[pairs.length - 1].key || !pairs[pairs.length - 1].value || pairs.length === maxPairs
                        }
                        onClick={handleAddPair}
                        color="blue"
                        variant="filled"
                        h={32}
                        miw={48}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </ActionIcon>
                    </If>
                    <If value={pairs.length > 1}>
                      <ActionIcon
                        data-testid={`button-remove-key-value-pair-${index + 1}`}
                        disabled={disabled}
                        color="gray.1"
                        variant="filled"
                        onClick={() => handleRemovePair(index)}
                        h={32}
                        miw={32}
                      >
                        <FontAwesomeIcon icon={faTrashCan} color="black" />
                      </ActionIcon>
                    </If>
                  </Group>
                </If>
              </Group>
            );
          })}
        </Stack>
      </>
    );
  },
);
