import { FeedbackStatus } from '@vision/ui/enums';
import { convertEnumToSelectItems } from '@vision/ui/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useFeedbackStatus() {
  const { i18n, t } = useTranslation();

  return useMemo(() => {
    return convertEnumToSelectItems(FeedbackStatus, (item) => ({
      label: t(`feedbackStatus.${item.value.toLowerCase()}`),
      value: item.value,
    }));
  }, [i18n.language]);
}
