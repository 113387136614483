import { ComboboxItem, TextInput, TextInputProps } from '@mantine/core';
import { DatePickerInput, DatePickerInputProps, DatesRangeValue } from '@mantine/dates';
import {
  SwitchCase,
  VLazyLoadSelect,
  VMultiSelect,
  VMultiSelectProps,
  VSelect,
  VSelectProps,
} from '@vision/ui/components';
import { UseLazyLoadSelectOptions } from '@vision/ui/hooks';
import React from 'react';

export type KeyValuePairInputType = 'input' | 'select' | 'multiselect' | 'lazyselect' | 'date';

export type KeyValuePairInputValueType = string | string[] | DatesRangeValue | Date | Date[];

type _KeyValuePairInputPropsType = TextInputProps &
  Omit<VSelectProps & VMultiSelectProps & DatePickerInputProps, 'data' | 'error'>;

export type KeyValuePairInputPropsType = Omit<_KeyValuePairInputPropsType, 'data-testid'>;

export interface KeyValuePairInputSwitchCaseProps {
  disabled: boolean;
  error?: React.ReactNode;
  handleChange: (index: number, value: KeyValuePairInputValueType) => void;
  index: number;
  inputProps?: KeyValuePairInputPropsType;
  inputType: KeyValuePairInputType;
  itemOptions: ComboboxItem[];
  label?: string;
  lazyLoadOptions?: UseLazyLoadSelectOptions<any, any>;
  name: string;
  placeholder?: string;
  value: KeyValuePairInputValueType;
}

export function KeyValuePairInputSwitchCase({
  disabled,
  error,
  handleChange,
  index,
  inputProps,
  inputType,
  itemOptions,
  label,
  lazyLoadOptions,
  name,
  placeholder,
  value,
}: KeyValuePairInputSwitchCaseProps) {
  return (
    <SwitchCase
      value={inputType}
      caseBy={{
        input: (
          <TextInput
            {...inputProps}
            label={label || inputProps?.label}
            placeholder={placeholder || inputProps?.placeholder}
            autoComplete={name}
            data-testid={`input-key-${index + 1}`}
            disabled={disabled}
            name={`${name}-${index}-key`}
            onChange={(e) => handleChange(index, e.target.value)}
            type="text"
            value={value as string}
            error={error}
          />
        ),
        select: (
          <VSelect
            {...inputProps}
            label={label || inputProps?.label}
            placeholder={placeholder || inputProps?.placeholder}
            data-testid={`input-key-${index + 1}`}
            data={itemOptions}
            autoComplete={name}
            disabled={disabled}
            name={`${name}-${index}-key`}
            onChange={(val) => handleChange(index, val)}
            value={value as string}
            error={error}
          />
        ),
        lazyselect: (
          <VLazyLoadSelect
            {...inputProps}
            {...lazyLoadOptions}
            label={label || inputProps?.label}
            placeholder={placeholder || inputProps?.placeholder}
            data-testid={`input-key-${index + 1}`}
            autoComplete={name}
            disabled={disabled}
            name={`${name}-${index}-key`}
            onChange={(val) => handleChange(index, val)}
            value={value as string}
            error={error}
          />
        ),
        multiselect: (
          <VMultiSelect
            {...inputProps}
            label={label || inputProps?.label}
            placeholder={placeholder || inputProps?.placeholder}
            data-testid={`input-key-${index + 1}`}
            data={itemOptions}
            autoComplete={name}
            disabled={disabled}
            name={`${name}-${index}-key`}
            onChange={(val) => handleChange(index, val)}
            value={(value as string[]) || []}
            error={error}
          />
        ),
        date: (
          <DatePickerInput
            {...inputProps}
            label={label || inputProps?.label}
            placeholder={placeholder || inputProps?.placeholder}
            data-testid={`input-key-${index + 1}`}
            value={(value as DatesRangeValue | Date | Date[]) || undefined}
            numberOfColumns={2}
            onChange={(val) => handleChange(index, val)}
            error={error}
          />
        ),
      }}
    />
  );
}
