import React from 'react';
import './TooltipCardContainer.scss';

interface TooltipCardContainerProps {}

export function TooltipCardContainer({ children }: React.PropsWithChildren<TooltipCardContainerProps>) {
  return (
    <div className="tooltip-card">
      <div className="tooltip-card-content">{children}</div>
    </div>
  );
}
