import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import classes from './TabButton.module.scss';

interface TabButtonProps extends ButtonProps, React.DOMAttributes<HTMLButtonElement> {
  isActive?: boolean;
  fontSize?: number;
  icon?: React.ReactNode;
  label: string;
}

const _TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ className, isActive, icon, label, fontSize = 14, ...props }: TabButtonProps, ref) => (
    <Button
      ref={ref}
      className={clsx(classes.tabButton, className)}
      data-active={isActive}
      variant="default"
      leftSection={icon}
      fz={fontSize}
      {...props}
    >
      {label}
    </Button>
  ),
);

export const TabButton = createPolymorphicComponent<'button', TabButtonProps>(_TabButton);
