import { GridComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { componentLoader, optionCreator } from '../../ECharts.utils';

export type EChartsGridProps = EventHandlerProps & GridComponentOption;
/**
 * @example
 * ```tsx
 * <EChartsGrid left="10%" right="10%" bottom="15%" />
 * ```
 */
export const EChartsGrid: EC<EChartsGridProps> = () => <></>;

EChartsGrid.optionOf = optionCreator('grid', {
  bottom: '20px',
  containLabel: true,
  left: '20px',
  right: '20px',
  top: '40px',
} as EChartsGridProps);

EChartsGrid.loadModule = componentLoader(['GridComponent']);
