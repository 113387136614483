import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type RadarSeriesProps = SeriesProps<RegisteredSeriesOption['radar']>;
/**
 * @example
 * ```tsx
 * <RadarSeries data={[
 *     [20, 34, 10, 38],
 *     [40, 35, 30, 50],
 *     [31, 38, 33, 44],
 *     [38, 15, 5, 42]
 * ]} />
 * ```
 */
export const RadarSeries: EC<RadarSeriesProps> = () => <></>;

RadarSeries.optionOf = seriesOptionCreator('radar');

RadarSeries.loadModule = chartLoader(['RadarChart']);
