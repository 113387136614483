import { useEffect, useRef, useState } from 'react';

function makeStyleTag() {
  const tag = document.createElement('style');
  tag.setAttribute('type', 'text/css');
  tag.setAttribute('mantine-user-select-lock', '');

  return tag;
}

function injectStyles(tag: any, css: string): void {
  if (tag.styleSheet) {
    tag.styleSheet.cssText = css;
  } else {
    tag.appendChild(document.createTextNode(css));
  }
}

function insertStyleTag(tag: HTMLStyleElement) {
  const head = document.head || document.getElementsByTagName('head')[0];

  head.appendChild(tag);
}

export function useUserSelectNoneLock(lock?: boolean) {
  const [locked, setLocked] = useState(lock || false);

  const stylesheet = useRef<CSSStyleSheet | any | null>(null);

  const lockUserSelection = () => {
    const styles = `body {
      user-select: none !important;
    }`;

    /**
     * By applying styles via style tag
     * we don't care about previous styles due to inheritance
     * when scroll gets unlocked we delete that style tag
     */
    const sheet = makeStyleTag();

    injectStyles(sheet, styles);
    insertStyleTag(sheet);

    stylesheet.current = sheet;
  };

  const unlockUserSelection = () => {
    if (!stylesheet?.current) return;

    stylesheet.current.parentNode.removeChild(stylesheet.current);
    stylesheet.current = null;
  };

  useEffect(() => {
    if (locked) {
      lockUserSelection();
    } else {
      unlockUserSelection();
    }

    return unlockUserSelection;
  }, [locked]);

  useEffect(() => {
    if (lock !== undefined) {
      setLocked(lock);
    }
  }, [lock]);

  return [locked, setLocked] as const;
}
