import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { array, string } from 'yup';
import { PieChart2CompareRenderer, PieChart2ValueComponent } from '../../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../../default-chart.config';
import { ExPieChart2Measures } from './ex-pie-chart2.measures';

export const ExPieChart2DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({
    selectedCompare: array().of(string()).min(2, 'validation:rules.generic.required'),
  }),
  measures: ExPieChart2Measures,
  compareRendererComponent: PieChart2CompareRenderer,
  responseMultiple: true, // Two compare and one measure requested by the client
  hideCompare: true,
  chartValueComponent: PieChart2ValueComponent,
  requestType: 'MultipleCompare',
  measureHeader: {
    labelKey: 'page-reports:measurement',
    showIcon: false,
  },
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 1,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 1,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
