interface SwitchCaseProps<Case extends string> {
  caseBy: Partial<Record<Case, JSX.Element | null>>;
  defaultComponent?: JSX.Element | null;
  value: Case;
}

/**
 * @example
 *  <SwitchCase
 *    value={status}
 *    // status is 'a', 'b', 'c'
 *    caseBy={{
 *      a: <AComponent />
 *      b: <BComponent />
 *      c: <CComponent />
 *    }}
 *    defaultComponent={<DefaultComponent />}
 * />
 */
export function SwitchCase<Case extends string>({ value, caseBy, defaultComponent = null }: SwitchCaseProps<Case>) {
  if (value === null || value === undefined) {
    return defaultComponent;
  }

  return caseBy[value] ?? defaultComponent;
}
