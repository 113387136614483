import { Group, GroupProps, Portal } from '@mantine/core';
import React from 'react';
import classes from './DrawerFooter.module.scss';

type DrawerFooterProps = Partial<{
  bottomContent: React.ReactNode;
  justify: GroupProps['justify'];
  topContent: React.ReactNode;
}>;

export function DrawerFooter({
  children,
  bottomContent,
  justify = 'right',
  topContent,
}: React.PropsWithChildren<DrawerFooterProps>) {
  return (
    <Portal target=".mantine-Drawer-content">
      <>
        {topContent}
        <Group justify={justify} gap={10} className={classes.drawerFooterContent}>
          {children}
        </Group>
        {bottomContent}
      </>
    </Portal>
  );
}
