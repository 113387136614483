import React from 'react';

interface CustomIconGeoChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconGeoChart1({ height, width, active, ...props }: CustomIconGeoChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77901 18.021L4 12.021L4.86862 8.76909L8.98431 6.74059L11.0009 6.021L12.8686 12.021V18.021H6.77901Z"
        fill={active ? '#0D7BFF' : '#DBDDE8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8687 18.021V13.9456L13.7007 11.5432L18.1384 10.3141L19.8687 7V20L12.8687 18.021Z"
        fill={active ? '#0D7BFF' : '#CFD2E1'}
        fillOpacity={active ? 0.3 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55615 14.021L4.86865 8.76909L9.55615 4.021L14.7124 8.76909L19.8687 6.7587L17.9937 14.021H9.55615Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
        fillOpacity={active ? 0.7 : 1}
      />
    </svg>
  );
}
