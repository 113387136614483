import { SpotlightActionData } from '@mantine/spotlight';
import React, { createContext, useMemo, useState } from 'react';

interface VSpotlightContextValues {
  filteredActions: SpotlightActionData[];
  setFilteredActions: (actions: SpotlightActionData[]) => void;
}

export const VSpotlightContext = createContext<VSpotlightContextValues>({
  filteredActions: [],
  setFilteredActions: null,
});

export function VSpotlightProvider({ children }: React.PropsWithChildren) {
  const [filteredActions, setFilteredActions] = useState<SpotlightActionData[]>([]);

  const contextValues: VSpotlightContextValues = useMemo(
    () => ({
      filteredActions,
      setFilteredActions,
    }),
    [filteredActions, setFilteredActions],
  );

  return <VSpotlightContext.Provider value={contextValues}>{children}</VSpotlightContext.Provider>;
}
