import React from 'react';

interface CustomIconCardChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconCardChart1({ height, width, active, ...props }: CustomIconCardChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20L5 9.203L10 4L15.5 9.203L20 7V20H5Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20L4 12.203L9 7L14.5 12.203L20 10V20H4Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
