import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpBaseRequest, UserGroup } from '@vision/ui/interfaces';
import { createHttpExtraOptions, sortByNameAlphabetical } from '@vision/ui/utils';
import { HttpMethod } from '../enums';
import { axiosBaseQuery } from './base.service';

export const UserGroupsService = createApi({
  reducerPath: 'UserGroupsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    userGroups: builder.query<UserGroup[], HttpBaseRequest<{ nodeId: string; includeSelf?: boolean }>>({
      query: ({ nodeId, includeSelf = false }) => ({
        url: `/v1/user_groups/${nodeId}`,
        method: HttpMethod.GET,
        params: {
          include_self: includeSelf,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
      transformResponse: (response: UserGroup[]) => {
        return sortByNameAlphabetical(response);
      },
    }),
  }),
});

export const { useUserGroupsQuery } = UserGroupsService;
