import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  ActionIconProps,
  Box,
  createPolymorphicComponent,
  Indicator,
  IndicatorProps,
  MantineColor,
  MantineTheme,
  parseThemeColor,
  useMantineTheme,
} from '@mantine/core';
import { darken } from '@vision/theme';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import classes from './ActionIconWithIndicator.module.scss';

export type ActionIconWithIndicatorProps = ActionIconProps &
  Omit<React.HTMLAttributes<HTMLButtonElement>, 'children' | 'color'> & {
    hoverColor?: MantineColor;
    icon: IconProp;
    iconColor?: MantineColor;
    withIndicator?: boolean;
    indicatorColor?: MantineColor;
    size?: number;
  };

function getActionIconWithIndicatorCssVariables(
  theme: MantineTheme,
  { color: colorProp, hoverColor }: Partial<ActionIconWithIndicatorProps>,
) {
  const parsedColor = parseThemeColor({
    color: colorProp,
    theme,
  });
  const parsedHoverColor = parseThemeColor({
    color: hoverColor,
    theme,
  });

  return {
    actionIconWithIndicator: {
      '--action-icon-with-indicator-hover-border-color': hoverColor ? parsedHoverColor.value : parsedColor.value,
      '--action-icon-with-indicator-hover-bg-color': hoverColor
        ? parsedHoverColor.value
        : darken(parsedColor.value, 0.1),
    },
  };
}

const _ActionIconWithIndicator = forwardRef<HTMLButtonElement, ActionIconWithIndicatorProps>(
  (
    {
      className,
      color,
      hoverColor = 'blue',
      icon,
      iconColor: iconColorProp,
      withIndicator,
      indicatorColor: indicatorColorProp = 'green',
      size = 40,
      ...props
    }: ActionIconWithIndicatorProps,
    ref,
  ) => {
    const theme = useMantineTheme();
    const Wrapper = withIndicator ? Indicator : Box;
    const styles = getActionIconWithIndicatorCssVariables(theme, {
      color,
      hoverColor,
    });

    const iconColor = parseThemeColor({
      color: iconColorProp || color,
      theme,
    });
    const indicatorColor = parseThemeColor({
      color: indicatorColorProp,
      theme,
    });

    const indicatorProps = {
      inline: true,
      size: size / 4.5,
      offset: 2.5,
      position: 'top-end',
      color: indicatorColor.value,
    } as IndicatorProps;

    return (
      <ActionIcon
        ref={ref}
        className={clsx(classes.actionIconWithIndicator, className)}
        color={color}
        size={size}
        style={styles.actionIconWithIndicator}
        {...props}
      >
        <Wrapper {...(withIndicator ? indicatorProps : {})}>
          <FontAwesomeIcon icon={icon} fontSize={size / 2.5} color={iconColor.value} />
        </Wrapper>
      </ActionIcon>
    );
  },
);

export const ActionIconWithIndicator = createPolymorphicComponent<'button', ActionIconWithIndicatorProps>(
  _ActionIconWithIndicator,
);
