import { RootState } from '@vision/ui/store';

export const selectedChartGroupSelector = (state: RootState) => state.ReportsDetailGraphicState.selectedChartGroup;

export const selectedChartGroupItemSelector = (state: RootState) =>
  state.ReportsDetailGraphicState.selectedChartGroupItem;

export const isGraphicSelectedSelector = (state: RootState) => !!state.ReportsDetailGraphicState.selectedChartGroupItem;

export const reportDetailSelector = (state: RootState) => state.ReportsDetailGraphicState.reportDetail;

export const reportGraphicDetailSelector = (state: RootState) => state.ReportsDetailGraphicState.graphicDetail;

export const feedbackSelector = (state: RootState) => state.ReportsDetailGraphicState.feedback;
