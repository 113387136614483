import { ReportChartGroupItemMeasure } from '@vision/ui/interfaces';

export const PieChart3Measures: ReportChartGroupItemMeasure[] = [
  {
    type: 'feedback',
    items: ['feedback-count'],
  },
  {
    type: 'response',
    items: ['response-count'],
  },
  {
    type: 'customer',
    items: ['customer-count'],
  },
];
