import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { Box } from '@mantine/core';
import { omit } from '@vision/ui/utils';
import clsx from 'clsx';
import React, { CSSProperties, useEffect } from 'react';
import './DraggableListItem.scss';

export interface RenderOptions<T = any> {
  data: T;
  disabled: boolean;
  dragOverlay: boolean;
  dragging: boolean;
  id: UniqueIdentifier;
  index: number;
  onRemove: VoidFunction;
  sorting: boolean;
}

export type ItemRenderType<T = any> = (options: RenderOptions<T>) => React.ReactNode;

export interface DraggableListItemProps<T = any> {
  children: ItemRenderType<T>;
  containerStyle?: CSSProperties;
  data: T;
  disabled?: boolean;
  dragOverlay?: boolean;
  dragging?: boolean;
  handleprops?: any;
  height?: number;
  id: UniqueIdentifier;
  index?: number;
  listeners?: DraggableSyntheticListeners;
  manualHandle?: boolean;
  onRemove?: VoidFunction;
  sorting?: boolean;
  style?: CSSProperties;
  transform?: Transform | null;
  transition?: string | null;
}

export const DraggableListItem = React.memo(
  React.forwardRef<HTMLDivElement, DraggableListItemProps>(
    (
      {
        children,
        containerStyle,
        data,
        disabled,
        dragOverlay,
        dragging,
        id,
        index,
        listeners,
        manualHandle,
        onRemove,
        sorting,
        style,
        transform,
        transition,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return (
        <Box
          className={clsx('draggable-container', {
            sorting,
          })}
          style={
            {
              ...containerStyle,
              transition,
              '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
              '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
              '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
              '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
            } as any
          }
          ref={ref}
        >
          <Box
            className={clsx('draggable', {
              dragging,
              dragOverlay,
              disabled,
            })}
            style={style}
            {...(!manualHandle ? listeners : undefined)}
            {...props}
            tabIndex={!manualHandle ? 0 : undefined}
          >
            {children({
              data: omit(data, 'sortId'),
              disabled,
              dragOverlay,
              dragging,
              id,
              index,
              onRemove,
              sorting,
            })}
          </Box>
        </Box>
      );
    },
  ),
);
