import React from 'react';

interface CustomIconTreeMapChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTreeMapChart1({ height, width, active, ...props }: CustomIconTreeMapChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path opacity="0.5" d="M4 8V12H8V8H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path opacity="0.3" d="M4 13V20H6V13H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path opacity="0.5" d="M7 13V14H8V13H7Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M4 4V7H8V4H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7V4H13V7H9Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7V4H20V7H14Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 10V8H20V10H14Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 14V8H13V14H9Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 15V11H20V15H14Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 20V16H20V20H14Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 20V15H13V20H7Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
    </svg>
  );
}
