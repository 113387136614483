import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Button, Indicator, Popover, TextInput, Tooltip } from '@mantine/core';
import { useClickOutside, useDebouncedValue, useDidUpdate, useInputState, useMediaQuery } from '@mantine/hooks';
import { If } from '@vision/ui/components';
import { Breakpoints } from '@vision/ui/constants';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './DataGridFilterSearch.module.scss';

interface DataGridFilterSearchProps {
  onSearch: (value: string) => void;
  searchText: string;
  tooltipLabel?: string;
  tooltipDisabled?: boolean;
}

function _DataGridFilterSearch({ onSearch, searchText, tooltipLabel, tooltipDisabled }: DataGridFilterSearchProps) {
  const isInputInsidePopover = useMediaQuery(Breakpoints.DOWN.MD);
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useInputState(searchText);
  const { t } = useTranslation();
  const btnRef = useRef<HTMLButtonElement>();
  const inputRef = useRef<HTMLInputElement>();

  const handleClickOutside = () => {
    if (value) {
      return;
    }
    setOpened(false);
  };

  const [debouncedValue] = useDebouncedValue(value, 300);
  useClickOutside(handleClickOutside, null, [btnRef.current, inputRef.current]);

  const clearSearch = () => {
    setValue('');
    setOpened(false);
  };

  const handleOpen = () => {
    setOpened(true);
  };

  const handleToggle = () => {
    setOpened((prev) => !prev);
  };

  useDidUpdate(() => {
    onSearch(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (isInputInsidePopover !== undefined) {
      setOpened(!!searchText && !isInputInsidePopover);
    }
  }, [isInputInsidePopover]);

  return (
    <div
      className={clsx('filter-search', {
        'filter-search--opened': opened,
      })}
    >
      <Popover
        opened={opened}
        disabled={!isInputInsidePopover}
        withArrow={false}
        offset={{
          crossAxis: -42,
          mainAxis: 5,
        }}
      >
        <Popover.Target>
          <Indicator disabled={!isInputInsidePopover || !value} size={8}>
            <Button
              ref={btnRef}
              className={clsx(classes.filterSearchBtn, {
                [classes.filterSearchBtnHidden]: opened && !isInputInsidePopover,
              })}
              variant="outline"
              color="gray"
              onClick={() => {
                if (isInputInsidePopover) {
                  handleToggle();
                } else {
                  handleOpen();
                }
              }}
              w={36}
              h={36}
              p={0}
              data-testid="button-filter-search"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown className="popover-dropdown-unstyled">
          <TextInput
            autoFocus={true}
            data-testid="input-filter-search"
            placeholder={t('search')}
            ref={inputRef}
            value={value}
            onChange={setValue}
            rightSection={
              <ActionIcon size="xs" onClick={clearSearch} color="gray">
                <FontAwesomeIcon icon={faXmark} />
              </ActionIcon>
            }
          />
        </Popover.Dropdown>
      </Popover>

      <If value={!isInputInsidePopover && opened}>
        <Tooltip label={tooltipLabel} disabled={tooltipDisabled || !tooltipLabel} position="bottom">
          <TextInput
            autoFocus={true}
            data-testid="input-filter-search"
            placeholder={t('search')}
            ref={inputRef}
            value={value}
            onChange={setValue}
            size="sm"
            rightSection={
              <ActionIcon size="xs" onClick={clearSearch} color="gray">
                <FontAwesomeIcon icon={faXmark} />
              </ActionIcon>
            }
          />
        </Tooltip>
      </If>
    </div>
  );
}

export const DataGridFilterSearch = React.memo(_DataGridFilterSearch);
