import React from 'react';

interface CustomIconBarChart2Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconBarChart2({ height, width, active, ...props }: CustomIconBarChart2Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M12 4L9 4L9 6L12 6L12 4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M19 6L12 6L12 8L19 8L19 6Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M17 10L12 10L12 12L17 12L17 10Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M18 18L12 18L12 20L18 20L18 18Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M14 14L12 14L12 16L14 16L14 14Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M12 8L5 8L5 10L12 10L12 8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M12 12L7 12L7 14L12 14L12 12Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M12 16L10 16L10 18L12 18L12 16Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
    </svg>
  );
}
