import { ReportChartGroupItemMeasure } from '@vision/ui/interfaces';

export const TableChart2Measures: ReportChartGroupItemMeasure[] = [
  {
    type: 'matrix-question',
    items: ['matrix-question-csat-overview', 'matrix-question-response-count'],
  },
  {
    type: 'overview',
    items: ['overview-compared', 'overview-distribution'],
  },
];
