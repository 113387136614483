import React from 'react';

interface CustomIconGaugeChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconGaugeChart1({ height, width, active, ...props }: CustomIconGaugeChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.26553 10.1832C3.76088 11.0762 3.43477 11.8834 3.28722 12.6047C3.13966 13.326 3.04392 14.0652 3 14.8221H6.26652C6.28562 14.1817 6.34236 13.6834 6.43672 13.3272C6.53109 12.9711 6.74764 12.4932 7.08637 11.8935C8.09295 10.4415 9.54299 9.58462 11.4365 9.32276C12.6371 9.15672 14.2413 9.6383 15.343 10.5958C15.8802 11.0627 16.3292 11.6254 16.6898 12.2838C16.954 12.8821 17.12 13.347 17.1878 13.6785C17.2557 14.01 17.2932 14.3912 17.3005 14.8221H20.5686C20.5754 14.8442 20.5754 14.7617 20.5686 14.5746C20.5477 13.9927 20.4627 13.4371 20.3983 13.0901C20.092 11.439 19.4226 10.3922 19.298 10.1832C18.9969 9.67846 18.564 9.20501 18.3213 8.92998C16.9431 7.36765 15.1332 6.42894 13.2642 6.13268C11.9846 5.92984 10.6258 5.9121 9.07129 6.42129C8.62613 6.56711 8.0772 6.79171 7.60451 7.02384C6.3202 7.65454 5.20721 8.70765 4.26553 10.1832Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50439 8.61121L8.09839 10.7429C8.56789 10.3933 8.92153 10.1537 9.15929 10.024C9.54945 9.81131 9.93076 9.67017 10.3104 9.55519C10.7022 9.43652 11.0918 9.34703 11.4559 9.32276C11.9535 9.28959 12.6846 9.28459 13.5421 9.58268C14.1439 9.79189 14.7507 10.1296 15.3625 10.5958L18.0437 8.61121C17.4816 8.06496 16.8924 7.60852 16.2763 7.2419C15.2141 6.60991 14.0232 6.24989 13.2837 6.13268C12.0041 5.92984 10.6453 5.9121 9.09075 6.42129C8.64559 6.56711 8.0518 6.77527 7.62397 7.02384C7.30725 7.20785 7.27991 7.16068 6.75399 7.51891C6.35614 7.78991 5.9396 8.15401 5.50439 8.61121Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7992 17C12.4067 17 12.9307 16.4449 12.9307 15.9C12.9307 15.5368 12.5535 14.6905 11.7992 13.3611C11.0659 14.6487 10.6992 15.495 10.6992 15.9C10.6992 16.5075 11.1917 17 11.7992 17Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
