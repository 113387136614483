import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  CatiNode,
  EmailCampaign,
  HttpBaseRequest,
  Option,
  PushNotificationCampaign,
  Question,
  SmsCampaign,
  WhatsappCampaign,
  Workflow,
  WorkflowMetaData,
  WorkflowRunHistory,
} from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const WorkflowsService = createApi({
  reducerPath: 'WorkflowsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    workflow: builder.query<ApiResponse<Workflow>, string>({
      query: (workflowId) => ({
        url: `/v2/workflows/${workflowId}`,
        method: HttpMethod.GET,
      }),
    }),
    deleteWorkflow: builder.mutation<void, { workflowMetaDataId: string; accountId: string }>({
      query: ({ workflowMetaDataId, accountId }) => ({
        url: `/v2/workflows/workflow_meta_data/${workflowMetaDataId}/delete?account_id=${accountId}`,
        method: HttpMethod.POST,
      }),
    }),
    workflows: builder.query<ApiResponse<Workflow[]>, { query: string; accountId: string }>({
      query: ({ accountId, query }) => ({
        url: `/v2/workflows${query ? `?${query}` : ''}&account_id=${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    workflowsCompact: builder.query<ApiResponse<Workflow[]>, HttpBaseRequest<{ accountId: string }>>({
      query: ({ accountId }) => ({
        url: `/v2/workflows/list/${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    runHistory: builder.query<ApiResponse<WorkflowRunHistory[]>, { query: string; accountId: string }>({
      query: ({ accountId, query }) => ({
        url: `/v2/workflows/run_history?${query ? `${query}` : ''}&account_id=${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    questions: builder.query<Question[], HttpBaseRequest<{ flowId: string }>>({
      query: ({ flowId }) => ({
        url: `/v2/workflows/questions/${flowId}`,
        method: HttpMethod.GET,
      }),
    }),
    questionOptions: builder.query<Option[], HttpBaseRequest<{ questionIds: string[] }>>({
      query: ({ questionIds }) => ({
        url: `/v2/workflows/options?questions=${questionIds.join(',')}`,
        method: HttpMethod.GET,
      }),
    }),
    publishWorkflow: builder.mutation<void, HttpBaseRequest<Workflow>>({
      query: (workflow) => ({
        url: `/v2/workflows`,
        method: HttpMethod.POST,
        data: workflow,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.publishWorkflow.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    campaigns: builder.query<
      {
        cati_nodes: CatiNode[];
        email_campaigns: EmailCampaign[];
        sms_campaigns: SmsCampaign[];
        whatsapp_campaigns: WhatsappCampaign[];
        push_notification_campaigns: PushNotificationCampaign[];
      },
      HttpBaseRequest<{ accountId: string }>
    >({
      query: ({ accountId }) => ({
        url: `/v2/workflows/campaign_list/${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    toggleWorkflowStatus: builder.mutation<void, HttpBaseRequest<{ workflowId: string }>>({
      query: ({ workflowId }) => ({
        url: `/v2/workflows/${workflowId}/status_update`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.toggleWorkflowStatus.{{status}}',
          },
        }),
      }),
    }),
    runningWorkflows: builder.query<ApiResponse<WorkflowRunHistory[]>, HttpBaseRequest<{ workflowId: string }>>({
      query: ({ workflowId }) => ({
        url: `/v2/workflows/${workflowId}/running`,
        method: HttpMethod.GET,
      }),
    }),
    workflowVersions: builder.query<
      ApiResponse<WorkflowMetaData>,
      HttpBaseRequest<{ workflowMetaDataId: string; accountId: string }>
    >({
      query: ({ workflowMetaDataId, accountId }) => ({
        url: `/v2/workflows/workflow_meta_data/${workflowMetaDataId}/versions?account_id=${accountId}`,
        method: HttpMethod.GET,
      }),
    }),
    editWorkflow: builder.mutation<ApiResponse<void>, HttpBaseRequest<Workflow>>({
      query: (workflow) => ({
        url: `/v2/workflows/workflow_meta_data/${workflow.workflow_revision.workflow_meta_data.id}/edit`,
        method: HttpMethod.POST,
        data: workflow,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.publishWorkflow.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    changeWorkflowVersion: builder.mutation<
      ApiResponse<WorkflowMetaData>,
      HttpBaseRequest<{ workflowMetaDataId: string; workflowRevisionId: string; accountId: string }>
    >({
      query: ({ workflowMetaDataId, workflowRevisionId, accountId }) => ({
        url: `/v2/workflows/workflow_meta_data/${workflowMetaDataId}/change_version`,
        method: HttpMethod.POST,
        data: { account_id: accountId, workflow_revision_id: workflowRevisionId },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.changeWorkflowVersion.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
  }),
});
export const {
  useChangeWorkflowVersionMutation,
  useDeleteWorkflowMutation,
  useEditWorkflowMutation,
  useLazyCampaignsQuery,
  useLazyQuestionOptionsQuery,
  useLazyQuestionsQuery,
  useLazyRunHistoryQuery,
  useLazyRunningWorkflowsQuery,
  useLazyWorkflowQuery,
  useLazyWorkflowsCompactQuery,
  useLazyWorkflowsQuery,
  useLazyWorkflowVersionsQuery,
  usePublishWorkflowMutation,
  useToggleWorkflowStatusMutation,
} = WorkflowsService;
