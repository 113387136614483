import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect, VSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, ensureArray, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionCategoryProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionCategory({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionCategoryProps) {
  const { t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestionOptions: true,
    includeQuestions: true,
  });

  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds.length > 0;

  const selectItems = useMemo(() => {
    const flowsWithQuestionCategory = flows?.filter((flow) => {
      return flow.questions.some((question) => !!question.category_id);
    });

    return convertArrayToSelectItems(flowsWithQuestionCategory, (item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [flows]);

  const selectedFlows = useMemo(() => {
    if (!flows) {
      return [];
    }

    return formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds.map((flowId) =>
      flows.find((item) => item.id === flowId),
    );
  }, [flows, formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds]);

  const flowQuestionCategoryNameSelectItems = useMemo(() => {
    return selectedFlows.map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((question) => !!question.category_id)
          .map((question) => ({
            label: question.category?.name,
            value: question.category_id,
          })),
      } as ComboboxItemGroup;
    });
  }, [selectedFlows]);

  const handleChangeFlow = (flowId: string) => {
    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionCategoryFlowIds: flowId ? [flowId] : [],
        selectedQuestionCategoryIds: [],
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VSelect
        id="report-compare-question-category-flow"
        data-testid="input-report-compare-question-category-flow"
        label={t('flows')}
        loading={isFetchingFlows}
        data={selectItems}
        clearable={true}
        searchable={true}
        placeholder={isFetchingFlows ? t('loading') : t('search')}
        value={formik.values.selectedCompareCriteria.selectedQuestionCategoryFlowIds[0] || null}
        onChange={handleChangeFlow}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionCategoryFlowIds')}
        withAsterisk={required}
      />

      <If value={isFlowSelected}>
        <VMultiSelect
          id="report-compare-flow-question-category-name"
          data-testid="input-report-compare-flow-question-category-name"
          label={t('question-category')}
          data={flowQuestionCategoryNameSelectItems}
          searchable={true}
          loading={isFetchingFlows}
          placeholder={isFetchingFlows ? t('loading') : t('search')}
          isValuesPillLoading={isFetchingFlows}
          value={formik.values.selectedCompareCriteria.selectedQuestionCategoryIds}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionCategoryIds', e)}
          onBlur={formik.handleBlur}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionCategoryIds')}
          withAsterisk={required}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
