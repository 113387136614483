import { faCircle as faCircleRegular, faFileExcel, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Anchor, Avatar, Box, Group, Image, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { DateFormatter, If, Uppercase, VSpoiler } from '@vision/ui/components';
import { SUPPORTED_LANGUAGES } from '@vision/ui/i18n';
import {
  NotificationItem,
  NotificationItemDetailObjectComment,
  NotificationItemDetailObjectFeedback,
} from '@vision/ui/interfaces';
import { ensureArray, ensureHexColor, getBodyText, getNameInitials, uuid } from '@vision/ui/utils';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './LayoutNotificationCard.module.scss';

interface LayoutNotificationCardProps {
  item: NotificationItem;
  onChangeReadStatus: (item: NotificationItem) => void;
}

function _LayoutNotificationCard({ item, onChangeReadStatus }: LayoutNotificationCardProps) {
  const { i18n, t } = useTranslation('translation');
  const theme = useMantineTheme();

  const isComment = item.action === 'new_comment';
  const name =
    item.detail.notification_creator_name ||
    item.detail.notification_creator_email ||
    item.detail.notification_creator_number;
  const hasAttachment = !isComment ? false : !!(item.detail_object as NotificationItemDetailObjectComment).attachment;
  const isAttachmentImage = !hasAttachment
    ? false
    : ((item.detail_object as NotificationItemDetailObjectComment)?.attachment_content_type?.startsWith('image/') ??
      false);
  const isAttachmentPdf = !hasAttachment
    ? false
    : ((item.detail_object as NotificationItemDetailObjectComment)?.attachment_content_type?.includes(
        'application/pdf',
      ) ?? false);
  const attachmentUrl = !hasAttachment ? null : (item.detail_object as NotificationItemDetailObjectComment)?.attachment;
  const attachmentFileName = !hasAttachment
    ? null
    : (item.detail_object as NotificationItemDetailObjectComment)?.attachment_file_name;
  const isInternal = !isComment
    ? null
    : ((item.detail_object as NotificationItemDetailObjectComment)?.internal ?? false);

  const feedbackResponses = useMemo(() => {
    if (isComment) {
      return [];
    }

    const findQuestionBySupportedLang = (body: Record<string, string>) => {
      const lang = Object.keys(body).find((langKey) =>
        SUPPORTED_LANGUAGES.some((item) => item === langKey.toLowerCase()),
      );
      return getBodyText(body, lang);
    };

    return ensureArray(item.detail_object as NotificationItemDetailObjectFeedback[])
      .map((feedback) => {
        /*
         * First find question text by current i18n lang
         * If it doesn't match then look for first matching language by looping over SUPPORTED_LANGUAGES
         * */
        const questionText =
          getBodyText(feedback.question.body, i18n.language) || findQuestionBySupportedLang(feedback.question.body);

        return {
          id: uuid(),
          label: questionText || '-',
          value: feedback.response,
        };
      })
      .map((feedback, index, feedbackList) => (
        <Stack key={feedback.id} mb={feedbackList.length !== index + 1 ? 10 : 0}>
          <Text component="strong" c="gray">
            {feedback.label}
          </Text>
          <Text component="span" c="gray">
            {feedback.value}
          </Text>
        </Stack>
      ));
  }, [isComment, item, i18n.language]);

  const handleMarkReadState = () => {
    onChangeReadStatus(item);
  };

  return (
    <div
      className={classes.layoutNotificationCard}
      data-read={item.is_read}
      data-testid={`section-notification-${item.id}`}
      data-is-comment={isComment}
    >
      <Stack gap={10}>
        <Group justify="space-between">
          <Group gap={10}>
            <Avatar
              size="xl"
              radius="xl"
              styles={{
                placeholder: {
                  backgroundColor: ensureHexColor(item.detail.notification_creator_avatar_color),
                  color: theme.white,
                },
              }}
            >
              <Uppercase value={getNameInitials(item.detail.notification_creator_name)} />
            </Avatar>

            <Stack align="flex-start">
              <Text c="gray" fw={700} maw={250} truncate={true} title={name}>
                {name}
              </Text>
              <Trans i18nKey={isComment ? 'notificationItemCommentText' : 'notificationItemFeedbackText'}>
                <Text c="gray" size="xs">
                  <Text component="strong" fw="bold" size="xs"></Text>
                </Text>
              </Trans>
            </Stack>
          </Group>

          <Group gap={5}>
            <DateFormatter value={new Date(item.created_at)} variant="medium">
              {(date, time) => (
                <Text component="span" c="gray">
                  {date}, <Text component="strong">{time}</Text>
                </Text>
              )}
            </DateFormatter>

            <Tooltip label={item.is_read ? t('markAsUnread') : t('markAsRead')} position="left" withinPortal={false}>
              <ActionIcon color="blue" onClick={handleMarkReadState} data-testid="button-notification-mark">
                <FontAwesomeIcon
                  icon={item.is_read ? faCircleRegular : faCircle}
                  fontSize={8}
                  color={theme.colors[item.is_read ? 'gray' : 'blue'][5]}
                />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>

        <div className={classes.layoutNotificationCardContent} data-internal={isInternal}>
          <VSpoiler maxHeight={130}>
            <If value={isComment}>
              <div
                dangerouslySetInnerHTML={{
                  __html: (item.detail_object as NotificationItemDetailObjectComment)?.body,
                }}
              ></div>

              <If value={hasAttachment}>
                <Box mt={10}>
                  <If value={isAttachmentImage}>
                    <Anchor href={attachmentUrl} target="_blank" title={attachmentFileName}>
                      <Image src={attachmentUrl} h={250} fit="contain" />
                    </Anchor>
                  </If>

                  <If value={!isAttachmentImage}>
                    <Anchor href={attachmentUrl} target="_blank" size="xl" title={attachmentFileName}>
                      <FontAwesomeIcon icon={isAttachmentPdf ? faFilePdf : faFileExcel} />
                    </Anchor>
                  </If>
                </Box>
              </If>
            </If>

            {feedbackResponses}
          </VSpoiler>
        </div>
      </Stack>
    </div>
  );
}

export const LayoutNotificationCard = React.memo(_LayoutNotificationCard);
