import { User } from '@vision/ui/interfaces';
import { ensureArray } from '../array.utils';

export function getMappedUserName(user: User) {
  if (user.full_name) {
    return user.full_name;
  }

  if (user.name) {
    return [user.name, user.surname].filter(Boolean).join(' ');
  }

  return user.email;
}

export function getUsersWithLabel(users: User[]): Array<User & { label: string }> {
  return ensureArray(users).map((item) => ({ ...item, label: getMappedUserName(item) }));
}

export function sortByNameAlphabetical<T extends { name: string }>(list: T[]): T[] {
  return ensureArray(list)
    .slice()
    .sort((a, b) => a.name?.localeCompare(b.name));
}
