import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse, HttpBaseRequest, NotificationItem } from '@vision/ui/interfaces';
import { HttpMethod } from '@vision/ui/enums';
import { axiosBaseQuery } from './base.service';
import { buildEndpointWithQuery, createHttpExtraOptions } from '@vision/ui/utils';

export const NotificationsService = createApi({
  reducerPath: 'NotificationsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNotificationCount: builder.query<
      ApiResponse<{ data: number }>,
      HttpBaseRequest<{ nodeId: string; unread?: boolean }>
    >({
      query: ({ nodeId, unread = false }) => ({
        url: `/v2/notifications/${nodeId}/count`,
        method: HttpMethod.GET,
        params: {
          unread,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    getNotifications: builder.query<
      ApiResponse<NotificationItem[]>,
      HttpBaseRequest<{ query: string; nodeId: string }>
    >({
      query: ({ query, nodeId }) => ({
        url: buildEndpointWithQuery(`/v2/notifications/${nodeId}`, query),
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    markAllNotificationsAsRead: builder.mutation<void, HttpBaseRequest<{ nodeId: string }>>({
      query: ({ nodeId }) => ({
        url: `/v2/notifications/${nodeId}/mark_all_as_seen`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    changeNotificationReadStatus: builder.mutation<
      ApiResponse<NotificationItem>,
      HttpBaseRequest<{ notificationId: string }>
    >({
      query: ({ notificationId }) => ({
        url: `/v2/notifications/${notificationId}/change_is_read`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationCountQuery,
  useLazyGetNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
  useChangeNotificationReadStatusMutation,
} = NotificationsService;
