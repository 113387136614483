import React from 'react';

interface CustomIconTableChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTableChart2({ height, width, active, ...props }: CustomIconTableChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7V4H19V7H13Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7V4H11V7H5Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 11V8H19V11H13Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 11V8H11V11H5Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 15V12H19V15H13Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 15V12H11V15H5Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 20V16H19V20H13Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 20V16H11V20H5Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
    </svg>
  );
}
