import { DatesRangeValue } from '@mantine/dates';
import { FeedbackStatus, ReportSpamType, YesNoType } from '@vision/ui/enums';
import {
  KeyValue,
  ReportChartCustomizeConfigurationTimeInterval,
  ReportChartFilterItemType,
  ReportChartGroupCompareItemType,
  ReportChartGroupItem,
  ReportChartMeasureType,
  ReportChartPeriodAndValueComparison,
  ReportChartPeriodAndValueComparisonApiType,
  ReportChartPeriodAndValueComparisonMapping,
  ReportChartTheme,
  ReportChartThemeDataLabelColorize,
  ReportChartThemeDataLabels,
  ReportChartThemeExtraSettings,
  ReportChartThemeSeries,
  ReportWidget,
  ReportWidgetDetail,
  ReportWidgetThemeDataLabels,
  ReportWidgetThemeExtraSettings,
  ReportWidgetThemeSeries,
  ReportsDetailGraphicSchemaValues,
  getReportChartMeasureTypeFromCXMetricMeasureApiType,
  getReportChartMeasureTypesFromCXMetricMeasureApiTypes,
} from '@vision/ui/interfaces';
import {
  DEFAULT_REPORT_CHART_THEME,
  DEFAULT_REPORT_DETAIL_GRAPHIC_COMPARE_SCHEMA_VALUES,
  DEFAULT_REPORT_DETAIL_GRAPHIC_FILTER_SCHEMA_VALUES,
  DEFAULT_REPORT_DETAIL_GRAPHIC_SCHEMA_VALUES,
  GRAPHIC_DEFAULT_DATE_RANGE,
  GraphicSerieDefaultThemeKeys,
  getDefaultThemeColorItems,
} from '@vision/ui/pages/ReportsDetailGraphic/constants';
import { ensureArray } from '../array.utils';
import { unixTimestampToDate } from '../date.utils';
import { insertIfObject } from '../object.utils';

// Eğer default temalardan herhangi biri seçili ise itemsları doldurmamız gerekli.
function setThemeSeries(series: ReportWidgetThemeSeries): ReportChartThemeSeries {
  let items: ReportChartThemeSeries['items'] = [];

  if (series.customize) {
    items = series.items.map((item) => {
      return {
        color: item.color,
        hidden: item.hidden || false,
        label: item.label,
      };
    });
  } else {
    items = getDefaultThemeColorItems(series.default_color_theme as GraphicSerieDefaultThemeKeys);
  }

  return {
    defaultColorTheme: series.customize ? 'customize' : series.default_color_theme || DEFAULT_REPORT_CHART_THEME,
    customize: series.customize,
    items: items,
  };
}

// Every time look to the theme config. If there is a api data dont use default theme.
// Because of that, we show to according to chart config. Look components
function setDataLabels(
  themeData: ReportWidgetThemeDataLabels,
  config: ReportChartThemeDataLabels,
): ReportChartThemeDataLabels {
  if (!config) return;

  if (!themeData) return config;

  let dataLabelColorize: ReportChartThemeDataLabelColorize | undefined;

  if (config?.dataLabelColorize !== undefined) {
    dataLabelColorize = {
      positive: {
        fontBold: themeData?.data_label_colorize?.positive?.font_bold || config.dataLabelColorize.positive.fontBold,
        fontColor: themeData?.data_label_colorize?.positive?.font_color || config.dataLabelColorize.positive.fontColor,
        fontFamily:
          themeData?.data_label_colorize?.positive?.font_family || config.dataLabelColorize.positive.fontFamily,
        fontSize: themeData?.data_label_colorize?.positive?.font_size || config.dataLabelColorize.positive.fontSize,
        backgroundColor:
          themeData?.data_label_colorize?.positive?.background_color ||
          config.dataLabelColorize.positive.backgroundColor,
      },
      negative: {
        fontBold: themeData?.data_label_colorize?.negative?.font_bold || config.dataLabelColorize.negative.fontBold,
        fontColor: themeData?.data_label_colorize?.negative?.font_color || config.dataLabelColorize.negative.fontColor,
        fontFamily:
          themeData?.data_label_colorize?.negative?.font_family || config.dataLabelColorize.negative.fontFamily,
        fontSize: themeData?.data_label_colorize?.negative?.font_size || config.dataLabelColorize.negative.fontSize,
        backgroundColor:
          themeData?.data_label_colorize?.negative?.background_color ||
          config.dataLabelColorize.negative.backgroundColor,
      },
    };
  }

  if (themeData?.active === false) {
    return {
      ...config,
      active: false,
      dataLabelColorize: dataLabelColorize,
    };
  }

  return {
    active: themeData.active || false,
    ...insertIfObject(!!config?.alignment, {
      alignment: themeData?.alignment || config?.alignment,
    }),
    ...insertIfObject(!!config.displayFormat, {
      displayFormat: themeData.display_format || config?.displayFormat,
    }),
    ...insertIfObject(!!config.labelDisplayFormat, {
      labelDisplayFormat: themeData.label_display_format || config?.labelDisplayFormat,
    }),
    ...insertIfObject(!!config.position, {
      position: themeData?.position || config?.position,
    }),
    ...insertIfObject(!!config.fontBold, {
      fontBold: themeData?.font_bold || config?.fontBold,
    }),
    ...insertIfObject(!!config.fontColor, {
      fontColor: themeData?.font_color || config?.fontColor,
    }),
    ...insertIfObject(!!config.fontFamily, {
      fontFamily: themeData?.font_family || config?.fontFamily,
    }),
    ...insertIfObject(!!config.fontSize, {
      fontSize: themeData?.font_size || config?.fontSize,
    }),

    dataLabelColorize: dataLabelColorize,
  };
}

function setExtraSettings(
  data: ReportWidgetThemeExtraSettings,
  themeConfig: ReportChartTheme,
): ReportChartThemeExtraSettings {
  if (!themeConfig.extraSettings) return {};

  return {
    orient: data?.orient || themeConfig?.extraSettings?.orient,
    ...insertIfObject(!!themeConfig.extraSettings.tableHeader, {
      tableHeader: {
        fontBold: data?.table_header?.font_bold || themeConfig?.extraSettings?.tableHeader?.fontBold,
        fontColor: data?.table_header?.font_color || themeConfig?.extraSettings?.tableHeader?.fontColor,
        fontSize: data?.table_header?.font_size || themeConfig?.extraSettings?.tableHeader?.fontSize,
        fontFamily: data?.table_header?.font_family || themeConfig?.extraSettings?.tableHeader?.fontFamily,
      },
    }),
  };
}

function themeConfig(reportDetail: ReportWidgetDetail, selectedChartGroupItem: ReportChartGroupItem): ReportChartTheme {
  const { theme: chartThemeData } = reportDetail;

  return {
    ...insertIfObject(!!selectedChartGroupItem.config.defaultTheme?.dataLabels, {
      dataLabels: setDataLabels(chartThemeData?.data_labels, selectedChartGroupItem.config?.defaultTheme?.dataLabels),
    }),

    serieLine: {
      active: chartThemeData.serie_line?.active,
      lineColor: chartThemeData.serie_line?.line_color,
      lineSize: chartThemeData.serie_line?.line_size,
      lineType: chartThemeData.serie_line?.line_type,
    },
    series: setThemeSeries(chartThemeData.series),
    xAxis: {
      displayFormat: chartThemeData.xAxis.display_format,
      fontBold: chartThemeData.xAxis.font_bold,
      fontColor: chartThemeData.xAxis.font_color,
      fontFamily: chartThemeData.xAxis.font_family,
      fontSize: chartThemeData.xAxis.font_size,
    },
    yAxis: {
      displayFormat: chartThemeData?.yAxis?.display_format,
      fontBold: chartThemeData.yAxis.font_bold,
      fontColor: chartThemeData.yAxis.font_color,
      fontFamily: chartThemeData.yAxis.font_family,
      fontSize: chartThemeData.yAxis.font_size,
    },
    title: {
      label: reportDetail.name,
      fontBold: chartThemeData.title.font_bold,
      fontColor: chartThemeData.title.font_color,
      fontFamily: chartThemeData.title.font_family,
      fontSize: chartThemeData.title.font_size,
    },
    ...insertIfObject(!!selectedChartGroupItem.config.defaultTheme?.extraSettings, {
      extraSettings: setExtraSettings(chartThemeData?.extra_settings, selectedChartGroupItem.config.defaultTheme),
    }),
  };
}

type ReportChartFilterItemDataType =
  | 'assignees'
  | 'node_type'
  | 'nodes'
  | 'flow_id'
  | 'intelligent_tags'
  | 'has_comments'
  | 'has_contact_info'
  | 'question_ids'
  | 'option_ids'
  | 'tag_groups'
  | 'tags'
  | 'language_ids'
  | 'node_types'
  | 'unit_ids'
  | 'unit_types'
  | 'is_not_spam'
  | 'feedback_status'
  | 'content_category_ids'
  | 'is_negative_feedback'
  | 'stage_titles'
  | 'department_ids';

const ReportFilterItemCompare: KeyValue<ReportChartFilterItemDataType, ReportChartFilterItemType>[] = [
  { key: 'assigned-users', value: 'assignees' },
  { key: 'channel-types', value: 'node_type' },
  { key: 'channels', value: 'nodes' },
  { key: 'flows', value: 'flow_id' },
  { key: 'intelligent-tags', value: 'intelligent_tags' },
  { key: 'only-negative-feedback', value: 'is_negative_feedback' },
  { key: 'only-feedback-with-comments', value: 'has_comments' },
  { key: 'only-feedback-with-contact-info', value: 'has_contact_info' },
  { key: 'questions', value: 'question_ids' },
  { key: 'question-options', value: 'option_ids' },
  { key: 'tag-groups', value: 'tag_groups' },
  { key: 'tags', value: 'tags' },
  { key: 'units', value: 'unit_ids' },
  { key: 'unit-types', value: 'unit_types' },
  { key: 'languages', value: 'language_ids' },
  { key: 'spam-feedback', value: 'is_not_spam' },
  { key: 'feedback-status', value: 'feedback_status' },
  { key: 'content-category', value: 'content_category_ids' },
  { key: 'stage-titles', value: 'stage_titles' },
  { key: 'departments', value: 'department_ids' },
];

/**
 * @param reportDetail api dan gelen data
 * @description
 * 1. api dan gelen data ve seçilen chart item a göre filter keylerini döner
 * @returns
 */
function filterKeys(reportDetail: ReportWidgetDetail): ReportChartFilterItemType[] {
  return ReportFilterItemCompare.filter((item) => {
    const reportDetailItem = reportDetail[item.value];
    return (Array.isArray(reportDetailItem) && reportDetailItem.length > 0) || reportDetailItem;
  }).map((item) => item.key);
}

export function setReportChartDateRange(from: number, to: number): DatesRangeValue {
  return [unixTimestampToDate(from), unixTimestampToDate(to)];
}

function getPeriodAndValueComparisonFromApiKey(
  apiKeys: ReportChartPeriodAndValueComparisonApiType[],
): ReportChartPeriodAndValueComparison[] {
  // Mapping'i tersine çeviriyoruz
  const reverseMapping = Object.entries(ReportChartPeriodAndValueComparisonMapping).reduce(
    (acc, [key, value]) => {
      acc[value] = key as ReportChartPeriodAndValueComparison;
      return acc;
    },
    {} as Record<ReportChartPeriodAndValueComparisonApiType, ReportChartPeriodAndValueComparison>,
  );

  return apiKeys.map((apiKey) => reverseMapping[apiKey]).filter(Boolean);
}

/**
 * @param reportDetailData api dan gelen data
 * @param selectedChartGroupItem seçilen chart item boş gelmemesi lazım asla
 * @returns ReportsDetailGraphicSchemaValues
 * @description
 * 1. api dan gelen data ve seçilen chart item a göre initial value oluşturur
 */
export function reportChartTransformInitialValue(
  reportDetailData: ReportWidget,
  selectedChartGroupItem: ReportChartGroupItem,
  isDefaultDateRange?: boolean,
): ReportsDetailGraphicSchemaValues {
  // burada seçilen chart item a göre active olan filter keylerini döner
  // burada değişim yaparken config e de bakmak gerekiyor. çünkü bazı filtreler config e göre değişebilir.
  // örneğin bar-chart da ki matrix-question measure seçildiğinde filtreden flow, question çıkar. ekranda compare yerine gelir.
  // sonrasında config e bakarak bu filtrelerin active olup olmadığına bakmak gerekiyor.
  const selectedFilterKeys = filterKeys(reportDetailData.detail);

  let selectedPeriodAndValueComparisonKeys: ReportChartPeriodAndValueComparison[] = [];

  if (reportDetailData.detail?.columns) {
    selectedPeriodAndValueComparisonKeys = getPeriodAndValueComparisonFromApiKey(reportDetailData.detail?.columns);
  }

  let chartActiveFilters: ReportChartFilterItemType[] = selectedFilterKeys;

  if (selectedChartGroupItem.config?.filters) {
    const measure = reportDetailData.detail.measure as ReportChartMeasureType;
    const applicableFilters = selectedChartGroupItem.config.filters(measure);

    chartActiveFilters = applicableFilters.filter((item) => selectedFilterKeys.includes(item));
  }

  return {
    ...DEFAULT_REPORT_DETAIL_GRAPHIC_SCHEMA_VALUES,

    selectedFilterKeys: chartActiveFilters,

    selectedFilters: {
      ...DEFAULT_REPORT_DETAIL_GRAPHIC_FILTER_SCHEMA_VALUES,

      ...insertIfObject(reportDetailData.detail?.nodes?.length > 0, {
        selectedChannelIds: reportDetailData.detail.nodes,
      }),
      ...insertIfObject(reportDetailData.detail?.assignees?.length > 0, {
        selectedAssignedUserIds: reportDetailData.detail?.assignees,
      }),
      ...insertIfObject(reportDetailData.detail?.node_type?.length > 0, {
        selectedChannelTypes: reportDetailData.detail?.node_type,
      }),
      ...insertIfObject(reportDetailData.detail?.flow_id?.length > 0, {
        selectedFlowIds: reportDetailData.detail?.flow_id,
      }),
      ...insertIfObject(reportDetailData.detail?.intelligent_tags?.length > 0, {
        selectedIntelligentTagIds: reportDetailData.detail?.intelligent_tags,
      }),
      ...insertIfObject(reportDetailData.detail?.language_ids?.length > 0, {
        selectedLanguageIds: reportDetailData.detail?.language_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.has_comments, {
        hasComments: reportDetailData.detail?.has_comments === true ? YesNoType.YES : YesNoType.NO,
      }),
      ...insertIfObject(reportDetailData.detail?.has_contact_info, {
        hasContactInfo: reportDetailData.detail?.has_contact_info === true ? YesNoType.YES : YesNoType.NO,
      }),
      ...insertIfObject(reportDetailData.detail?.question_ids?.length > 0, {
        selectedQuestionIds: reportDetailData.detail?.question_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.option_ids?.length > 0, {
        selectedQuestionOptionIds: reportDetailData.detail?.option_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.tag_groups?.length > 0, {
        selectedTagGroupIds: reportDetailData.detail?.tag_groups,
      }),
      ...insertIfObject(reportDetailData.detail?.tags?.length > 0, {
        selectedTagIds: reportDetailData.detail?.tags,
      }),
      ...insertIfObject(reportDetailData.detail?.unit_ids?.length > 0, {
        selectedUnitIds: reportDetailData.detail?.unit_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.unit_types?.length > 0, {
        selectedUnitTypeIds: reportDetailData.detail?.unit_types,
      }),

      includeIncompleteFeedback: reportDetailData?.detail?.include_incomplete_feedback || false,

      ...insertIfObject(reportDetailData.detail?.feedback_status?.length > 0, {
        selectedFeedbackStatus: reportDetailData.detail?.feedback_status,
      }),

      ...insertIfObject(reportDetailData.detail?.content_category_ids?.length > 0, {
        selectedContentCategoryIds: reportDetailData.detail?.content_category_ids,
      }),

      ...insertIfObject(!!reportDetailData.detail?.is_negative_feedback, {
        onlyNegativeFeedback: reportDetailData.detail?.is_negative_feedback === true ? YesNoType.YES : YesNoType.NO,
      }),

      ...insertIfObject(!!reportDetailData.detail?.is_spam, {
        spamFeedback: ReportSpamType.SPAM,
      }),

      ...insertIfObject(!!reportDetailData.detail?.is_not_spam, {
        spamFeedback: ReportSpamType.NOT_SPAM,
      }),

      ...insertIfObject(reportDetailData.detail?.stage_titles?.length > 0, {
        selectedEmployeeStageTitles: reportDetailData.detail?.stage_titles,
      }),

      ...insertIfObject(reportDetailData.detail?.department_ids?.length > 0, {
        selectedDepartmentIds: reportDetailData.detail?.department_ids,
      }),
    },

    selectedCompareCriteria: {
      ...DEFAULT_REPORT_DETAIL_GRAPHIC_COMPARE_SCHEMA_VALUES,
      ...insertIfObject(reportDetailData.detail?.group_by_unit_types?.length > 0, {
        selectedUnitTypeIds: reportDetailData.detail?.group_by_unit_types,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_unit_ids?.length > 0, {
        selectedUnitIds: reportDetailData.detail?.group_by_unit_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_assignee_ids?.length > 0, {
        selectedUserAssignedIds: reportDetailData.detail?.group_by_assignee_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_user_ids?.length > 0, {
        selectedUserIds: reportDetailData.detail?.group_by_user_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_archiving_user_ids?.length > 0, {
        selectedUserArchivingIds: reportDetailData.detail?.group_by_archiving_user_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_responsible_user_ids?.length > 0, {
        selectedUserResponsibleIds: reportDetailData.detail?.group_by_responsible_user_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_flow_ids?.length > 0, {
        selectedFlowIds: reportDetailData.detail?.group_by_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_company_ids?.length > 0, {
        selectedCompanyIds: reportDetailData.detail?.group_by_company_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_customer_schema_ids?.length > 0, {
        selectedSchemaCodes: reportDetailData.detail?.group_by_customer_schema_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_tag_group_ids?.length > 0, {
        selectedTagGroupIds: reportDetailData.detail?.group_by_tag_group_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_tag_tag_group_ids?.length > 0, {
        selectedTagTagGroupIds: reportDetailData.detail?.group_by_tag_tag_group_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_tag_ids?.length > 0, {
        selectedTagGroupNameIds: reportDetailData.detail?.group_by_tag_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_category_flow_ids?.length > 0, {
        selectedQuestionCategoryFlowIds: reportDetailData.detail?.group_by_category_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_category_ids?.length > 0, {
        selectedQuestionCategoryIds: reportDetailData.detail?.group_by_category_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_flow_ids?.length > 0, {
        selectedQuestionFlowIds: reportDetailData.detail?.group_by_question_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_ids?.length > 0, {
        selectedQuestionIds: reportDetailData.detail?.group_by_question_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_option_flow_ids?.length > 0, {
        selectedQuestionOptionFlowIds: reportDetailData.detail?.group_by_question_option_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_option_question_ids?.length > 0, {
        selectedQuestionOptionQuestionIds: reportDetailData.detail?.group_by_question_option_question_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_option_ids?.length > 0, {
        selectedQuestionOptionIds: reportDetailData.detail?.group_by_question_option_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_response_body_flow_ids?.length > 0, {
        selectedQuestionResponseFlowIds: reportDetailData.detail?.group_by_response_body_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_response_body_question_ids?.length > 0, {
        selectedQuestionResponseQuestionIds: reportDetailData.detail?.group_by_response_body_question_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_key_flow_ids?.length > 0, {
        selectedQuestionKeyFlowIds: reportDetailData.detail?.group_by_question_key_flow_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_question_key_question_ids?.length > 0, {
        selectedQuestionKeyIds: reportDetailData.detail?.group_by_question_key_question_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_channel_types?.length > 0, {
        selectedChannelTypes: reportDetailData.detail?.group_by_channel_types,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_channel_ids?.length > 0, {
        selectedChannelIds: reportDetailData.detail?.group_by_channel_ids,
      }),
      ...insertIfObject(reportDetailData.detail?.group_by_content_category_ids?.length > 0, {
        selectedContentCategoryIds: reportDetailData.detail?.group_by_content_category_ids,
      }),

      ...insertIfObject(reportDetailData.detail?.group_by_statuses?.length > 0, {
        selectedFeedbackStatus: reportDetailData.detail?.group_by_statuses as FeedbackStatus[],
      }),

      ...insertIfObject(reportDetailData.detail?.group_by_intelligent_tag_ids?.length > 0, {
        selectedIntelligentTagIds: reportDetailData.detail?.group_by_intelligent_tag_ids,
      }),
    },

    selectedDateRange: isDefaultDateRange
      ? GRAPHIC_DEFAULT_DATE_RANGE
      : setReportChartDateRange(reportDetailData.detail.from, reportDetailData.detail.to),
    selectedMeasure: reportDetailData.detail.measure as ReportChartMeasureType,
    selectedMultipleMeasure: reportDetailData?.detail?.measures as ReportChartMeasureType[],
    selectedCompare: (reportDetailData.detail.compares as ReportChartGroupCompareItemType[]) || [],

    ...insertIfObject(!!reportDetailData?.detail?.line_measure, {
      selectedLineMeasure: getReportChartMeasureTypeFromCXMetricMeasureApiType(reportDetailData?.detail?.line_measure),
    }),

    ...insertIfObject(!!reportDetailData.detail?.bar_measures, {
      selectedBarMeasures: getReportChartMeasureTypesFromCXMetricMeasureApiTypes(
        ensureArray(reportDetailData.detail.bar_measures),
      ),
    }),

    ...insertIfObject(!!selectedPeriodAndValueComparisonKeys.length, {
      selectedPeriodAndValueComparison: selectedPeriodAndValueComparisonKeys,
    }),

    // customize ı olmayan grafikler var buna göre düzenlenecek
    selectedCustomize: {
      ...selectedChartGroupItem?.config?.customize,

      ...insertIfObject(!!reportDetailData.detail?.campaign_type, {
        campaignType: reportDetailData.detail?.campaign_type,
      }),

      ...insertIfObject(!!selectedChartGroupItem.config.customize.transactionalDate, {
        transactionalDate: {
          active: reportDetailData.detail.transactional_date,
        },
      }),

      ...insertIfObject(reportDetailData?.detail?.peak_definitions?.length > 0, {
        peakDefinition: {
          active: true,
          values: ensureArray(reportDetailData?.detail?.peak_definitions).map((item) => ({
            name: item.name,
            dateRange: [
              new Date(item.date_range[0] * 1000),
              new Date(item.date_range[1] * 1000),
            ] as unknown as DatesRangeValue,
            color: item.color,
          })),
        },
      }),

      ...insertIfObject(!!reportDetailData?.detail?.reference_definition, {
        referenceDefinition: {
          active: true,
          value: {
            name: reportDetailData?.detail?.reference_definition?.name,
            score: reportDetailData?.detail?.reference_definition?.score,
          },
        },
      }),

      ...insertIfObject(!!reportDetailData.detail.interval, {
        timeInterval: reportDetailData.detail.interval as ReportChartCustomizeConfigurationTimeInterval,
      }),

      ...insertIfObject(!!selectedChartGroupItem.config.customize.limits, {
        limits: {
          ...selectedChartGroupItem.config.customize.limits,
          ...insertIfObject(!!reportDetailData.detail.upper_limit, {
            upperLimitActive: true,
            upperLimit: reportDetailData.detail.upper_limit,
          }),
          ...insertIfObject(!!reportDetailData.detail.lower_limit, {
            lowerLimitActive: true,
            lowerLimit: reportDetailData.detail.lower_limit,
          }),
        },
      }),
    },

    selectedTheme: {
      ...selectedChartGroupItem?.config?.defaultTheme,
      // eski migration yapılan raporlar için default theme olmayabilir. bu durumda default theme oluşturulmalı
      ...insertIfObject(!!reportDetailData.detail?.theme, {
        ...themeConfig(reportDetailData.detail, selectedChartGroupItem),
      }),
    },
  };
}
