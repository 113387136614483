import { Group, Text } from '@mantine/core';
import React from 'react';
import classes from './ReportsDetailGraphicConfigurationValueHeader.module.scss';

interface ReportsDetailGraphicConfigurationValueHeaderProps {
  icon?: React.ReactNode;
  label: string;
}

export function ReportsDetailGraphicConfigurationValueHeader({
  icon,
  label,
}: ReportsDetailGraphicConfigurationValueHeaderProps) {
  return (
    <Group className={classes.valueHeader} gap={10} justify="center">
      <Text component="span" size="xs" fw={500}>
        {label}
      </Text>

      {icon}
    </Group>
  );
}
