import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { Flow, FlowTemplate, HttpBaseRequest, SaveFlowTemplateRequest } from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const FlowsService = createApi({
  reducerPath: 'FlowsService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFlows: builder.query<
      Flow[],
      HttpBaseRequest<{
        nodeId: string;
        includeQuestionOptions?: boolean;
        includeQuestions?: boolean;
        allowSavingIncompleteSurvey?: boolean;
      }>
    >({
      query: ({ nodeId, includeQuestionOptions, includeQuestions, allowSavingIncompleteSurvey }) => ({
        url: '/v1/flows',
        method: HttpMethod.GET,
        params: {
          node_id: nodeId,
          include_questions: includeQuestions,
          include_options: includeQuestionOptions,
          allow_saving_incomplete_survey: allowSavingIncompleteSurvey,
        },
      }),
    }),
    saveFlowTemplate: builder.mutation<FlowTemplate, HttpBaseRequest<SaveFlowTemplateRequest>>({
      query: (data) => ({
        url: '/v1/flow_templates',
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateFlowTemplate: builder.mutation<FlowTemplate, HttpBaseRequest<FlowTemplate>>({
      query: (data) => ({
        url: `/v1/flow_templates/${data.id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
  }),
});

export const { useGetFlowsQuery, useSaveFlowTemplateMutation, useUpdateFlowTemplateMutation } = FlowsService;
