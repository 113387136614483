import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { TreeSelectItemType } from '@vision/ui/interfaces';
import { convertToList } from '@vision/ui/utils';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTreeSelectContext } from '../../TreeSelectContext';
import classes from './TreeSelectItem.module.scss';

interface TreeSelectItemProps {
  isChild?: boolean;
  treeSelectItem: TreeSelectItemType;
  level?: number;
}

export function TreeSelectItem({ isChild, treeSelectItem, level = 1 }: TreeSelectItemProps) {
  const [expanded, setExpanded] = useState(false);
  const { handleToggleSelect, selectedTreeSelectItems } = useTreeSelectContext();
  const hasChild = treeSelectItem.children.length > 0;

  const isSelected = useMemo(() => {
    return selectedTreeSelectItems.some((item) => item.id === treeSelectItem.id);
  }, [selectedTreeSelectItems, treeSelectItem]);

  const allChildrens = useMemo(() => {
    return convertToList(treeSelectItem);
  }, [treeSelectItem]);

  const isAnyChildrenSelected = useMemo(() => {
    return allChildrens.some((item) => selectedTreeSelectItems.some((seleced) => seleced.id === item.id));
  }, [selectedTreeSelectItems, allChildrens]);

  useEffect(() => {
    const anySelectedChildren = allChildrens.some((item) =>
      selectedTreeSelectItems.some((seleced) => seleced.id === item.id),
    );
    if (anySelectedChildren && !expanded) {
      setExpanded(true);
    }
  }, []);

  const handleToggleExpand = () => {
    if (!hasChild) {
      return;
    }

    setExpanded((prev) => !prev);
  };

  const handleCheckboxChange = () => {
    handleToggleSelect(treeSelectItem);
  };

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Stack
      className={clsx({
        [classes.treeSelectItemWrapperRoot]: !isChild,
      })}
    >
      <UnstyledButton
        pl={level * 10}
        className={clsx(classes.treeSelectItem, {
          [classes.treeSelectItemHasChild]: hasChild,
          [classes.treeSelectItemExpanded]: expanded,
        })}
        onClick={handleToggleExpand}
      >
        <Group justify="space-between" gap={10} wrap="nowrap">
          <Group gap={10} wrap="nowrap">
            {hasChild && (
              <FontAwesomeIcon
                className={classes.treeSelectItemIcon}
                fontSize={12}
                icon={expanded ? faChevronDown : faChevronRight}
              />
            )}

            <Box w={150} ml={!hasChild && 18}>
              <Text
                className={classes.treeSelectItemLabel}
                size="xs"
                fw={600}
                truncate={true}
                title={treeSelectItem.label}
              >
                {treeSelectItem.label}
              </Text>
            </Box>
          </Group>

          <Checkbox
            classNames={{
              inner: classes.treeSelectItemCheckboxInner,
              input: classes.treeSelectItemCheckboxInput,
            }}
            disabled={treeSelectItem.disabled}
            checked={isSelected}
            radius="xl"
            indeterminate={!isSelected && isAnyChildrenSelected}
            onChange={handleCheckboxChange}
            onClick={handleCheckboxClick}
          />
        </Group>
      </UnstyledButton>

      {expanded &&
        hasChild &&
        treeSelectItem.children.map((childItem) => (
          <TreeSelectItem isChild={true} key={childItem.id} treeSelectItem={childItem} level={level + 1} />
        ))}
    </Stack>
  );
}
