import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type GaugeSeriesProps = SeriesProps<RegisteredSeriesOption['gauge']>;
/**
 * @example
 * ```tsx
 * <GaugeSeries
 *     name="Pressure"
 *     detail={{ formatter: '{value}' }}
 *     data={[{ value: 50, name: 'SCORE' }]}
 * />
 */
export const GaugeSeries: EC<GaugeSeriesProps> = () => <></>;

GaugeSeries.optionOf = seriesOptionCreator('gauge');

GaugeSeries.loadModule = chartLoader(['GaugeChart']);
