import { LegendComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { componentLoader, optionCreator } from '../../ECharts.utils';

export type LegendProps = EventHandlerProps & LegendComponentOption;
/**
 * @example
 * ```tsx
 * <Legend data={['sales']} />
 * ```
 */
export const Legend: EC<LegendProps> = () => <></>;

Legend.optionOf = optionCreator('legend', {
  bottom: 0,
} as LegendProps);

Legend.loadModule = componentLoader(['LegendComponent']);
