import { ComboboxItemGroup } from '@mantine/core';
import { If, VMultiSelect } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { QuestionStyles, ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, ensureArray, getBodyText, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

const QUESTION_WITH_OPTION_TYPES: QuestionStyles[] = [
  'plain',
  'radio',
  'score',
  'checklist',
  'matrix',
  'star',
  'dropdown',
  'hidden_validation',
  'gender',
  'ranking',
  'random',
];

interface ReportsDetailGraphicCompareQuestionOptionProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionOption({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionOptionProps) {
  const { i18n, t } = useTranslation();
  const accountId = useSelectedAccountId();
  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestionOptions: true,
    includeQuestions: true,
  });

  const { textWithNativeEmoji } = useEmojiContext();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds.length > 0;
  const isFlowQuestionSelected = formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds.length > 0;

  const selectFlowItems = useMemo(
    () =>
      convertArrayToSelectItems(flows, (item) => ({
        value: item.id,
        label: item.name,
      })),
    [flows],
  );

  const selectedFlows = useMemo(() => {
    if (!flows) {
      return [];
    }
    // formikteki seçilen flow idlerini alıp, flowları bulup döndürüyoruz
    return formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds.map((flowId) =>
      flows.find((item) => item.id === flowId),
    );
  }, [flows, formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds]);

  const selectedFlowQuestions = useMemo(() => {
    return selectedFlows.map((item) => ensureArray(item.questions)).flat();
  }, [selectedFlows, formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds]);

  const flowQuestionSelectItems = useMemo(() => {
    return selectedFlows.map((item) => {
      return {
        group: item.name,
        items: ensureArray(item.questions)
          .filter((question) => !!question.body)
          .filter((question) => QUESTION_WITH_OPTION_TYPES.includes(question.style))
          .map((question) => ({
            label: getBodyText(question.body, i18n.language) || t('no-question-title'),
            value: question.id,
          })),
      } as ComboboxItemGroup;
    });
  }, [selectedFlows, formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds]);

  const flowQuestionOptionSelectItems = useMemo(() => {
    return selectedFlowQuestions
      .filter((question) => !!question.body)
      .filter((question) =>
        formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds.includes(question.id),
      )
      .map((item) => {
        return {
          group: getBodyText(item.body, i18n.language),
          items: ensureArray(item.options).map((questionOption) => ({
            label: textWithNativeEmoji(getBodyText(questionOption.body, i18n.language)),
            value: questionOption.id,
          })),
        } as ComboboxItemGroup;
      });
  }, [selectedFlowQuestions, formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds]);

  const handleSelectFlow = (values: string[]) => {
    // Seçilen flow'ları bul
    const newSelectedFlows = values.map((flowId) => flows.find((item) => item.id === flowId));

    // Seçilen flow'larda yer alan soru ID'lerini bul
    const validQuestionIds = new Set(
      newSelectedFlows.flatMap((flow) => flow?.questions?.map((question) => question.id) || []),
    );

    // Seçilen flow'larda yer alan seçenek ID'lerini bul
    const validQuestionOptionIds = new Set(
      newSelectedFlows.flatMap(
        (flow) => flow?.questions?.flatMap((question) => question.options?.map((option) => option.id) || []) || [],
      ),
    );

    // Mevcut seçili sorular arasından geçerli olanları filtrele
    const newSelectedQuestionIds = formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds.filter(
      (id) => validQuestionIds.has(id),
    );

    // Mevcut seçili seçenekler arasından geçerli olanları filtrele
    const newSelectedQuestionOptionIds = formik.values.selectedCompareCriteria.selectedQuestionOptionIds.filter((id) =>
      validQuestionOptionIds.has(id),
    );

    // Formik değerlerini güncelle
    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionOptionFlowIds: values,
        selectedQuestionOptionQuestionIds: newSelectedQuestionIds,
        selectedQuestionOptionIds: newSelectedQuestionOptionIds,
      },
    });
  };

  const handleSelectQuestion = (value: string[]) => {
    // Geçerli soru ID'leri listesi
    const validQuestionIds = new Set(value);

    // Yeni seçilen soru seçeneklerinin ID'lerini filtrele
    const newSelectedQuestionOptionIds = formik.values.selectedCompareCriteria.selectedQuestionOptionIds.filter(
      (selectedQuestionOptionId) =>
        selectedFlowQuestions
          .filter((question) => !!question.body && validQuestionIds.has(question.id))
          .some((question) =>
            question.options?.some((questionOption) => questionOption.id === selectedQuestionOptionId),
          ),
    );

    formik.setValues({
      ...formik.values,
      selectedCompareCriteria: {
        ...formik.values.selectedCompareCriteria,
        selectedQuestionOptionQuestionIds: value,
        selectedQuestionOptionIds: newSelectedQuestionOptionIds,
      },
    });
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VMultiSelect
        id="report-compare-flows"
        data-testid="input-report-compare-flows"
        label={t('flows')}
        loading={isFetchingFlows}
        placeholder={isFetchingFlows ? t('loading') : t('search')}
        isValuesPillLoading={isFetchingFlows}
        data={selectFlowItems}
        searchable={true}
        value={formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds}
        onChange={handleSelectFlow}
        onBlur={formik.handleBlur}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionFlowIds')}
        withAsterisk={required}
      />

      <If value={isFlowSelected}>
        <VMultiSelect
          id="report-compare-flow-question"
          data-testid="input-report-compare-flow-question"
          label={t('question')}
          data={flowQuestionSelectItems}
          loading={isFetchingFlows}
          placeholder={isFetchingFlows ? t('loading') : t('search')}
          isValuesPillLoading={isFetchingFlows}
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds}
          onChange={handleSelectQuestion}
          onBlur={formik.handleBlur}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionQuestionIds')}
          withAsterisk={required}
        />

        <If value={isFlowQuestionSelected}>
          <VMultiSelect
            id="report-compare-flow-question-option"
            data-testid="input-report-compare-flow-question-option"
            label={t('questionOption')}
            loading={isFetchingFlows}
            placeholder={isFetchingFlows ? t('loading') : t('search')}
            isValuesPillLoading={isFetchingFlows}
            searchable={true}
            data={flowQuestionOptionSelectItems}
            value={formik.values.selectedCompareCriteria.selectedQuestionOptionIds}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionIds', e)}
            error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionIds')}
            withAsterisk={required}
          />
        </If>
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
