import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  ContactPolicy,
  EmailCampaign,
  GroupedChannels,
  HttpBaseRequest,
  SmsCampaign,
} from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const ContactPolicyService = createApi({
  reducerPath: 'ContactPolicyService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getContactPolicies: builder.query<ApiResponse<ContactPolicy[]>, { query: string; accountId: string }>({
      query: ({ accountId, query }) => ({
        url: `/v2/accounts/${accountId}/contact_policies${query ? `?${query}` : ''}`,
        method: HttpMethod.GET,
      }),
    }),
    deleteContactPolicy: builder.mutation<void, { contactPolicyId: string; accountId: string }>({
      query: ({ accountId, contactPolicyId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies/${contactPolicyId}/delete`,
        method: HttpMethod.POST,
      }),
    }),
    createContactPolicy: builder.mutation<void, HttpBaseRequest<{ contactPolicy: ContactPolicy; accountId: string }>>({
      query: ({ contactPolicy, accountId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies`,
        method: HttpMethod.POST,
        data: contactPolicy,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.createContactPolicy.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    updateContactPolicy: builder.mutation<void, HttpBaseRequest<{ contactPolicy: ContactPolicy; accountId: string }>>({
      query: ({ contactPolicy, accountId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies/${contactPolicy.id}`,
        method: HttpMethod.POST,
        data: contactPolicy,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.updateContactPolicy.{{status}}',
            autoHandleError: false,
          },
        }),
      }),
    }),
    campaignsForContactPolicy: builder.query<
      { email_campaigns: EmailCampaign[]; sms_campaigns: SmsCampaign[] },
      HttpBaseRequest<{ accountId: string }>
    >({
      query: ({ accountId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies/campaign_list`,
        method: HttpMethod.GET,
      }),
    }),
    toggleContactPolicyStatus: builder.mutation<void, HttpBaseRequest<{ contactPolicyId: string; accountId: string }>>({
      query: ({ contactPolicyId, accountId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies/${contactPolicyId}/status_update`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.toggleContactPolicyStatus.{{status}}',
          },
        }),
      }),
    }),
    getChannelList: builder.query<GroupedChannels, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/v2/accounts/${accountId}/contact_policies/channel_list`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useCreateContactPolicyMutation,
  useDeleteContactPolicyMutation,
  useGetChannelListQuery,
  useLazyCampaignsForContactPolicyQuery,
  useLazyGetContactPoliciesQuery,
  useToggleContactPolicyStatusMutation,
  useUpdateContactPolicyMutation,
} = ContactPolicyService;
