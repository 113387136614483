import { ReportChartGroupItemMeasure } from '@vision/ui/interfaces';

export const EXTrendChart4Measures: ReportChartGroupItemMeasure[] = [
  {
    type: 'feedback',
    items: [
      'feedback-count',
      'feedback-elc-score',
      'feedback-candidate-score',
      'feedback-nps',
      'feedback-csat',
      'feedback-sentiment-score',
      'feedback-weighted-score',
    ],
  },
];
