import { ReportChartGroupItemMeasure } from '@vision/ui/interfaces';

export const TableChart1Measures: ReportChartGroupItemMeasure[] = [
  {
    type: 'feedback',
    items: [
      'feedback-count',
      'feedback-nps',
      'feedback-csat',
      'feedback-sentiment-score',
      'feedback-weighted-score',
      'feedback-chat-score',
    ],
  },
  {
    type: 'action-time',
    items: [
      'action-time-completion',
      'action-time-read',
      'action-time-response',
      'action-time-internal-note',
      'action-time-archived',
    ],
  },
  {
    type: 'response',
    items: ['response-count', 'response-ranking-overview'],
  },
  {
    type: 'customer',
    items: ['customer-count'],
  },
  {
    type: 'kpi',
    items: ['nps-kpi', 'csat-kpi', 'nps-realization-rate', 'csat-realization-rate'],
  },
];
