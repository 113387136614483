import React from 'react';

interface CustomIconBarChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconBarChart1({ height, width, active, ...props }: CustomIconBarChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M12 5L8 5L8 7L12 7L12 5Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M17 5L12 5L12 7L17 7L17 5Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M12 8L4 8L4 10L12 10L12 8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M20 8L12 8L12 10L20 10L20 8Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M12 14L6 14L6 16L12 16L12 14Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M18 14L12 14L12 16L18 16L18 14Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M12 11L7 11L7 13L12 13L12 11Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M12 17L9 17L9 19L12 19L12 17Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M15 11L12 11L12 13L15 13L15 11Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M16 17L12 17L12 19L16 19L16 17Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
    </svg>
  );
}
