import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import classes from './DraggableListItemHandle.module.scss';

type DragHandleProps = {
  id: UniqueIdentifier;
} & ButtonProps;

function _DraggableListItemHandle({ children, id, className, ...others }: DragHandleProps) {
  const { listeners, setActivatorNodeRef } = useSortable({
    id,
  });

  return (
    <Button
      className={clsx(classes.draggableListItemHandle, className)}
      ref={setActivatorNodeRef}
      size="compact-xs"
      color="dark"
      variant="subtle"
      {...others}
      {...listeners}
    >
      {children || <FontAwesomeIcon icon={faGripDotsVertical} />}
    </Button>
  );
}

export const DraggableListItemHandle = createPolymorphicComponent<'button', DragHandleProps>(
  React.memo(_DraggableListItemHandle),
);
