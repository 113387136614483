import { defaultVariantColorsResolver, parseThemeColor, VariantColorsResolver } from '@mantine/core';

export const visionVariantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  // Override some properties for variant
  if (!parsedColor.isThemeColor && parsedColor.color === 'high' && input.variant === 'filled') {
    return {
      ...defaultResolvedColors,
      color: 'var(--mantine-color-green-5)',
      background: 'var(--mantine-color-green-0)',
    };
  }

  if (!parsedColor.isThemeColor && parsedColor.color === 'low' && input.variant === 'filled') {
    return {
      ...defaultResolvedColors,
      color: 'var(--mantine-color-red-9)',
      background: 'var(--mantine-color-red-0)',
    };
  }

  if (!parsedColor.isThemeColor && parsedColor.color === 'neutral' && input.variant === 'filled') {
    return {
      ...defaultResolvedColors,
      color: 'var(--mantine-color-orange-4)',
      background: 'var(--mantine-color-orange-0)',
    };
  }

  return defaultResolvedColors;
};
