import { Account } from '@vision/ui/interfaces';
import { selectDefaultAssignedNode } from '@vision/ui/utils';
import { useMemo } from 'react';
import { useSelectedAccountId } from './useSelectedAccountId';
import { useUser } from './useUser';

export function useAccount(): Account {
  const user = useUser();
  const selectedAccountId = useSelectedAccountId();

  return useMemo(() => {
    const defaultAccount = selectDefaultAssignedNode(user);
    if (selectedAccountId) {
      const account = user?.assigned_nodes.find((node) => node.id === selectedAccountId);
      return account || defaultAccount;
    }
    return defaultAccount;
  }, [user, selectedAccountId]);
}
