import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ActivateUserRequest,
  HttpBaseRequest,
  LoginResponse,
  ResetPasswordResponse,
  User,
  UserUpdateRequest,
  UserWithOtp,
} from '@vision/ui/interfaces';
import { ENV, createHttpExtraOptions, insertIfObject } from '@vision/ui/utils';
import JSEncrypt from 'jsencrypt';
import { axiosBaseQuery } from './base.service';

const encryptor = new JSEncrypt();
encryptor.setPublicKey(ENV.LOGIN_PUBLIC_KEY);

export const AuthService = createApi({
  reducerPath: 'AuthService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    authenticate: builder.query<User, HttpBaseRequest<{ id: string; token: string }>>({
      query: ({ id, token }) => ({
        url: `/v1/users/${id}/authenticate`,
        method: HttpMethod.POST,
        data: {
          token,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    login: builder.mutation<
      LoginResponse,
      HttpBaseRequest<{ email: string; password: string; recaptchaToken?: string; account_code?: string }>
    >({
      query: ({ account_code, email, recaptchaToken, password }) => ({
        url: '/v1/login',
        method: HttpMethod.POST,
        data: {
          hash: encryptor.encrypt(JSON.stringify({ email, password })),
          ...insertIfObject(!!recaptchaToken, {
            recaptcha: recaptchaToken,
          }),
          ...insertIfObject(!!account_code, {
            account_code,
          }),
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.login.{{status}}',
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/v1/logout',
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    loginDetails: builder.query<{ redirection_url: string }, HttpBaseRequest<{ accountCode: string }>>({
      query: ({ accountCode }) => ({
        url: `/public/v1/accounts/${accountCode}/login_details`,
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
    forgotPassword: builder.mutation<Record<any, any>, HttpBaseRequest<{ email: string; recaptchaToken?: string }>>({
      query: ({ email, recaptchaToken }) => ({
        url: '/v1/send_password_reset_email',
        method: HttpMethod.POST,
        data: {
          email,
          ...insertIfObject(!!recaptchaToken, {
            recaptcha: recaptchaToken,
          }),
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    validateResetPasswordHash: builder.query<ResetPasswordResponse, HttpBaseRequest<{ hash: string }>>({
      query: ({ hash }) => ({
        url: '/v1/reset_password',
        method: HttpMethod.GET,
        params: {
          hash,
        },
      }),
    }),
    resetPassword: builder.mutation<
      ResetPasswordResponse,
      HttpBaseRequest<{ hash: string; password: string; password_check: string }>
    >({
      query: ({ hash, password, password_check }) => ({
        url: '/v1/reset_password',
        method: HttpMethod.POST,
        data: {
          hash,
          password,
          password_check,
        },
      }),
    }),
    unsubscribeEmail: builder.mutation<void, HttpBaseRequest<{ nodeId: string; customerId: string }>>({
      query: ({ nodeId, customerId }) => ({
        url: `/v1/nodes/${nodeId}/customers/${customerId}/unsubscribe`,
        method: HttpMethod.POST,
      }),
    }),
    checkInvitationToken: builder.query<User, HttpBaseRequest<{ hash: string }>>({
      query: ({ hash }) => ({
        url: '/v1/check_invitation_token',
        method: HttpMethod.GET,
        params: {
          hash,
        },
      }),
    }),
    activateUser: builder.mutation<User, HttpBaseRequest<ActivateUserRequest>>({
      query: (request) => ({
        url: '/v1/activate_user',
        method: HttpMethod.POST,
        data: request,
      }),
    }),
    resendOtp: builder.mutation<UserWithOtp, HttpBaseRequest<{ email: string; password: string }>>({
      query: (request) => ({
        url: '/v1/resend_otp',
        method: HttpMethod.POST,
        data: request,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.resendOtp.{{status}}',
          },
        }),
      }),
    }),
    validateOtp: builder.mutation<User, HttpBaseRequest<{ email: string; password: string; otp: string }>>({
      query: ({ email, otp, password }) => ({
        url: '/v1/validate_otp',
        method: HttpMethod.POST,
        data: {
          hash: encryptor.encrypt(JSON.stringify({ email, password })),
          otp,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.otp.{{status}}',
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateAuthUser: builder.mutation<User, UserUpdateRequest>({
      query: (user) => ({
        url: `/v1/users/${user.id}/update`,
        method: HttpMethod.POST,
        data: user,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.profile.{{status}}',
          },
        }),
      }),
    }),
    loginAsUser: builder.mutation<LoginResponse, string>({
      query: (userId) => ({
        url: `/v1/users/${userId}/login_as_user`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.loginAsUser.{{status}}',
          },
        }),
      }),
    }),
    changePassword: builder.mutation<
      User,
      HttpBaseRequest<{ userId: string; confirmPassword: string; currentPassword: string; newPassword: string }>
    >({
      query: ({ userId, confirmPassword, currentPassword, newPassword }) => ({
        url: `/v1/users/${userId}/change_password`,
        method: HttpMethod.POST,
        data: {
          current_password: currentPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.changePassword.{{status}}',
          },
        }),
      }),
    }),
    uploadProfilePicture: builder.mutation<User, HttpBaseRequest<{ userId: string; formData: FormData }>>({
      query: ({ userId, formData }) => ({
        url: `/v1/users/${userId}/upload_profile_picture`,
        method: HttpMethod.POST,
        data: formData,
      }),
    }),
    deleteProfilePicture: builder.query<User, HttpBaseRequest<{ userId: string }>>({
      query: ({ userId }) => ({
        url: `/v1/users/${userId}/delete_profile_picture`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useActivateUserMutation,
  useCheckInvitationTokenQuery,
  useForgotPasswordMutation,
  useLazyAuthenticateQuery,
  useLoginDetailsQuery,
  useLoginMutation,
  useLogoutMutation,
  useResendOtpMutation,
  useResetPasswordMutation,
  useUnsubscribeEmailMutation,
  useValidateOtpMutation,
  useValidateResetPasswordHashQuery,
  useUpdateAuthUserMutation,
  useLoginAsUserMutation,
  useChangePasswordMutation,
  useUploadProfilePictureMutation,
  useLazyDeleteProfilePictureQuery,
} = AuthService;
