import { createSlice } from '@reduxjs/toolkit';
import { AccountSettingsResponse, SmsProviderKeyType } from '@vision/ui/interfaces';
import { AccountService, SmsProviderService } from '@vision/ui/services';

interface FormPayload {
  id: string;
  smsProviderId: string;
  smsProviderType: string;
}

interface FormState {
  accountSettings: AccountSettingsResponse;
  activeFormPayload: {
    formName: SmsProviderKeyType | '';
    formPayload: FormPayload;
    actionType?: 'add' | 'edit' | 'copy';
  };
  loadingRequest: boolean;
  isSuccessfulFormRequest: boolean;
}

const initialState: FormState = {
  accountSettings: {} as AccountSettingsResponse,
  activeFormPayload: {
    formName: '',
    formPayload: {} as FormPayload,
    actionType: undefined,
  },
  loadingRequest: false,
  isSuccessfulFormRequest: false,
};

export const SmsProviderState = createSlice({
  name: 'SmsProviderState',
  initialState,
  reducers: {
    setSmsProviderActiveForm: (state, action) => {
      state.activeFormPayload = action.payload;
    },
    setSmsProviderLoadingRequest: (state, action) => {
      state.loadingRequest = action.payload;
    },
    setSmsProviderIsSuccessfulFormRequest: (state, action) => {
      state.isSuccessfulFormRequest = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(AccountService.endpoints.getAccountSettings.matchFulfilled, (state, { payload }) => {
      state.accountSettings = payload;
    });
    builder.addMatcher(SmsProviderService.endpoints.createSmsProvider.matchFulfilled, (state) => {
      state.isSuccessfulFormRequest = true;
    });
    builder.addMatcher(SmsProviderService.endpoints.updateSmsProvider.matchFulfilled, (state) => {
      state.isSuccessfulFormRequest = true;
    });
  },
});

export const { setSmsProviderActiveForm, setSmsProviderLoadingRequest, setSmsProviderIsSuccessfulFormRequest } =
  SmsProviderState.actions;
