import { ReportChartConfiguration, ReportChartFilterItemTypes, ReportChartMeasureType } from '@vision/ui/interfaces';
import { array, string } from 'yup';
import { BarChart3ChartValueComponent, BarChart3CompareRenderer } from '../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../default-chart.config';
import { BarChart3Filtres } from './bar-chart3.filtre';

export const BarChart3DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema(
    {
      selectedLineMeasure: string().required('validation:rules.reports.lineMeasure.required'),
      selectedBarMeasures: array()
        .of(string().required('validation:rules.generic.required'))
        .min(1, 'validation:rules.generic.required'),
    },
    true,
  ),
  measures: null,
  defaultMeasure: 'cx-metrics',
  compareRendererComponent: BarChart3CompareRenderer,
  lineMeasure: null,
  barMeasure: [],
  chartValueComponent: BarChart3ChartValueComponent,
  hideCompare: true,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
    sortResults: {
      limit: 0,
      order: null,
    },
  },
  filters: (measureType: ReportChartMeasureType) => {
    for (const item of BarChart3Filtres) {
      if (item.measures.includes(measureType)) {
        return item.filterItems;
      }
    }
    return ReportChartFilterItemTypes;
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: true,
      displayFormat: 'auto',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      labelDisplayFormat: 'number',
      position: 'outside',
    },
    serieDot: {
      active: false,
      symbolSize: 6,
      symbolType: 'circle',
      symbolFilling: 'full',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
