import { VMultiSelect } from '@vision/ui/components';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ApiResponseLegacy, IntelligentTag, ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetIntelligentTagsQuery } from '@vision/ui/services';
import { convertArrayToSelectItems, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareTagIntelligentProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareTagIntelligent({
  required,
}: ReportsDetailGraphicCompareTagIntelligentProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  // Bu API /v2 pagination yapısına çevrilmeli ve sonrasında aşağıda VLazyLoadMultiSelect kullanılmalı
  const { data: intelligentTags = {} as ApiResponseLegacy<IntelligentTag[]> } = useGetIntelligentTagsQuery({
    nodeId: accountId,
  });
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const selectItems = useMemo(
    () =>
      convertArrayToSelectItems(intelligentTags?.data, (item) => ({
        value: item.id as string,
        label: item.name as string,
      })),
    [intelligentTags],
  );

  return (
    <ReportsDetailGraphicCompareLayout title={t('intelligentTags')}>
      <VMultiSelect
        id="report-compare-intelligent-tags"
        data-testid="input-report-compare-intelligent-tags"
        label={t('intelligentTags')}
        data={selectItems}
        searchable={true}
        value={formik.values.selectedCompareCriteria.selectedIntelligentTagIds}
        onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedIntelligentTagIds', e)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedIntelligentTagIds')}
        withAsterisk={required}
        withSelectAll={false}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
