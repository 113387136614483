import React from 'react';
import { Spoiler, SpoilerProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface VSpoilerProps extends Pick<SpoilerProps, 'maxHeight'> {}

export function VSpoiler({ children, maxHeight }: React.PropsWithChildren<VSpoilerProps>) {
  const { t } = useTranslation();

  return (
    <Spoiler maxHeight={maxHeight} showLabel={t('showMore')} hideLabel={t('hide')}>
      {children}
    </Spoiler>
  );
}
