import { VMultiSelect } from '@vision/ui/components';
import { FeedbackStatus } from '@vision/ui/enums';
import { useFeedbackStatus, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues } from '@vision/ui/interfaces';
import { useGetFlowsQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareFeedbackStatusProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareFeedbackStatus({}: ReportsDetailGraphicCompareFeedbackStatusProps) {
  const { t } = useTranslation(['translation', 'page-reports']);
  const feedbackStatus = useFeedbackStatus();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const { data: flows, isFetching: isFetchingFlows } = useGetFlowsQuery({
    nodeId: accountId,
    includeQuestions: false,
    includeQuestionOptions: false,
    allowSavingIncompleteSurvey: true,
  });

  const isFlowExist = useMemo(() => {
    return flows && flows.length > 0;
  }, [flows]);

  const selectItems = useMemo(() => {
    if (isFlowExist) {
      return feedbackStatus;
    }
    return feedbackStatus.filter((item) => item.value != FeedbackStatus.IN_COMPLETE);
  }, [isFlowExist, feedbackStatus]);

  return (
    <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.feedback-status')}>
      <VMultiSelect
        id="report-compare-feedback-status"
        data-testid="input-report-compare-feedback-status"
        label={t('page-reports:reportCompareGroupItemType.feedback-status')}
        data={selectItems}
        loading={isFetchingFlows}
        value={formik.values.selectedCompareCriteria.selectedFeedbackStatus}
        onChange={(value) => formik.setFieldValue('selectedCompareCriteria.selectedFeedbackStatus', value)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedFeedbackStatus')}
        searchable={true}
        withAsterisk={true}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
