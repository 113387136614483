import React from 'react';

interface CustomIconPieChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconPieChart1({ height, width, active, ...props }: CustomIconPieChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <mask
        id="mask0_11465_22091"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="16"
        height="16"
      >
        <path
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11465_22091)">
        <path
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
          fill={active ? '#0D7BFF' : '#AAAFC9'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.0061V12.0001H20.9975C20.8679 9.26677 20.0341 7.09262 18.4959 5.4776C16.9577 3.86259 14.7924 3.03876 12 3.0061Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
}
