import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { seriesOptionCreator } from '../../ECharts.utils';

export type ScatterSeriesProps = SeriesProps<RegisteredSeriesOption['scatter']>;
/**
 * @example
 * ```tsx
 * <ScatterSeries data={[
 *     [10.0, 8.04],
 *     [8.07, 6.95],
 *     [13.0, 7.58]
 * ]} />
 * ```
 */
export const ScatterSeries: EC<ScatterSeriesProps> = () => <></>;

ScatterSeries.optionOf = seriesOptionCreator('scatter');

ScatterSeries.loadModule = async () => {
  const [{ ScatterChart }, { UniversalTransition }] = await Promise.all([
    import('echarts/charts'),
    import('echarts/features'),
  ]);
  return [ScatterChart, UniversalTransition];
};
