import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  CompanyResponse,
  CreateCompanyMeetingNoteRequest,
  CreateCompanyNoteRequest,
  CreateCompanyTodoRequest,
  Note,
  NoteMeeting,
  NoteTodo,
  NoteTodoItem,
  NoteUpdateRequest,
} from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '../utils';
import { axiosBaseQuery } from './base.service';

export const BusinessDetailService = createApi({
  reducerPath: 'BusinessDetailService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCompany: builder.query<CompanyResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/companies/${id} `,
        method: HttpMethod.GET,
      }),
    }),
    updateCompany: builder.mutation<CompanyResponse, { id: string; data: CompanyResponse; showMessage?: boolean }>({
      query: ({ id, data, showMessage = true }) => ({
        url: `/v1/companies/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: showMessage,
            translationMessageKey: 'http.message.companies.updateCompany.{{status}}',
          },
        }),
      }),
    }),
    deleteCompanySegment: builder.mutation<void, { id: string; segment_id: string }>({
      query: ({ id, segment_id }) => ({
        url: `/v1/companies/${id}/segments/${segment_id}/destroy`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    updateCompanySegment: builder.mutation<void, { id: string; data: { segment_id: string } }>({
      query: ({ id, data }) => ({
        url: `/v1/companies/${id}/segments`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
    }),
    createCompanyMeetingNote: builder.mutation<NoteMeeting, CreateCompanyMeetingNoteRequest>({
      query: (data) => ({
        url: `/v1/meeting_notes`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.createMeetingNote.{{status}}',
          },
        }),
      }),
    }),
    deleteCompanyMeetingNote: builder.mutation<NoteMeeting, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/meeting_notes/${id}/destroy`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.deleteMeetingNote.{{status}}',
          },
        }),
      }),
    }),
    updateCompanyMeetingNote: builder.mutation<NoteMeeting, { id: string; data: NoteUpdateRequest; message?: string }>({
      query: ({ id, data, message = 'updateMeetingNote' }) => ({
        url: `/v1/meeting_notes/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: `http.message.companies.${message}.{{status}}`,
          },
        }),
      }),
    }),
    createCompanyNote: builder.mutation<Note, CreateCompanyNoteRequest>({
      query: (data) => ({
        url: `/v1/company_notes`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.createNote.{{status}}',
          },
        }),
      }),
    }),
    deleteCompanyNote: builder.mutation<Note, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/company_notes/${id}/destroy`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.deleteNote.{{status}}',
          },
        }),
      }),
    }),
    updateCompanyNote: builder.mutation<Note, { id: string; data: NoteUpdateRequest; message?: string }>({
      query: ({ id, data, message = 'updateNote' }) => ({
        url: `/v1/company_notes/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: `http.message.companies.${message}.{{status}}`,
          },
        }),
      }),
    }),
    createCompanyTodo: builder.mutation<NoteTodo, CreateCompanyTodoRequest>({
      query: (data) => ({
        url: `/v1/to_do_notes`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.createTodo.{{status}}',
          },
        }),
      }),
    }),
    updateCompanyTodo: builder.mutation<NoteTodoItem, NoteTodoItem>({
      query: (data) => ({
        url: `/v1/to_do_items/${data.id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.companies.updateTodo.{{status}}',
          },
        }),
      }),
    }),
  }),
});

export const {
  useUpdateCompanyMutation,
  useCreateCompanyNoteMutation,
  useCreateCompanyTodoMutation,
  useLazyGetCompanyQuery,
  useCreateCompanyMeetingNoteMutation,
  useDeleteCompanyMeetingNoteMutation,
  useDeleteCompanyNoteMutation,
  useUpdateCompanyNoteMutation,
  useUpdateCompanyMeetingNoteMutation,
  useDeleteCompanySegmentMutation,
  useUpdateCompanySegmentMutation,
  useUpdateCompanyTodoMutation,
} = BusinessDetailService;
