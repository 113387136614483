import React from 'react';

interface CustomIconWordCloudChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconWordCloudChart1({ height, width, active, ...props }: CustomIconWordCloudChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M17.25 17.9999H7.49997H5.80472C4.70985 17.9999 3.72033 17.3474 3.28904 16.3411C2.20542 13.8126 4.06011 10.9999 6.81099 10.9999H7.26272C7.40126 10.9999 7.52113 10.9035 7.55084 10.7682L7.88773 9.23344C8.88545 4.68826 15.3478 4.63504 16.4202 9.16316C16.467 9.36053 16.6432 9.49991 16.846 9.49991H17.25C19.5972 9.49991 21.5 11.4027 21.5 13.7499C21.5 16.0971 19.5972 17.9999 17.25 17.9999Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.9917 13.1418V11.9738H16V13.1418H8.9917Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.9917 15.478V14.31H14.8319V15.478H8.9917Z" fill="white" />
    </svg>
  );
}
