import {
  CreateConnectionFunc,
  SocketIO,
  SocketLikeWithNamespace,
  WebSocketConnectionStatus,
  WebSocketContextInterface,
} from '@vision/ui/interfaces';
import { __DEV__, ENV } from '@vision/ui/utils';
import React, { createContext, useCallback, useRef, useState } from 'react';
import { io } from 'socket.io-client';

export const WebSocketContext = createContext<WebSocketContextInterface<any>>({
  createConnection: () => undefined,
  getLastMessage: () => undefined,
  registerSharedListener: () => undefined,
  unregisterSharedListener: () => undefined,
  getError: () => undefined,
  getStatus: () => 'disconnected',
});

const connectionUrl = ENV.SOCKET_URL;

function createSocket() {
  return io(connectionUrl, {
    transports: ['websocket', 'polling'],
    reconnectionAttempts: __DEV__ ? 5 : Infinity,
  }) as SocketLikeWithNamespace;
}

export function WebSocketProvider({ children }: React.PropsWithChildren) {
  const connections = useRef<Record<string, number>>({});
  const sockets = useRef<Record<string, SocketIO>>({});
  const [statuses, setStatuses] = useState<Record<string, WebSocketConnectionStatus>>({
    [connectionUrl]: 'disconnected',
  });
  const [lastMessages, setLastMessages] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});

  const createConnection: CreateConnectionFunc<any> = () => {
    if (!(connectionUrl in connections.current)) {
      connections.current[connectionUrl] = 1;
    } else {
      connections.current[connectionUrl] += 1;
    }

    const cleanup = () => {
      if (--connections.current[connectionUrl] === 0) {
        const socketsToClose = Object.keys(sockets.current).filter((key) => key.includes(connectionUrl));

        for (const key of socketsToClose) {
          sockets.current[key].disconnect();
          delete sockets.current[key];
        }
      }
    };

    // By default, socket.io-client creates a new connection for the same namespace
    // The next line prevents that
    if (sockets.current[connectionUrl]) {
      sockets.current[connectionUrl].connect();
      return { socket: sockets.current[connectionUrl], cleanup };
    }
    const handleConnect = () => setStatuses((state) => ({ ...state, [connectionUrl]: 'connected' }));

    const handleDisconnect = () => setStatuses((state) => ({ ...state, [connectionUrl]: 'disconnected' }));

    const socket = createSocket();

    socket.namespaceKey = connectionUrl;

    sockets.current = Object.assign({}, sockets.current, {
      [connectionUrl]: socket,
    });
    socket.on('error', (error: any) => setError(error));
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    return { socket, cleanup };
  };

  const setLastMessage = (forEvent: string, message: any) => {
    setLastMessages((state) => ({
      ...state,
      [`${connectionUrl}${forEvent}`]: message,
    }));
  };

  const setError = (error: any) => {
    setErrors((state) => ({
      ...state,
      [connectionUrl]: error,
    }));
  };

  const registerSharedListener = (forEvent = '') => {
    if (sockets.current[connectionUrl] && !sockets.current[connectionUrl].hasListeners(forEvent)) {
      sockets.current[connectionUrl].on(forEvent, (message: any) => setLastMessage(forEvent, message));
    }
  };

  const unregisterSharedListener = (forEvent = '') => {
    if (sockets.current[connectionUrl] && sockets.current[connectionUrl].hasListeners(forEvent)) {
      sockets.current[connectionUrl].removeListener(forEvent);
    }
  };

  const getLastMessage = useCallback((forEvent = '') => lastMessages[`${connectionUrl}${forEvent}`], [lastMessages]);

  const getStatus = useCallback(() => statuses[connectionUrl], [statuses]);

  const getError = useCallback(() => errors[connectionUrl], [errors]);

  return (
    <WebSocketContext.Provider
      value={{
        createConnection,
        registerSharedListener,
        unregisterSharedListener,
        getError,
        getLastMessage,
        getStatus,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}
