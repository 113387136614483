import { DatesRangeValue } from '@mantine/dates';
import { PISANO_THEME } from '@vision/theme';
import {
  ReportChartGroupCompareItemType,
  ReportChartThemeFont,
  ReportChartThemeSerieItem,
  ReportChartThemeSeries,
} from '@vision/ui/interfaces';
import { insertIfObject } from '@vision/ui/utils';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { ObjectShape, TestContext, array, object, string } from 'yup';

export const DefaultReportChartThemeFont: ReportChartThemeFont = {
  fontBold: true,
  fontColor: PISANO_THEME.colors.dark[5],
  fontFamily: 'Poppins',
  fontSize: 12,
};

export const GRAPHIC_TITLE_MAX_CHAR_COUNT = 150;

export const GRAPHIC_DEFAULT_DATE_RANGE: DatesRangeValue = [
  dayjs(new Date()).subtract(30, 'day').startOf('day').toDate(),
  dayjs(new Date()).endOf('day').toDate(),
];

export const DEFAULT_REPORT_CHART_THEME = 'THEME_2';

// PISANO_COLORS.theme_1[9], dan gelmeli
export const GRAPHIC_SERIE_DEFAULT_THEME_COLORS = {
  THEME_1: ['#3D95FF', '#FF8989', '#38D0A9', '#FFDC82', '#FF8FCE', '#33C5D0', '#DDBBFF', '#FFB156', '#DBDDE8'],
  THEME_2: ['#38D0A9', '#0D7BFF', '#FFD363', '#FF6C6C', '#00B7C4', '#FF9D2C', '#77B4FF', '#FF73C2', '#E4E4EB'],
  THEME_3: ['#494969', '#499BFF', '#06C494', '#BCBCCD', '#0057C1', '#C6DFFF'],
};

export type GraphicSerieDefaultThemeKeys = keyof typeof GRAPHIC_SERIE_DEFAULT_THEME_COLORS;

export function getDefaultThemeColor(theme: GraphicSerieDefaultThemeKeys): string[] {
  return GRAPHIC_SERIE_DEFAULT_THEME_COLORS[theme || DEFAULT_REPORT_CHART_THEME] || [];
}

export function getDefaultThemeColorItems(theme: GraphicSerieDefaultThemeKeys): ReportChartThemeSerieItem[] {
  return getDefaultThemeColor(theme).map((color) => {
    return { color, hidden: false } as ReportChartThemeSerieItem;
  });
}

export const DefaultReportChartThemeSeries: ReportChartThemeSeries = {
  customize: false,
  items: getDefaultThemeColorItems(DEFAULT_REPORT_CHART_THEME),
  defaultColorTheme: DEFAULT_REPORT_CHART_THEME,
};

// Genel doğrulama fonksiyonu
export function createCompareConditionalValidation(
  field: string,
  compareField: string,
  compareValue: ReportChartGroupCompareItemType,
  errorMessage: string,
  min: number = 1, // Varsayılan min değeri 1
  max?: number, // Max değeri opsiyonel
) {
  return array()
    .of(string())
    .test(field, errorMessage, function (value: any, context: TestContext) {
      const compareFieldValue = context.from[1]?.value[compareField];
      if (compareFieldValue && compareFieldValue.includes(compareValue)) {
        const isValidMin = value && value.length >= min;
        const isValidMax = max !== undefined ? value.length <= max : true;

        return isValidMin && isValidMax;
      }
      return true;
    });
}

export const DefaultReportChartCompareSchema = {
  selectedUnitIds: array().of(string()),
  selectedUnitTypeIds: array().of(string()),
  selectedUserAssignedIds: array().of(string()),
  selectedUserArchivingIds: array().of(string()),
  selectedUserResponsibleIds: array().of(string()),
  selectedFlowIds: array().of(string()),
  selectedCompanyIds: array().of(string()),
  selectedSchemaCodes: createCompareConditionalValidation(
    'selectedSchemaCodes',
    'selectedCompare',
    'customer-schema',
    'validation:rules.generic.required',
  ),
  selectedQuestionFlowIds: createCompareConditionalValidation(
    'selectedQuestionFlowIds',
    'selectedCompare',
    'question-title',
    'validation:rules.generic.required',
  ),
  selectedQuestionIds: createCompareConditionalValidation(
    'selectedQuestionIds',
    'selectedCompare',
    'question-title',
    'validation:rules.generic.required',
  ),
  selectedQuestionOptionFlowIds: createCompareConditionalValidation(
    'selectedQuestionOptionFlowIds',
    'selectedCompare',
    'question-option',
    'validation:rules.generic.required',
  ),
  selectedQuestionOptionQuestionIds: createCompareConditionalValidation(
    'selectedQuestionOptionQuestionIds',
    'selectedCompare',
    'question-option',
    'validation:rules.generic.required',
  ),
  selectedQuestionOptionIds: createCompareConditionalValidation(
    'selectedQuestionOptionIds',
    'selectedCompare',
    'question-option',
    'validation:rules.generic.required',
  ),
  selectedQuestionResponseFlowIds: createCompareConditionalValidation(
    'selectedQuestionResponseFlowIds',
    'selectedCompare',
    'question-response',
    'validation:rules.generic.required',
  ),
  selectedQuestionResponseQuestionIds: createCompareConditionalValidation(
    'selectedQuestionResponseQuestionIds',
    'selectedCompare',
    'question-response',
    'validation:rules.generic.required',
  ),
  selectedQuestionKeyFlowIds: createCompareConditionalValidation(
    'selectedQuestionKeyFlowIds',
    'selectedCompare',
    'question-key',
    'validation:rules.generic.required',
  ),
  selectedQuestionKeyIds: createCompareConditionalValidation(
    'selectedQuestionKeyIds',
    'selectedCompare',
    'question-key',
    'validation:rules.generic.required',
  ),
  selectedQuestionCategoryFlowIds: createCompareConditionalValidation(
    'selectedQuestionCategoryFlowIds',
    'selectedCompare',
    'question-category-name',
    'validation:rules.generic.required',
    1,
    1,
  ),
  selectedQuestionCategoryIds: createCompareConditionalValidation(
    'selectedQuestionCategoryIds',
    'selectedCompare',
    'question-category-name',
    'validation:rules.generic.required',
  ),
  selectedContentCategoryIds: createCompareConditionalValidation(
    'selectedContentCategoryIds',
    'selectedCompare',
    'category-content-name',
    'validation:rules.generic.required',
  ),
  selectedFeedbackStatus: createCompareConditionalValidation(
    'selectedFeedbackStatus',
    'selectedCompare',
    'feedback-status',
    'validation:rules.reports.selectedCompare.selectedFeedbackStatus.required',
  ),
};

export function extendDefaultChartValidationSchema(params?: ObjectShape, withCompareCriteria = true) {
  return object().shape({
    selectedDateRange: array().of(string()).min(2, 'validation:rules.generic.required'),
    selectedCompare: array()
      .of(string().required('validation:rules.generic.required'))
      .min(1, 'validation:rules.generic.required'),
    selectedMeasure: string().required('validation:rules.generic.required'),
    selectedTheme: object().shape({
      title: object().shape({
        label: string()
          .max(
            GRAPHIC_TITLE_MAX_CHAR_COUNT,
            t('validation:rules.generic.max', {
              count: GRAPHIC_TITLE_MAX_CHAR_COUNT,
            }),
          )
          .required('validation:rules.reports.selectedTheme.title.label.required'),
      }),
    }),
    ...insertIfObject(withCompareCriteria, {
      selectedCompareCriteria: object().shape({
        ...DefaultReportChartCompareSchema,
      }),
    }),
    ...params,
  });
}
