import { Box, Button, Divider, Group, Loader, Pagination, Text } from '@mantine/core';
import { useDebouncedValue, useDidUpdate, useMediaQuery } from '@mantine/hooks';
import { If } from '@vision/ui/components';
import { Breakpoints, DATA_GRID_DEFAULT_PAGE_SIZES } from '@vision/ui/constants';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './VPagination.module.scss';

interface VPaginationProps {
  className?: string;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageCount: number;
  pageSize: number;
  totalRecordCount: number;
}

export function VPagination({
  className,
  currentPage,
  onPageChange,
  onPageSizeChange,
  pageCount,
  pageSize,
  totalRecordCount,
}: VPaginationProps) {
  const { t } = useTranslation(['data-grid']);
  const [page, setPage] = useState(currentPage);
  const [size, setSize] = useState(pageSize);
  const [debouncedSize] = useDebouncedValue(size, 150);
  const [debouncedPage] = useDebouncedValue(page, 150);
  const [pageSizeCalculating, setPageSizeCalculating] = useState(false);
  const matches = useMediaQuery(Breakpoints.DOWN.XS);

  const { firstItem, lastItem } = useMemo(
    () => ({
      firstItem: size * (page - 1) + 1,
      lastItem: Math.min(size * page, totalRecordCount),
    }),
    [page, size, totalRecordCount],
  );

  const siblings = useMemo(() => (matches ? 0 : 1), [matches]);

  useDidUpdate(() => {
    onPageChange?.(debouncedPage);
  }, [debouncedPage]);

  useDidUpdate(() => {
    onPageSizeChange?.(debouncedSize);
  }, [debouncedSize]);

  useDidUpdate(() => {
    if (page > 1) {
      setPageSizeCalculating(true);
    }
  }, [size]);

  useDidUpdate(() => {
    setPage(currentPage);
  }, [currentPage]);

  useDidUpdate(() => {
    setSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    setPageSizeCalculating(false);
  }, [pageCount, currentPage]);

  return (
    <div className={clsx(classes.vPagination, className)} data-testid="section-pagination-container">
      <Group className={classes.vPaginationContainer}>
        <Text component="span" c="dark" mr={5}>
          {t('data-grid:footer.listPerPage')}
        </Text>
        <Box>
          {DATA_GRID_DEFAULT_PAGE_SIZES.map((item) => (
            <Button
              data-testid={`button-pagination-${item.value}`}
              key={`size-${item.value}`}
              variant="subtle"
              className={clsx(classes.vPaginationButton, {
                [classes.vPaginationButtonActive]: size === item.value,
              })}
              onClick={() => setSize(item.value)}
            >
              {item.label}
            </Button>
          ))}
        </Box>
        <Divider orientation="vertical" visibleFrom="xs" />
        <If value={pageSizeCalculating}>
          <Loader size="xs" variant="dots" />
        </If>
        <If value={!pageSizeCalculating}>
          <Text component="span" fw={500} c="dark" data-testid="text-pagination-items">
            {t('data-grid:footer.listItems', {
              first: firstItem,
              last: lastItem,
              total: totalRecordCount,
            })}
          </Text>
        </If>
      </Group>

      <Pagination
        data-testid="section-pagination"
        className={classes.vPaginationMantine}
        total={pageCount}
        value={page}
        onChange={setPage}
        withControls={!matches}
        siblings={siblings}
      />
    </div>
  );
}
