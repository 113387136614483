import { ReportChartGroupItemMeasure } from '@vision/ui/interfaces';

export const RadarChart1Measures: ReportChartGroupItemMeasure[] = [
  {
    type: 'feedback',
    items: ['feedback-nps', 'feedback-csat'],
  },
  {
    type: 'kpi',
    items: ['nps-kpi', 'csat-kpi'],
  },
];
