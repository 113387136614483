import { ReportChartGroupItemType, ReportChartMeasureType } from '@vision/ui/interfaces';
import { useMemo } from 'react';
import { useReportChartCompareConfig } from './useReportChartCompareConfig';

export function useReportCombinationMulti(
  chartGroupItemType: ReportChartGroupItemType,
  measureTypes: ReportChartMeasureType[],
) {
  const config = useReportChartCompareConfig();

  return useMemo(() => {
    const items = [];

    if (!measureTypes) {
      return [];
    }

    for (const measureType of measureTypes) {
      for (const item of config) {
        if (!item.charts.includes(chartGroupItemType)) {
          continue;
        }
        if (!item.measures.includes(measureType)) {
          continue;
        }

        if (item.charts.includes(chartGroupItemType) && item.measures.includes(measureType)) {
          items.push(item);
        }
      }
    }

    return items;
  }, [chartGroupItemType, measureTypes, config]);
}
