import { ComboboxParsedItemGroup } from '@mantine/core';
import { VMultiSelect, VSelect } from '@vision/ui/components';
import { ReportChartGroupCompareItemType } from '@vision/ui/interfaces';
import { useTranslation } from 'react-i18next';

interface ReportsDetailGraphicConfigurationValueCompareSelectProps {
  compareSelectItems: ComboboxParsedItemGroup[];
  onChange: (values: ReportChartGroupCompareItemType[]) => void;
  value: ReportChartGroupCompareItemType[];
  disabled: boolean;
  error: React.ReactNode;
  multiple: boolean;
}

export function ReportsDetailGraphicConfigurationValueCompareSelect({
  compareSelectItems,
  onChange,
  value,
  disabled,
  error,
  multiple: isMultiple,
}: ReportsDetailGraphicConfigurationValueCompareSelectProps) {
  const { t } = useTranslation(['page-reports', 'translation']);

  return (
    <>
      {isMultiple ? (
        <VMultiSelect
          id="report-compare-multiple"
          data-testid="input-report-compare"
          data={compareSelectItems}
          label={t('compare')}
          withAsterisk={true}
          value={value}
          placeholder={value.length < 2 ? t('translation:search') : null}
          onChange={onChange}
          error={error}
          disabled={disabled}
          maxValues={2}
          showAllSelectedPill={false}
          withSelectAll={false}
          withCloseDropdownMaxValue={true}
          searchable={value.length < 2}
        />
      ) : (
        <VSelect
          id="report-compare-single"
          data-testid="input-report-compare-single"
          data={compareSelectItems}
          label={t('compare')}
          withAsterisk={true}
          clearable={true}
          searchable={true}
          value={value[0] || null} //Todo: başka bir çözüm yapılabilir. eğer selectedCompare[0] yoksa null dönsün diye çünkü render olmuyor
          onChange={(e) => onChange([e as ReportChartGroupCompareItemType])}
          error={error}
          disabled={disabled}
        />
      )}
    </>
  );
}
