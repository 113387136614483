import qs from 'qs';
import { insertIfObject } from '../object.utils';

export function buildNotificationsApiQuery(page: number, isUnread = false) {
  return qs.stringify({
    page,
    per_page: 10,
    sort_order: 'desc',
    sort_by: 'created_at',
    ...insertIfObject(isUnread, {
      is_read: false,
    }),
  });
}
