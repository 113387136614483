import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { PieChart1CompareRenderer } from '../../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../../default-chart.config';
import { ExPieChart1Measures } from './ex-pie-chart1.measures';

export const ExPieChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: ExPieChart1Measures,
  compareRendererComponent: PieChart1CompareRenderer,
  compareHeader: {
    labelKey: 'page-reports:compare',
    showIcon: false,
  },
  measureHeader: {
    labelKey: 'page-reports:measurement',
    showIcon: false,
  },
  customize: {
    active: (values) => !!values.selectedMeasure && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
