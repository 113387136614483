import React from 'react';

interface CustomIconContactsProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconContacts({ height, width, ...props }: CustomIconContactsProps) {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M7.979 7.102a3.207 3.207 0 1 0 0-6.415 3.207 3.207 0 0 0 0 6.415zm0 .421A6.779 6.779 0 0 0 1.2 14.302v1.01h13.598v-.969a6.82 6.82 0 0 0-6.82-6.82z"
        stroke="currentColor"
        strokeWidth="1.375"
      />
    </svg>
  );
}
