import {
  faArrowsLeftRight,
  faArrowsUpDown,
  faImage,
  faRotateLeft,
  faRotateRight,
  faTrashUndo,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Group, Slider, Stack } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useDidUpdate, useToggle } from '@mantine/hooks';
import { useImageCropperAspectRatios } from '@vision/ui/hooks';
import { ImageAspectRatioType } from '@vision/ui/interfaces';
import { readFileContent } from '@vision/ui/utils';
import 'cropperjs/dist/cropper.css';
import { MutableRefObject, forwardRef, useEffect, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { useEnsuredForwardedRef } from 'react-use';

const DEFAULT_ASPECT_RATIO: ImageAspectRatioType = '1:1';
const DEFAULT_INITIAL_ASPECT_RATIO = 1;

interface ImageCropperProps {
  image: FileWithPath | string;
}

export const ImageCropper = forwardRef<ReactCropperElement, ImageCropperProps>(({ image }: ImageCropperProps, ref) => {
  const cropperRef = useEnsuredForwardedRef(ref as MutableRefObject<ReactCropperElement>);
  const [imageSrc, setImageSrc] = useState<string>(null);
  const [minSliderZoom, setMinSliderZoom] = useState(0);
  const [zoom, setZoom] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [scaleX, toggleScaleX] = useToggle([1, -1]);
  const [scaleY, toggleScaleY] = useToggle([1, -1]);
  const [aspectRatio, setAspectRatio] = useState<ImageAspectRatioType>(DEFAULT_ASPECT_RATIO);
  const imageCropperAspectRatios = useImageCropperAspectRatios();

  const handleChangeAspectRatio = (ratio: ImageAspectRatioType) => {
    setAspectRatio(ratio);
    const aspectRatio = imageCropperAspectRatios.find((item) => item.key === ratio);
    cropperRef.current?.cropper.setAspectRatio(aspectRatio.value);
  };

  const handleChangeZoom = (value: number) => {
    setZoom(value);
    cropperRef.current?.cropper.zoomTo(value);
  };

  const handleChangeRotation = (value: number) => {
    setRotation(value);
    cropperRef.current?.cropper.rotateTo(value);
  };

  const handleMirrorImageHorizontally = () => {
    cropperRef.current?.cropper.scaleX(scaleX);
  };

  const handleMirrorImageVertically = () => {
    cropperRef.current?.cropper.scaleY(scaleY);
  };

  const handleReset = () => {
    handleChangeAspectRatio(DEFAULT_ASPECT_RATIO);
    handleChangeZoom(minSliderZoom);
    handleChangeRotation(0);
    if (scaleX === -1) {
      toggleScaleX();
    }
    if (scaleY === -1) {
      toggleScaleY();
    }
    cropperRef.current?.cropper.reset();
  };

  const handleCalculateMinSliderZoom = () => {
    const imageData = cropperRef.current?.cropper.getImageData();
    const minSliderZoom = imageData.width / imageData.naturalWidth;
    const parsed = parseFloat(minSliderZoom.toFixed(4));
    setMinSliderZoom(parsed);
  };

  const handleOnReady = () => {
    handleCalculateMinSliderZoom();
  };

  const handleImageProp = async (imageProp: FileWithPath | string) => {
    const isFileInstance = imageProp instanceof File;

    if (isFileInstance) {
      const fileContent = await readFileContent(imageProp).asDataURL();
      setImageSrc(fileContent);
    } else {
      setImageSrc(imageProp as string);
    }
  };

  useDidUpdate(() => {
    handleMirrorImageHorizontally();
  }, [scaleX]);

  useDidUpdate(() => {
    handleMirrorImageVertically();
  }, [scaleY]);

  useEffect(() => {
    handleImageProp(image);
  }, [image]);

  return (
    <Stack data-testid="image-cropper" gap={20}>
      <Cropper
        ref={cropperRef}
        initialAspectRatio={DEFAULT_INITIAL_ASPECT_RATIO}
        ready={handleOnReady}
        src={imageSrc}
        style={{ width: '100%', height: 530 }}
      />

      <Group wrap="nowrap" gap={15}>
        <Button.Group>
          {imageCropperAspectRatios.map((ratio) => (
            <Button
              data-testid="button-ratio"
              key={ratio.key}
              size="compact-md"
              variant={aspectRatio === ratio.key ? 'filled' : 'default'}
              onClick={() => handleChangeAspectRatio(ratio.key)}
            >
              {ratio.label}
            </Button>
          ))}
        </Button.Group>

        <Group w="100%" wrap="nowrap" gap={15}>
          <FontAwesomeIcon icon={faImage} size="sm" />

          <Slider
            min={minSliderZoom}
            max={1}
            step={0.0001}
            w="100%"
            showLabelOnHover={false}
            label={null}
            value={zoom}
            onChange={handleChangeZoom}
          />

          <FontAwesomeIcon icon={faImage} size="xl" />
        </Group>

        <Group w="100%" wrap="nowrap" gap={15}>
          <FontAwesomeIcon icon={faRotateLeft} size="lg" />

          <Slider
            min={0}
            max={360}
            step={0.1}
            w="100%"
            showLabelOnHover={false}
            label={null}
            value={rotation}
            onChange={handleChangeRotation}
          />

          <FontAwesomeIcon icon={faRotateRight} size="lg" />
        </Group>

        <Button.Group>
          <Button data-testid="button-x" size="compact-md" variant="default" onClick={() => toggleScaleX()}>
            <FontAwesomeIcon icon={faArrowsLeftRight} />
          </Button>
          <Button data-testid="button-y" size="compact-md" variant="default" onClick={() => toggleScaleY()}>
            <FontAwesomeIcon icon={faArrowsUpDown} />
          </Button>
        </Button.Group>

        <Button data-testid="button-reset" size="compact-md" variant="default" onClick={handleReset}>
          <FontAwesomeIcon icon={faTrashUndo} />
        </Button>
      </Group>
    </Stack>
  );
});
