import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { If } from '../If';

export interface EmptyContentViewProps {
  buttonLabel?: string;
  buttonVisible?: boolean;
  className?: string;
  dataTestId?: string;
  description?: string;
  icon: React.ReactNode;
  onClick?: VoidFunction;
  title: string;
}

export function EmptyContentView({
  buttonLabel,
  buttonVisible = true,
  className,
  dataTestId = 'section-empty-content-view',
  description,
  icon,
  onClick,
  title,
}: EmptyContentViewProps) {
  const { t } = useTranslation();

  return (
    <Flex align="center" justify="center" direction="column" className={className} data-testid={dataTestId}>
      <Stack align="center">
        {icon}

        <If value={!!title}>
          <Title order={3} fw={700} fz={16} lh="24px" mt={20} mb={10}>
            {title}
          </Title>
        </If>
        <If value={!!description}>
          <Text fz={14} mb={10}>
            {description}
          </Text>
        </If>
        <If value={buttonVisible}>
          <Button
            data-testid="button-empty-content-add"
            leftSection={<FontAwesomeIcon icon={faPlus} />}
            size="sm"
            onClick={onClick}
            miw={95}
          >
            {buttonLabel || t('add')}
          </Button>
        </If>
      </Stack>
    </Flex>
  );
}
