import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { seriesOptionCreator } from '../../ECharts.utils';

export type LineSeriesProps = SeriesProps<RegisteredSeriesOption['line']>;
/**
 * @example
 * ```tsx
 * <LineSeries data={[5, 20, 36, 10, 10, 20]} />
 * ```
 */
export const LineSeries: EC<LineSeriesProps> = () => <></>;

LineSeries.optionOf = seriesOptionCreator('line', {
  smooth: true,
  labelLayout: { hideOverlap: true },
} as LineSeriesProps);

LineSeries.loadModule = async () => {
  const [{ LineChart }, { UniversalTransition }, { MarkAreaComponent, MarkLineComponent }] = await Promise.all([
    import('echarts/charts'),
    import('echarts/features'),
    import('echarts/components'),
  ]);
  return [LineChart, UniversalTransition, MarkAreaComponent, MarkLineComponent];
};
