import { createApi } from '@reduxjs/toolkit/query/react';
import { CatiCallStatus, HttpMethod } from '@vision/ui/enums';
import { ApiResponse, CatiCall } from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const CatiCallService = createApi({
  reducerPath: 'CatiCallService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    catiCalls: builder.query<ApiResponse<CatiCall[]>, { query: string; accountId: string }>({
      query: ({ query, accountId }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${accountId}/cati_calls`, query),
        method: HttpMethod.GET,
      }),
    }),
    updateCatiCallStatus: builder.mutation<void, { id: string; status: CatiCallStatus }>({
      query: ({ id, status }) => ({
        url: `/v2/cati_calls/${id}`,
        method: HttpMethod.POST,
        data: {
          status,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.catiCall.updateCatiCallStatus.{{status}}',
          },
        }),
      }),
    }),
    deleteCatiCalls: builder.mutation<void, { accountId: string; ids: string[]; except: boolean; query?: string }>({
      query: ({ accountId, ids, except, query }) => ({
        url: `/v2/${except ? `accounts/${accountId}/cati_calls/delete_all_except_ids` : 'cati_calls/delete_multiple'}`,
        method: HttpMethod.POST,
        data: {
          // if except is true, the parameters will be query: query, except_ids: ids, otherwise cati_call_ids: ids
          ...(except ? { query, except_ids: ids } : { cati_call_ids: ids }),
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.catiCall.deleteCatiCalls.{{status}}',
          },
        }),
      }),
    }),
  }),
});

export const { useLazyCatiCallsQuery, useUpdateCatiCallStatusMutation, useDeleteCatiCallsMutation } = CatiCallService;
