import React from 'react';

interface CustomIconTrendChart3Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconTrendChart3({ height, width, active, ...props }: CustomIconTrendChart3Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20L4 5H7L7 20H4Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17L19 17V20L7 20L7 17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        d="M6 13.5C8.5 15 11.4 15.5 13 13.5C15 11 14 6.5 19 6.5"
        stroke={active ? '#0D7BFF' : '#AAAFC9'}
        strokeWidth="2"
      />
    </svg>
  );
}
