import { ReportChartGroupItemType, ReportChartMeasureType } from '@vision/ui/interfaces';
import { useMemo } from 'react';
import { useReportChartCompareConfig } from './useReportChartCompareConfig';

export function useReportCombination(
  chartGroupItemType: ReportChartGroupItemType,
  measureType: ReportChartMeasureType,
) {
  const config = useReportChartCompareConfig();

  return useMemo(() => {
    for (const item of config) {
      if (!item.charts.includes(chartGroupItemType)) {
        continue;
      }
      if (!item.measures.includes(measureType)) {
        continue;
      }

      if (item.charts.includes(chartGroupItemType) && item.measures.includes(measureType)) {
        return item;
      }
    }

    return null;
  }, [chartGroupItemType, measureType, config]);
}
