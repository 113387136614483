import { AccountType } from '@vision/ui/enums';
import { Account } from '@vision/ui/interfaces';
import { useMemo } from 'react';
import { useAccount } from './useAccount';

export function checkAccountType(account: Account, accountType: AccountType | AccountType[]) {
  if (Array.isArray(accountType)) {
    return accountType.includes(account.type);
  }
  return accountType === account.type;
}

export function useCheckAccountType(accountType: AccountType | AccountType[]): boolean {
  const account = useAccount();

  return useMemo(() => {
    if (!accountType) {
      return true;
    }
    return checkAccountType(account, accountType);
  }, [account, accountType]);
}
