import { Group, Text } from '@mantine/core';
import { DateFormatter, If } from '@vision/ui/components';
import { FilterType } from '@vision/ui/enums';
import { Filter, FilterSelections } from '@vision/ui/interfaces';
import { ensureArray } from '@vision/ui/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface DatepickerTooltipLabelProps {
  from: number;
  to: number;
}

function _DatepickerTooltipLabel({ from, to }: DatepickerTooltipLabelProps) {
  const { t } = useTranslation();
  const startDate = new Date(from);
  const endDate = new Date(to);

  return (
    <Group gap={10}>
      <Text data-testid="text-data-grid-filter-selection-date-start">
        {t('startDate')}: <DateFormatter value={startDate} />
      </Text>
      <If value={!!to}>
        <Text data-testid="text-data-grid-filter-selection-date-end">
          {t('endDate')}: <DateFormatter value={endDate} />
        </Text>
      </If>
    </Group>
  );
}

const DatepickerTooltipLabel = React.memo(_DatepickerTooltipLabel);

interface DataGridFilterSelectionTooltipLabelProps {
  filter: Filter;
  filterSelections: FilterSelections[];
}

function _DataGridFilterSelectionTooltipLabel({ filter, filterSelections }: DataGridFilterSelectionTooltipLabelProps) {
  const selectedOptions = useMemo(() => {
    const found = filterSelections.find((item) => item.key === filter.key);
    if (!found) {
      return null;
    }

    if (filter.type === FilterType.CHECKBOX) {
      const selectedValues = ensureArray(found.value) as string[];
      const options = filter.options.filter((item) => selectedValues.includes(item.value));
      return ensureArray(options)
        .map((item) => item.label)
        .join(', ');
    }

    if (filter.type === FilterType.RADIO || filter.type === FilterType.TEXT) {
      const option = filter.options.find((item) => item.value === found.value);
      return option?.label || (found.value as string);
    }

    if (filter.type === FilterType.DATEPICKER) {
      const [from, to] = (found.value as string).split(',').map(Number);
      return <DatepickerTooltipLabel from={from} to={to} />;
    }

    if (filter.type === FilterType.RADIO_DATEPICKER) {
      const isRadioSelection = filter.options.some((option) => option.value === found.value); // direkt bir value eşleşmesi var ise RADIO dur
      const val = found.value as string;
      if (isRadioSelection) {
        const option = filter.options.find((item) => item.value === found.value);
        return option?.label || val;
      }
      const [from, to] = val.split(',').map(Number);
      return <DatepickerTooltipLabel from={from} to={to} />;
    }
  }, [filter, filterSelections]);

  return <>{selectedOptions}</>;
}

export const DataGridFilterSelectionTooltipLabel = React.memo(_DataGridFilterSelectionTooltipLabel);
