import { Divider } from '@mantine/core';

interface ReportsDetailGraphicCompareLayoutProps {
  title: React.ReactNode;
}

export function ReportsDetailGraphicCompareLayout({
  children,
  title,
}: React.PropsWithChildren<ReportsDetailGraphicCompareLayoutProps>) {
  return (
    <>
      <Divider my="xs" label={title} labelPosition="left" />
      {children}
    </>
  );
}
