import React from 'react';

interface CustomIconBarChart4Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconBarChart4({ height, width, active, ...props }: CustomIconBarChart4Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path d="M4 14V20H7V14H4Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M4 9V13H7V9H4Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M8 12V20H12V12H8Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M8 4V11H12V4H8Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M17 14V20H20V14H17Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M17 8V13H20V8H17Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
      <path d="M13 10V20H16V10H13Z" fill={active ? '#0D7BFF' : '#AAAFC9'} />
      <path d="M13 6V9H16V6H13Z" fill={active ? '#0D7BFF' : '#E4E4EB'} fillOpacity={active ? 0.5 : 1} />
    </svg>
  );
}
