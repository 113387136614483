import { ReportChartMeasureType } from '@vision/ui/interfaces';
import { ReportsDetailGraphicMeasureMatrixQuestion } from '../../../ReportsDetailGraphicMeasureComponents';

interface BarChart5MeasureRendererProps {
  measure: ReportChartMeasureType;
}

// burası daha dinamik olacak
export function BarChart5MeasureRenderer({ measure }: BarChart5MeasureRendererProps) {
  if (measure === 'matrix-question-csat-overview') {
    return <ReportsDetailGraphicMeasureMatrixQuestion />;
  }

  return null;
}
