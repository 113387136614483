import { TooltipComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { optionCreator } from '../../ECharts.utils';

export type EChartsTooltipProps = EventHandlerProps & TooltipComponentOption;
/**
 * @example
 * ```tsx
 * <EChartsTooltip formatter={() => 'This is a tooltip'} />
 * ```
 */
export const EChartsTooltip: EC<EChartsTooltipProps> = () => <></>;

EChartsTooltip.optionOf = optionCreator('tooltip');

EChartsTooltip.loadModule = async () => {
  const [{ LabelLayout }, { TooltipComponent }] = await Promise.all([
    import('echarts/features'),
    import('echarts/components'),
  ]);
  return [LabelLayout, TooltipComponent];
};
