import { useMemo } from 'react';
import { ImageAspectRatioItem } from '@vision/ui/interfaces';
import { useTranslation } from 'react-i18next';

export function useImageCropperAspectRatios(): ImageAspectRatioItem[] {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    return [
      {
        key: 'free',
        label: t('cropImageFree'),
        value: NaN,
      },
      {
        key: '16:9',
        label: '16:9',
        value: 16 / 9,
      },
      {
        key: '4:3',
        label: '4:3',
        value: 4 / 3,
      },
      {
        key: '2:3',
        label: '2:3',
        value: 2 / 3,
      },
      {
        key: '1:1',
        label: '1:1',
        value: 1,
      },
    ];
  }, [i18n.language]);
}
