import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  CreateSegmentGroupRequest,
  SegmentGroup,
  SegmentGroupDetailRequest,
  SegmentGroupDetailResponse,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const SegmentsService = createApi({
  reducerPath: 'SegmentsService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['SegmentGroupDetail'],
  endpoints: (builder) => ({
    getSegmentGroups: builder.query<ApiResponse<SegmentGroup[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/segment_groups`, query),
        method: HttpMethod.GET,
      }),
    }),
    getSegmentGroupDetail: builder.query<
      ApiResponse<SegmentGroupDetailResponse>,
      { nodeId: string; segmentGroupId: string }
    >({
      query: ({ nodeId, segmentGroupId }) => ({
        url: `/v2/accounts/${nodeId}/segment_groups/${segmentGroupId}`,
        method: HttpMethod.GET,
      }),
      providesTags: ['SegmentGroupDetail'],
    }),
    updateSegmentGroupDetail: builder.mutation<
      ApiResponse<SegmentGroupDetailResponse>,
      { nodeId: string; segmentGroupId: string; data: SegmentGroupDetailRequest }
    >({
      query: ({ nodeId, segmentGroupId, data }) => ({
        url: `/v2/accounts/${nodeId}/segment_groups/${segmentGroupId}`,
        method: HttpMethod.POST,
        data,
      }),
      invalidatesTags: ['SegmentGroupDetail'],
    }),
    deleteSegmentGroup: builder.mutation<void, { nodeId: string; segmentGroupId: string }>({
      query: ({ nodeId, segmentGroupId }) => ({
        url: `/v2/accounts/${nodeId}/segment_groups/${segmentGroupId}/delete`,
        method: HttpMethod.POST,
      }),
    }),
    createSegmentGroup: builder.mutation<void, { nodeId: string; data: CreateSegmentGroupRequest }>({
      query: ({ data, nodeId }) => ({
        url: `/v2/accounts/${nodeId}/segment_groups`,
        method: HttpMethod.POST,
        data,
      }),
    }),
  }),
});

export const {
  useCreateSegmentGroupMutation,
  useDeleteSegmentGroupMutation,
  useGetSegmentGroupDetailQuery,
  useLazyGetSegmentGroupDetailQuery,
  useLazyGetSegmentGroupsQuery,
  useUpdateSegmentGroupDetailMutation,
} = SegmentsService;
