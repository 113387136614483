import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Group, Text } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { ColumnSizingState, RowData, SortingState } from '@tanstack/react-table';
import { DataGridEmptyViewOptions, DataGridSimple, DataGridSimpleProps } from '@vision/ui/components';
import { DATA_GRID_DEFAULT_CURRENT_PAGE, DATA_GRID_DEFAULT_PER_PAGE } from '@vision/ui/constants';
import { DataGridContextProvider } from '@vision/ui/context';
import { useDataGridFilterHeadroom, useDataGridParams, useNavigationLinkMatched } from '@vision/ui/hooks';
import { DataGridFilterChange, Filter, FilterSelections, Meta, Nullable, Pagination } from '@vision/ui/interfaces';
import {
  ensureArray,
  normalizeSelectionsWithMetaResponse,
  parseQueryObjectToSelections,
  parseSelectionsToQueryObject,
  processFilterSelections,
} from '@vision/ui/utils';
import clsx from 'clsx';
import qs from 'qs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { If } from '../If';
import classes from './DataGrid.module.scss';
import { DataGridFilterSearch, DataGridFilters } from './components';

interface DefaultSortParam {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

export interface DataGridProps<TData extends RowData> extends DataGridSimpleProps<TData> {
  extraQueryParams?: Record<string, boolean | number | string>;
  meta: Nullable<Meta>;
  onColumnSizeChange?: (value: ColumnSizingState) => void;
  onFilterChange?: (data: DataGridFilterChange) => void;
  onRecordAdd?: VoidFunction;
  recordAddButtonLabel?: string;
  recordAddButtonVisible?: boolean;
  actionSlotTooltip?: string;
  defaultSortParam?: DefaultSortParam;
}

function _DataGrid<TData extends RowData>({
  actionSlot,
  actionSlotTooltip,
  children,
  emptyViewOptions,
  extraQueryParams,
  meta: metaProp,
  onFilterChange,
  onRecordAdd,
  recordAddButtonLabel,
  recordAddButtonVisible = true,
  defaultSortParam,
  ...props
}: React.PropsWithChildren<DataGridProps<TData>>) {
  const { i18n, t } = useTranslation(['translation', 'data-grid']);
  const {
    filterSelectionsDataGridUrlParams,
    dataGridUrlParamsWithFilterSelections,
    query,
    updateDataGridUrlParams,
    params,
  } = useDataGridParams(extraQueryParams);
  const [meta, setMeta] = useState<Meta>(() => metaProp);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [internalPagination, setInternalPagination] = useState<Pagination>(meta?.pagination);
  const skipFilterChange = useRef<boolean>(false);
  const pinned = useDataGridFilterHeadroom();
  const matchedLink = useNavigationLinkMatched();

  const handleChangePageSize = useCallback(
    (value: number) => {
      updateDataGridUrlParams({
        page: DATA_GRID_DEFAULT_CURRENT_PAGE, // when page size changes, reset to page: 1
        per_page: value,
      });
    },
    [updateDataGridUrlParams],
  );

  const handleChangePage = useCallback(
    (value: number) => {
      updateDataGridUrlParams({
        page: value,
      });
    },
    [updateDataGridUrlParams],
  );

  const handleChangeColumnSize = useCallback((value: ColumnSizingState) => {
    if (props.onColumnSizeChange) {
      props.onColumnSizeChange(value);
    }
  }, []);

  const handleUpdateFilterSelections = useCallback(
    (selections: FilterSelections[]) => {
      updateDataGridUrlParams({
        extraQueryValues: parseSelectionsToQueryObject(selections),
        fc: true, // used on useDataGridParams.ts
        page: DATA_GRID_DEFAULT_CURRENT_PAGE, // when filters changes, reset to page: 1
        per_page: params.per_page ?? DATA_GRID_DEFAULT_PER_PAGE,
      });
    },
    [params],
  );

  const handleSearch = useCallback(
    (value: string) => {
      updateDataGridUrlParams({
        search_string: value,
      });
    },
    [updateDataGridUrlParams],
  );

  const filterSelectionsFromUrl = useMemo(() => {
    if (!meta) {
      return [];
    }
    return normalizeSelectionsWithMetaResponse(meta, parseQueryObjectToSelections(filterSelectionsDataGridUrlParams));
  }, [filterSelectionsDataGridUrlParams, meta]);

  const currentPage = useMemo(() => {
    if (dataGridUrlParamsWithFilterSelections.page) {
      return Number(dataGridUrlParamsWithFilterSelections.page);
    }
    return meta?.pagination?.current || DATA_GRID_DEFAULT_CURRENT_PAGE;
  }, [dataGridUrlParamsWithFilterSelections, meta]);

  const pageSize = useMemo(() => {
    if (dataGridUrlParamsWithFilterSelections.per_page) {
      return Number(dataGridUrlParamsWithFilterSelections.per_page);
    }
    return meta?.pagination?.per_page || DATA_GRID_DEFAULT_PER_PAGE;
  }, [dataGridUrlParamsWithFilterSelections, meta]);

  const freeSearchTextQuery = useMemo(() => {
    return dataGridUrlParamsWithFilterSelections.search_string || '';
  }, [dataGridUrlParamsWithFilterSelections]);

  const memoizedEmptyViewOptions: DataGridEmptyViewOptions = useMemo(() => {
    return {
      ...emptyViewOptions,
      buttonVisible: recordAddButtonVisible,
    };
  }, [emptyViewOptions, recordAddButtonVisible]);

  useDidUpdate(() => {
    setInternalPagination({
      ...meta?.pagination,
      current: currentPage,
      per_page: pageSize,
    });
  }, [meta?.pagination, currentPage, pageSize]);

  useEffectOnce(() => {
    // If defaultSortParam exists and there is no query param in the URL, we add the default sort param to the URL.
    const queryParams = new URLSearchParams(window.location.search);
    if (defaultSortParam && queryParams.size == 0) {
      updateDataGridUrlParams({
        sort_by: defaultSortParam.sortBy,
        sort_order: defaultSortParam.sortOrder,
      });
    }
  });

  useDidUpdate(() => {
    if (sorting.length) {
      const { desc, id } = sorting[0];

      updateDataGridUrlParams({
        sort_by: id,
        sort_order: desc ? 'desc' : 'asc',
      });
    } else {
      updateDataGridUrlParams({
        sort_by: null,
        sort_order: null,
      });
    }
  }, [sorting]);

  useDidUpdate(() => {
    // Api response u bekle
    if (!meta) {
      return;
    }

    const selectionsFromUrl = normalizeSelectionsWithMetaResponse(
      meta,
      parseQueryObjectToSelections(filterSelectionsDataGridUrlParams),
    );

    // URL de zaten filter query var ise veya filter lar kullanıcı tarafından değiştirildiyse olanı kullan ve override etme
    if (selectionsFromUrl.length || dataGridUrlParamsWithFilterSelections.fc) {
      return;
    }

    const selectionsFromResponse = processFilterSelections(meta);

    if (selectionsFromResponse.length) {
      /*
         1- Eğer sayfa açılışında hiç query param yok ise default olarak page ve per_page ekleniyor
         2- Parent componente onFilterChange event ı ile query string gönderiliyor
         3- Parent component page ve per_page içeren bu query string i kullanarak ilk api isteğini atıyor
         4- Gelen response da eğer default seçili filter var ise bu seçili filter ları URL e query param olarak ekliyoruz
         5- Ekledikten sonra ikinci bir istek atmamak için bu kontrol mevcut çünkü zaten ilk response da backend default filter seçimlerine göre veri dönüyor olacak
      */
      skipFilterChange.current = true;
      updateDataGridUrlParams({
        extraQueryValues: parseSelectionsToQueryObject(selectionsFromResponse),
      });
    }
  }, [meta]);

  useDidUpdate(() => {
    if (skipFilterChange.current) {
      skipFilterChange.current = false;
      return;
    }

    if (query) {
      if (dataGridUrlParamsWithFilterSelections.sort_by) {
        setSorting([
          {
            id: dataGridUrlParamsWithFilterSelections.sort_by,
            desc: dataGridUrlParamsWithFilterSelections.sort_order === 'desc',
          },
        ]);
      }
      onFilterChange?.({
        params,
        query,
        queryWithoutPaginationAndSorting: qs.stringify(params.extraQueryValues),
      });
    }

    // Dil değiştiğinde tekrar api isteği atılması gerek, api responseda bazı label lar translate ediliyor
  }, [query, i18n.language]);

  useDidUpdate(() => {
    if (!metaProp) {
      return;
    }

    // Elimizde dynamic filter'lar için options var ise gelen meta ile merge etmemiz gerek
    const mergedMeta: Meta = {
      ...metaProp,
      filters: metaProp.filters.map((item) => {
        const found = meta?.filters?.find(({ dynamic, key }) => dynamic && key === item.key);
        if (found) {
          return {
            ...item,
            options: found.options.length ? found.options : item.options,
          };
        }

        return item;
      }),
    };

    setMeta(mergedMeta);
  }, [metaProp, i18n.language]);

  useDidUpdate(() => {
    if (!metaProp) {
      return;
    }
    /*
     * Eğer meta.pagination.page değeri ile query parameter "page" değeri farklı ise
     * query parameter değerini meta.pagination.page değeri yap
     * */
    const queryParams = new URLSearchParams(window.location.search);
    const page = Number(queryParams.get('page'));
    if (metaProp.pagination.current !== page) {
      skipFilterChange.current = true;
      handleChangePage(metaProp.pagination.current);
    } else {
      skipFilterChange.current = false;
    }
  }, [metaProp]);

  const handleUpdateFilter = useCallback(
    (filter: Filter) => {
      const index = meta.filters.findIndex((item) => item.key === filter.key);
      const updatedMeta: Meta = {
        ...meta,
        filters: [...meta.filters.slice(0, index), filter, ...meta.filters.slice(index + 1)],
      };
      setMeta(updatedMeta);
    },
    [meta, setMeta],
  );

  const memoizedActionSlot = useMemo(() => {
    const tooltipLabel = t('data-grid:searchFields', {
      fields: ensureArray(meta?.generic_search_keys)
        .map((key) => t(`data-grid:searchFieldKeys.${key}`))
        .join(', '),
    });

    return (
      <>
        <Group
          justify="space-between"
          className={clsx({
            [classes.dataGridMemoizedActionSlotPinned]: pinned,
            [classes.dataGridMemoizedActionSlotPinnedWithNavigation]: pinned && matchedLink,
          })}
          wrap="nowrap"
          mb={20}
          data-testid="section-data-grid-actions"
        >
          <If value={meta?.filters?.length > 0}>
            <DataGridFilters
              filterSelectionsFromUrl={filterSelectionsFromUrl}
              onFilterSelectionChange={handleUpdateFilterSelections}
            />
          </If>

          <If value={!!meta}>
            <Group gap={5} className={classes.dataGridActionSlot}>
              <If value={meta?.searchable}>
                <DataGridFilterSearch
                  onSearch={handleSearch}
                  searchText={freeSearchTextQuery}
                  tooltipDisabled={!meta?.generic_search_keys?.length}
                  tooltipLabel={actionSlotTooltip || tooltipLabel}
                />
              </If>

              <If value={!!actionSlot}>
                <div className={classes.dataGridActionSlotItems}>{actionSlot}</div>
              </If>

              <If value={meta?.pagination?.total_count > 0 && recordAddButtonVisible}>
                <Button
                  className={classes.dataGridActionSlotBtnAdd}
                  data-testid="button-data-grid-add"
                  color="blue.4"
                  variant="filled"
                  size="sm"
                  onClick={onRecordAdd}
                >
                  <Group gap={10}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                    <Text className={classes.dataGridActionSlotBtnAddText}>{recordAddButtonLabel || t('add')}</Text>
                  </Group>
                </Button>
              </If>
            </Group>
          </If>
        </Group>
        {children}
      </>
    );
  }, [
    meta,
    filterSelectionsFromUrl,
    handleUpdateFilterSelections,
    handleSearch,
    freeSearchTextQuery,
    actionSlot,
    actionSlotTooltip,
    onRecordAdd,
    recordAddButtonLabel,
  ]);

  const memoizedDataGridContextValues = useMemo(() => {
    return {
      meta,
      updateFilter: handleUpdateFilter,
    };
  }, [meta, handleUpdateFilter]);

  return (
    <DataGridContextProvider value={memoizedDataGridContextValues}>
      <DataGridSimple
        pagination={internalPagination}
        sortingState={sorting}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onColumnSizeChange={handleChangeColumnSize}
        onSortChange={setSorting}
        actionSlot={memoizedActionSlot}
        withFilters={true}
        emptyViewOptions={memoizedEmptyViewOptions}
        {...props}
      />
    </DataGridContextProvider>
  );
}

export const DataGrid = React.memo(_DataGrid) as typeof _DataGrid;
