import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationsService } from '@vision/ui/services';
import { buildNotificationsApiQuery } from '@vision/ui/utils';

export const refetchNotificationsAction = createAsyncThunk(
  'NotificationState/refetchNotificationsAction',
  (accountId: string, { dispatch }) => {
    const notificationCountAction = NotificationsService.endpoints.getNotificationCount.initiate(
      {
        nodeId: accountId,
        unread: true,
      },
      { forceRefetch: true },
    );
    const notificationsAction = NotificationsService.endpoints.getNotifications.initiate(
      {
        nodeId: accountId,
        query: buildNotificationsApiQuery(1, false),
      },
      { forceRefetch: true },
    );
    dispatch(notificationCountAction);
    dispatch(notificationsAction);
  },
);
