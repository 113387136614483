import { NodeType } from '@vision/ui/enums';
import { Account, INode, User } from '@vision/ui/interfaces';
import { ensureArray } from '../array.utils';

export function selectDefaultAssignedNode(user: User): Account {
  if (!user || !ensureArray(user.assigned_nodes).length) {
    return {} as Account;
  }
  // Pisano root hesap olduğu için bu hesabın seçilemiyor olması gerekmekte
  const filtered = user.assigned_nodes.filter((item) => item.code !== 'Pisano');
  if (!filtered.length) {
    return {} as Account;
  }
  return filtered[0];
}

export function filterUnitTypeNodeListByNodeType(nodeList: INode[]) {
  const notAllowedNodeTypes = [
    // NodeType.ACCOUNT,
    NodeType.KIOSK,
    NodeType.LINK,
    NodeType.MAIL,
    NodeType.MOBILE,
    NodeType.PISANO,
    NodeType.SMS,
    NodeType.WEB,
  ];
  return ensureArray(nodeList).filter((item) => !notAllowedNodeTypes.includes(item.type as NodeType));
}
