import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../default-chart.config';
import { WordCloudChart1Measures } from './word-cloud-chart1.measures';

export const WordCloudChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}, false),
  measures: WordCloudChart1Measures,
  compareRendererComponent: null,
  hideCompare: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
  },
};
