import { DateFormatVariant, SupportedLanguages } from '@vision/ui/interfaces';
import { formatDate } from '@vision/ui/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface DateFormatterProps {
  children?: (date: string, time: string) => JSX.Element;
  locale?: SupportedLanguages;
  variant?: DateFormatVariant;
  value: number | string | Date;
}

export function DateFormatter({ children, locale, variant = 'full', value }: DateFormatterProps) {
  const { i18n } = useTranslation();

  const { date, time } = useMemo(
    () => formatDate(value, variant, locale || i18n.language),
    [value, variant, i18n.language],
  );

  if (!value) {
    return null;
  }

  if (children) {
    return children(date, time);
  }

  return (
    <>
      {date}, {time}
    </>
  );
}
