import loadable, { DefaultComponent, LoadableComponent } from '@loadable/component';
import { Loader } from '@mantine/core';
import React from 'react';

export function getDisplayName<T>(Component: React.ComponentType<T>) {
  return Component.displayName || Component.name || 'Component';
}

export function loadableWithFallback<Props, Module = DefaultComponent<Props>>(
  loadFn: (props: Props) => Promise<Module>,
): LoadableComponent<Props>;
export function loadableWithFallback<Props>(
  loadFn: (props: Props) => Promise<DefaultComponent<Props>>,
): LoadableComponent<Props>;
export function loadableWithFallback<Component extends React.ComponentClass<any>>(
  loadFn: (props: React.ComponentProps<Component>) => Promise<Component | { default: Component }>,
) {
  return loadable(loadFn, {
    fallback: <Loader />,
  });
}
