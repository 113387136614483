import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ApiResponseSimple,
  ReportChartGroup,
  ReportChartGroupItem,
  ReportDetail,
  ReportFeedbackResponseCollection,
  ReportWidget,
} from '@vision/ui/interfaces';
import { ReportsService } from '@vision/ui/services';

export interface ReportsDetailGraphicStateType {
  selectedChartGroup: ReportChartGroup;
  selectedChartGroupItem: ReportChartGroupItem;
  reportDetail?: ReportDetail;
  graphicDetail?: ReportWidget;
  feedback?: ApiResponseSimple<ReportFeedbackResponseCollection>; // şuanda kullanılmıyor theme deki costumize işlemler için kullanılacak
}

const initialState: ReportsDetailGraphicStateType = {
  selectedChartGroup: null,
  selectedChartGroupItem: null,
  reportDetail: null,
  graphicDetail: null,
  feedback: null,
};

export const ReportsDetailGraphicState = createSlice({
  name: 'ReportsDetailGraphicState',
  initialState,
  reducers: {
    resetReportsDetailGraphicAction: (state) => {
      Object.assign(state, initialState);
    },
    selectChartGroupItemAction: (
      state,
      {
        payload,
      }: PayloadAction<{
        group: ReportChartGroup;
        groupItem: ReportChartGroupItem;
      }>,
    ) => {
      state.selectedChartGroup = payload.group;
      state.selectedChartGroupItem = payload.groupItem;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ReportsService.endpoints.getReportDetail.matchFulfilled, (state, { payload }) => {
      state.reportDetail = payload.data;
    });
    builder.addMatcher(ReportsService.endpoints.getReportGraphicDetail.matchFulfilled, (state, { payload }) => {
      state.graphicDetail = payload.data;
    });
  },
});

export const { resetReportsDetailGraphicAction, selectChartGroupItemAction } = ReportsDetailGraphicState.actions;
