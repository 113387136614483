import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiResponse,
  ApiResponseLegacy,
  CreateTagGroupRequest,
  IntelligentTag,
  TagGroup,
  TagGroupItem,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, insertIfObject } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const TagService = createApi({
  reducerPath: 'TagService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTagGroups: builder.query<ApiResponse<TagGroup[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/tag_groups`, query),
        method: HttpMethod.GET,
      }),
    }),
    getTagList: builder.query<
      ApiResponse<TagGroupItem[]>,
      { nodeId: string; query?: string; tagGroups?: Array<string> }
    >({
      query: ({ nodeId, query, tagGroups }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/tag_groups/tags`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!tagGroups && !!tagGroups.length, {
            tag_group_ids: tagGroups.join(','),
          }),
        },
      }),
    }),
    createTagGroup: builder.mutation<ApiResponse<TagGroup>, { nodeId: string; data: CreateTagGroupRequest }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    updateTagGroup: builder.mutation<ApiResponse<TagGroup>, { nodeId: string; data: TagGroup }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups/${data.id}`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    deleteTagGroup: builder.mutation<void, { nodeId: string; tagGroupId: string }>({
      query: ({ nodeId, tagGroupId }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups/${tagGroupId}/delete`,
        method: HttpMethod.POST,
      }),
    }),
    getIntelligentTags: builder.query<ApiResponseLegacy<IntelligentTag[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId }) => ({
        url: '/v1/intelligent_tags',
        method: HttpMethod.GET,
        params: {
          account_id: nodeId,
        },
      }),
    }),
  }),
});

export const {
  useGetIntelligentTagsQuery,
  useLazyGetIntelligentTagsQuery,
  useCreateTagGroupMutation,
  useDeleteTagGroupMutation,
  useLazyGetTagGroupsQuery,
  useLazyGetTagListQuery,
  useUpdateTagGroupMutation,
} = TagService;
