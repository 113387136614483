import { ReportChartMeasureType } from '@vision/ui/interfaces';
import {
  ReportsDetailGraphicMeasureMatrixQuestion,
  ReportsDetailGraphicMeasurePeriodAndValueComparison,
} from '../../../ReportsDetailGraphicMeasureComponents';

interface TableChart2MeasureRendererProps {
  measure: ReportChartMeasureType;
}

export function TableChart2MeasureRenderer({ measure }: TableChart2MeasureRendererProps) {
  const isMatrixQuestion = (measure: string) =>
    ['matrix-question-csat-overview', 'matrix-question-response-count'].includes(measure);

  if (isMatrixQuestion(measure)) {
    return <ReportsDetailGraphicMeasureMatrixQuestion multiple={false} />;
  }

  if (measure === 'overview-compared') {
    return <ReportsDetailGraphicMeasurePeriodAndValueComparison />;
  }

  return null;
}
