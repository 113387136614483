export enum NodeType {
  ACCOUNT = 'Account',
  KIOSK = 'Kiosk',
  LINK = 'Link',
  MAIL = 'Mail',
  MOBILE = 'Mobile',
  PISANO = 'Pisano',
  SMS = 'Sms',
  SUB_TARGET = 'Subtarget',
  TARGET = 'Target',
  TARGET_GROUP = 'TargetGroup',
  WEB = 'Web',
}
