import { ENV } from '@vision/ui/utils';
import classes from './FeedbackWebIframe.module.scss';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export interface FeedbackWebIframeHandler {
  resetIframe: VoidFunction;
}

interface FeedbackWebIframeProps {
  sourceQueryString: string;
}

export const FeedbackWebIframe = forwardRef<FeedbackWebIframeHandler, FeedbackWebIframeProps>(
  ({ sourceQueryString }: FeedbackWebIframeProps, ref) => {
    const [sourceUrl, setSourceUrl] = useState<string>('');
    const [iframeKey, setIframeKey] = useState<number>(0);

    const handleResetIframe = () => {
      setIframeKey(iframeKey + 1);
    };

    useImperativeHandle(
      ref,
      () => ({
        resetIframe: handleResetIframe,
      }),
      [handleResetIframe],
    );

    useEffect(() => {
      setSourceUrl(`${ENV.FEEDBACK_WEB_URL}/feedback?${sourceQueryString}`);
    }, [sourceQueryString]);

    return <iframe className={classes.feedbackWebIframe} key={iframeKey} src={sourceUrl} />;
  },
);
