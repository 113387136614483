import {
  faCameraSecurity,
  faChartNetwork,
  faChartSimple,
  faCommentLines,
  faDiagramProject,
  faGear,
  faKey,
  faMessageDots,
  faSitemap,
  faSliders,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { getRoute } from '@vision/ui/constants';
import { AccountType, Role } from '@vision/ui/enums';
import {
  CustomIconAdmin,
  CustomIconCati,
  CustomIconContacts,
  CustomIconDashboard,
  CustomIconFlow,
  CustomIconHeadSideHeadPhones,
} from '@vision/ui/icons';
import { Account, NavigationLink, Permission, User } from '@vision/ui/interfaces';
import { checkPermission, ensureArray, insertIfArray } from '@vision/ui/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomIconCatiList } from '../icons/CustomIconCatiList';
import { changeSecuritySettingsModalVisibleAction } from '../store';
import { useAccount } from './useAccount';
import { useAppDispatch } from './useAppDispatch';
import { checkAccountType } from './useCheckAccountType';
import { checkRole } from './useCheckRole';
import { useExAccount } from './useExAccount';
import { usePermissions } from './usePermissions';
import { useUser } from './useUser';

export function checkRoleAndPermission(
  item: NavigationLink,
  user: User,
  account: Account,
  userPermissions: Permission,
) {
  return [
    { value: item.permission, valid: checkPermission(item.permission, userPermissions) },
    { value: item.role, valid: checkRole(user, item.role) },
    { value: item.accountType, valid: checkAccountType(account, item.accountType) },
  ]
    .filter(({ value }) => {
      if (!value) {
        return false;
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    })
    .every(({ valid }) => valid);
}

function checkPermissionRecursively(
  item: NavigationLink,
  user: User,
  account: Account,
  userPermissions: Permission,
): NavigationLink {
  return {
    ...item,
    items: ensureArray(item.items)
      .filter((item) => checkRoleAndPermission(item, user, account, userPermissions))
      .map((child) => {
        if (ensureArray(child.items).length) {
          return checkPermissionRecursively(child, user, account, userPermissions);
        }

        return child;
      }),
  } as NavigationLink;
}

function checkIfNavigationLinkIsValid(item: NavigationLink) {
  return item.items?.length > 0 || !!item.href || typeof item.onClick === 'function';
}

function checkIfNavigationLinkIsValidRecursively(item: NavigationLink): NavigationLink {
  return {
    ...item,
    items: ensureArray(item.items)
      .filter(checkIfNavigationLinkIsValid)
      .map((child) => {
        if (ensureArray(child.items).length) {
          return checkIfNavigationLinkIsValidRecursively(child);
        }

        return child;
      }),
  } as NavigationLink;
}

export function useNavigationLinks(): NavigationLink[] {
  const user = useUser();
  const account = useAccount();
  const isExAccount = useExAccount();
  const userPermissions = usePermissions();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(['routes']);

  const translateRoute = useCallback((key: string) => t(`routes:${key}`), []);

  const cxSettingsMenu = useMemo(
    () =>
      [
        {
          id: 'team',
          label: translateRoute('team'),
          icon: faUsers,
          items: [getRoute('Users'), getRoute('AuditLogs')],
        },
        {
          id: 'feedback',
          label: translateRoute('feedback'),
          icon: faCommentLines,
          items: [
            getRoute('ContactPolicy'),
            getRoute('TagGroups'),
            getRoute('QuickResponses'),
            getRoute('ReviewWidgets'),
            getRoute('KnowledgeBases'),
            getRoute('SchemaFields'),
          ],
        },
        {
          id: 'automation',
          label: translateRoute('automation'),
          icon: faGear,
          items: [
            getRoute('Escalations'),
            getRoute('AutomatedJobs'),
            getRoute('ScheduleJobs'),
            getRoute('EmailAutomations'),
          ],
        },
        {
          id: 'organizationalStructure',
          label: translateRoute('organizationalStructure'),
          icon: faSitemap,
          items: [getRoute('Units'), getRoute('TreeView'), getRoute('JourneyMaps')],
        },
        {
          id: 'accountSettings',
          label: translateRoute('accountSettings'),
          icon: faSliders,
          accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
          permission: 'settings.accountSettings',
          items: [
            getRoute('RolesAndPermissions'),
            getRoute('SmsProvider'),
            getRoute('MailProvider'),
            getRoute('FeedbackProvider'),
            getRoute('FtpAccount'),
            getRoute('RemoteDatabaseAccount'),
            getRoute('SamlConfiguration'),
            getRoute('SmtpConfiguration'),
            getRoute('IntelligentTags'),
            getRoute('ContentCategories'),
            getRoute('ReportTheme'),
          ],
        },
        {
          id: 'uberAdmin',
          label: translateRoute('uberAdmin'),
          icon: faKey,
          role: Role.UBER_ADMIN,
          items: [
            getRoute('PisanoMembers'),
            getRoute('ReportWizard'),
            getRoute('Accounts'),
            getRoute('AccountStatistics'),
            getRoute('Verticals'),
            getRoute('LdapConfiguration'),
          ],
        },
        {
          id: 'securitySettings',
          label: translateRoute('securitySettings'),
          icon: faCameraSecurity,
          permission: 'settings.securitySettings',
          onClick: () => {
            dispatch(changeSecuritySettingsModalVisibleAction(true));
          },
        },
      ] as NavigationLink[],
    [],
  );

  const exSettingsMenu = useMemo(
    () =>
      [
        {
          id: 'team',
          label: translateRoute('team'),
          icon: faUsers,
          items: [
            getRoute('Users'),
            getRoute('AuditLogs'),
            getRoute('ContactPolicy'),
            getRoute('TagGroups'),
            getRoute('QuickResponses'),
            getRoute('ReviewWidgets'),
            getRoute('KnowledgeBases'),
            getRoute('Escalations'),
            getRoute('AutomatedJobs'),
            getRoute('ScheduleJobs'),
            getRoute('EmailAutomations'),
            getRoute('Units'),
            getRoute('TreeView'),
            getRoute('JourneyMaps'),
          ],
        },
        {
          id: 'accountSettings',
          label: translateRoute('accountSettings'),
          icon: faSliders,
          accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
          permission: 'settings.accountSettings',
          items: [
            getRoute('RolesAndPermissions'),
            getRoute('SmsProvider'),
            getRoute('MailProvider'),
            getRoute('HrisProvider'),
            getRoute('FeedbackProvider'),
            getRoute('FtpAccount'),
            getRoute('RemoteDatabaseAccount'),
            getRoute('SamlConfiguration'),
            getRoute('SmtpConfiguration'),
            getRoute('IntelligentTags'),
            getRoute('ContentCategories'),
            getRoute('ReportTheme'),
          ],
        },
        {
          id: 'uberAdmin',
          label: translateRoute('uberAdmin'),
          icon: faKey,
          role: Role.UBER_ADMIN,
          items: [
            getRoute('PisanoMembers'),
            getRoute('ReportWizard'),
            getRoute('Accounts'),
            getRoute('AccountStatistics'),
            getRoute('Verticals'),
            getRoute('LdapConfiguration'),
            getRoute('SchemaFields'),
          ],
        },
        {
          id: 'securitySettings',
          label: translateRoute('securitySettings'),
          icon: faCameraSecurity,
          permission: 'settings.securitySettings',
          onClick: () => {
            dispatch(changeSecuritySettingsModalVisibleAction(true));
          },
        },
      ] as NavigationLink[],
    [],
  );

  return useMemo(
    () =>
      (
        [
          ...insertIfArray(isExAccount, [
            {
              icon: <CustomIconDashboard />,
              ...getRoute('EXDashboard'),
            },
            {
              icon: <CustomIconHeadSideHeadPhones />,
              ...getRoute('EXReports'),
            },
            {
              icon: faChartSimple,
              ...getRoute('NewReports'),
              hideFromNavigation: true,
            },
          ]),
          ...insertIfArray(!isExAccount, [
            {
              icon: faChartSimple,
              ...getRoute('Reports'),
            },
            {
              icon: faChartSimple,
              ...getRoute('NewReports'),
              hideFromNavigation: true,
            },
            {
              icon: <CustomIconHeadSideHeadPhones />,
              ...getRoute('Insights'),
            },
          ]),
          {
            id: 'catiMenu',
            icon: <CustomIconCati />,
            label: translateRoute('CATI'),
            items: [
              {
                icon: <CustomIconCati />,
                ...getRoute('Cati'),
              },
              {
                icon: <CustomIconCatiList />,
                ...getRoute('CatiList'),
              },
            ],
          },
          {
            icon: faMessageDots,
            ...getRoute('Inbox'),
          },
          {
            ...getRoute('Profile'),
            hideFromNavigation: true,
          },
          {
            icon: <CustomIconFlow />,
            ...getRoute('Flows'),
          },
          {
            icon: faChartNetwork,
            ...getRoute('Channels'),
          },
          {
            ...getRoute('Workflows'),
            icon: faDiagramProject,
            itemsHidden: true,
            items: [getRoute('WorkflowsRunHistory')],
          },
          ...insertIfArray(!isExAccount, [
            {
              ...getRoute('Contacts'),
              icon: <CustomIconContacts />,
              itemsHidden: true,
              items: [getRoute('Business'), getRoute('Segments'), getRoute('ContactsList')],
            },
          ]),
          ...insertIfArray(isExAccount, {
            ...getRoute('EXEmployees'),
            icon: faSitemap,
          }),
          {
            id: 'settings',
            icon: <CustomIconAdmin />,
            label: translateRoute('settings'),
            permission: 'settings',
            items: isExAccount ? exSettingsMenu : cxSettingsMenu,
          },
        ] as NavigationLink[]
      )
        // İlk seviyeyi filtrele
        .filter((link) => checkRoleAndPermission(link, user, account, userPermissions))
        // Kalan seviyeler içerisinde recursive filtrele
        .map((link) => checkPermissionRecursively(link, user, account, userPermissions))
        .filter(checkIfNavigationLinkIsValid)
        .map(checkIfNavigationLinkIsValidRecursively)
        .map((link) => {
          const addParentToNavigationLinksRecursively: (
            item: NavigationLink,
            parent: NavigationLink,
          ) => NavigationLink = (item: NavigationLink, parent: NavigationLink) => {
            return {
              ...item,
              items: ensureArray(item.items).map((child) => addParentToNavigationLinksRecursively(child, item)),
              parentNavigationLink: parent,
            } as NavigationLink;
          };

          return {
            ...link,
            items: link.items.map((child) => addParentToNavigationLinksRecursively(child, link)),
          } as NavigationLink;
        }),
    [i18n.language, user, userPermissions, isExAccount],
  );
}
