import { ReportChartGroupCompare, ReportChartGroupCompareConfig } from '@vision/ui/interfaces';
import { useMemo } from 'react';
import { useExAccount } from './useExAccount';

export function useReportChartCompareConfig(): ReportChartGroupCompareConfig[] {
  const isExAccount = useExAccount();

  const cxCompareConfig: ReportChartGroupCompareConfig[] = useMemo(
    () =>
      [
        {
          charts: [
            'trend-chart-1',
            'trend-chart-2',
            'trend-chart-3',
            'trend-chart-4',
            'bar-chart-1',
            'bar-chart-2',
            'bar-chart-3',
            'bar-chart-4',
            'bar-chart-5',
            'scatter-chart-1',
            'pie-chart-1',
            'pie-chart-2',
            'pie-chart-3',
            'table-chart-1',
            'heatmap-chart-1',
          ],
          measures: [
            'feedback-count',
            'feedback-nps',
            'feedback-csat',
            'feedback-sentiment-score',
            'feedback-weighted-score',
            'feedback-chat-score',
            'action-time-completion',
            'action-time-read',
            'action-time-response',
            'action-time-internal-note',
            'action-time-archived',
            'customer-count',
            'channel-metric',
          ],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'user',
              items: ['user-assigned', 'user-archiving', 'user-responsible'],
            },
            {
              type: 'flow',
              items: ['flow-name'],
            },
            {
              type: 'customer',
              items: ['customer-company', 'customer-schema'],
            },
            {
              type: 'question',
              items: [
                'question-title',
                'question-option',
                'question-response',
                'question-key',
                'question-category-name',
              ],
            },
            {
              type: 'tag',
              items: ['tag-name', 'tag-group', 'tag-intelligent'],
            },
            {
              type: 'time-period',
              items: ['time-period-by-hour', 'time-period-by-day', 'time-period-by-month'],
            },
            {
              type: 'channel',
              items: ['channel-name'],
            },
            {
              type: 'category',
              items: ['category-content-name'],
            },
            {
              type: 'feedback',
              items: ['feedback-status'],
            },
          ],
        },
        {
          charts: [
            'trend-chart-1',
            'trend-chart-2',
            'trend-chart-3',
            'trend-chart-4',
            'bar-chart-1',
            'bar-chart-2',
            'bar-chart-3',
            'bar-chart-4',
            'bar-chart-5',
            'scatter-chart-1',
            'pie-chart-1',
            'pie-chart-2',
            'pie-chart-3',
            'table-chart-1',
            'heatmap-chart-1',
          ],
          measures: ['response-count', 'response-ranking-overview'],
          compareItems: [
            {
              type: 'question',
              items: [
                'question-title',
                'question-option',
                'question-response',
                'question-key',
                'question-category-name',
              ],
            },
          ],
        },
        {
          charts: ['bar-chart-1', 'bar-chart-2', 'bar-chart-3', 'bar-chart-4', 'bar-chart-5'],
          measures: ['matrix-question-csat-overview'],
          compareItems: [] as ReportChartGroupCompare[],
        },
        {
          charts: ['radar-chart-1'],
          measures: ['feedback-nps', 'feedback-csat', 'nps-kpi', 'csat-kpi'],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'channel',
              items: ['channel-name'],
            },
          ],
        },
        {
          charts: ['tree-map-chart-1', 'tree-map-chart-2'],
          measures: [
            'feedback-count',
            'feedback-nps',
            'feedback-csat',
            'feedback-sentiment-score',
            'feedback-weighted-score',
            'feedback-chat-score',
            'customer-count',
            'response-count',
            'response-ranking-overview',
          ],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'category',
              items: ['category-content-name'],
            },
          ],
        },
        {
          charts: ['table-chart-2'],
          measures: [
            'matrix-question-csat-overview',
            'matrix-question-response-count',
            'overview-compared',
            'overview-distribution',
            'nps-realization-rate',
            'csat-realization-rate',
          ],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'channel',
              items: ['channel-name'],
            },
          ],
        },
        {
          charts: ['table-chart-1'],
          measures: ['nps-kpi', 'csat-kpi', 'nps-realization-rate', 'csat-realization-rate'],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'channel',
              items: ['channel-name'],
            },
          ],
        },
        {
          charts: ['geo-chart-1'],
          measures: [
            'feedback-count',
            'feedback-nps',
            'feedback-csat',
            'feedback-sentiment-score',
            'feedback-weighted-score',
            'feedback-chat-score',
            'action-time-completion',
            'action-time-read',
            'action-time-response',
            'action-time-internal-note',
            'action-time-archived',
            'customer-count',
            'channel-metric',
            'nps-realization-rate',
            'csat-realization-rate',
            'response-count',
            'response-ranking-overview',
          ],
          compareItems: [
            {
              type: 'geo',
              items: ['geo-world-by-continents', 'geo-world-by-countries', 'geo-world-by-region'],
            },
          ],
        },
      ] satisfies ReportChartGroupCompareConfig[],
    [],
  );

  const exCompareConfig: ReportChartGroupCompareConfig[] = useMemo(
    () =>
      [
        {
          charts: [
            'trend-chart-1',
            'trend-chart-2',
            'trend-chart-3',
            'trend-chart-4',
            'bar-chart-1',
            'bar-chart-2',
            'bar-chart-4',
            'bar-chart-5',
            'pie-chart-1',
            'pie-chart-2',
            'pie-chart-3',
          ],
          measures: ['feedback-count', 'feedback-nps', 'feedback-csat', 'feedback-weighted-score'],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'user',
              items: ['user-assigned', 'user-archiving', 'user-responsible'],
            },
            {
              type: 'flow',
              items: ['flow-name'],
            },
            {
              type: 'stage',
              items: ['stage-name'],
            },
            {
              type: 'department',
              items: ['department-name'],
            },
            {
              type: 'tag',
              items: ['tag-name', 'tag-group'],
            },
            {
              type: 'question',
              items: ['question-title', 'question-option', 'question-response'],
            },
          ],
        },
        {
          charts: [
            'trend-chart-1',
            'trend-chart-2',
            'trend-chart-3',
            'trend-chart-4',
            'bar-chart-1',
            'bar-chart-2',
            'bar-chart-4',
            'bar-chart-5',
          ],
          measures: ['feedback-elc-score', 'feedback-candidate-score'],
          compareItems: [
            {
              type: 'tag',
              items: ['tag-name', 'tag-group'],
            },
            {
              type: 'stage',
              items: ['stage-name'],
            },
            {
              type: 'department',
              items: ['department-name'],
            },
          ],
        },
        {
          charts: [
            'trend-chart-1',
            'trend-chart-2',
            'trend-chart-3',
            'trend-chart-4',
            'bar-chart-1',
            'bar-chart-2',
            'bar-chart-4',
            'bar-chart-5',
          ],
          measures: ['feedback-sentiment-score'],
          compareItems: [
            {
              type: 'unit',
              items: ['unit-name'],
            },
            {
              type: 'user',
              items: ['user-assigned', 'user-archiving', 'user-responsible'],
            },
            {
              type: 'flow',
              items: ['flow-name'],
            },
            {
              type: 'tag',
              items: ['tag-name', 'tag-group'],
            },
            {
              type: 'question',
              items: ['question-response'],
            },
          ],
        },
      ] as ReportChartGroupCompareConfig[],
    [],
  );

  return isExAccount ? exCompareConfig : cxCompareConfig;
}
