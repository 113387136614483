import { VisualMapComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { componentLoader, optionCreator } from '../../ECharts.utils';

export type VisualMapProps = EventHandlerProps & VisualMapComponentOption;
/**
 * @example
 * ```tsx
 * <VisualMap
 *    calculable={true}
 *    inRange={{
 *      color: ['red', 'yellow'],
 *    }}
 *    outOfRange={{
 *      color: ['red', 'white'],
 *    }}
 *    min={0}
 *    max={10000}
 *  />
 * ```
 */
export const VisualMap: EC<VisualMapProps> = () => <></>;

VisualMap.optionOf = optionCreator('visualMap');

VisualMap.loadModule = componentLoader(['VisualMapComponent']);
