import { faAnglesUpDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Pill, PillsInput, PillsInputProps, Popover, Tooltip } from '@mantine/core';
import { useDisclosure, useInputState, useTimeout } from '@mantine/hooks';
import { If } from '@vision/ui/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTreeSelectContext } from '../../TreeSelectContext';
import { TreeSelectAllSelectButton } from '../TreeSelectAllSelectButton';
import { TreeSelectItem } from '../TreeSelectItem';
import classes from './TreeSelectItemRenderer.module.scss';

const MAX_VISIBLE_ITEMS = 2;

interface TreeSelectItemRendererProps {
  inputProps?: Omit<PillsInputProps, 'data-testid'> & {
    placeholder?: string;
  };
  withDropdown?: boolean;
  showSelectedItemsOnTop?: boolean;
  withSelectAll?: boolean;
}

export function TreeSelectItemRenderer({
  inputProps,
  withDropdown,
  showSelectedItemsOnTop,
  withSelectAll = true,
}: TreeSelectItemRendererProps) {
  const { t } = useTranslation();
  const [opened, handlers] = useDisclosure(false);
  const [searchText, setSearchText] = useInputState('');
  const { handleSearchTree, treeSelectItems, selectedTreeSelectItems } = useTreeSelectContext();
  const clearSearchText = useTimeout(() => setSearchText(''), 200);

  const filteredTreeSelectItems = useMemo(() => {
    if (searchText) {
      return handleSearchTree(searchText);
    }

    return treeSelectItems;
  }, [treeSelectItems, searchText]);

  const items = useMemo(() => {
    if (selectedTreeSelectItems.length > 0) {
      return filteredTreeSelectItems
        .reduce((acc, item) => {
          const selected = selectedTreeSelectItems.some((selectedItem) => item.id === selectedItem.id);

          if (showSelectedItemsOnTop && selected) {
            return [item, ...acc];
          }

          return [...acc, item];
        }, [])
        .map((item) => <TreeSelectItem key={item.id} treeSelectItem={item} />);
    }

    return filteredTreeSelectItems.map((item) => <TreeSelectItem key={item.id} treeSelectItem={item} />);
  }, [filteredTreeSelectItems, selectedTreeSelectItems, searchText]);

  const visibleSelectedTreeSelectItems = useMemo(() => {
    if (selectedTreeSelectItems.length > MAX_VISIBLE_ITEMS) {
      return selectedTreeSelectItems.slice(0, MAX_VISIBLE_ITEMS);
    }
    return selectedTreeSelectItems;
  }, [selectedTreeSelectItems]);

  const hiddenSelectedTreeSelectItems = useMemo(() => {
    if (selectedTreeSelectItems.length > MAX_VISIBLE_ITEMS) {
      return selectedTreeSelectItems.slice(MAX_VISIBLE_ITEMS);
    }
    return [];
  }, [selectedTreeSelectItems]);

  const hiddenSelectedTreeSelectItemsLabels = useMemo(() => {
    return hiddenSelectedTreeSelectItems.map((item) => item.label).join(', ');
  }, [hiddenSelectedTreeSelectItems]);

  const handleOnClose = () => {
    handlers.close();
    clearSearchText.start();
  };

  if (withDropdown) {
    return (
      <Popover
        opened={opened}
        onChange={handleOnClose}
        onOpen={handlers.open}
        classNames={{
          dropdown: classes.treeSelectDropdown,
        }}
        width="target"
        withArrow={false}
      >
        <Popover.Target>
          <PillsInput
            data-testid="tree-select-input"
            onClick={handlers.open}
            w="100%"
            rightSection={<FontAwesomeIcon icon={faAnglesUpDown} />}
            {...inputProps}
          >
            <Pill.Group>
              {visibleSelectedTreeSelectItems.map((item) => (
                <Pill key={item.id}>{item.label}</Pill>
              ))}
              {selectedTreeSelectItems.length > MAX_VISIBLE_ITEMS && (
                <Tooltip label={hiddenSelectedTreeSelectItemsLabels}>
                  <Pill>+{hiddenSelectedTreeSelectItems.length}</Pill>
                </Tooltip>
              )}
              <PillsInput.Field
                placeholder={inputProps?.placeholder || t('select')}
                value={searchText}
                onChange={setSearchText}
              />
            </Pill.Group>
          </PillsInput>
        </Popover.Target>

        <Popover.Dropdown>
          <If value={withSelectAll && !searchText.length}>
            <>
              <TreeSelectAllSelectButton />
              <Divider my={10} />
            </>
          </If>

          {items}
        </Popover.Dropdown>
      </Popover>
    );
  }

  return <Box>{items}</Box>;
}
