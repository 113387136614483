import { YAXisComponentOption } from 'echarts';
import { EC, EventHandlerProps } from '../../ECharts.types';
import { componentLoader, optionCreator } from '../../ECharts.utils';

export type YAxisProps = EventHandlerProps & YAXisComponentOption;
/**
 * @example
 * ```tsx
 * <YAxis />
 * ```
 */
export const YAxis: EC<YAxisProps> = () => <></>;

YAxis.optionOf = optionCreator('yAxis');

YAxis.loadModule = componentLoader(['GridComponent']);
