import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { chartLoader, seriesOptionCreator } from '../../ECharts.utils';

export type PieSeriesProps = SeriesProps<RegisteredSeriesOption['pie']>;
/**
 * @example
 * ```tsx
 * <PieSeries data={[
 *     { value: 1048, name: 'Search Engine' },
 *     { value: 735, name: 'Direct' },
 *     { value: 580, name: 'Email' },
 *     { value: 484, name: 'Union Ads' },
 *     { value: 300, name: 'Video Ads' }
 * ]} />
 * ```
 */
export const PieSeries: EC<PieSeriesProps> = () => <></>;

PieSeries.optionOf = seriesOptionCreator('pie');

PieSeries.loadModule = chartLoader(['PieChart']);
