import React from 'react';

interface CustomIconPieChart2Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconPieChart2({ height, width, active, ...props }: CustomIconPieChart2Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <mask
        id="mask0_11465_22114"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="16"
        height="16"
      >
        <path
          d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11465_22114)">
        <path
          d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
          fill={active ? '#0D7BFF' : '#AAAFC9'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.0061V12.0001H20.9975C20.8679 9.26677 20.0341 7.09262 18.4959 5.4776C16.9577 3.86259 14.7924 3.03876 12 3.0061Z"
          fill="white"
          fillOpacity="0.5"
        />
      </g>
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12L12 12L12 15C12.9117 14.9568 13.6369 14.6788 14.1756 14.1659C14.7143 13.653 14.9891 12.9311 15 12Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
