import { useMemo } from 'react';

export interface UppercaseProps {
  value: string;
}

export function Uppercase({ value }: UppercaseProps) {
  const transformed = useMemo(() => value?.toLocaleUpperCase(), [value]);

  if (!value) {
    return null;
  }

  return transformed;
}
