import { ReportChartFilterConfig } from '@vision/ui/interfaces';

export const BarChart5Filtres: ReportChartFilterConfig[] = [
  {
    measures: [
      'feedback-count',
      'feedback-nps',
      'feedback-csat',
      'feedback-sentiment-score',
      'feedback-weighted-score',
      'feedback-chat-score',
      'action-time-completion',
      'action-time-read',
      'action-time-response',
      'action-time-internal-note',
      'action-time-archived',
      'customer-count',
      'channel-metric',
    ],
    filterItems: [
      'channel-types',
      'channels',
      'flows',
      'questions',
      'question-options',
      'assigned-users',
      'feedback-status',
      'intelligent-tags',
      'languages',
      'only-negative-feedback',
      'only-feedback-with-comments',
      'only-feedback-with-contact-info',
      'tags',
      'tag-groups',
      'unit-types',
      'units',
      'spam-feedback',
      'content-category',
    ],
  },
  {
    measures: ['matrix-question-csat-overview'],
    filterItems: [
      'channel-types',
      'channels',
      'question-options',
      'assigned-users',
      'feedback-status',
      'intelligent-tags',
      'languages',
      'only-negative-feedback',
      'only-feedback-with-comments',
      'only-feedback-with-contact-info',
      'tags',
      'tag-groups',
      'unit-types',
      'units',
      'spam-feedback',
      'content-category',
    ],
  },
];
