import { flexRender, Row } from '@tanstack/react-table';
import { DataGridRowExpansion, useRowExpansion } from '@vision/ui/components';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { If } from '../../../If';
import { DataGridRowDataId } from '../../DataGridSimple';
import classes from './DataGridRow.module.scss';

interface DataGridRowProps<TData> {
  ellipsis: boolean;
  expansion: ReturnType<typeof useRowExpansion<TData>>;
  isColumnResizing?: boolean;
  onRowClick?: (data: TData) => void;
  row: Row<TData>;
  rowDataId?: keyof TData | DataGridRowDataId<TData>;
  rowExpansionLazy?: boolean;
  isActiveCursor?: boolean;
}

function _DataGridRow<TData>({
  ellipsis,
  expansion,
  isColumnResizing,
  onRowClick,
  row,
  rowDataId,
  rowExpansionLazy,
  isActiveCursor,
}: DataGridRowProps<TData>) {
  const rowId = useMemo(() => {
    if (!rowDataId) {
      return '';
    }
    if (typeof rowDataId === 'function') {
      return `-${rowDataId?.(row.original)}`;
    }
    return `-${String(row.original[rowDataId])}`;
  }, [rowDataId, row]);

  const handleRowClick = () => {
    onRowClick?.(row.original);

    if (expansion.enabled) {
      const { isRowExpanded, expandOnClick, expandRow, collapseRow } = expansion;
      if (expandOnClick) {
        if (isRowExpanded(row.original)) {
          collapseRow(row);
        } else {
          expandRow(row.original);
        }
      }
    }
  };

  const rowCells = useMemo(() => {
    return row.getVisibleCells().map((cell) => (
      <td
        className={classes.dataGridRowColumn}
        data-testid={`section-data-grid-table-row-cell-${cell.id}`}
        id={cell.id}
        key={cell.id}
      >
        <div
          className={clsx(classes.dataGridRowCell, {
            [classes.ellipsis]: ellipsis,
          })}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      </td>
    ));
  }, [row, isColumnResizing]);

  return (
    <>
      <tr
        data-testid={`section-data-grid-table-row${rowId}`}
        className={clsx(classes.dataGridRow, {
          [classes.cursor]: expansion.enabled || isActiveCursor,
        })}
        role="row"
        onClick={handleRowClick}
        data-expanded={(expansion.enabled && expansion?.isRowExpanded(row.original)) || null}
      >
        {rowCells}
      </tr>
      <If value={expansion.enabled}>
        <DataGridRowExpansion
          className={classes.dataGridRow}
          open={expansion?.isRowExpanded(row.original)}
          content={expansion?.content(row)}
          collapseProps={expansion?.collapseProps}
          rowExpansionLazy={rowExpansionLazy}
        />
      </If>
    </>
  );
}

export const DataGridRow = React.memo(_DataGridRow) as typeof _DataGridRow;
