import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { BarChart5CompareRenderer } from '../../../components';
import { DefaultReportChartThemeFont, extendDefaultChartValidationSchema } from '../../default-chart.config';
import { ExBarChart5Measures } from './ex-bar-chart5.measures';

export const ExBarChart5DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: ExBarChart5Measures,
  compareRendererComponent: BarChart5CompareRenderer,
  compareMultiple: true,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    sortResults: {
      limit: 0,
      order: null,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: false,
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      labelDisplayFormat: 'number',
      position: 'bottom',
    },
    series: {
      customize: false,
      items: [],
    },
  },
};
