import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ThemeIcon } from '@mantine/core';
import { areArraysEqualInOrder } from '@vision/ui/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTreeSelectContext } from '../../TreeSelectContext';
import classes from './TreeSelectAllSelectButton.module.scss';

export function TreeSelectAllSelectButton() {
  const { t } = useTranslation();
  const { treeSelectItems, selectedTreeSelectItems, handleToggleSelectAllMainCategories } = useTreeSelectContext();

  const isSelectedAllMainCategories = useMemo(() => {
    if (treeSelectItems.length === 0 || selectedTreeSelectItems.length === 0) {
      return false;
    }

    return areArraysEqualInOrder(
      treeSelectItems
        .filter((item) => item.parentId === '0' || item.parentId === null)
        .map((item) => item.id)
        .sort((a, b) => a.localeCompare(b)),
      selectedTreeSelectItems
        .filter((item) => item.parentId === '0' || item.parentId === null)
        .map((item) => item.id)
        .sort((a, b) => a.localeCompare(b)),
    );
  }, [treeSelectItems, selectedTreeSelectItems]);

  return (
    <Box className={classes.optionsSelectAllBtnWrapperRoot}>
      <Button
        data-testid="button-select-all"
        classNames={{
          root: classes.optionsSelectAllBtn,
          section: classes.optionsSelectAllBtnSection,
        }}
        variant="subtle"
        color="gray"
        size="compact-xs"
        fullWidth={true}
        justify="left"
        px={0}
        leftSection={
          <ThemeIcon className={classes.optionsSelectAllBtnIcon} variant="transparent" size={20} color="gray">
            <FontAwesomeIcon icon={faCheck} fontSize={14} />
          </ThemeIcon>
        }
        onClick={handleToggleSelectAllMainCategories}
      >
        {t(isSelectedAllMainCategories ? 'deselectAllMainCategories' : 'selectAllMainCategories')}
      </Button>
    </Box>
  );
}
