import { ComboboxParsedItemGroup, Stack } from '@mantine/core';
import { VSelect } from '@vision/ui/components';
import { useAppSelector, useReportCombinationMulti } from '@vision/ui/hooks';
import {
  ReportChartGroupCompare,
  ReportChartGroupCompareConfig,
  ReportChartMeasureType,
  ReportsDetailGraphicSchemaValues,
} from '@vision/ui/interfaces';
import { convertToSelectGroupItems } from '@vision/ui/pages/ReportsDetailGraphic/utils';
import { selectedChartGroupItemSelector } from '@vision/ui/store';
import { ensureArray } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RadarChart1Measures } from '../../../../constants/radar-chart1';
import { ReportsDetailGraphicConfigurationValueCompare } from '../../../ReportsDetailGraphicConfigurationValue';
import { ReportsDetailGraphicConfigurationValueHeader } from '../../../ReportsDetailGraphicConfigurationValueHeader';

export function RadarChart1ValueComponent() {
  const { t } = useTranslation(['translation', 'page-reports']);
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const selectedChartGroupItem = useAppSelector(selectedChartGroupItemSelector);

  const reportCombination: ReportChartGroupCompareConfig[] = useReportCombinationMulti(
    selectedChartGroupItem?.type,
    Array.from(new Set(ensureArray(formik.values.selectedMultipleMeasure))),
  );

  const compareSelectItems: ComboboxParsedItemGroup[] = useMemo(() => {
    if (!reportCombination) {
      return [];
    }

    const allCompareItems: ReportChartGroupCompare[] = reportCombination.reduce((acc, curr) => {
      return [...acc, ...curr.compareItems];
    }, []);

    // We group allCompareItems by type, combine the items in each group and return them.
    const allCompareItemsGrouped = allCompareItems.reduce((acc, curr) => {
      const existingGroup = acc.find((item) => item.type === curr.type);
      if (existingGroup) {
        // Combine the items in the group. but same items should not be duplicated.
        existingGroup.items = Array.from(new Set([...existingGroup.items, ...curr.items]));
      } else {
        acc.push({ type: curr.type, items: [...curr.items] });
      }
      return acc;
    }, [] as ReportChartGroupCompare[]);

    const commonItems = allCompareItemsGrouped.filter((item) => {
      const existAll = reportCombination.every((r) => r.compareItems.some((i) => i.type === item.type));
      return existAll;
    });

    return convertToSelectGroupItems(commonItems, 'reportCompareGroupType', 'reportCompareGroupItemType');
  }, [reportCombination]);

  const measurementSelectItems = useMemo(() => {
    if (!selectedChartGroupItem) {
      return [];
    }

    return convertToSelectGroupItems(
      RadarChart1Measures,
      'reportMeasurementsGroupType',
      'reportMeasurementsGroupItemType',
    );
  }, [selectedChartGroupItem]);

  const handleOnMeasureChange = (value: ReportChartMeasureType, index: number) => {
    const newMeasures: ReportChartMeasureType[] = [
      ...formik.values.selectedMultipleMeasure.slice(0, index),
      value,
      ...formik.values.selectedMultipleMeasure.slice(index + 1),
    ].filter(Boolean);

    formik.setFieldValue('selectedMultipleMeasure', newMeasures.slice(0, 2));
  };

  const measurement1SelectItems = useMemo(() => {
    const selectedSecondMeasure = formik.values.selectedMultipleMeasure[1];

    if (selectedSecondMeasure) {
      return measurementSelectItems
        .map((group) => {
          return {
            ...group,
            items: group.items.filter((item) => item.value !== selectedSecondMeasure),
          };
        })
        .filter((group) => group.items.length > 0);
    }

    return measurementSelectItems;
  }, [formik.values.selectedMultipleMeasure[1]]);

  const measurement2SelectItems = useMemo(() => {
    const selectedFirstMeasure = formik.values.selectedMultipleMeasure[0];

    if (selectedFirstMeasure) {
      return measurementSelectItems
        .map((group) => {
          return {
            ...group,
            items: group.items.filter((item) => item.value !== selectedFirstMeasure),
          };
        })
        .filter((group) => group.items.length > 0);
    }

    return measurementSelectItems;
  }, [formik.values.selectedMultipleMeasure[0]]);

  return (
    <Stack gap={20}>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader label={t('page-reports:measurement') + ' 1'} />

        <VSelect
          data-testid="input-funnel-second-measure"
          id="input-funnel-second-measure"
          label={t('page-reports:measurement')}
          data={measurement1SelectItems}
          value={formik.values?.selectedMultipleMeasure?.[0] || null}
          onChange={(value) => handleOnMeasureChange(value as ReportChartMeasureType, 0)}
          withinPortal={true}
          withAsterisk={true}
          w="100%"
        />
      </Stack>
      <Stack gap={10}>
        <ReportsDetailGraphicConfigurationValueHeader label={t('page-reports:measurement') + ' 2'} />

        <VSelect
          data-testid="input-funnel-second-measure"
          id="input-funnel-second-measure"
          label={t('page-reports:measurement')}
          disabled={!formik.values.selectedMultipleMeasure?.length}
          withinPortal={true}
          withAsterisk={true}
          value={formik.values?.selectedMultipleMeasure?.[1] || null}
          onChange={(value) => handleOnMeasureChange(value as ReportChartMeasureType, 1)}
          data={measurement2SelectItems}
          clearable={true}
          w="100%"
        />
      </Stack>

      <ReportsDetailGraphicConfigurationValueCompare
        compareSelectItems={compareSelectItems}
        disabled={!formik.values.selectedMultipleMeasure?.length}
        showIcon={false}
        labelKey="page-reports:compare"
      />
    </Stack>
  );
}
