import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonProps, createPolymorphicComponent, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

type FilterAddButtonProps = ButtonProps &
  Partial<{
    count: number;
  }>;

const _FilterAddButton = React.forwardRef<HTMLButtonElement, FilterAddButtonProps>(
  ({ count, children, ...props }: React.PropsWithChildren<FilterAddButtonProps>, ref) => {
    const theme = useMantineTheme();

    return (
      <Button
        ref={ref}
        leftSection={<FontAwesomeIcon icon={faFilter} color={theme.colors.gray[5]} />}
        rightSection={
          count > 0 ? (
            <Badge radius="sm" variant="filled" w={20} h={20} p={0} fz={8}>
              {count}
            </Badge>
          ) : null
        }
        color="light"
        size="xs"
        {...props}
      >
        <Text c="gray" component="span" lh="unset">
          {children}
        </Text>
      </Button>
    );
  },
);

export const FilterAddButton = createPolymorphicComponent<'button', FilterAddButtonProps>(_FilterAddButton);
