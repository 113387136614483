import { ReportChartConfiguration, ReportChartFilterItemTypes, ReportChartMeasureType } from '@vision/ui/interfaces';
import { array, object, string, TestContext } from 'yup';
import { BarChart5CompareRenderer, BarChart5MeasureRenderer } from '../../components';
import {
  DefaultReportChartCompareSchema,
  DefaultReportChartThemeFont,
  extendDefaultChartValidationSchema,
} from '../default-chart.config';
import { BarChart5Filtres } from './bar-chart5.filtre';
import { BarChart5Measures } from './bar-chart5.measures';

export const BarChart5DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema(
    {
      selectedCompareCriteria: object().shape({
        ...DefaultReportChartCompareSchema,
      }),
      selectedCompare: array()
        .of(string())
        .when('selectedMeasure', {
          is: (value: string) => !['matrix-question-csat-overview'].includes(value),
          then: (field) => field.min(1, 'validation:rules.generic.required'),
        }),
      selectedFilters: object().shape({
        selectedQuestionIds: array()
          .of(string())
          .test(
            'selectedCompareCriteria',
            'validation:rules.generic.required',
            function (value: any, context: TestContext) {
              const selectedMeasure = context.from[1]?.value['selectedMeasure'];
              if (['matrix-question-csat-overview'].includes(selectedMeasure) && value.length === 0) {
                return false;
              }
              return true;
            },
          ),
      }),
    },
    false,
  ),
  measures: BarChart5Measures,
  compareRendererComponent: BarChart5CompareRenderer,
  measureRendererComponent: BarChart5MeasureRenderer,
  compareMultiple: true,
  hideCompareCondition: (values) => ['matrix-question-csat-overview'].includes(values.selectedMeasure),
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    transactionalDate: {
      active: false,
    },
    sortResults: {
      limit: 0,
      order: null,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  filters: (measureType: ReportChartMeasureType) => {
    for (const item of BarChart5Filtres) {
      if (item.measures.includes(measureType)) {
        return item.filterItems;
      }
    }
    return ReportChartFilterItemTypes;
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: false,
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      labelDisplayFormat: 'number',
      position: 'outside',
    },
    series: {
      customize: false,
      items: [],
    },
  },
};
