import React from 'react';

interface CustomIconScatterChart1Props extends React.SVGProps<SVGSVGElement> {
  active?: boolean;
}

export function CustomIconScatterChart1({ height, width, active, ...props }: CustomIconScatterChart1Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 20L4 5H7L7 20H4Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17L20 17V20L4 20L4 17Z"
        fill={active ? '#0D7BFF' : '#E4E4EB'}
        fillOpacity={active ? 0.5 : 1}
      />
      <path
        d="M11 16C12.1046 16 13 15.1046 13 14C13 12.8954 12.1046 12 11 12C9.89543 12 9 12.8954 9 14C9 15.1046 9.89543 16 11 16Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        d="M17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
      <path
        d="M12.5 9C13.3284 9 14 8.32843 14 7.5C14 6.67157 13.3284 6 12.5 6C11.6716 6 11 6.67157 11 7.5C11 8.32843 11.6716 9 12.5 9Z"
        fill={active ? '#0D7BFF' : '#AAAFC9'}
      />
    </svg>
  );
}
