import { forwardRef } from 'react';
import { Box, createPolymorphicComponent, Radio, RadioProps } from '@mantine/core';
import classes from './RadioButton.module.scss';

interface RadioButtonProps extends RadioProps {
  grow?: boolean;
}

const _RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(({ grow, ...props }, ref) => (
  <Box className={classes.radioButton} data-grow={grow}>
    <Radio ref={ref} {...props} />
  </Box>
));

export const RadioButton = createPolymorphicComponent<'input', RadioButtonProps>(_RadioButton);
