import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PERMISSIONS, transformPermissions } from '@vision/ui/constants';
import i18n, { SUPPORTED_LANGUAGES } from '@vision/ui/i18n';
import { Nullable, Permission, User, UserWithOtp } from '@vision/ui/interfaces';
import { AccountService, AuthService } from '@vision/ui/services';
import { ensureArray, mergeDeep, selectDefaultAssignedNode } from '@vision/ui/utils';

function updateCurrentUserForClojureApp(state: AuthStateType) {
  // Clojure app "currentUser" değerini okuyarak authentication yapıyor
  localStorage.setItem(
    'currentUser',
    JSON.stringify({ ...state.user, selectedAccountId: state.selectedAccountId } as User),
  );
}

function changeLanguageByUserLang(languageCode: string) {
  const userLanguage = languageCode.toLowerCase();
  const supported = SUPPORTED_LANGUAGES.some((lang) => lang === userLanguage);
  if (supported) {
    i18n.changeLanguage(userLanguage);
  }
}

function patchAuthentication(state: AuthStateType, payload: User) {
  const selectedAccountId = state.selectedAccountId || selectDefaultAssignedNode(payload).id;

  state.id = payload.id;
  state.token = payload.token;
  state.user = payload;
  state.permissions = transformPermissions(payload.permissions);
  state.selectedAccountId = selectedAccountId;
  state.otpUserInfo = null;

  updateCurrentUserForClojureApp(state);
  changeLanguageByUserLang(payload.language.code);
}

export interface AuthStateType {
  id: Nullable<string>;
  otpUserInfo: {
    email: string;
    password: string;
  };
  permissions: Permission;
  selectedAccountId: string;
  token: Nullable<string>;
  user: User;
  securitySettingsModalVisible?: boolean;
  showReportPageAlertMessage?: boolean;
}

const initialState: AuthStateType = {
  id: null,
  otpUserInfo: null,
  permissions: DEFAULT_PERMISSIONS,
  selectedAccountId: null,
  token: null,
  user: {} as User,
  showReportPageAlertMessage: false,
};

export const AuthState = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    logoutAction: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem('currentUser');
    },

    updateOtpUserInfoAction: (state, action: PayloadAction<{ email: string; password: string }>) => {
      state.otpUserInfo = action.payload;
    },

    changeSelectedAccountAction: (state, { payload: id }: PayloadAction<string>) => {
      const node = ensureArray(state.user.assigned_nodes).find((item) => item.id === id);
      if (node) {
        state.selectedAccountId = id;
        state.user.node = node;

        updateCurrentUserForClojureApp(state);
      }
    },
    changeSecuritySettingsModalVisibleAction: (state, { payload: visible }: PayloadAction<boolean>) => {
      state.securitySettingsModalVisible = visible;
    },

    setShowPageTopMessageAction: (state, { payload: visible }: PayloadAction<boolean>) => {
      state.showReportPageAlertMessage = visible;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(AuthService.endpoints.login.matchFulfilled, (state, { payload }) => {
      if ((payload as UserWithOtp).otp_required) {
        return;
      }
      patchAuthentication(state, payload as User);
    });
    builder.addMatcher(AuthService.endpoints.authenticate.matchFulfilled, (state, { payload }) => {
      patchAuthentication(state, payload);
    });
    builder.addMatcher(AuthService.endpoints.validateOtp.matchFulfilled, (state, { payload }) => {
      patchAuthentication(state, payload);
    });
    builder.addMatcher(AccountService.endpoints.accountCompleteLogin.matchFulfilled, (state, { payload }) => {
      patchAuthentication(state, payload);
    });
    builder.addMatcher(AuthService.endpoints.updateAuthUser.matchFulfilled, (state, { payload }) => {
      state.user = mergeDeep(state.user, payload);
    });
    builder.addMatcher(AuthService.endpoints.loginAsUser.matchFulfilled, (state, { payload }) => {
      patchAuthentication(state, payload as User);
    });
    builder.addMatcher(AuthService.endpoints.changePassword.matchFulfilled, (state, { payload }) => {
      patchAuthentication(state, payload as User);
    });
    builder.addMatcher(AuthService.endpoints.uploadProfilePicture.matchFulfilled, (state, { payload }) => {
      state.user.avatar = payload.avatar;
    });
    builder.addMatcher(AuthService.endpoints.deleteProfilePicture.matchFulfilled, (state, { payload }) => {
      state.user.avatar = payload.avatar;
    });
  },
});

export const {
  logoutAction,
  updateOtpUserInfoAction,
  changeSelectedAccountAction,
  changeSecuritySettingsModalVisibleAction,
  setShowPageTopMessageAction,
} = AuthState.actions;
