import { Flex, Input, Stack, Text } from '@mantine/core';
import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import { useUncontrolled } from '@mantine/hooks';
import { InputSizes } from '@vision/theme';
import { If, Lowercase } from '@vision/ui/components';
import { Nullable } from '@vision/ui/interfaces';
import { useTranslation } from 'react-i18next';
import classes from './DatepickerWithStartEnd.module.scss';

type DateProps = Pick<
  DatePickerInputProps,
  'error' | 'disabled' | 'placeholder' | 'id' | 'name' | 'minDate' | 'maxDate'
> & {
  'data-testid'?: string;
};

export interface DatepickerWithStartEndValue {
  from: Date;
  to?: Nullable<Date>;
}

export interface DatepickerWithStartEndProps extends Pick<DatePickerInputProps, 'withAsterisk' | 'label' | 'size'> {
  clearEndWithStart?: boolean;
  endDateProps?: DateProps;
  inputWidth?: number;
  maxDate?: Date;
  minDate?: Date;
  onChange: (value: DatepickerWithStartEndValue) => void;
  startDateProps?: DateProps;
  value: DatepickerWithStartEndValue;
}

export function DatepickerWithStartEnd({
  clearEndWithStart = true,
  endDateProps,
  inputWidth,
  label,
  maxDate,
  minDate,
  onChange,
  size,
  startDateProps,
  value: valueProp,
  withAsterisk,
}: DatepickerWithStartEndProps) {
  const { t } = useTranslation();
  const [value, setValue] = useUncontrolled<DatepickerWithStartEndValue>({
    value: valueProp,
    defaultValue: {
      from: null,
      to: null,
    },
    onChange,
  });

  return (
    <Stack align="flex-start">
      <If value={!!label}>
        <Input.Label required={withAsterisk}>{label}</Input.Label>
      </If>
      <Flex className={classes.datepickerWithStartEnd}>
        <DatePickerInput
          {...startDateProps}
          data-testid="input-date-from"
          placeholder={startDateProps?.placeholder || t('startDate')}
          w={inputWidth || '100%'}
          minDate={minDate}
          maxDate={maxDate}
          value={value.from}
          onChange={(date) =>
            setValue({
              from: date,
              to: clearEndWithStart && !date ? null : value.to,
            })
          }
          size={size}
        />
        <Text
          className={classes.datepickerWithStartEndText}
          style={{
            height: InputSizes[size || 'sm'],
          }}
          c="gray"
        >
          <Lowercase value={t('and')} />
        </Text>
        <DatePickerInput
          {...endDateProps}
          data-testid="input-date-to"
          placeholder={endDateProps?.placeholder || t('endDate')}
          w={inputWidth || '100%'}
          minDate={endDateProps.minDate || minDate}
          maxDate={maxDate}
          value={value.to}
          onChange={(date) =>
            setValue({
              ...value,
              to: date,
            })
          }
          size={size}
        />
      </Flex>
    </Stack>
  );
}
