import { RegisteredSeriesOption } from 'echarts/types/dist/shared';
import { EC, SeriesProps } from '../../ECharts.types';
import { componentLoader, seriesOptionCreator } from '../../ECharts.utils';

export type BarSeriesProps = SeriesProps<RegisteredSeriesOption['bar']>;
/**
 * @example
 * ```tsx
 * <BarSeries data={[5, 20, 36, 10, 10, 20]} />
 * ```
 */
export const BarSeries: EC<BarSeriesProps> = () => <></>;

BarSeries.optionOf = seriesOptionCreator('bar', {
  labelLayout: { hideOverlap: true },
} as BarSeriesProps);

BarSeries.loadModule = async () => {
  const [{ BarChart }, { UniversalTransition }, components] = await Promise.all([
    import('echarts/charts'),
    import('echarts/features'),
    componentLoader(['DatasetComponent', 'TransformComponent'])(),
  ]);
  return [BarChart, UniversalTransition, ...(components as any[])];
};
