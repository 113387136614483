import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportChartPeriodAndValueComparison } from '../interfaces';
import { convertEnumToSelectItems } from '../utils';

const REPORT_CHART_PERIODS_AND_VALUE_COMPARISON: ReportChartPeriodAndValueComparison[] = [
  'yesterday-nps',
  'last-week-nps',
  'last-month-nps',
  'last-year-nps',
  'yesterday-count',
  'last-week-count',
  'last-month-count',
  'last-year-count',
  'yesterday-positivity',
  'last-week-positivity',
  'last-month-positivity',
];

export function useReportChartPeriodAndValueComparison() {
  const { i18n, t } = useTranslation('page-reports');

  return useMemo(() => {
    return convertEnumToSelectItems(REPORT_CHART_PERIODS_AND_VALUE_COMPARISON, (item) => ({
      label: t(`periodAndValueComparisonType.${item.value}`),
      value: item.value,
    }));
  }, [i18n.language]);
}
