import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, CustomerListItem } from '../interfaces';
import { axiosBaseQuery } from './base.service';
import { buildEndpointWithQuery } from '@vision/ui/utils';

export const CustomerListService = createApi({
  reducerPath: 'CustomerListService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCustomerLists: builder.query<ApiResponse<CustomerListItem[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/customer_lists`, query),
        method: HttpMethod.GET,
      }),
    }),
    deleteCustomerList: builder.mutation<void, { id: string; nodeId: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/customer_lists/${id}/delete`,
        method: HttpMethod.POST,
      }),
    }),
  }),
});

export const { useDeleteCustomerListMutation, useLazyGetCustomerListsQuery } = CustomerListService;
