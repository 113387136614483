import { PISANO_COLORS } from '@vision/theme';
import { ReportTimeIntervalType } from '@vision/ui/enums';
import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { TrendChart3CompareRenderer } from '../../../components';
import {
  DefaultReportChartThemeFont,
  DefaultReportChartThemeSeries,
  extendDefaultChartValidationSchema,
} from '../../default-chart.config';
import { EXTrendChart3Measures } from './ex-trend-chart3.measures';

export const EXTrendChart3DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema({}),
  measures: EXTrendChart3Measures,
  compareRendererComponent: TrendChart3CompareRenderer,
  customize: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    timeInterval: ReportTimeIntervalType.DAY,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
    referenceDefinition: {
      active: true,
      value: { name: '', score: null },
    },
  },
  defaultTheme: {
    active: (values) => values.selectedMeasure && values.selectedCompare.length > 0,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
      activeDisplayFormat: true,
      displayFormat: 'auto',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      displayFormat: 'auto',
      position: 'top',
    },
    serieDot: {
      active: false,
      symbolSize: 6,
      symbolType: 'circle',
      symbolFilling: 'full',
    },
    serieLine: {
      active: false,
      lineColor: PISANO_COLORS.dark[5],
      lineSize: 2,
      lineType: 'dashed',
    },
    series: {
      ...DefaultReportChartThemeSeries,
    },
  },
};
