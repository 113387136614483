export type ReportChartGroupCompareUnitType = 'unit-best' | 'unit-name' | 'unit-type' | 'unit-worst';

export type ReportChartGroupCompareUserType =
  | 'user-archiving'
  | 'user-assigned'
  | 'user-best-assigned'
  | 'user-responsible'
  | 'user-worst-assigned';

export type ReportChartGroupCompareFlowType = 'flow-id' | 'flow-name';

export type ReportChartGroupCompareCustomerType = 'customer-company' | 'customer-schema';

export type ReportChartGroupCompareQuestionType =
  | 'question-best'
  | 'question-option'
  | 'question-response'
  | 'question-title'
  | 'question-worst'
  | 'question-key'
  | 'question-category-name';

export type ReportChartGroupCompareTagType = 'tag-name' | 'tag-group' | 'tag-intelligent';

export type ReportChartGroupCompareGeoType =
  | 'geo-world-by-continents'
  | 'geo-world-by-countries'
  | 'geo-world-by-region';

export type ReportChartGroupCompareTimePeriodType =
  | 'time-period-best-day'
  | 'time-period-best-hour'
  | 'time-period-by-day'
  | 'time-period-by-hour'
  | 'time-period-by-month'
  | 'time-period-worst-day'
  | 'time-period-worst-hour';

export type ReportChartGroupCompareCategoryType = 'category-name' | 'category-content-name';

export type ReportChartGroupCompareChannelType = 'channel-name';

export type ReportChartGroupCompareFeedbackStatusType = 'feedback-status';

export type ReportChartGroupCompareComparedOverviewType =
  | 'compared-overview-csat-last-month'
  | 'compared-overview-csat-last-week'
  | 'compared-overview-csat-last-year'
  | 'compared-overview-csat-yesterday'
  | 'compared-overview-feedback-count-last-month'
  | 'compared-overview-feedback-count-last-week'
  | 'compared-overview-feedback-count-last-year'
  | 'compared-overview-feedback-count-yesterday'
  | 'compared-overview-nps-last-month'
  | 'compared-overview-nps-last-week'
  | 'compared-overview-nps-last-year'
  | 'compared-overview-nps-yesterday';

export type ReportChartGroupCompareStageType = 'stage-name';

export type ReportChartGroupCompareDepartmentType = 'department-name';

export type ReportChartGroupCompareItemType =
  | ReportChartGroupCompareCategoryType
  | ReportChartGroupCompareChannelType
  | ReportChartGroupCompareComparedOverviewType
  | ReportChartGroupCompareCustomerType
  | ReportChartGroupCompareUserType
  | ReportChartGroupCompareFlowType
  | ReportChartGroupCompareGeoType
  | ReportChartGroupCompareQuestionType
  | ReportChartGroupCompareTagType
  | ReportChartGroupCompareTimePeriodType
  | ReportChartGroupCompareUnitType
  | ReportChartGroupCompareFeedbackStatusType
  | ReportChartGroupCompareStageType
  | ReportChartGroupCompareDepartmentType;

export type ReportChartGroupCompareType =
  | 'category'
  | 'channel'
  | 'compared-overview'
  | 'customer'
  | 'flow'
  | 'geo'
  | 'question'
  | 'tag'
  | 'time-period'
  | 'user'
  | 'unit'
  | 'feedback'
  | 'stage'
  | 'department';

export interface ReportChartCompareItemTransformType {
  keyType: ReportChartGroupCompareItemType;
  compareKey: string[];
}

export const ReportChartCompareTransform: ReportChartCompareItemTransformType[] = [
  {
    keyType: 'user-assigned',
    compareKey: ['selectedUserAssignedIds'],
  },
  {
    keyType: 'user-archiving',
    compareKey: ['selectedUserArchivingIds'],
  },
  {
    keyType: 'user-responsible',
    compareKey: ['selectedUserResponsibleIds'],
  },
  {
    keyType: 'flow-name',
    compareKey: ['selectedFlowIds'],
  },
  {
    keyType: 'unit-name',
    compareKey: ['selectedUnitTypeIds', 'selectedUnitIds'],
  },
  { keyType: 'customer-company', compareKey: ['selectedCompanyIds'] },
  { keyType: 'customer-schema', compareKey: ['selectedSchemaCodes'] },
  { keyType: 'tag-name', compareKey: ['selectedTagTagGroupIds', 'selectedTagGroupNameIds'] },
  { keyType: 'tag-group', compareKey: ['selectedTagGroupIds'] },
  { keyType: 'tag-intelligent', compareKey: ['selectedIntelligentTagIds'] },
  {
    keyType: 'channel-name',
    compareKey: ['selectedChannelTypes', 'selectedChannelIds'],
  },
  {
    keyType: 'question-title',
    compareKey: ['selectedQuestionFlowIds', 'selectedQuestionIds'],
  },
  {
    keyType: 'question-option',
    compareKey: ['selectedQuestionOptionFlowIds', 'selectedQuestionOptionQuestionIds', 'selectedQuestionOptionIds'],
  },
  {
    keyType: 'question-response',
    compareKey: ['selectedQuestionResponseFlowIds', 'selectedQuestionResponseQuestionIds'],
  },
  {
    keyType: 'question-key',
    compareKey: ['selectedQuestionKeyFlowIds', 'selectedQuestionKeyIds'],
  },
  {
    keyType: 'question-category-name',
    compareKey: ['selectedQuestionCategoryFlowIds', 'selectedQuestionCategoryIds'],
  },
  {
    keyType: 'category-content-name',
    compareKey: ['selectedContentCategoryIds'],
  },
  {
    keyType: 'feedback-status',
    compareKey: ['selectedFeedbackStatus'],
  },
];
